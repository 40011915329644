function isDigit(str: string): boolean {
  return /^[0-9]+$/.test(str);
}

function filterDigits(str: string): string {
  return str.replace(/[^0-9]/g, '');
}

function insertHyphenByCount(str: string, counts: number[]): string {
  let result = '';
  let index = 0;

  for (const count of counts) {
    if (index + count < str.length) {
      result += str.slice(index, index + count) + '-';
      index += count;
    } else {
      result += str.slice(index);
      break;
    }
  }
  return result;
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.includes('-')) {
    return phoneNumber;
  }
  if (!isDigit(phoneNumber)) {
    phoneNumber = filterDigits(phoneNumber);
  }
  switch (phoneNumber.length) {
    case 12:
      return insertHyphenByCount(phoneNumber, [4, 4, 4]);
    case 11:
      return insertHyphenByCount(phoneNumber, [3, 4, 4]);
    case 10: {
      if (phoneNumber.startsWith('02')) {
        return insertHyphenByCount(phoneNumber, [2, 4, 4]);
      }
      return insertHyphenByCount(phoneNumber, [3, 3, 4]);
    }
    case 9:
      return insertHyphenByCount(phoneNumber, [2, 3, 4]);
    case 8:
      return insertHyphenByCount(phoneNumber, [4, 4]);
    case 7:
      return insertHyphenByCount(phoneNumber, [3, 4]);
    case 6:
      return insertHyphenByCount(phoneNumber, [3, 3]);
    default:
      return phoneNumber;
  }
}
