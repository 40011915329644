import { useState } from 'react';
import { Button, Modal } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { SwitchCase } from '@sweep/utils/react';
import {
  RecordItemStatus,
  SEND_EMAIL_STATUS,
} from 'src/stores/SendEmailWithPurchaseOrderFileStore';
import EmailSendFail from './EmailSendFail';
import EmailSendResultTabButton from './EmailSendResultTabButton';
import EmailSendSuccess from './EmailSendSuccess';

export function DoneRecordSection(props: {
  records: RecordItemStatus[];
  selectedRecordIds: string[];
  onSubmit: () => void;
}) {
  const { records, onSubmit } = props;
  const [selectedTab, setSelectedTab] = useState<'fail' | 'success'>('fail');

  const failedRecords = records.filter(
    (recordItemStatus) =>
      recordItemStatus.emailStatus === SEND_EMAIL_STATUS.ERROR
  );

  const successRecords = records.filter(
    (recordItemStatus) =>
      recordItemStatus.emailStatus === SEND_EMAIL_STATUS.SUCCESS
  );

  return (
    <>
      <Modal.Title>발주서 전송</Modal.Title>
      <Modal.Description>
        발주서 전송에 실패한 공급사가 있습니다. 별도 이메일 전송이 필요합니다.
      </Modal.Description>
      <div className="h-32px" />
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <EmailSendResultTabButton
            type="fail"
            isSelected={selectedTab === 'fail'}
            onClick={() => setSelectedTab('fail')}
            count={failedRecords.length}
          />
          <EmailSendResultTabButton
            type="success"
            isSelected={selectedTab === 'success'}
            onClick={() => setSelectedTab('success')}
            count={successRecords.length}
          />
        </div>
      </div>
      <div className={container()}>
        <SwitchCase
          value={selectedTab}
          caseBy={{
            fail: failedRecords.map((recordItemStatus) => {
              const { recordId, supplierName, filename, supplierEmail } =
                recordItemStatus;
              return (
                <EmailSendFail
                  key={recordId}
                  supplierName={supplierName}
                  filename={filename}
                  supplierEmail={supplierEmail}
                />
              );
            }),
            success: successRecords.map((recordItemStatus) => {
              const { recordId, supplierName, filename } = recordItemStatus;
              return (
                <EmailSendSuccess
                  key={recordId}
                  supplierName={supplierName}
                  filename={filename}
                />
              );
            }),
          }}
        />
      </div>
      <div className="flex justify-center gap-[12px] pt-[20px]">
        <Button onClick={onSubmit} className="w-[200px]">
          확인
        </Button>
      </div>
    </>
  );
}

const container = cva([
  'flex flex-col gap-[8px]',
  'h-[360px] overflow-y-scroll px-[32px] py-[24px]',
  'rounded-r-[8px] border border-gray-300 bg-gray-100',
]);
