import { action, makeObservable, observable, runInAction } from 'mobx';
import { CreatePartnerDTO } from 'models/Partner';
import {
  createPartner,
  deletePartner,
  getPartners,
  updatePartner,
} from 'network/partner';
import { CreatePluginDTO } from 'network/plugin';
import { Partner } from '@sweep/contract';
import { OMSStore } from './OMSStore';

export class PartnerStore {
  partners: Partner[] = [];
  private partnersMap = new Map<string, Partner>();

  getPartnerById(partnerId: string) {
    return this.partnersMap.get(partnerId);
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      partners: observable,

      init: action.bound,
      loadPartners: action.bound,

      register: action.bound,
      update: action.bound,
      _update: action.bound,
      delete: action.bound,

      createPlugin: action.bound,
      deletePlugin: action.bound,
    });
  }

  async init() {
    await this.loadPartners();
  }

  async loadPartners() {
    const response = await getPartners();
    const partners = response.result;
    if (partners == null) {
      return;
    }

    runInAction(() => {
      this.partners = partners;
      this.partnersMap = new Map(
        partners.map((partner) => [partner._id, partner])
      );
    });
  }

  async register(partner: CreatePartnerDTO) {
    const registeredPartner = await createPartner(partner);
    runInAction(() => {
      this.partners = [...this.partners, registeredPartner];
      this.partnersMap.set(registeredPartner._id, registeredPartner);
    });

    return registeredPartner;
  }

  async update(id: string, partner: Partial<Partner>) {
    this._update(id, partner);

    await updatePartner(id, partner);
  }

  async _update(id: string, partner: Partial<Partner>) {
    const prevPartner = this.partnersMap.get(id);
    if (prevPartner == null) {
      return;
    }

    const updatedPartner = { ...prevPartner, ...partner };
    this.partners = this.partners.map((partner) =>
      partner._id === id ? updatedPartner : partner
    );
    this.partnersMap.set(id, updatedPartner);
  }

  async delete(partnerId: string) {
    this.partners = this.partners.filter(
      (partner) => partner._id !== partnerId
    );
    this.partnersMap.delete(partnerId);

    await deletePartner(partnerId);
  }

  async createPlugin(partnerId: string, createPluginDTO: CreatePluginDTO) {
    const partner = await this.oms.plugin.createPartnerPlugin(
      partnerId,
      createPluginDTO
    );

    this._update(partnerId, { pluginIds: partner?.pluginIds });
  }

  async deletePlugin(partnerId: string, pluginId: string) {
    const prevPartner = this.partnersMap.get(partnerId);
    if (prevPartner == null) {
      return;
    }

    const pluginIds = prevPartner.pluginIds?.filter((id) => id !== pluginId);

    this._update(partnerId, { pluginIds });

    await this.oms.plugin.deletePartnerPlugin(partnerId, pluginId);
  }

  @action.bound
  async connectPartnershipUUID(partnerId: string, partnershipUUID: string) {
    this._update(partnerId, { partnershipUUID });
  }
}
