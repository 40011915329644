import { ButtonHTMLAttributes, ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'large';
  variant?: 'solid' | 'line';
  color: 'blue' | 'gray' | 'lightGray';
  children: ReactNode;
}

export function IconButton({
  size = 'large',
  variant = 'solid',
  color = 'gray',
  disabled,
  className,
  children,
  ...props
}: IconButtonProps) {
  return (
    <button
      className={button({ size, color, variant, className })}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}

const button = cva('flex-center flex shrink-0', {
  variants: {
    size: {
      large: 'size-40px rounded-8px',
    },
    color: {
      blue: '',
      gray: '',
      lightGray: '',
    },
    variant: {
      solid: 'text-white',
      line: 'border',
    },
  },
  compoundVariants: [
    // Size
    {
      size: 'large',
      variant: 'solid',
      className: 'size-40px',
    },
    {
      size: 'large',
      variant: 'line',
      className: 'size-38px',
    },
    // Color
    {
      color: 'blue',
      variant: 'solid',
      className: 'bg-blue-500',
    },
    {
      color: 'gray',
      variant: 'solid',
      className: 'bg-gray-500',
    },
    {
      color: 'lightGray',
      variant: 'solid',
      className: 'bg-gray-400',
    },
    {
      color: 'blue',
      variant: 'line',
      className: 'border-blue-500 text-blue-500',
    },
    {
      color: 'gray',
      variant: 'line',
      className: 'border-gray-300 text-gray-600',
    },
    {
      color: 'lightGray',
      variant: 'line',
      className: 'border-gray-300 text-gray-400',
    },
  ],
});
