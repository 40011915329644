import { DispatchedOrder, OrderStatus, ShoppingMall } from '@sweep/contract';
import { LocalStorageValue } from '@sweep/utils/mobx';
import { OrderFactory } from 'src/mocks/common/factory/OrderFactory';
import { randomShippingCompany } from 'src/mocks/common/random/randomShippingCompany';
import { randomShippingNumber } from 'src/mocks/common/random/randomShippingNumber';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../common/constants';

class DispatchHandlerStore {
  _shoppingMalls: ShoppingMall[] | null = null;
  _orders: DispatchedOrder[] | null = null;

  get shoppingMalls() {
    if (this._shoppingMalls === null) {
      this._shoppingMalls = getShoppingMallNames().map((name) => ({
        _id: name,
        name,
      }));
    }
    return this._shoppingMalls;
  }

  get orders() {
    if (this._orders === null) {
      this._orders = getOrders(this.shoppingMalls);
    }
    return this._orders;
  }

  constructor() {}

  getOrdersByShoppingMall(shoppingMall: string) {
    return this.orders.filter((order) => order.shoppingMall === shoppingMall);
  }
}

export type ShoppingMallListType = 'many' | 'some';
export const shoppingMallTypeStorage =
  new LocalStorageValue<ShoppingMallListType>({
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/shopping-mall-list-type`,
    defaultValue: 'some',
  });

export type DispatchedOrderType = 'many-stress' | 'many' | 'some';
export const dispatchedOrderTypeStorage =
  new LocalStorageValue<DispatchedOrderType>({
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/dispatched-order-type`,
    defaultValue: 'some',
  });

function getShoppingMallNames() {
  switch (shoppingMallTypeStorage.value) {
    case 'some':
      return SHOPPING_MALLS_SOME;
    case 'many':
      return SHOPPING_MALLS_MANY;
  }
}

const SHOPPING_MALLS_SOME = [
  'NAVER-1',
  'NAVER-2',
  'NAVER-3',
  'ALI-1',
  'ALI-2',
  '11번가-1',
  '올웨이즈-아주아주 무지막지 무지막지하게 긴 이름의 쇼핑몰 진짜진짜 엄청 김',
];

const SHOPPING_MALLS_MANY = [
  'NAVER-1',
  'NAVER-2',
  'NAVER-3',
  'AliExpress-1',
  'AliExpress-2',
  '11번가-1',
  '카페24-2',
  '카페24-3',
  '카페24-4',
  'Gmarket-1',
  'Gmarket-2',
  'Gmarket-3',
  'Gmarket-4',
  'Gmarket-5',
  'Gmarket-6',
  'Gmarket-7',
  'Gmarket-8',
];

function getOrders(shoppingMalls: ShoppingMall[]): DispatchedOrder[] {
  const [
    paymentCompleteCount,
    productPreparingCount,
    inDeliveryCount,
    cancelRequestCount,
    refundRequestCount,
  ] = getOrderCounts();

  return shoppingMalls
    .map((shoppingMall) => {
      const shoppingMallName = shoppingMall.name;
      const paymentCompleteOrders = Array.from(
        { length: paymentCompleteCount },
        (_, i) => OrderFactory.createDispatchedOrder(shoppingMallName, i)
      );

      const prevProductPreparingCount = paymentCompleteCount;
      const productPreparingOrders = Array.from(
        { length: productPreparingCount },
        (_, i) =>
          OrderFactory.createDispatchedOrder(
            shoppingMallName,
            i + prevProductPreparingCount,
            { orderStatus: OrderStatus.productPreparing }
          )
      );

      const prevInDeliveryCount = paymentCompleteCount + productPreparingCount;
      const inDeliveryOrders = Array.from({ length: inDeliveryCount }, (_, i) =>
        OrderFactory.createDispatchedOrder(
          shoppingMallName,
          i + prevInDeliveryCount,
          {
            orderStatus: OrderStatus.inDelivery,
            shippingCompany: randomShippingCompany(),
            shippingNumber: randomShippingNumber(),
          }
        )
      );

      const prevCancelRequestCount =
        paymentCompleteCount + productPreparingCount + inDeliveryCount;
      const cancelRequestOrders = Array.from(
        { length: cancelRequestCount },
        (_, i) =>
          OrderFactory.createDispatchedOrder(
            shoppingMallName,
            i + prevCancelRequestCount,
            { orderStatus: OrderStatus.cancelRequest }
          )
      );

      const prevRefundRequestCount =
        paymentCompleteCount +
        productPreparingCount +
        inDeliveryCount +
        cancelRequestCount;
      const refundRequestOrders = Array.from(
        { length: refundRequestCount },
        (_, i) =>
          OrderFactory.createDispatchedOrder(
            shoppingMallName,
            i + prevRefundRequestCount,
            { orderStatus: OrderStatus.refundRequest }
          )
      );

      return [
        ...paymentCompleteOrders,
        ...productPreparingOrders,
        ...inDeliveryOrders,
        ...cancelRequestOrders,
        ...refundRequestOrders,
      ];
    })
    .flat();
}

/**
 * @returns [결제완료, 상품준비중, 배송중, 취소요청, 기타]
 */
function getOrderCounts(): [number, number, number, number, number] {
  switch (dispatchedOrderTypeStorage.value) {
    case 'some':
      return [3, 1, 2, 1, 1];
    case 'many':
      return [10, 5, 5, 5, 5];
    case 'many-stress':
      return [800, 400, 600, 200, 100];
  }
}

export const dispatchStore = new DispatchHandlerStore();
