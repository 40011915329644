import { compressSync, decompressSync, strFromU8, strToU8 } from 'fflate';
import { DispatchedOrder } from '@sweep/contract';
import {
  LocalStorageNullableStringArrayValue,
  LocalStorageNullableStringValue,
  LocalStorageNumberRecordValue,
  LocalStorageValue,
} from '@sweep/utils/mobx';

export class LocalStore {
  lastUserId = new LocalStorageNullableStringValue({
    key: 'User#lastUserId',
    defaultValue: null,
  });
  userId = new LocalStorageNullableStringValue({
    key: 'User#userID',
    defaultValue: null,
  });
  authToken = new LocalStorageNullableStringValue({
    key: 'User#authToken',
    defaultValue: null,
  });

  shoppingMallDispatches = new LocalStorageNullableStringArrayValue({
    key: 'shoppingMallDispatches',
    defaultValue: null,
  });

  dispatchedOrders = new LocalStorageValue<DispatchedOrder[]>({
    key: 'dispatchedOrders',
    defaultValue: [],
    parse: parseOrders,
    stringify: stringifyOrders,
  });

  lastDispatchedAts = new LocalStorageNumberRecordValue({
    key: 'lastDispatchedAts',
    defaultValue: {},
  });

  reset = () => {
    this.shoppingMallDispatches.setValue(null);
    this.dispatchedOrders.setValue([]);
    this.lastDispatchedAts.setValue({});
  };
}

function stringifyOrders(orders: DispatchedOrder[]) {
  const encoded = encodeURI(JSON.stringify(orders));
  const u8 = strToU8(encoded, true);
  const compressed = compressSync(u8);
  return strFromU8(compressed, true);
}

function parseOrders(compressed: string) {
  const u8 = strToU8(compressed, true);
  const decompressed = decompressSync(u8);
  const decompressedString = strFromU8(decompressed, true);
  return JSON.parse(decodeURI(decompressedString)) as DispatchedOrder[];
}
