import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Partnership,
  USER_COMPANY_TYPE,
  UserCompanyType,
} from '@sweep/contract';
import { getTabGroups } from 'src/components/Sidebar/services/getTabGroups';
import { getTabGroupsForGuestRetailer } from 'src/components/Sidebar/services/getTabGroupsForGuestRetailer';
import { useOMSStore } from 'src/hooks/useOMSStore';
import OMSHeader from '../components/OMSHeader';
import Sidebar from '../components/Sidebar/Sidebar';
import useAuthentication from '../hooks/useAuthentication';
import UserStore from '../stores/UserStore';

function selectTabGroups({
  partnerships,
  companyType,
  isAdmin,
  cs,
}: {
  partnerships: Partnership[];
  companyType: UserCompanyType | '';
  isAdmin: boolean;
  cs: boolean;
}) {
  if (companyType === USER_COMPANY_TYPE.RETAILER_GUEST) {
    return getTabGroupsForGuestRetailer({
      isAdmin,
      partnerships,
    });
  }

  return getTabGroups({ companyType, isAdmin, cs });
}

function OMSServiceLayout() {
  const navigate = useNavigate();
  const auth = useAuthentication();
  const oms = useOMSStore();

  // TODO(@이지원): useAuthentication을 사용하지 않도록 리팩토링
  useEffect(() => {
    const checkLoginStatus = async () => {
      const isLoggedIn = await auth?.checkLoginStatus();
      if (!isLoggedIn) {
        navigate('/sign-in');
      }
    };

    checkLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyType = oms.user.companyType;

  const baseUrl = window.location.origin;
  const isAdmin =
    baseUrl.includes('localhost:3000') ||
    baseUrl.includes('127.0.0.1:3000') ||
    oms.user.admin;
  const cs = oms.user.deploy?.cs ?? false;
  const partnerships = Array.from(oms.partnership.partnerships.values());

  const tabGroups = selectTabGroups({ companyType, isAdmin, cs, partnerships });

  return (
    <div className="flex w-full overflow-x-hidden" style={{ height: '100vh' }}>
      <div className="w-[280px]">
        <Sidebar tabGroups={tabGroups} />
      </div>
      <div
        className="flex w-full flex-col"
        style={{ maxWidth: 'calc(100% - 280px)' }}
      >
        <div className="h-[77px] shrink-0">
          <OMSHeader userStore={UserStore} />
        </div>
        <div className="grow">
          {oms.isInitialized ? (
            <Outlet />
          ) : (
            <div className="size-full bg-gray-100"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(OMSServiceLayout);
