import { createContext, PropsWithChildren, useContext } from 'react';
import { DateRange } from '@sweep/utils';

interface RangePickerContextValue {
  range: DateRange;
  onHoveredChange: (hovered: Date | null) => void;
  leftMonth: Date;
  rightMonth: Date;
  onDayClick: (date: Date) => void;
}

const RangePickerContext = createContext<RangePickerContextValue | null>(null);

interface RangePickerProviderProps extends PropsWithChildren {
  value: RangePickerContextValue;
}

export function RangePickerProvider({
  children,
  value,
}: RangePickerProviderProps) {
  return (
    <RangePickerContext.Provider value={value}>
      {children}
    </RangePickerContext.Provider>
  );
}

export function useRangePicker() {
  const context = useContext(RangePickerContext);
  if (!context) {
    throw new Error('useRangePicker must be used within a RangePickerProvider');
  }
  return context;
}
