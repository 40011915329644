import { imageSweep } from 'src/images';

export function InitialTitleSection() {
  return (
    <div>
      <img
        src={imageSweep}
        alt="service icon"
        className="w-88px mb-16px h-auto"
      />
      <p className="font-pretendard mb-22px text-[44px] font-bold text-gray-700">
        주소 변환기
      </p>
      <div className="gap-16px flex items-center">
        <p className="font-pretendard text-[26px] font-semibold text-gray-600">
          쇼핑몰에서 <span className="text-blue-500">오기입된 주소</span>,{' '}
          스윕이 자동으로 변환해드릴게요.
        </p>
        <p className="text-medium-l text-gray-500">
          7일간 누구나 자유롭게 사용 가능합니다.
        </p>
      </div>
    </div>
  );
}
