import { z } from 'zod';

export const PreRegistrationSchema = z.object({
  companyName: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  monthlyOrderCount: z.string().optional(),
  memo: z.string().optional(),
  from: z.string().optional(),
});

export type PreRegistration = z.infer<typeof PreRegistrationSchema>;
