import { useState } from 'react';
import { IconDragFilesNoshadow } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

interface FileUploadProps {
  onUpload: (file: File) => void;
}

function FileUpload({ onUpload }: FileUploadProps) {
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files == null || files.length === 0) {
      return;
    }

    onUpload(files[0]);
    event.target.value = '';
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();

    // Check if we're actually leaving the container
    const container = event.currentTarget;
    const relatedTarget = event.relatedTarget as Node | null;

    if (relatedTarget == null || !container.contains(relatedTarget)) {
      setIsDragging(false);
    }
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(false);

    const files = Array.from(event.dataTransfer.files);
    if (files.length === 0) {
      return;
    }

    onUpload(files[0]);
  };

  return (
    <div
      className={container({ isDragging })}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <IconDragFilesNoshadow className={icon()} />
      <p className={text({ isDragging })}>
        {isDragging ? (
          '파일을 여기에 놓으세요!'
        ) : (
          <>
            카카오 주문 <span className="text-blue-500">엑셀 파일</span>을
            여기에 끌어다 놓으세요.
          </>
        )}
      </p>
      <p className="text-semibold-s mb-12px text-gray-400">또는</p>
      <Button variant="solid" className="w-200px" asChild>
        <label className="cursor-pointer">
          파일 올리기
          <input
            className="hidden"
            type="file"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileChange}
          />
        </label>
      </Button>
    </div>
  );
}

const container = cva(
  [
    'flex-center py-40px mb-16px flex flex-col bg-white',
    'shadow-line rounded-8px border-4 transition-colors duration-200',
  ],
  {
    variants: {
      isDragging: {
        true: 'border-dashed border-gray-300',
        false: 'border-white',
      },
    },
  }
);

const icon = cva('mb-16px');

const text = cva('text-semibold-l mb-20px text-gray-700', {
  variants: {
    isDragging: {
      true: 'text-primary-500',
    },
  },
});

export default FileUpload;
