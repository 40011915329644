import { IconFile400, IconTrashcan } from '@sweep/asset/icons';
import { If } from '@sweep/utils/react';
import { toast } from 'src/third-parties/toast';

export default function FileItemSent({
  filename,
  createdAt,
  isConfirmed,
  onClickDelete,
}: {
  filename: string;
  createdAt: string;
  isConfirmed: boolean;
  onClickDelete: () => void;
}) {
  return (
    <div className="p-20px rounded-12px flex w-full items-center justify-between border border-gray-200 bg-white">
      <div className="gap-8px flex flex-col">
        <div className="gap-12px flex items-center">
          <IconFile400 />
          <p className="text-semibold-m text-gray-700">{filename}</p>
        </div>
        <div className="gap-4px flex flex-col">
          <p className="text-medium-s text-gray-500">보낸 시간: {createdAt}</p>
        </div>
      </div>
      <If is={isConfirmed}>
        <div
          className="p-12px rounded-12px flex items-center justify-center border border-gray-200 bg-gray-200"
          onClick={() =>
            toast.error('파트너사가 주문을 확인하여 삭제할 수 없습니다.', {
              duration: 3000,
            })
          }
        >
          <IconTrashcan className="text-white" />
        </div>
      </If>
      <If is={!isConfirmed}>
        <div
          className="p-12px rounded-12px flex items-center justify-center border border-gray-200 bg-gray-400"
          onClick={onClickDelete}
        >
          <IconTrashcan className="text-white" />
        </div>
      </If>
    </div>
  );
}
