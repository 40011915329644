import { c } from '../../internal/contract';
import {
  FulfilledOrderTransactionConfirmBodySchema,
  FulfilledOrderTransactionConfirmPathParamsSchema,
  FulfilledOrderTransactionConfirmResponseSchema,
  FulfilledOrderTransactionCountResponseSchema,
  FulfilledOrderTransactionCreateBodySchema,
  FulfilledOrderTransactionCreateResponseSchema,
  FulfilledOrderTransactionDeleteResponseSchema,
  FulfilledOrderTransactionFindAllQuerySchema,
  FulfilledOrderTransactionFindAllResponseSchema,
  FulfilledOrderTransactionGetDownloadUrlResponseSchema,
} from '../../type/partnership/FulfilledOrderTransactionDTO';
import { CommonErrorResponseSchema } from '../commonErrorResponse';

export const FulfilledOrderTransactionRouterContract = c.router({
  create: {
    method: 'POST',
    path: '/fulfilled-order-transaction',
    body: FulfilledOrderTransactionCreateBodySchema,
    responses: {
      200: FulfilledOrderTransactionCreateResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  findAll: {
    method: 'GET',
    path: '/fulfilled-order-transaction',
    query: FulfilledOrderTransactionFindAllQuerySchema,
    responses: {
      200: FulfilledOrderTransactionFindAllResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  count: {
    method: 'GET',
    path: '/fulfilled-order-transaction/count',
    query: FulfilledOrderTransactionFindAllQuerySchema,
    responses: {
      200: FulfilledOrderTransactionCountResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  confirm: {
    method: 'PUT',
    path: '/fulfilled-order-transaction/:fulfilledOrderTransactionId/confirm',
    pathParams: FulfilledOrderTransactionConfirmPathParamsSchema,
    body: FulfilledOrderTransactionConfirmBodySchema,
    responses: {
      200: FulfilledOrderTransactionConfirmResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  delete: {
    method: 'DELETE',
    path: '/fulfilled-order-transaction/:fulfilledOrderTransactionId',
    responses: {
      200: FulfilledOrderTransactionDeleteResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  getDownloadUrl: {
    method: 'GET',
    path: '/fulfilled-order-transaction/:fulfilledOrderTransactionId/download-url',
    responses: {
      200: FulfilledOrderTransactionGetDownloadUrlResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
});
