import { z } from 'zod';
import { PARTNERSHIP_HISTORY_TYPE } from './PartnershipHistory';

export const PartnershipHistoryCreateDTO = z.object({
  partnershipId: z.string(),
  actionType: z.enum([
    PARTNERSHIP_HISTORY_TYPE.CREATE,
    PARTNERSHIP_HISTORY_TYPE.UPDATE,
  ]),
  actionUserId: z.string(),
  actionAt: z.date(),
  payload: z.record(z.string(), z.any()).nullable(),
});

export type PartnershipHistoryCreateDTO = z.infer<
  typeof PartnershipHistoryCreateDTO
>;
