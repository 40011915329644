import { useRef, useState } from 'react';
import { Button, Select } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { numberToExcelColumn } from '@sweep/utils';

interface MatchingSelectSectionProps {
  rows: string[][];
  onSubmit: (addressColumn: number) => Promise<void>;
}

export function MatchingSelectSection({
  rows,
  onSubmit,
}: MatchingSelectSectionProps) {
  const [addressIndex, setAddressIndex] = useState(-1);
  const headerRefs = useRef<(HTMLTableCellElement | null)[]>([]);

  const handleAddressChange = (index: number) => {
    setAddressIndex(index);
    headerRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  if (rows.length === 0) {
    return null;
  }

  const headers = rows[0];
  const bodyRows = rows.slice(1, 10);

  const border = (index: number) =>
    index !== headers.length - 1 ? 'right' : 'default';

  return (
    <div className="gap-20px flex flex-col">
      <div className="rounded-8px shadow-line p-20px flex-center flex bg-white">
        <p className="mr-15px text-medium-s text-gray-700">주소 열</p>
        <Select
          placeholder="주소 열을 선택해주세요."
          className="max-w-380px mr-24px flex-1"
          value={addressIndex}
          onChange={handleAddressChange}
        >
          {headers.map((header, index) => (
            <Select.Option key={header} value={index}>
              {`${numberToExcelColumn(index + 1)} - ${header}`}
            </Select.Option>
          ))}
        </Select>
        <Button
          disabled={addressIndex === -1}
          onClick={() => onSubmit(addressIndex)}
        >
          선택 완료
        </Button>
      </div>

      <div className="rounded-8px p-24px shadow-line flex bg-white">
        <div className="w-1200px overflow-x-auto">
          <table className={table()}>
            <thead>
              <tr className="bg-gray-50">
                {headers.map((_, index) => (
                  <th
                    key={index}
                    ref={(el) => (headerRefs.current[index] = el)}
                    className={headerCellClass({
                      color: 'blue',
                      border: border(index),
                    })}
                  >
                    {numberToExcelColumn(index + 1)}
                  </th>
                ))}
              </tr>
              <tr className="bg-gray-50">
                {headers.map((header, index) => (
                  <th
                    key={header}
                    className={headerCellClass({
                      color: 'black',
                      border: border(index),
                    })}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bodyRows.map((row, index) => (
                <tr key={index}>
                  {row.map((cell, index) => (
                    <td
                      key={cell}
                      className={cellClass({
                        border: border(index),
                        selected: index === addressIndex,
                      })}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const table = cva('table-fixed border-collapse border border-gray-200');

const headerCellClass = cva(
  [
    'px-16px w-200px max-w-200px py-[8.5px]',
    'bg-gray-100',
    'text-medium-s truncate text-left',
    'border-b border-gray-200',
  ],
  {
    variants: {
      color: {
        black: 'text-gray-700',
        blue: 'text-blue-500',
      },
      border: {
        default: '',
        right: 'border-r',
      },
    },
  }
);

const cellClass = cva(
  [
    'px-16px w-300px max-w-300px py-[8.5px]',
    'text-medium-s truncate text-gray-700',
    'border-b border-gray-200',
  ],
  {
    variants: {
      border: {
        default: '',
        right: 'border-r',
      },
      selected: {
        true: 'bg-blue-200',
        false: '',
      },
    },
  }
);
