import { z } from 'zod';

export const INQUIRY_STATUS = {
  ANSWERED: '답변완료',
  UNANSWERED: '미답변',
} as const;

export type InquiryStatus = keyof typeof INQUIRY_STATUS;

export const INQUIRY_CATEGORY = {
  CALL: '고객센터문의',
  ORDER: '주문문의',
  PRODUCT: '상품문의',
  CUSTOMER: '고객문의',
} as const;

export type InquiryCategory = keyof typeof INQUIRY_CATEGORY;

export const INQUIRY_REPLY_TYPE = {
  WRITE: 'WRITE',
  PASS: 'PASS',
  CONFIRM: 'CONFIRM',
} as const;

export type InquiryReplyType = keyof typeof INQUIRY_REPLY_TYPE;

export const InquiryReplySchema = z.object({
  inquiryId: z.string(),
  replyId: z.string(),
  content: z.string(),
  replyDate: z.number(),
});

export type InquiryReply = z.infer<typeof InquiryReplySchema>;

export const InquirySchema = z.object({
  shoppingMall: z.string(),
  inquiryUniqueCode: z.string(),

  inquiryId: z.string(),
  inquiryCategory: z.custom<InquiryCategory>(),
  inquiryType: z.string().optional(), // NOTE(@형준): inquiryCategory 의 서브 타입, 둘의 연관 관계를 더 잘 표현하는 변수값으로 변경해야 함

  inquiryStatus: z.custom<InquiryStatus>(),

  title: z.string().optional(),
  content: z.string().optional(),

  buyerEmail: z.string().optional(),
  buyerName: z.string().optional(),
  buyerContactNumber: z.string().optional(),

  inquirerId: z.string().optional(),
  inquirerName: z.string().optional(),

  orderNumbers: z.array(z.string()).optional(),
  productIds: z.array(z.string()).optional(),
  productNames: z.array(z.string()).optional(),

  inquiryDate: z.number(),

  replyType: z.custom<InquiryReplyType>(),
  replies: z.array(InquiryReplySchema).optional(),

  // NOTE(@형준): shoppingMall에서 제공하는 추가 데이터
  extra: z.record(z.string(), z.unknown()).optional(),
});

export type Inquiry = z.infer<typeof InquirySchema>;

export const CoupangInquiryCategorySchema = z.enum(['CALL', 'CUSTOMER']);
export type CoupangInquiryCategory = z.infer<
  typeof CoupangInquiryCategorySchema
>;

export const CoupangInquirySchema = InquirySchema.extend({
  inquiryCategory: CoupangInquiryCategorySchema,
  replyId: z.string().optional(),
});

export type CoupangInquiry = z.infer<typeof CoupangInquirySchema>;
