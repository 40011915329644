import { z } from 'zod';
import { PARTNERSHIP_TYPE, PartnershipSchema } from './Partnership';

export const PartnershipCreateDTO = PartnershipSchema.omit({
  _id: true,
  createdAt: true,
  updatedAt: true,
});

export type PartnershipCreateDTO = z.infer<typeof PartnershipCreateDTO>;

export const PartnershipRegisterRetailerBodySchema = z.object({
  partnershipType: z.enum([
    PARTNERSHIP_TYPE.SUPPLY,
    PARTNERSHIP_TYPE.FULFILLMENT,
  ]),

  retailerName: z.string(),
  retailerEmail: z.string(),

  supplierPartnerId: z.string(),
});

export type PartnershipRegisterRetailerBody = z.infer<
  typeof PartnershipRegisterRetailerBodySchema
>;

export const PartnershipRegisterRetailerResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    partnershipUUID: z.string(),
    partnership: PartnershipSchema,
  }),
});

export type PartnershipRegisterRetailerResponse = z.infer<
  typeof PartnershipRegisterRetailerResponseSchema
>;

export const PartnershipFindAllQuerySchema = z.object({
  partnershipType: z
    .enum([PARTNERSHIP_TYPE.SUPPLY, PARTNERSHIP_TYPE.FULFILLMENT])
    .optional(),
  supplierUserId: z.string().optional(),
  retailerUserId: z.string().optional(),
});

export type PartnershipFindAllQuery = z.infer<
  typeof PartnershipFindAllQuerySchema
>;

export const PartnershipFindAllResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(PartnershipSchema),
});

export type PartnershipFindAllResponse = z.infer<
  typeof PartnershipFindAllResponseSchema
>;

export const PartnershipFindByIdPathParamsSchema = z.object({
  partnershipId: z.string(),
});

export type PartnershipFindByIdPathParams = z.infer<
  typeof PartnershipFindByIdPathParamsSchema
>;

export const PartnershipFindByIdResponseSchema = z.object({
  success: z.boolean(),
  data: PartnershipSchema,
});

export type PartnershipFindByIdResponse = z.infer<
  typeof PartnershipFindByIdResponseSchema
>;
