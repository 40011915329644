import { parseNumber, sum } from '@sweep/utils';
import { SettlementOrder } from '../interface';

export function getSalesChannelTotalPrice(orders: SettlementOrder[]) {
  const totalQuantity = sum(
    orders.map((order) => parseNumber(order.quantity) ?? 0)
  );
  const totalSellingPrice = sum(orders.map((order) => order.sellingPrice ?? 0));
  const totalSellingShippingPrice = sum(
    orders.map((order) => order.sellingShippingPrice ?? 0)
  );
  const totalPrice = sum(orders.map((order) => order.totalSellingPrice ?? 0));

  return {
    totalQuantity,
    totalSellingPrice,
    totalSellingShippingPrice,
    totalPrice,
  };
}
