import { z } from 'zod';

export const PurchaseOrderTransactionSchema = z.object({
  _id: z.string(),
  partnershipUUID: z.string(),

  files: z.object({
    original: z.object({
      uuid: z.string(),
      filename: z.string(),
      extension: z.string(),
    }),
    preprocessed: z
      .object({
        uuid: z.string().optional(),
        filename: z.string().optional(),
        extension: z.string().optional(),
      })
      .optional(),
  }),

  retailerUserId: z.string(),
  retailer: z.object({
    name: z.string(),
    email: z.string(),
    supplierId: z.string().optional(),
  }),

  supplierUserId: z.string(),
  supplier: z.object({
    name: z.string(),
    email: z.string(),
    partnerId: z.string(),
  }),

  // 이후 작업에서 활용 예정
  //   orderCount: z.number(),
  //   totalQuantity: z.number(),

  createdAt: z.string(),
  updatedAt: z.string(),
  confirmedAt: z.string().nullable(),
  deletedAt: z.string().nullable(),
});

export type PurchaseOrderTransaction = z.infer<
  typeof PurchaseOrderTransactionSchema
>;
