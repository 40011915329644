import { IconDownloadLarge, IconFile600 } from '@sweep/asset/icons';

export default function FileItemReceived({
  filename,
  createdAt,
  onClickDownload,
}: {
  filename: string;
  createdAt: string;
  onClickDownload: () => void;
}) {
  return (
    <div className="p-20px rounded-12px flex w-full items-center justify-between border border-gray-200 bg-white">
      <div className="gap-8px flex flex-col">
        <div className="gap-12px flex items-center">
          <IconFile600 />
          <p className="text-semibold-m text-gray-700">{filename}</p>
        </div>
        <div className="gap-4px flex flex-col">
          <p className="text-medium-s text-gray-500">보낸 시간: {createdAt}</p>
        </div>
      </div>
      <div
        className="p-12px rounded-12px flex items-center justify-center border bg-blue-500"
        onClick={onClickDownload}
      >
        <IconDownloadLarge className="text-white" />
      </div>
    </div>
  );
}
