export const KAKAO_HEADERS = [
  '결제번호',
  '배송지/수신자정보 입력일',
  '주문상태',
  '주문번호',
  '개인통관고유부호',
  '채널상품번호',
  '상품명',
  '옵션',
  '수량',
  '배송방법',
  '택배사코드',
  '송장번호',
  '수령인명',
  '수령인연락처1',
  '하이픈포함 수령인연락처1',
  '수령인연락처2',
  '하이픈포함 수령인연락처2',
  '배송지주소',
  '우편번호',
  '배송메세지',
  '배송예정일',
  '주문일',
  '상품금액',
  '옵션금액',
  '판매자할인금액',
  '판매자쿠폰할인금액',
  '정산기준금액',
  '기본수수료',
  '노출추가수수료',
  '추천리워드수수료',
  '수수료할인금액',
  '채널',
  '브랜드',
  '모델명',
  '판매자상품번호',
  '옵션코드',
  '최초배송비번호',
  '배송비지불방법',
  '기본배송비 유형',
  '기본배송비 금액',
  '도서산간 추가 배송비 금액',
  '유입경로',
  '톡딜여부',
  '상품유형',
  'biz판매여부',
];

export const OPTION_KEY = '옵션';
export const PRICE_KEYS = [
  '상품금액',
  '옵션금액',
  '판매자할인금액',
  '판매자쿠폰할인금액',
  '정산기준금액',
  '기본수수료',
  '노출추가수수료',
  '추천리워드수수료',
  '수수료할인금액',

  '기본배송비 금액',
  '도서산간 추가 배송비 금액',
];
