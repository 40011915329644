import {
  IconArrowDownModal,
  IconCheckMedium,
  IconRandom,
} from '@sweep/asset/icons';
import { Button, Divider, TextInput } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { useConvertAddress } from '../../hooks/useConvertAddress';

function InitialTestSection({ className }: { className?: string }) {
  const { value, onChange, convertedValue, generate, convert, copy } =
    useConvertAddress();

  return (
    <div className={container({ className })}>
      <div className="gap-32px px-24px py-28px flex flex-col">
        <div className="gap-8px flex-start flex">
          <IconCheckMedium className="text-blue-400" />
          <p className="text-semibold-m text-gray-600">주소 변환 예시</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="gap-8px px-20px py-9px flex items-center justify-center rounded-full bg-gray-100">
            <p className="text-semibold-m text-gray-500">
              대한민국、서울특별시、관악구、Nambusunhwan-ro、1838, CS tower
              1201-ho
            </p>
          </div>
          <IconArrowDownModal className="text-gray-400" />
          <div className="gap-8px px-20px py-9px flex items-center justify-center rounded-full bg-blue-50">
            <p className="text-semibold-m text-blue-600">
              서울특별시 관악구 남부순환로 1838 CS tower 1201호
            </p>
          </div>
        </div>
        <Divider type="horizontal" />
        <div className="flex flex-col items-center">
          <div className="gap-8px flex-start mb-24px flex w-full items-center">
            <IconCheckMedium className="text-blue-400" />
            <p className="text-semibold-m text-gray-600">직접 변환해보기</p>
            <Button
              color="gray"
              size="xsmall"
              rightAccessory={<IconRandom />}
              onClick={generate}
            >
              예시 주소
            </Button>
          </div>
          <div className="gap-12px flex w-full items-center">
            <label htmlFor="" className="text-medium-s w-64px text-gray-500">
              변환할 주소
            </label>
            <TextInput
              className="flex-1"
              placeholder="변환할 주소를 입력해주세요."
              value={value}
              onChange={onChange}
            />
            <Button color="gray" onClick={convert}>
              변환하기
            </Button>
          </div>
          <IconArrowDownModal className="text-gray-400" />
          <div className="gap-12px flex w-full items-center">
            <label htmlFor="" className="text-medium-s w-64px text-gray-500">
              변환 결과
            </label>
            <TextInput disabled className="flex-1" value={convertedValue} />
            <Button color="lightGray" onClick={copy}>
              복사하기
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const container = cva('shadow-line rounded-8px mr-32px bg-white');
export default InitialTestSection;
