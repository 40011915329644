import { z } from 'zod';

export const REMOTE_STORAGE_FILE_TYPE = {
  SPREADSHEET: 'spreadsheet',
  SPREADSHEET_PARSED: 'spreadsheet-parsed',
} as const;

export type RemoteStorageFileType =
  (typeof REMOTE_STORAGE_FILE_TYPE)[keyof typeof REMOTE_STORAGE_FILE_TYPE];

export const RemoteStorageFileSchema = z.object({
  userId: z.string(),
  fileType: z.nativeEnum(REMOTE_STORAGE_FILE_TYPE),
  fileUUID: z.string(),
  createdAt: z.date(),
  deletedAt: z.date().nullable(),
  filemeta: z.object({
    originalName: z.string(),
    mimeType: z.string(),
    size: z.number(),
  }),
});

export type RemoteStorageFile = z.infer<typeof RemoteStorageFileSchema>;

export const RemoteStorageFileUploadSchema = z.object({
  file: z.custom<File>(),
});

export type RemoteStorageFileUpload = z.infer<
  typeof RemoteStorageFileUploadSchema
>;
