import { SHOPPING_MALL_NAMES, ShoppingMallName } from './ShoppingMallName';

export const CAFE_PREFIX = 'CAF-';
export const COUPANG_PREFIX = 'CUP-';
export const ALIEXPRESS_PREFIX = 'ALI-';
export const KAKAO_PREFIX = 'KAK-';
export const NAVER_SMART_STORE_PREFIX = 'NSS-';
export const ELEVEN_PREFIX = 'ELV-';
export const GMARKET_PREFIX = 'GMA-';
export const AUCTION_PREFIX = 'AUC-';
export const ALWAYZ_PREFIX = 'AWZ-';
export const KAKAOMAKERS_PREFIX = 'MAK-';
export const SHOPBY_PREFIX = 'SHP-';
export const LOTTE_ON_PREFIX = 'LOT-';
export const IMWEB_PREFIX = 'IMW-';

export function isCafeUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(CAFE_PREFIX);
}

export function isCoupangUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(COUPANG_PREFIX);
}

export function isAliExpressUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(ALIEXPRESS_PREFIX);
}

export function isKakaoUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(KAKAO_PREFIX);
}

export function isNaverSmartStoreUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(NAVER_SMART_STORE_PREFIX);
}

export function isElevenUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(ELEVEN_PREFIX);
}

export function isGmarketUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(GMARKET_PREFIX);
}

export function isAuctionUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(AUCTION_PREFIX);
}

export function isAlwayzUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(ALWAYZ_PREFIX);
}

export function isKakaoMakersUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(KAKAOMAKERS_PREFIX);
}

export function isShopByUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(SHOPBY_PREFIX);
}

export function isLotteOnUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(LOTTE_ON_PREFIX);
}

export function isImwebOnUniqueCode(uniqueCode: string) {
  return uniqueCode.startsWith(IMWEB_PREFIX);
}

export function getShoppingMallNameByUniqueCode(
  uniqueCode: string
): ShoppingMallName {
  if (isAliExpressUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.ALIEXPRESS;
  }

  if (isCoupangUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.COUPANG;
  }

  if (isKakaoUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.KAKAOSHOP;
  }

  if (isNaverSmartStoreUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.NAVER;
  }

  if (isElevenUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.ELEVEN;
  }

  if (isGmarketUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.GMARKET;
  }

  if (isAuctionUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.AUCTION;
  }

  if (isAlwayzUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.ALWAYZ;
  }

  if (isKakaoMakersUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.KAKAOMAKERS;
  }

  if (isShopByUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.SHOPBY;
  }

  if (isImwebOnUniqueCode(uniqueCode)) {
    return SHOPPING_MALL_NAMES.IMWEB;
  }

  throw new Error(`Unknown ShoppingMall Unique Code: ${uniqueCode}`);
}
