import { z } from 'zod';

export const BaseDispatchAuthTokenSchema = z.object({
  _id: z.string(),
  userId: z.string(),
  shoppingMall: z.string(),
  dispatchOrderUpdateCron: z.boolean().optional(),
  refreshTokenActive: z.boolean().optional(),
});

export type BaseDispatchAuthToken = z.infer<typeof BaseDispatchAuthTokenSchema>;
