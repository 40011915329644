import { z } from 'zod';
import { BaseOrderSchema } from './BaseOrder';
import { OrderStatusSchema } from './OrderStatus';

export const DispatchedOrderSchema = BaseOrderSchema.extend({
  orderStatus: OrderStatusSchema,

  shoppingMall: z.string().optional(),
  shoppingMallId: z.string().optional(),
  // NOTE(@이지원): marketName, platformName, offlineMallName는 레거시일 수 있음
  marketName: z.string().nullish(),
  platformName: z.string().nullish(),
  offlineMallName: z.string().nullish(),

  productCode: z.string().nullish(),
  detailOptionCode: z.string().nullish(),

  shippingCompany: z.string().nullish(),
  shippingNumber: z.string().nullish(),
  sweepShippingCompany: z.string().nullish(),

  orderNumber: z.string().nullish(),
  childOrderNumber: z.string().nullish(),

  orderDate: z.string().nullish(),
  paymentDate: z.string().nullish(),
  cancelDate: z.string().nullish(),
  updateDate: z.string().nullish(),
  // NOTE(@이지원): 알리에서 상품 준비 중 상태를 위한 필드
  orderCheckedDate: z.string().nullish(),

  buyerId: z.string().nullish(),
  buyerName: z.string().nullish(),
  buyerContactNumber: z.string().nullish(),

  // NOTE(@이지원): 알리 주문수집에서 반환하는 필드
  sweepedShippingCompany: z.string().nullish(),
  sweepedShippingNumber: z.string().nullish(),

  // NOTE(@이지원): 쿠팡
  cancelCode: z.union([z.string(), z.number()]).nullish(),

  updatedAt: z.number().nullish(),

  // NOTE(@형준): shoppingMall에서 제공하는 추가 데이터
  extra: z.record(z.string(), z.unknown()).nullish(),
});

export type DispatchedOrder = z.infer<typeof DispatchedOrderSchema>;
