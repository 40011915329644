import { CustomComponents } from 'react-day-picker';
import { Badge } from '@sweep/sds';
import { formatDate } from '@sweep/utils';

export const MonthCaption: CustomComponents['MonthCaption'] = ({
  calendarMonth,
  displayIndex,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Badge color="blue" variant="soft">
        {formatDate(calendarMonth.date, 'yyyy년 M월')}
      </Badge>
    </div>
  );
};
