import { DateRange, max, min } from '@sweep/utils';
import { Selecting } from '../interface';

export function getUpdatedDateRange(
  range: DateRange,
  selection: Selecting,
  value: Date
): DateRange {
  const { from, to } = range;
  switch (selection) {
    case 'first-from': {
      return { from: value, to };
    }
    case 'first-to': {
      return { from, to: value };
    }
    case 'second-from': {
      const minFrom = min([value, to]);
      const maxTo = max([value, to]);
      return { from: minFrom, to: maxTo };
    }
    case 'second-to': {
      const minFrom = min([from, value]);
      const maxTo = max([from, value]);
      return { from: minFrom, to: maxTo };
    }
    case null: {
      return range;
    }
  }
}
