import { z } from 'zod';

export const ORDER_TRANSACTION_TYPE = {
  PURCHASE_ORDER: 'purchase-order',
  FULFILLED_ORDER: 'fulfilled-order',
} as const;

export type OrderTransactionType =
  (typeof ORDER_TRANSACTION_TYPE)[keyof typeof ORDER_TRANSACTION_TYPE];

export const ORDER_TRANSACTION_EVENT_TYPE = {
  CREATED: 'created',
  CONFIRMED: 'confirmed',
  DELETED: 'deleted',
  GET_DOWNLOAD_URL: 'get-download-url',
  DOWNLOAD_PREPROCESSED_FILE: 'download-preprocessed-file',
} as const;

export type OrderTransactionEventType =
  (typeof ORDER_TRANSACTION_EVENT_TYPE)[keyof typeof ORDER_TRANSACTION_EVENT_TYPE];

export const OrderTransactionHistory = z.object({
  _id: z.string(),
  transactionType: z.enum([
    ORDER_TRANSACTION_TYPE.PURCHASE_ORDER,
    ORDER_TRANSACTION_TYPE.FULFILLED_ORDER,
  ]),
  transactionId: z.string(),

  eventUserId: z.string(),
  eventAt: z.date(),
  eventType: z.enum([
    ORDER_TRANSACTION_EVENT_TYPE.CREATED,
    ORDER_TRANSACTION_EVENT_TYPE.CONFIRMED,
    ORDER_TRANSACTION_EVENT_TYPE.DELETED,
    ORDER_TRANSACTION_EVENT_TYPE.GET_DOWNLOAD_URL,
    ORDER_TRANSACTION_EVENT_TYPE.DOWNLOAD_PREPROCESSED_FILE,
  ]),
  eventPayload: z.record(z.string(), z.any()).nullable(),
});

export type OrderTransactionHistory = z.infer<typeof OrderTransactionHistory>;
