import { z } from 'zod';
import { SHOPPING_MALL_NAMES } from '../shopping-mall/ShoppingMallName';
import {
  BaseDispatchAuthToken,
  BaseDispatchAuthTokenSchema,
} from './DispatchAutoToken';

export const CoupangTokenSchema = BaseDispatchAuthTokenSchema.extend({
  vendorId: z.string(),
  access_key: z.string(),
  secret_key: z.string(),
  // TODO(@이지원): 고객사에게 모두 정보를 받으면 undefined 삭제
  wingId: z.string().optional(),
  expiresAt: z.string().optional(),
});

export type CoupangToken = z.infer<typeof CoupangTokenSchema>;

function isCoupangToken(token: BaseDispatchAuthToken): token is CoupangToken {
  return token.shoppingMall === SHOPPING_MALL_NAMES.COUPANG;
}

export function toCoupangToken<T extends BaseDispatchAuthToken>(
  token: T
): CoupangToken {
  if (!isCoupangToken(token)) {
    throw new Error('Invalid shopping mall');
  }

  return {
    ...token,
    vendorId: token.vendorId,
    access_key: token.access_key,
    secret_key: token.secret_key,
    wingId: token.wingId,
    expiresAt: token.expiresAt,
  };
}
