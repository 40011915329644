import { Selecting } from '../interface';

export function getNextRangeSelection(selection: Selecting): Selecting {
  switch (selection) {
    case 'first-from': {
      return 'second-to';
    }
    case 'first-to': {
      return 'second-from';
    }
    case 'second-from': {
      return null;
    }
    case 'second-to': {
      return null;
    }
    case null: {
      return null;
    }
  }
}
