import { z } from 'zod';

const PriceHistorySchema = z.object({
  value: z.number(),
  changedAt: z.string(),
});
export type PriceHistory = z.infer<typeof PriceHistorySchema>;

const UnitSchema = z.object({
  unit: z.string(),
  optionCode: z.string().nullish(),

  sellingPrice: z.number().nullish(),
  sellingPriceHistory: PriceHistorySchema.array().nullish(),
  sellingShippingPrice: z.number().nullish(),

  supplierPrice: z.number().nullish(),
  supplierPriceHistory: PriceHistorySchema.array().nullish(),
  supplierShippingPrice: z.number().nullish(),

  amount: z.number().nullish(),

  supplierId: z.string().nullish(),
});

export type Unit = z.infer<typeof UnitSchema>;

export const SalesChannelSupplierIdSchema = z.union([
  z.object({
    partnerId: z.string(),
    supplierId: z.string().nullish(),
  }),
  z.object({
    shoppingMallId: z.string(),
    supplierId: z.string().nullish(),
  }),
]);

export type SalesChannelSupplierId = z.infer<
  typeof SalesChannelSupplierIdSchema
>;

export const ProductSchema = z.object({
  _id: z.string(),
  productName: z.string(),

  units: UnitSchema.array().optional(),

  sellingPrice: z.number().nullish(),
  sellingPriceHistory: PriceHistorySchema.array().nullish(),
  sellingShippingPrice: z.number().nullish(),

  supplierPrice: z.number().nullish(),
  supplierPriceHistory: PriceHistorySchema.array().nullish(),
  supplierShippingPrice: z.number().nullish(),

  amount: z.number().nullish(),

  supplierId: z.string().nullish(),
  salesChannelSupplierIds: SalesChannelSupplierIdSchema.array().optional(),

  useSupplierByOption: z.boolean().optional(),
  useSupplierBySalesChannel: z.boolean().optional(),
});

export type Product = z.infer<typeof ProductSchema>;
