import { z } from 'zod';

export const SweepServerErrorCodes = {
  UNKNOWN: 'SWEEP:UNKNOWN',
  INTERNAL_SERVER_ERROR: 'SWEEP:INTERNAL_SERVER_ERROR',
  UNAUTHORIZED_INVALID_TOKEN: 'SWEEP:UNAUTHORIZED_INVALID_TOKEN',
  UNAUTHORIZED_TOKEN_NOT_FOUND: 'SWEEP:UNAUTHORIZED_TOKEN_NOT_FOUND',
  UNAUTHORIZED: 'SWEEP:UNAUTHORIZED',
  FORBIDDEN: 'SWEEP:FORBIDDEN',
  NOT_FOUND: 'SWEEP:NOT_FOUND',
  BAD_REQUEST: 'SWEEP:BAD_REQUEST',
} as const;

export type SweepServerErrorCodeKeys = keyof typeof SweepServerErrorCodes;

export const CommonErrorResponseSchema = z.object({
  success: z.boolean(),
  error: z.string(),
  customErrorCode: z.string(),
});

export type CommonErrorResponse = z.infer<typeof CommonErrorResponseSchema>;

export const ZodErrorResponseSchema = z.object({
  name: z.literal('ZodError'),
  issues: z.array(
    z.object({
      code: z.string(),
      message: z.string(),
      path: z.array(z.string()),
      expected: z.string(),
      received: z.string(),
    })
  ),
});
