import { action, computed, observable } from 'mobx';
import { DateRange, endOfDay, getStartDays, startOfDay } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { SettlementOrder } from '../interface';
import { SettlementStore } from './SettlementStore';

export class SupplierSettlementScreenStore {
  @observable
  accessor range: DateRange = {
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  };

  @computed
  get orders(): SettlementOrder[] {
    const days = getStartDays(this.range);

    return days.flatMap(
      (day) => this.settlement.orders[day.toISOString()] ?? []
    );
  }

  getOrders(range: DateRange) {
    const days = getStartDays(range);

    return days.flatMap(
      (day) => this.settlement.orders[day.toISOString()] ?? []
    );
  }

  constructor(
    private readonly oms: OMSStore,
    private readonly settlement: SettlementStore
  ) {}

  @action.bound
  setRange(range: DateRange) {
    this.range = {
      from: startOfDay(new Date(range.from)),
      to: endOfDay(new Date(range.to)),
    };
  }

  async ensureOrders(range: DateRange) {
    await this.settlement.ensure(range);
  }
}
