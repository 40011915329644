import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconArrowBothLargeThin,
  IconFactory,
  IconShopLarge,
} from '@sweep/asset/icons';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { openConfirmDialog } from 'src/components/openConfirmDialog';
import { NODE_ENV } from 'src/env';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { amplitude } from 'src/third-parties/amplitude';
import { createDebug } from 'src/third-parties/createDebug';
import { toast } from 'src/third-parties/toast';
import FileDragAndDropSection from './components/FileDragAndDropSection';
import FileItemReceived from './components/FileItemReceived';
import FileItemSent from './components/FileItemSent';
import FileSectionEmpty from './components/FileSectionEmpty';

const debug = createDebug('partnership-purchase-order');

function PartnershipPurchaseOrderScreen() {
  const navigate = useNavigate();
  const oms = useOMSStore();
  const { partnershipUUID } = useParams();

  const selectedPartnership = oms.partnership.partnerships.get(
    partnershipUUID ?? ''
  );

  const retailerName = selectedPartnership?.retailer?.name ?? 'Unknown';

  const supplierName = selectedPartnership?.supplier?.name ?? 'Unknown';

  const sentFileTransactions =
    oms.retailerOrderTransaction.selectedOutgoingTransactions;

  const receivedFileTransactions =
    oms.retailerOrderTransaction.selectedIncomingTransactions;

  const uploadedFileNames =
    oms.retailerOrderTransaction.selectedPartnershipUploadedFileNames;

  const modifyFilename = (filename: string) => {
    const retailerName =
      oms.retailerOrderTransaction.selectedPartnershipRetailerName;
    const modifiedFileName = `${retailerName}_${filename}`;

    return modifiedFileName;
  };

  useEffect(() => {
    // NOTE(@형준): 잘못된 접근을 하는경우 404 페이지로 보내줘야 함
    // TODO(@형준): 페이지 처음 접근하는 시점에 partnership 로딩이 완료되지 않았다면 기다렸다고 로딩시키게 해줘야 함, (initialize 값 활용 ?)

    if (partnershipUUID != null) {
      oms.retailerOrderTransaction.selectedPartnershipUUID = partnershipUUID;
    }
  }, [partnershipUUID, navigate, oms]);

  useEffect(() => {
    // NOTE(@형준): 테스트 완료 후 30초로 고정
    const IS_PRODUCTION = NODE_ENV === 'production';

    const THIRTY_SECONDS_IN_MS = 30 * 1000;
    const TEN_SECONDS_IN_MS = 10 * 1000;
    const INTERVAL_IN_MS = IS_PRODUCTION
      ? THIRTY_SECONDS_IN_MS
      : TEN_SECONDS_IN_MS;

    const interval = setInterval(async () => {
      debug('checkAndReloadIncomingTransactions');
      const isReloaded =
        await oms.retailerOrderTransaction.checkAndReloadIncomingTransactions();

      if (isReloaded) {
        console.log('주문 파일이 업데이트 되었습니다.');
      }
    }, INTERVAL_IN_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    amplitude.track('Pageview partnership-purchase-order', {
      PartnershipUUID: partnershipUUID,
      'Supplier name': supplierName,
    });
  }, [partnershipUUID, supplierName]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (partnershipUUID == null) {
      toast.error('파일 업로드에 실패했습니다.');
      return;
    }

    amplitude.track('Upload partnership-purchase-order', {
      PartnershipUUID: partnershipUUID,
      'Supplier name': supplierName,
    });

    if (event.target.files && event.target.files.length > 0) {
      const targetFiles = Array.from(event.target.files);
      const modifiedTargetFilenames = targetFiles.map((file) =>
        modifyFilename(file.name)
      );

      if (
        modifiedTargetFilenames.some((name) => uploadedFileNames.includes(name))
      ) {
        toast.error('이미 업로드된 파일이 포함되어 있습니다.');

        return;
      }

      const uploadFileCount = targetFiles.length;

      const firstFilename = targetFiles?.at(0)?.name ?? '';
      const modifiedFileName = modifyFilename(firstFilename);

      const title =
        uploadFileCount > 1
          ? `'${modifiedFileName}' 파일 외 ${uploadFileCount - 1}개 파일을 업로드할까요?`
          : `'${modifiedFileName}' 파일을 업로드할까요?`;
      const description = '파트너사가 주문 확인한 뒤에는 삭제할 수 없습니다.';

      const isConfirmed = await openConfirmDialog(title, description);

      if (!isConfirmed) {
        return false;
      }

      const results: boolean[] = [];
      for (const file of targetFiles) {
        const result = await oms.retailerOrderTransaction.sendPurchaseOrderFile(
          file,
          partnershipUUID
        );
        results.push(result);
      }

      const isAllSuccess = results.every((result) => result);

      const SUCCESS_MESSAGE_FILE_UPLOADED = '파일이 업로드되었습니다.';
      const ERROR_MESSAGE_FILE_UPLOAD_FAILED = '파일 업로드에 실패했습니다.';

      if (isAllSuccess) {
        toast.success(SUCCESS_MESSAGE_FILE_UPLOADED);
      } else {
        toast.error(ERROR_MESSAGE_FILE_UPLOAD_FAILED);
      }
    }
  };

  return (
    <div className="relative w-full min-w-[1200px] text-[#03183A]">
      <div className="px-40px py-14px rounded-8px flex items-center justify-center border-b border-gray-300 bg-white">
        <div className="w-600px flex items-center justify-end">
          <div className="gap-12px flex items-center">
            <IconShopLarge className="text-gray-400" />
            <p className="text-bold-xl text-gray-600">{retailerName}</p>
          </div>
        </div>
        <div className="mx-20px flex items-center justify-center">
          <IconArrowBothLargeThin className="text-gray-400" />
        </div>
        <div className="w-600px flex items-center">
          <div className="gap-12px flex items-center">
            <IconFactory />
            <p className="text-bold-xl text-gray-600">{supplierName}</p>
          </div>
        </div>
      </div>

      <div className="gap-20px py-32px flex min-h-[calc(100vh-100px)] justify-center bg-gray-100">
        <div className="gap-32px w-600px flex flex-col items-center">
          <div className="gap-16px flex flex-col items-center justify-center">
            <div className="gap-6px flex flex-col items-center">
              <p className="text-extrabold-l text-gray-700">발주 파일 업로드</p>
              <p className="text-medium-m text-gray-500">
                오늘 업로드한 발주 파일이 나타납니다.
              </p>
            </div>
          </div>
          <FileDragAndDropSection
            onUpload={handleFileUpload}
            buttonColor="text-blue-500"
          />
          <div className="gap-16px px-40px flex w-full flex-col">
            <div className="gap-16px p-28px rounded-16px shadow-line flex flex-col items-center border border-gray-200 bg-gray-200">
              <If is={sentFileTransactions.length > 0}>
                {sentFileTransactions.map((fileTransaction) => (
                  <FileItemSent
                    key={fileTransaction.files.original.uuid}
                    filename={fileTransaction.files.original.filename}
                    createdAt={formatDate(
                      new Date(fileTransaction.createdAt),
                      'yyyy-MM-dd HH:mm'
                    )}
                    isConfirmed={fileTransaction.confirmedAt != null}
                    onClickDelete={() => {
                      if (partnershipUUID == null) {
                        return;
                      }

                      oms.retailerOrderTransaction.deletePurchaseOrderTransaction(
                        fileTransaction._id
                      );
                    }}
                  />
                ))}
              </If>
              <If is={sentFileTransactions.length === 0}>
                <FileSectionEmpty />
              </If>
            </div>
          </div>
        </div>
        <div className="mx-4px border border-gray-200" />
        <div className="gap-32px w-600px flex flex-col items-center">
          <div className="gap-16px flex flex-col items-center justify-center">
            <div className="gap-6px flex flex-col items-center">
              <p className="text-extrabold-l text-gray-700">
                운송장 파일 다운로드
              </p>
              <p className="text-medium-m text-gray-500">
                파트너사가 처리한 운송장 파일이 나타납니다.
              </p>
            </div>
          </div>
          <div className="gap-16px px-40px flex w-full flex-col">
            <div className="gap-16px p-28px rounded-16px flex flex-col items-center border border-gray-200 bg-gray-200">
              <If is={receivedFileTransactions.length > 0}>
                {receivedFileTransactions.map((fileTransaction) => (
                  <FileItemReceived
                    key={fileTransaction.files.original.uuid}
                    filename={fileTransaction.files.original.filename}
                    createdAt={formatDate(
                      new Date(fileTransaction.createdAt),
                      'yyyy-MM-dd HH:mm'
                    )}
                    onClickDownload={() => {
                      amplitude.track(
                        'Click download-partnership-shipping-info',
                        {
                          PartnershipUUID: partnershipUUID,
                        }
                      );

                      oms.retailerOrderTransaction.downloadFulfilledOrderFile(
                        fileTransaction._id
                      );
                    }}
                  />
                ))}
              </If>
              <If is={receivedFileTransactions.length === 0}>
                <FileSectionEmpty />
              </If>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(PartnershipPurchaseOrderScreen);
