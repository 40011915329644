import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { If } from '@sweep/utils/react';
import { TableFilterStore } from '../TableFilterStore';
import TableFilterContent from './TableFilterContent';
import FilterTriggerButton from './TableFilterTrigger';

interface TableFilterDropdownProps<T> {
  id: string;
  store: TableFilterStore;
  rows: T[];
  accessorFn?: (order: T) => string | undefined | null;
}

function TableFilterDropdown<T extends Record<string, unknown>>({
  id,
  store,
  rows,
  accessorFn,
}: TableFilterDropdownProps<T>) {
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <FilterTriggerButton
        ref={ref}
        onClick={() => setOpen(!open)}
        active={store.isFiltered(id)}
      />
      <If is={open}>
        <TableFilterContent
          store={store}
          rows={rows}
          id={id}
          accessorFn={accessorFn}
          open={open}
          setOpen={setOpen}
          triggerRef={ref}
        />
      </If>
    </>
  );
}

export default observer(TableFilterDropdown);
