import { EvaluateOperatorResult, Token } from '../../interface';
import { CAN_NOT_CONVERT_TO_NUMBER, INVALID_EXPRESSION } from '../error';
import { parseNumber } from '../parseNumber';

export function evaluateOperatorNeg(stack: Token[]): EvaluateOperatorResult {
  const operand = stack.pop();
  if (operand == null) {
    return INVALID_EXPRESSION;
  }

  const parsed = parseNumber(operand);
  if (parsed == null) {
    return CAN_NOT_CONVERT_TO_NUMBER(operand);
  }

  return { success: true, stack: [...stack, -parsed] };
}
