import { action, computed, observable } from 'mobx';
import { uniqueByUniqueCode } from 'services/order/uniqueByUniqueCode';
import { OMSStore } from 'stores/OMSStore';
import { DispatchedOrder, isDispatchedOrder } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { PluginExecutionService } from '../plugin/PluginExecutionService';

export class DispatchOrderStore {
  @observable
  accessor _dispatchedOrders: DispatchedOrder[] = [];

  @observable
  accessor _lastDispatchedAts: Record<string, number | undefined> = {};

  @observable
  accessor rejectedShoppingMallNames: string[] = [];

  @computed
  get dispatchedOrders() {
    return this._dispatchedOrders;
  }

  @computed
  get lastDispatchedAts() {
    return this._lastDispatchedAts;
  }

  @computed
  get latestDispatchedAt() {
    const lastDispatchedAts = Object.values(this.lastDispatchedAts).filter(
      isNotNil
    );

    if (lastDispatchedAts.length === 0) {
      return null;
    }

    return Math.max(...lastDispatchedAts);
  }

  @computed
  get dispatchedOrdersByUniqueCode() {
    return new Map(
      this.dispatchedOrders.map((order) => [order.uniqueCode, order])
    );
  }

  getDispatchedOrder(uniqueCode: string) {
    return this.dispatchedOrdersByUniqueCode.get(uniqueCode);
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  init() {
    // TODO(@이지원): requestId로 받아오게 되면, API 요청에서 dispatchedOrders 받아오기
    this._dispatchedOrders = this.oms.local.dispatchedOrders.value;
    this._lastDispatchedAts = this.oms.local.lastDispatchedAts.value;
  }

  @action.bound
  setLastDispatchedAts(lastDispatchedAts: Record<string, number | undefined>) {
    this._lastDispatchedAts = lastDispatchedAts;
    this.oms.local.lastDispatchedAts.setValue(lastDispatchedAts);
  }

  @action.bound
  clearLastDispatchedAts() {
    this.setLastDispatchedAts({});
  }

  @action.bound
  pushRejectedShoppingMallName(shoppingMallName: string) {
    if (this.rejectedShoppingMallNames.includes(shoppingMallName)) {
      return;
    }

    this.rejectedShoppingMallNames = [
      ...this.rejectedShoppingMallNames,
      shoppingMallName,
    ];
  }

  @action.bound
  removeRejectedShoppingMallName(shoppingMallNames: string[]) {
    const nameSet = new Set(this.rejectedShoppingMallNames);
    shoppingMallNames.forEach((name) => {
      nameSet.delete(name);
    });
    this.rejectedShoppingMallNames = Array.from(nameSet);
  }

  @action.bound
  updateLastDispatchedAt(shoppingMallName: string, date: number) {
    this.setLastDispatchedAts({
      ...this.lastDispatchedAts,
      [shoppingMallName]: date,
    });
  }

  @action.bound
  setDispatchedOrders(orders: DispatchedOrder[]) {
    this._dispatchedOrders = orders;
    // TODO(@이지원): 주문수집 구 로직이 모두 없어지면, orders를 다 저장하는게 아니라, requestId만 저장하고 새로고침 할 때마다 다시 불러오기
    this.oms.local.dispatchedOrders.setValue(orders);
  }

  @action.bound
  pushDispatchedOrders(orders: DispatchedOrder[]) {
    // NOTE(@형준): 여기 mergeByUniqueCode로 변경해야할지 고민 (SWP-993)
    const newDispatchedOrders = uniqueByUniqueCode([
      ...orders,
      ...this.dispatchedOrders,
    ]);
    this.setDispatchedOrders(newDispatchedOrders);
  }

  @action.bound
  removeDispatchedOrdersByUniqueCodes(uniqueCodes: string[]) {
    const uniqueCodeSet = new Set(uniqueCodes);
    const filteredOrders = this.dispatchedOrders.filter(
      (order) => !uniqueCodeSet.has(order.uniqueCode)
    );
    this.setDispatchedOrders(filteredOrders);
  }

  async transformOrders(orders: DispatchedOrder[]): Promise<DispatchedOrder[]> {
    const plugins = this.oms.plugin.getPlugins(this.oms.user.dispatchPlugins);
    const executionService = new PluginExecutionService(
      this.oms,
      plugins,
      'dispatch'
    );

    const transformedOrders = await executionService.execute(orders);
    const dispatchedOrders = transformedOrders.filter(isDispatchedOrder);
    const uniqueOrders = uniqueByUniqueCode(dispatchedOrders);

    return uniqueOrders;
  }
}
