import { z } from 'zod';

const ExcelColorSchema = z.object({
  argb: z
    .string()
    .describe('Hex string for alpha-red-green-blue e.g. FF00FF00'),
  theme: z.number(),
});

const ExcelFillPatternsSchema = z.enum([
  'none',
  'solid',
  'darkVertical',
  'darkHorizontal',
  'darkGrid',
  'darkTrellis',
  'darkDown',
  'darkUp',
  'lightVertical',
  'lightHorizontal',
  'lightGrid',
  'lightTrellis',
  'lightDown',
  'lightUp',
  'darkGray',
  'mediumGray',
  'lightGray',
  'gray125',
  'gray0625',
]);

const ExcelFillSchema = z.object({
  type: z.literal('pattern'),
  pattern: ExcelFillPatternsSchema,
  fgColor: ExcelColorSchema.partial().optional(),
  bgColor: ExcelColorSchema.partial().optional(),
});
export type ExcelFill = z.infer<typeof ExcelFillSchema>;

const ExcelFontSchema = z.object({
  name: z.string(),
  size: z.number(),
  family: z.number(),
  scheme: z.enum(['minor', 'major', 'none']),
  charset: z.number(),
  color: ExcelColorSchema.partial(),
  bold: z.boolean(),
  italic: z.boolean(),
  underline: z.union([
    z.boolean(),
    z.enum([
      'none',
      'single',
      'double',
      'singleAccounting',
      'doubleAccounting',
    ]),
  ]),
  vertAlign: z.enum(['superscript', 'subscript']),
  strike: z.boolean(),
  outline: z.boolean(),
});
export type ExcelFont = z.infer<typeof ExcelFontSchema>;

const ExcelStyleSchema = z.object({
  font: ExcelFontSchema.partial().optional(),
  fill: ExcelFillSchema.optional(),
});
export type ExcelStyle = z.infer<typeof ExcelStyleSchema>;

const ExcelFormatSchema = z.object({
  type: z.enum(['number', 'date']),
  numFmt: z.string().optional(),
});
export type ExcelFormat = z.infer<typeof ExcelFormatSchema>;

export const ExcelModelSchema = z.object({
  formats: z.record(ExcelFormatSchema).optional(),
  styles: z.record(ExcelStyleSchema).optional(),
});
export type ExcelModel = z.infer<typeof ExcelModelSchema>;
