import { overlay } from 'overlay-kit';
import { useState } from 'react';
import { Button, Modal, TextInput } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { isEmptyString, PHONE_NUMBER_REGEX } from '@sweep/utils';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { client } from 'src/network/client';
import { toast } from 'src/third-parties/toast';

interface UserOpinionModalProps {
  open: boolean;
  onClose: () => void;
}

function UserOpinionModal({ open, onClose }: UserOpinionModalProps) {
  const oms = useOMSStore();
  const [content, setContent] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const validate = (): boolean => {
    if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      toast.error('올바른 전화번호 형식으로 입력해주세요.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (validate() === false) {
      return;
    }

    await oms.loading.batch(() =>
      client.userOpinion.create({
        body: {
          phoneNumber,
          opinion: `*주소변환기 페이지 문의입니다.\n${content.trim()}`,
        },
      })
    );
    toast.success('의견을 남겨주셔서 감사드립니다. 빠르게 답변드리겠습니다.');
    onClose();
  };

  const disabled = isEmptyString(content) || isEmptyString(phoneNumber);

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: true, escapeKey: true }}
      className={modal()}
    >
      <Modal.Title>의견 남기기</Modal.Title>
      <Modal.Description className="mb-20px">
        주소 변환 중 어려움을 겪으시는 지점을 알려주세요.
      </Modal.Description>
      <div className="flex flex-col items-center">
        <textarea
          className={textarea()}
          value={content}
          onChange={(event) => setContent(event.target.value)}
          placeholder="내용을 입력해주세요."
        />
        <div className={inputContainer()}>
          <span className={label()}>연락처</span>
          <TextInput
            value={phoneNumber}
            className="flex-1"
            onChange={setPhoneNumber}
            placeholder="010-0000-0000"
          />
        </div>
        <Button
          onClick={handleSubmit}
          className="w-200px"
          color="gray"
          disabled={disabled}
        >
          제출하기
        </Button>
      </div>
    </Modal>
  );
}

const modal = cva('w-500px');

const textarea = cva([
  'h-136px rounded-8px p-16px mb-20px w-full resize-none',
  'border border-gray-300 outline-none focus:outline-none',
]);

const inputContainer = cva('gap-12px mb-20px flex w-full');

const label = cva('text-medium-s flex-center flex text-gray-700');

export function openUserOpinionModal() {
  return overlay.open(({ isOpen, close, unmount }) => (
    <UserOpinionModal
      open={isOpen}
      onClose={() => {
        close();
        unmount();
      }}
    />
  ));
}
