import { useCallback, useState } from 'react';
import { filterRows as _filterOrders } from '../services/filterRows';
import { sortOrders } from '../services/sortOrders';
import { TableFilterStore } from '../TableFilterStore';

export function useTableFilter() {
  const [store] = useState(() => new TableFilterStore());

  const filterOrders = useCallback(
    <T extends Record<string, unknown>, U extends T | T[]>(rows: U[]): U[] => {
      const removedFieldsOrders = _filterOrders(
        rows,
        store.removedFields,
        store.accessorFns
      );
      const sortedOrders = sortOrders(
        removedFieldsOrders,
        store.sort,
        store.accessorFns
      );
      return sortedOrders;
    },
    [store.removedFields, store.sort, store.accessorFns]
  );

  return { store, filterOrders };
}
