import { PartnerFormModal } from 'forms/partner/PartnerFormModal';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { overlay } from 'overlay-kit';
import { toast } from 'src/third-parties/toast';
import Button from '../../../components/buttons/Button';
import OMSContainer from '../../../components/OMSContainer';
import { openConfirmDialog } from '../../../components/openConfirmDialog';
import PartnerList from '../components/PartnerList';

const PartnerManagementScreen = observer(() => {
  const oms = useOMSStore();

  const handleRegisterClick = () => {
    overlay.open(({ isOpen, unmount }) => (
      <PartnerFormModal
        type="register"
        open={isOpen}
        onSubmit={async (partner) => {
          if (partner == null) {
            unmount();
            return;
          }

          const matchingLength = partner?.matchings?.length ?? 0;
          if (matchingLength === 0) {
            toast.error('판매처의 엑셀 양식을 올려주세요.');
            return;
          }

          oms.partner.register(partner);
          toast.success('판매처가 등록되었습니다.');

          unmount();
        }}
      >
        <PartnerFormModal.InfoInput />
        <hr />
        <PartnerFormModal.FileUpload />
        <hr />
        <PartnerFormModal.ColumnMatchingInput />
      </PartnerFormModal>
    ));
  };

  const handleEditClick = (partnerId: string) => {
    const partner = oms.partner.getPartnerById(partnerId);
    if (partner == null) {
      return;
    }

    const partnershipUUID = partner.partnershipUUID;

    overlay.open(({ isOpen, unmount }) => (
      <PartnerFormModal
        type="update"
        open={isOpen}
        defaultPartner={partner}
        onSubmit={(partner) => {
          if (partner != null) {
            oms.partner.update(partnerId, partner);
            toast.success('판매처 정보가 수정되었습니다.');
          }

          unmount();
        }}
      >
        <PartnerFormModal.InfoInput />
        <hr />
        <div className="flex gap-4">
          <div className="flex-1">
            <PartnerFormModal.FileUpload />
          </div>
          <div className="w-px bg-gray-200" />

          <div className="flex-1">
            <PartnerFormModal.PartnershipCreateInput
              partnerId={partnerId}
              partnershipUUID={partnershipUUID}
              partnerName={partner.name}
              partnerEmail={partner.email}
            />
          </div>
        </div>

        <hr />
        <PartnerFormModal.ColumnMatchingInput />
      </PartnerFormModal>
    ));
  };

  const handleDeleteClick = async (partnerId: string) => {
    const isConfirmed = await openConfirmDialog('판매처를 삭제할까요?');
    if (!isConfirmed) {
      return;
    }
    oms.loading.batch(async () => {
      await oms.partner.delete(partnerId);
      toast.success('판매처가 삭제되었습니다.');
    });
  };

  return (
    <OMSContainer>
      <div className="flex justify-between">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          판매처 등록 및 관리
        </h1>
        <Button name={`+ 판매처 추가`} onClick={handleRegisterClick} />
      </div>

      <div className="mt-[20px]">
        <PartnerList
          partners={oms.partner.partners}
          onClick={(partnerId) => handleEditClick(partnerId)}
          onDelete={(partnerId) => handleDeleteClick(partnerId)}
        />
      </div>
    </OMSContainer>
  );
});

export default PartnerManagementScreen;
