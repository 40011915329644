import { formatDate, uniq } from '@sweep/utils';
import {
  ADDRESS_VALIDATOR_USAGE_DATE_FORMAT,
  ADDRESS_VALIDATOR_USAGE_DATES_KEY,
} from './constants';

export function getUsedDateLength() {
  const usedDates =
    localStorage.getItem(ADDRESS_VALIDATOR_USAGE_DATES_KEY)?.split(',') ?? [];
  const today = formatDate(new Date(), ADDRESS_VALIDATOR_USAGE_DATE_FORMAT);

  return uniq([...usedDates, today]).length;
}
