import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
//https://github.com/VikLiegostaiev/react-page-scroller
//https://github.com/subtirelumihail/react-fullpage?tab=readme-ov-file
//https://github.com/zwug/react-full-page?tab=readme-ov-file
//https://github.com/alvarotrigo/fullPage.js#options
import { useNavigate } from 'react-router-dom';
import Button from '../components/buttons/Button';
import NewFooter from '../components/footer/NewFooter';
import examplePDF2 from '../files/ali.pdf';
import examplePDF from '../files/ex.pdf';
import useGoogleTag from '../hooks/useGoogleTag';
import closeIcon from '../icons/close.png';
import {
  imageLogo11,
  imageLogoAliexpress,
  imageLogoAuction,
  imageLogoGmarket,
  imageLogoKakao,
  imageLogoNaver,
  imgLogoAllways,
  orderimgsection1mobile,
  orderimgsection2mobile,
  orderimgsection3img1mobile,
  orderimgsection3img2mobile,
  orderimgsection3img3mobile,
  orderimgsection4img1mobile,
  orderimgsection4img2mobile,
  part4section1,
  part4section2,
  part4section3left,
  part4section3right,
  part4section4left,
  part4section4right,
} from '../images';
import Sweep from '../images/Sweep.png';

const App = () => {
  useGoogleTag('AW-16638455187');
  const fp = window.fullpage;
  const [showHeader, setShowHeader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [destinationPage, setDestinationPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [direction, setDirection] = useState('');
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [showSecondContent2, setShowSecondContent2] = useState(false);

  const navigate = useNavigate();
  const [animate, setAnimate] = useState(true);
  const onStop = () => setAnimate(false);
  const onRun = () => setAnimate(true);
  const showSecondContentRef = useRef(showSecondContent);
  const showSecondContentRef2 = useRef(showSecondContent2);

  const recatch_iframe_link =
    'https://sweepingoms.recatch.cc/workflows/nsidynrpek?theme=light&primaryColor=%232a7cef';
  // const [isMobile, setIsMobile] = useState(false);
  const isMobile = useMediaQuery({
    query: '(max-width: 1099px) or (orientation: portrait)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 834px) and (orientation: portrait)',
  });
  const downloadPDF2 = () => {
    const link = document.createElement('a');
    link.href = examplePDF2;
    link.setAttribute(
      'download',
      '(스윕) 알리익스프레스 주문관리 솔루션 소개서.pdf'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const scrollRef = useRef(null);

  const feedbackOn = true;
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    new fp('#fullpage', {
      lockAnchors: false,
      navigation: !isMobile || isTablet,
      anchors: ['first', 'second', 'third', 'fourth', 'fifth', 'footer'],
      normalScrollElements: '.scrollable-content',
      navigationPosition: 'right',
      showActiveTooltip: true,
      scrollOverflow: false,
      fixedElements: '#fixedElement',
      // sectionsColor: ['#ff5f45', '#0798ec', '#fc6c7c'],
      menu: '#menu',
      // responsiveHeight: 500,
      scrollingSpeed: 700,
      loopBottom: true,
      afterLoad: (origin, destination, direction) => {
        setDirection('');
        setCurrentPage(destination.index);
      },
      onLeave: (origin, destination, direction) => {
        console.log(
          'leaving from ' +
            origin.index +
            ' to ' +
            destination.index +
            ' ' +
            direction
        );
        setDirection(direction);
        setDestinationPage(destination.index);
        if (direction === 'down') {
          setShowHeader(false);
        } else if (direction === 'up') {
          setShowHeader(true);
        }
        if (destination.index === 0) {
          setShowHeader(true);
        }
      },
    });
    window.fullpage_api.silentMoveTo('first');

    return () => {
      if (window.fullpage_api) {
        window.fullpage_api.destroy('all');
      }
    };
  }, [isMobile, isTablet]);
  const Mobile = ({ children }) => {
    return <>{isMobile && children}</>;
  };

  const PC = ({ children }) => {
    return <>{!isMobile && children}</>;
  };
  const [iframeLoading, setIframeLoading] = useState(true);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  useEffect(() => {
    console.log('current page ' + currentPage);
  }, [currentPage]);

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = examplePDF;
    link.setAttribute('download', '(스윕)서비스소개서.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);
  useEffect(() => {
    (function (r, e, c, a, t, ch) {
      var h = r.getElementsByTagName(e)[0],
        i = r.createElement(c);
      i.async = true;
      i.id = 'recatch-embed-script';
      i.src =
        'https://cdn.recatch.cc/recatch-embed.iife.js?t=' +
        a[0] +
        '&b=' +
        a[1] +
        '&c=' +
        t +
        '&tr=true&th=' +
        ch +
        '&mode=sdk&pc=%23426cdb';
      h.appendChild(i);
    })(
      document,
      'head',
      'script',
      ['sweepingoms', 'nsidynrpek'],
      'recatch-form',
      'light'
    );
  }, []);
  const images = [
    imageLogoKakao,
    imageLogoNaver,
    imageLogo11,
    imageLogoAuction,
    imageLogoGmarket,
    imageLogoAliexpress,
    imgLogoAllways,
  ];
  const hasBanner = false;
  return (
    <div className="flex flex-col">
      {!isMobile && (
        <>
          <header
            className={`font-pretendard fixed  z-10 flex w-full flex-col items-center border-b border-[#E0E7ED] bg-white transition-transform duration-300 ${
              showHeader ? 'translate-y-0' : '-translate-y-full'
            }`}
          >
            {hasBanner && (
              <div className="h-[141px] w-full">
                <div className="flex h-[121px] justify-center bg-[#35B7FF]">
                  <img
                    src={BannerLarge}
                    alt="banner"
                    className="absolute top-0 h-[141px] object-cover"
                  />
                </div>
              </div>
            )}
            <div
              className={`flex w-full max-w-[1200px] flex-col items-center justify-between px-12 py-4 md:flex-row ${hasBanner ? 'pb-4' : 'py-4'}`}
              style={hasBanner ? { paddingTop: 'calc(1rem - 17px)' } : {}}
            >
              <div
                className="logo mx-auto md:mx-0"
                onClick={() => navigate('/')}
              >
                <img
                  src={Sweep}
                  alt="service icon"
                  className="h-auto w-[95px]"
                />
              </div>
              <div className="flex items-center gap-[16px]">
                <div
                  onClick={() => navigate('/sign-in')}
                  className="text-md cursor-pointer rounded-lg px-[16px] py-[10px] text-[16px] font-semibold text-blue-500 hover:bg-blue-100"
                >
                  로그인
                </div>
                <div className="hidden md:block">
                  <Button
                    name="도입 문의하기"
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    className="text-sm"
                  />
                </div>
              </div>
            </div>
          </header>
          <div className="flex flex-col" id="fullpage">
            <div className="section" id="screen1">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="mt-[30px] flex flex-col space-y-[16px] text-center">
                  <span className="text-[35px] font-bold leading-tight text-[#343D4B]">
                    번거로운 복사 붙여넣기 작업 없이
                    <br />
                    쉽고 간편한 원클릭 발주통합
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      파트너사별로 상이한 주문서 엑셀 형식 및 상품명을 직접
                      하나의 엑셀 파일로 변환했던 작업 방식에서 벗어나
                      <br /> 주문 엑셀 파일 입력 시{' '}
                      <span className="text-blue-500">
                        원하는 통합 엑셀 양식
                      </span>
                      으로 자동 변환됩니다.
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[20px]">
                  <img src={part4section1}></img>
                </div>
              </section>
            </div>
            <div className="section" id="screen2">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="mt-[30px] flex flex-col space-y-[16px] text-center">
                  <span className="text-[35px] font-bold leading-tight text-[#343D4B]">
                    송장번호 입력 후 판매처별
                    <br />
                    운송장 일괄 등록 양식으로의 변환도 자동으로
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      택배사 프로그램 또는 공급처로부터 전달받은 송장 입력
                      양식을 수기로 변환할 필요 없이
                      <br />
                      송장 파일 업로드 시{' '}
                      <span className="text-blue-500">
                        판매처별 운송장 일괄 등록 양식으로 자동 변환
                      </span>
                      됩니다.
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[20px]">
                  <img src={part4section2}></img>
                </div>
              </section>
            </div>
            <div className="section" id="screen3">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="flex flex-col space-y-[16px] text-center">
                  <span className="text-[35px] font-bold text-[#343D4B]">
                    그 밖의 번거로운 엑셀 작업도 한 번의 설정을 통해 자동으로
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      쇼핑몰별로 상이하게 등록된 상품명 변환 및 합/배송 분리
                      처리 등<br />그 밖의 모든 번거로운 엑셀 작업도{' '}
                      <span className="text-blue-500">
                        한 번의 설정만으로 자동화
                      </span>
                      하세요.
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[40px]">
                  <div className="flex w-1/2 flex-col gap-[40px]">
                    <div className="relative flex h-[315px] items-center ">
                      <img
                        src={part4section3left}
                        className=" absolute left-[-17px] top-0"
                      ></img>
                    </div>
                    <div className="flex flex-col ">
                      <span className="mb-[16px] text-[24px] font-semibold text-[#263142]">
                        합/분리 배송 처리도 스윕을 통해 자동으로
                      </span>
                      <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                        단 한 번의 클릭만으로 상품별로 합포장 가능한 최대수량을
                        지정하고, <br />
                        합/분리 배송 처리를 자동화하세요
                      </span>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col gap-[40px]">
                    <div className="flex rounded-[16px] bg-[#F2F6FA] px-[40px] py-[25px]">
                      <img src={part4section3right} className=" w-full"></img>
                    </div>
                    <div className="flex flex-col ">
                      <span className="mb-[16px] text-[24px] font-semibold text-[#263142]">
                        쇼핑몰별 상품명 변환도 스윕을 통해 자동으로
                      </span>
                      <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                        쇼핑몰별로 상이하고, 자주 바뀌는 등록 상품명을 매칭하고
                        저장해
                        <br />
                        발주 오류 없이 우리 업체 또는 거래처 상품명으로
                        변환하세요.
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="section" id="screen4">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="flex flex-col space-y-[16px] text-center">
                  <span className="text-[35px] font-bold leading-tight text-[#343D4B]">
                    매일 발주 업무 이후
                    <br />
                    전체 및 판매처/공급처별 판매 수량 집계도 자동으로
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      매일 발주 업무가 종료된 이후 상품별 전체 판매 수량 및
                      판매처/공급처별 판매 수량을
                      <br />
                      자동으로 집계함으로써{' '}
                      <span className="text-blue-500">
                        판매 현황을 파악하고 발주 오류를 최소화
                      </span>
                      하세요.{' '}
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[40px]">
                  <div className="flex  items-center rounded-[16px] bg-[#F2F6FA] px-[80px] py-[60px] ">
                    <img src={part4section4left} className="w-[336px]"></img>
                  </div>

                  <div className="flex rounded-[16px] bg-[#F2F6FA] px-[80px] py-[60px]">
                    <img src={part4section4right} className=" w-[524px]"></img>
                  </div>
                </div>
              </section>
            </div>
            <div className="section fp-auto-height">
              <NewFooter isMobile={false} />
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <div
          className="fp-auto-height flex flex-col"
          id="fullpage"
          ref={scrollRef}
        >
          <div className="section" id="screen1">
            <section className="relative flex h-full h-screen flex-col gap-[30px]">
              <div
                className="flex h-[64px] w-full items-center justify-between px-[40px]"
                onClick={() => navigate('/')}
              >
                <img src={Sweep} className="h-[24px]"></img>
              </div>
              <div className="flex w-full  flex-col items-center justify-center">
                <div className="mb-[40px] flex w-full flex-col space-y-[16px] px-[40px]">
                  <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                    번거로운 작업 없이 쉽고
                    <br />
                    간편한 원클릭 발주통합
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      파트너사별로 상이한 주문서 엑셀 형식 및<br />
                      상품명을 직접 하나의 엑셀 파일로 변환했던
                      <br />
                      작업 방식에서 벗어나 주문 엑셀 파일 입력 시<br />
                      원하는 통합 엑셀 양식으로 자동 변환됩니다.
                    </span>
                  </div>
                </div>
                <div className="flex w-full flex-col gap-[12px] px-[40px]">
                  <img src={orderimgsection1mobile}></img>
                </div>
              </div>
            </section>
          </div>
          <div className="section" id="screen2">
            <section className="relative flex h-full h-screen flex-col items-center  space-y-[40px] pt-[40px]">
              <div className="flex w-full flex-col space-y-[16px] pl-[40px]">
                <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                  판매처별 운송장 일괄 등록
                  <br /> 양식 변환도 자동으로
                </span>
                <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                  <span>
                    택배사 프로그램 또는 공급처로부터 전달받은
                    <br /> 송장 입력 양식을 수기로 변환할 필요 없이
                    <br />
                    송장 파일 업로드 시 판매처별 운송장 일괄 등록
                    <br /> 양식으로 자동 변환됩니다.
                  </span>
                </div>
              </div>
              <div className="flex w-full  flex-col gap-[12px] px-[40px]">
                <img src={orderimgsection2mobile}></img>
              </div>
            </section>
          </div>
          <div className="section" id="screen3">
            <section className="relative flex h-full h-screen flex-col items-center  space-y-[20px] pt-[40px]">
              <div className="flex w-full flex-col space-y-[16px] pl-[40px]">
                <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                  그 밖의 번거로운 작업도
                  <br />
                  한 번의 설정을 통해
                  <br />
                  자동으로
                </span>
                <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                  <span>
                    쇼핑몰별로 상이하게 등록된 상품명 변환 및<br />
                    합/배송 분리 처리 등 그 밖의 모든 번거로운 <br />
                    엑셀 작업도 한 번의 설정만으로 자동화하세요.
                    <br />
                  </span>
                </div>
              </div>
              <div className="flex w-full  flex-col gap-[12px] px-[40px]">
                <img src={orderimgsection3img1mobile}></img>
                <img src={orderimgsection3img2mobile}></img>
              </div>
            </section>
          </div>
          <div className="section fp-auto-height fp-noscroll " id="screen4">
            <div className="flex flex-col px-[40px] py-[30px]">
              <div className="animate-slideindown flex size-full flex-col">
                {' '}
                <span className="mb-[16px] text-[20px] font-semibold text-[#263142]">
                  합/분리 배송 처리도
                  <br />
                  스윕을 통해 자동으로
                </span>
                <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                  단 한 번의 클릭만으로 상품별로 합포장 <br />
                  가능한 최대수량을 지정하고, <br />
                  합/분리 배송 처리를 자동화하세요
                </span>
              </div>
            </div>
          </div>
          <div className="section" id="screen5">
            <section className="relative flex size-full h-screen flex-col items-center space-y-[40px] pt-[100px]">
              <img
                src={orderimgsection3img3mobile}
                className="h-[170px] w-[295px]"
              ></img>
              <div className="flex w-full flex-col px-[40px] py-[30px]">
                <div className="animate-slideindown flex size-full flex-col">
                  {' '}
                  <span className="mb-[16px] text-[20px] font-semibold text-[#263142]">
                    쇼핑몰별 상품명 변환도
                    <br />
                    스윕을 통해 자동으로{' '}
                  </span>
                  <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                    쇼핑몰별로 상이하고, 자주 바뀌는
                    <br />
                    등록 상품명을 매칭하고 저장해
                    <br />
                    발주 오류 없이 우리 업체 또는 거래처
                    <br />
                    상품명으로 변환하세요.
                  </span>
                </div>
              </div>
            </section>
          </div>
          <div className="section" id="screen5">
            <section className="relative flex h-full h-screen flex-col items-center space-y-[20px] pt-[40px]">
              <div className="flex w-full flex-col space-y-[16px] pl-[40px]">
                <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                  매일 발주 업무 이후
                  <br />
                  전체 및 판매처/공급처별
                  <br /> 판매 수량 집계도 자동으로
                </span>
                <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                  <span>
                    매일 발주 업무가 종료된 이후 상품별
                    <br />
                    전체 판매 수량 및 판매처/공급처별 판매 수량을
                    <br />
                    자동으로 집계함으로써 판매 현황을 파악하고
                    <br /> 발주 오류를 최소화하세요.
                  </span>
                </div>
              </div>
              <div className="flex w-full  flex-col gap-[12px] px-[40px]">
                <img src={orderimgsection4img1mobile}></img>
                <img src={orderimgsection4img2mobile}></img>
              </div>
            </section>
          </div>
          <div className="section fp-auto-height">
            <NewFooter isMobile={false} />
          </div>
        </div>
      )}
      {isModalOpen && (
        <div
          onClick={() => setIsModalOpen(false)}
          className={`fixed inset-0 z-40 flex size-full items-center justify-center overflow-visible bg-gray-700 bg-opacity-50`}
        >
          <div
            className={`h-[820px] w-full scale-75  rounded-3xl border bg-white pt-[30px] shadow-lg md:w-[530px]`}
          >
            <div className="relative size-full px-[10px]">
              <button
                className="modal-close absolute right-7 top-[-5px] z-40 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              >
                <img src={closeIcon} alt="close" className="h-[32px]" />
              </button>
              {iframeLoading && (
                <p className="fixed inset-0 z-50 flex items-center justify-center">
                  기다려 주세요...
                </p>
              )}
              <iframe
                className="z-10 size-full pb-10"
                src={recatch_iframe_link}
                onLoad={handleIframeLoad}
                style={{
                  display: iframeLoading ? 'none' : 'block',
                }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
