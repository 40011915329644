import { DateRange, startOfDay, subDays, subMonths } from '@sweep/utils';

export function getRangeSelectOptions(): {
  value: DateRange;
  label: string;
}[] {
  const today = startOfDay(new Date());

  return [
    {
      value: {
        from: today,
        to: today,
      },
      label: '오늘',
    },
    {
      value: {
        from: subDays(today, 7),
        to: today,
      },
      label: '1주',
    },
    {
      value: {
        from: subMonths(today, 1),
        to: today,
      },
      label: '1개월',
    },
    {
      value: {
        from: subMonths(today, 6),
        to: today,
      },
      label: '6개월',
    },
  ];
}
