import { z } from 'zod';

export const SupplierCustomizedSettingsSchema = z.object({
  templateFile: z.string().optional(),
  headerRowIndex: z.number().optional(),
  minRowIndex: z.number().optional(),
  isTemplateFileEnabled: z.boolean().optional(),
});

export type SupplierCustomizedSettings = z.infer<
  typeof SupplierCustomizedSettingsSchema
>;

export const UserCustomizedSettingsSchema = z.object({
  fields: z
    .array(
      z.object({
        target: z.string(),
        type: z.enum(['number']),
      })
    )
    .optional(),
});

export type UserCustomizedSettings = z.infer<
  typeof UserCustomizedSettingsSchema
>;

export const ExcelFileRecordSchema = z.object({
  filename: z.string(),
  fileExtension: z.string(),
  orders: z.array(z.record(z.string(), z.unknown())),
  headers: z.array(z.string()),
  headerKeys: z.array(z.string()),
  columnMappings: z.record(z.string(), z.string()),
  separateAddressEnabled: z.boolean(),
  userExcelSettingFields: UserCustomizedSettingsSchema.optional(),
  supplierCustomizedSettings: SupplierCustomizedSettingsSchema.optional(),
});

export type ExcelFileRecord = z.infer<typeof ExcelFileRecordSchema>;
