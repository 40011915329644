import { Order } from '@sweep/contract';
import { DAY_IN_MS, formatDate, parseNumber } from '@sweep/utils';
import { AbstractPlugin } from '../../interface';

interface ExcelCustomFormatDatePluginConfig {
  rules: {
    key: string;
    format: string;
  }[];
}

export class ExcelCustomFormatDatePlugin extends AbstractPlugin<ExcelCustomFormatDatePluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order): Order => {
    this.config.rules.forEach((rule) => {
      const { key, format } = rule;
      const value = order[key];
      if (typeof value !== 'string' && typeof value !== 'number') {
        return;
      }

      const serialNumber = parseNumber(value);
      if (serialNumber === null) {
        return;
      }

      const date = toDateFromSerialNumber(serialNumber);

      order[key] = formatDate(date, format);
    });
    return order;
  };
}

// NOTE(@이지원): https://stackoverflow.com/a/64107728
function toDateFromSerialNumber(serialNumber: number) {
  const date = new Date(Math.round((serialNumber - 25569) * DAY_IN_MS));
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  return date;
}
