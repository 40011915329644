import { z } from 'zod';
import { c } from '../internal/contract';
import { AnyAddressSchema, NormalizedAddressSchema } from '../type/Address';

export const addressContract = c.router({
  normalizeAddresses: {
    method: 'POST',
    path: '/address/normalize',
    body: z.array(AnyAddressSchema),
    responses: {
      200: z.array(NormalizedAddressSchema),
    },
  },
});
