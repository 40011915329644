import { action, computed, observable, runInAction } from 'mobx';
import { Partnership, PARTNERSHIP_TYPE } from '@sweep/contract';
import * as PartnershipAPI from 'src/network/partnership/PartnershipAPI';
import { OMSStore } from '../OMSStore';

export class PartnershipStore {
  @observable accessor outboundPartnerships: Partnership[] = [];
  @observable accessor inboundPartnerships: Partnership[] = [];

  constructor(private readonly oms: OMSStore) {}

  @computed
  get partnerships() {
    const mergedPartnerships = [
      ...this.outboundPartnerships,
      ...this.inboundPartnerships,
    ];

    const partnershipKeyByUUID = new Map<string, Partnership>();

    for (const partnership of mergedPartnerships) {
      partnershipKeyByUUID.set(partnership.partnershipUUID, partnership);
    }

    return partnershipKeyByUUID;
  }

  @action.bound
  async init() {
    await this.loadPartnerships();
  }

  @action.bound
  async loadPartnerships() {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return;
    }

    const outboundPartnershipResponse =
      await PartnershipAPI.findAllPartnerships({
        retailerUserId: userId,
      });

    if (
      outboundPartnershipResponse.status === 200 &&
      outboundPartnershipResponse.body.success
    ) {
      const outboundPartnerships = outboundPartnershipResponse.body.data;

      runInAction(() => {
        this.outboundPartnerships = outboundPartnerships;
      });
    }

    const inboundPartnershipResponse = await PartnershipAPI.findAllPartnerships(
      {
        supplierUserId: userId,
      }
    );

    if (
      inboundPartnershipResponse.status === 200 &&
      inboundPartnershipResponse.body.success
    ) {
      const inboundPartnerships = inboundPartnershipResponse.body.data;

      runInAction(() => {
        this.inboundPartnerships = inboundPartnerships;
      });
    }
  }

  @action.bound
  async registerRetailerPartnership(params: {
    partnerId: string;
    partnerName: string;
    partnerEmail: string;
  }) {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return;
    }

    const response = await PartnershipAPI.registerRetailerPartnership({
      partnershipType: PARTNERSHIP_TYPE.FULFILLMENT,
      retailerName: params.partnerName,
      retailerEmail: params.partnerEmail,
      supplierPartnerId: params.partnerId,
    });

    if (response.status === 200 && response.body.success) {
      const successResponse = response.body.data;
      const partnershipUUID = successResponse.partnershipUUID;
      const partnership = successResponse.partnership;

      const partnerId = partnership.supplier.partnerId;
      if (partnerId != null) {
        this.oms.partner.update(partnerId, {
          partnershipUUID,
        });
      }

      runInAction(() => {
        this.inboundPartnerships.push(partnership);
      });

      return partnershipUUID;
    }

    return null;
  }
}
