import React, { createContext, useContext } from 'react';
import { assert } from '@sweep/utils';
import { useRefs } from '@sweep/utils/react';
import { Slot } from '../Slot';

type CollectionElement = HTMLElement;
export type CollectionProps = React.ComponentPropsWithoutRef<typeof Slot>;

// We have resorted to returning slots directly rather than exposing primitives that can then
// be slotted like `<CollectionItem as={Slot}>…</CollectionItem>`.
// This is because we encountered issues with generic types that cannot be statically analysed
// due to creating them dynamically via createCollection.

export function createCollection<
  ItemElement extends HTMLElement,
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  ItemData = {},
>(name: string) {
  /* -----------------------------------------------------------------------------------------------
   * CollectionProvider
   * ---------------------------------------------------------------------------------------------*/

  const PROVIDER_NAME = name + 'CollectionProvider';

  type ContextValue = {
    collectionRef: React.RefObject<CollectionElement | null>;
    itemMap: Map<
      React.RefObject<ItemElement | null>,
      { ref: React.RefObject<ItemElement | null> } & ItemData
    >;
  };
  const context = createContext<ContextValue | null>(null);

  const CollectionProvider: React.FC<{
    children?: React.ReactNode;
  }> = (props) => {
    const { children } = props;
    const ref = React.useRef<CollectionElement>(null);
    const itemMap = React.useRef<ContextValue['itemMap']>(new Map()).current;
    return (
      <context.Provider
        value={{
          itemMap,
          collectionRef: ref,
        }}
      >
        <Slot ref={ref}>{children}</Slot>
      </context.Provider>
    );
  };

  CollectionProvider.displayName = PROVIDER_NAME;

  /* -----------------------------------------------------------------------------------------------
   * useCollectionContext
   * ---------------------------------------------------------------------------------------------*/

  function useCollectionContext() {
    const collectionContext = useContext(context);
    assert(collectionContext != null, 'Collection context not found');

    return collectionContext;
  }

  /* -----------------------------------------------------------------------------------------------
   * CollectionSlot
   * ---------------------------------------------------------------------------------------------*/

  const COLLECTION_SLOT_NAME = name + 'CollectionSlot';

  const CollectionSlot = React.forwardRef<CollectionElement, CollectionProps>(
    (props, forwardedRef) => {
      const { children } = props;
      const collectionContext = useCollectionContext();
      const composedRefs = useRefs([
        forwardedRef,
        collectionContext.collectionRef,
      ]);
      return <Slot ref={composedRefs}>{children}</Slot>;
    }
  );

  CollectionSlot.displayName = COLLECTION_SLOT_NAME;

  /* -----------------------------------------------------------------------------------------------
   * CollectionItem
   * ---------------------------------------------------------------------------------------------*/

  const ITEM_SLOT_NAME = name + 'CollectionItemSlot';
  const ITEM_DATA_ATTR = 'data-sds-collection-item';

  type CollectionItemSlotProps = ItemData & {
    children: React.ReactNode;
  };

  const CollectionItemSlot = React.forwardRef<
    ItemElement,
    CollectionItemSlotProps
  >((props, forwardedRef) => {
    const { children, ...itemData } = props;
    const ref = React.useRef<ItemElement>(null);
    const composedRefs = useRefs([forwardedRef, ref]);
    const collectionContext = useCollectionContext();

    React.useEffect(() => {
      collectionContext.itemMap.set(ref, {
        ref,
        ...(itemData as unknown as ItemData),
      });
      return () => void collectionContext?.itemMap.delete(ref);
    });

    return (
      <Slot {...{ [ITEM_DATA_ATTR]: '' }} ref={composedRefs}>
        {children}
      </Slot>
    );
  });

  CollectionItemSlot.displayName = ITEM_SLOT_NAME;

  /* -----------------------------------------------------------------------------------------------
   * useCollection
   * ---------------------------------------------------------------------------------------------*/

  function useCollection() {
    const collectionContext = useCollectionContext();

    const getItems = React.useCallback(() => {
      const collectionNode = collectionContext.collectionRef.current;
      if (!collectionNode) {
        return [];
      }
      const orderedNodes = Array.from(
        collectionNode.querySelectorAll(`[${ITEM_DATA_ATTR}]`)
      );
      const items = Array.from(collectionContext.itemMap.values());
      const orderedItems = items.sort(
        (a, b) =>
          orderedNodes.indexOf(a.ref.current!) -
          orderedNodes.indexOf(b.ref.current!)
      );
      return orderedItems;
    }, [collectionContext.collectionRef, collectionContext.itemMap]);

    return getItems;
  }

  return [
    {
      Provider: CollectionProvider,
      Slot: CollectionSlot,
      ItemSlot: CollectionItemSlot,
    },
    useCollection,
  ] as const;
}
