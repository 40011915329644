import { Workbook } from 'exceljs';

export function getUniqueSheetName(sheetName: string, workbook: Workbook) {
  const sheetNames = workbook.worksheets.map((worksheet) => worksheet.name);
  if (!sheetNames.includes(sheetName)) {
    return sheetName;
  }

  let uniqueSheetName = sheetName;
  let counter = 1;
  while (sheetNames.includes(uniqueSheetName)) {
    uniqueSheetName = `${sheetName} (${counter})`;
    counter++;
  }

  return uniqueSheetName;
}
