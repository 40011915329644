import saveAs from 'file-saver';
import { action, computed, observable, runInAction } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import {
  FulfilledOrderTransaction,
  PurchaseOrderTransaction,
  REMOTE_STORAGE_FILE_TYPE,
  SEND_ORDER_TRANSACTION_PROGRESS_STATUS,
  SendOrderTransactionProgress,
} from '@sweep/contract';
import { FormulaeSpreadsheetTypes } from '@sweep/spreadsheet/types';
import * as SpreadsheetUtils from '@sweep/spreadsheet/utils';
import { formatDate } from '@sweep/utils';
import * as FulfilledOrderTransactionAPI from 'src/network/partnership/FulfilledOrderTransactionAPI';
import * as PurchaseOrderTransactionAPI from 'src/network/partnership/PurchaseOrderTransactionAPI';
import * as RemoteStorageFileAPI from 'src/network/partnership/RemoteStroageFileAPI';
import { MultiSheetExcelFile } from 'src/services/file/interface';
import { toast } from 'src/third-parties/toast';
import { modifyFilename } from 'src/utils/modifyFilename';
import { OMSStore } from '../OMSStore';

const ERROR_MESSAGE_FILE_DOWNLOAD_FAILED = '파일 다운로드에 실패했습니다.';
const ERROR_MESSAGE_FILE_UPLOAD_FAILED = '파일 업로드에 실패했습니다.';

export class SupplierOrderTransactionStore {
  @observable accessor isInitialized: boolean = false;
  @observable accessor selectedDate: string;

  @observable accessor outgoingTransactions: FulfilledOrderTransaction[] = [];
  @observable accessor incomingTransactions: PurchaseOrderTransaction[] = [];
  @observable accessor lastIncomingTransactionId: string | null = null;

  @observable
  accessor outgoingTransactionProgress: SendOrderTransactionProgress[] = [];

  @computed
  get newReceivedFiles() {
    return this.incomingTransactions.filter(
      (transaction) => transaction.confirmedAt == null
    );
  }

  @computed
  get confirmedFiles() {
    return this.incomingTransactions.filter(
      (transaction) => transaction.confirmedAt != null
    );
  }

  constructor(private oms: OMSStore) {
    this.selectedDate = formatDate(new Date(), 'yyyy-MM-dd');
  }

  @action.bound
  async init() {
    await this.loadOutgoingTransactions(this.selectedDate);
    await this.loadIncomingTransactions(this.selectedDate);

    runInAction(() => {
      this.isInitialized = true;
    });
  }

  @action.bound
  async loadOutgoingTransactions(date: string) {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return;
    }

    const response =
      await FulfilledOrderTransactionAPI.findAllFulfilledOrderTransactions({
        supplierUserId: userId,
        createdAtDate: date,
      });

    if (response.status === 200 && response.body.success) {
      runInAction(() => {
        this.outgoingTransactions = response.body.data;
      });
    }

    // TODO(@형준) 오류 처리
  }

  @action.bound
  async loadIncomingTransactions(date: string) {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return;
    }

    const response =
      await PurchaseOrderTransactionAPI.findAllPurchaseOrderTransactions({
        supplierUserId: userId,
        createdAtDate: date,
      });

    if (response.status === 200 && response.body.success) {
      runInAction(() => {
        const incomingTransactions = response.body.data;
        const sortedIncomingTransactions = incomingTransactions.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        const lastIncomingTransaction =
          sortedIncomingTransactions[sortedIncomingTransactions.length - 1];

        this.incomingTransactions = sortedIncomingTransactions;
        this.lastIncomingTransactionId = lastIncomingTransaction?._id;
      });
    }

    // TODO(@형준) 오류 처리
  }

  @action.bound
  async checkAndReloadIncomingTransactions(): Promise<boolean> {
    const currentDate = formatDate(new Date(), 'yyyy-MM-dd');

    if (currentDate !== this.selectedDate) {
      this.selectedDate = currentDate;
      await this.loadOutgoingTransactions(currentDate);
      await this.loadIncomingTransactions(currentDate);

      return true;
    }

    const isAvailable = await this.isIncomingTransactionAvailable();

    if (isAvailable) {
      await this.loadIncomingTransactions(currentDate);

      return true;
    }

    return false;
  }

  private async isIncomingTransactionAvailable(): Promise<boolean> {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return false;
    }

    const lastTransactionId =
      this.incomingTransactions[this.incomingTransactions.length - 1]?._id;

    const response =
      await PurchaseOrderTransactionAPI.countPurchaseOrderTransactions({
        supplierUserId: userId,
        lastTransactionId: lastTransactionId ?? undefined,
      });

    if (response.status === 200 && response.body.success) {
      return response.body.data.count > 0;
    }

    return false;
  }

  @action.bound
  async confirm(transactionId: string) {
    const response =
      await PurchaseOrderTransactionAPI.confirmPurchaseOrderTransaction(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      const confirmedAt = response.body.data.confirmedAt;
      runInAction(() => {
        const updatedIncomingTransactions = this.incomingTransactions.map(
          (transaction) => {
            if (transaction._id === transactionId) {
              return { ...transaction, confirmedAt };
            }

            return transaction;
          }
        );

        this.incomingTransactions = updatedIncomingTransactions;
      });
    }
  }

  @action.bound
  async downloadPreprocessedContent(
    transactionId: string
  ): Promise<MultiSheetExcelFile | null> {
    const selectedTransaction = this.incomingTransactions.find(
      (transaction) => transaction._id === transactionId
    );

    if (selectedTransaction == null) {
      return null;
    }

    const response =
      await PurchaseOrderTransactionAPI.getPurchaseOrderTransactionPreprocessedContent(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      const { content } = response.body.data;
      const parsedData = JSON.parse(content);

      const { filename, extension } = selectedTransaction.files.original;

      return convertParsedDataToMultiSheetExcelFile({
        filename: filename,
        extension: extension,
        data: parsedData,
      });
    }

    return null;
  }

  @action.bound
  clearOutgoingTransactionProgress() {
    this.outgoingTransactionProgress = [];
  }

  @action.bound
  async sendFulfilledPurchaseOrderFile(
    params: SendOrderTransactionProgress['payload']
  ): Promise<SendOrderTransactionProgress> {
    const { partnershipUUID, buffer, filename } = params;
    const partnership = this.oms.partnership.partnerships.get(partnershipUUID);

    const sendOrderTransactionProgress: SendOrderTransactionProgress = {
      isSuccess: false,
      partnershipUUID,
      statuses: [SEND_ORDER_TRANSACTION_PROGRESS_STATUS.PENDING],
      payload: params,
      extra: {
        partnerName: partnership?.retailer.name ?? '',
      },
      key: uuidV4(),
    };

    const supplierUserId = this.oms.user.userId;

    if (supplierUserId == null) {
      return this.appendOutgoingTransactionProgress({
        ...sendOrderTransactionProgress,
        statuses: [
          ...sendOrderTransactionProgress.statuses,
          SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
        ],
      });
    }

    if (partnership == null) {
      return this.appendOutgoingTransactionProgress({
        ...sendOrderTransactionProgress,
        statuses: [
          ...sendOrderTransactionProgress.statuses,
          SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
        ],
      });
    }

    const retailerUserId = partnership.retailerUserId;

    return await this.oms.loading.batch(async () => {
      const file = new File([buffer], filename, {
        type: 'application/octet-stream',
      });

      const modifiedFile = modifyFilename(
        file,
        (name) => `${partnership.supplier.name}_${name}`
      );

      const remoteStorageFile = await RemoteStorageFileAPI.upload(
        modifiedFile,
        REMOTE_STORAGE_FILE_TYPE.SPREADSHEET
      );

      if (
        remoteStorageFile.status !== 200 ||
        remoteStorageFile.body.success === false
      ) {
        toast.error(ERROR_MESSAGE_FILE_UPLOAD_FAILED);
        return this.appendOutgoingTransactionProgress({
          ...sendOrderTransactionProgress,
          statuses: [
            ...sendOrderTransactionProgress.statuses,
            SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
          ],
        });
      }

      const { fileUUID } = remoteStorageFile.body.data;

      const response =
        await FulfilledOrderTransactionAPI.createFulfilledOrderTransaction({
          partnershipUUID,
          fileUUID,
          retailerUserId,
          supplierUserId,
        });

      if (response.status === 200 && response.body.success) {
        sendOrderTransactionProgress.statuses = [
          ...sendOrderTransactionProgress.statuses,
          SEND_ORDER_TRANSACTION_PROGRESS_STATUS.SUCCESS,
        ];
        sendOrderTransactionProgress.isSuccess = true;
      }

      return this.appendOutgoingTransactionProgress({
        ...sendOrderTransactionProgress,
        statuses: [
          ...sendOrderTransactionProgress.statuses,
          SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
        ],
      });
    });
  }

  @action.bound
  async retryFailedOutgoingTransactionProgress(): Promise<boolean> {
    const failedTransactionProgress = this.outgoingTransactionProgress.filter(
      (transaction) => transaction.isSuccess === false
    );

    await this.oms.loading.batch(async () => {
      for (const transactionProgress of failedTransactionProgress) {
        const { partnershipUUID, buffer, filename } =
          transactionProgress.payload;
        const partnership =
          this.oms.partnership.partnerships.get(partnershipUUID);
        const supplierUserId = this.oms.user.userId;

        if (supplierUserId == null || partnership == null) {
          continue;
        }

        const retailerUserId = partnership.retailerUserId;

        // Update status to PENDING again
        const updatedProgress = {
          ...transactionProgress,
          statuses: [SEND_ORDER_TRANSACTION_PROGRESS_STATUS.PENDING],
        };

        this.outgoingTransactionProgress = this.outgoingTransactionProgress.map(
          (progress) =>
            progress.key === updatedProgress.key ? updatedProgress : progress
        );

        const file = new File([buffer], filename, {
          type: 'application/octet-stream',
        });

        const remoteStorageFile = await RemoteStorageFileAPI.upload(
          file,
          REMOTE_STORAGE_FILE_TYPE.SPREADSHEET
        );

        if (
          remoteStorageFile.status !== 200 ||
          remoteStorageFile.body.success === false
        ) {
          toast.error(ERROR_MESSAGE_FILE_UPLOAD_FAILED);

          // Update with FAILED status
          const failedProgress = {
            ...updatedProgress,
            statuses: [
              ...updatedProgress.statuses,
              SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
            ],
            isSuccess: false,
          };

          this.outgoingTransactionProgress =
            this.outgoingTransactionProgress.map((progress) =>
              progress.key === failedProgress.key ? failedProgress : progress
            );

          continue;
        }

        const { fileUUID } = remoteStorageFile.body.data;

        const response =
          await FulfilledOrderTransactionAPI.createFulfilledOrderTransaction({
            partnershipUUID,
            fileUUID,
            retailerUserId,
            supplierUserId,
          });

        if (response.status === 200 && response.body.success) {
          // Update with SUCCESS status
          const successProgress = {
            ...updatedProgress,
            statuses: [
              ...updatedProgress.statuses,
              SEND_ORDER_TRANSACTION_PROGRESS_STATUS.SUCCESS,
            ],
            isSuccess: true,
          };

          this.outgoingTransactionProgress =
            this.outgoingTransactionProgress.map((progress) =>
              progress.key === successProgress.key ? successProgress : progress
            );
        } else {
          // Update with FAILED status
          const failedProgress = {
            ...updatedProgress,
            statuses: [
              ...updatedProgress.statuses,
              SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
            ],
            isSuccess: false,
          };

          this.outgoingTransactionProgress =
            this.outgoingTransactionProgress.map((progress) =>
              progress.key === failedProgress.key ? failedProgress : progress
            );
        }
      }
    });

    const isAllSuccess = this.outgoingTransactionProgress.every(
      (transactionProgress) => transactionProgress.isSuccess
    );

    return isAllSuccess;
  }

  @action.bound
  async downloadPurchaseOrderFile(transactionId: string) {
    const response =
      await PurchaseOrderTransactionAPI.getPurchaseOrderTransactionDownloadUrl(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      const downloadUrl = response.body.data.downloadUrl;

      saveAs(downloadUrl);
    } else {
      toast.error(ERROR_MESSAGE_FILE_DOWNLOAD_FAILED);
    }
  }

  @action.bound
  async downloadFulfilledOrderFile(transactionId: string) {
    const response =
      await FulfilledOrderTransactionAPI.getFulfilledOrderTransactionDownloadUrl(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      const downloadUrl = response.body.data.downloadUrl;

      saveAs(downloadUrl);
    } else {
      toast.error(ERROR_MESSAGE_FILE_DOWNLOAD_FAILED);
    }
  }

  private appendOutgoingTransactionProgress(
    dto: SendOrderTransactionProgress
  ): SendOrderTransactionProgress {
    const existingTransactionProgress = this.outgoingTransactionProgress.find(
      (transactionProgress) => transactionProgress.key === dto.key
    );

    if (existingTransactionProgress != null) {
      this.outgoingTransactionProgress = this.outgoingTransactionProgress.map(
        (transactionProgress) => {
          if (transactionProgress.key === dto.key) {
            return dto;
          }

          return transactionProgress;
        }
      );
    }

    this.outgoingTransactionProgress.push(dto);

    return dto;
  }
}

function convertParsedDataToMultiSheetExcelFile(params: {
  filename: string;
  extension: string;
  data: FormulaeSpreadsheetTypes.IntermediateData;
}): MultiSheetExcelFile {
  const { filename, extension, data } = params;

  const sheets = data.sheets;
  const convertedData = sheets.map(({ data }) =>
    SpreadsheetUtils.convertFormulaeTo2DArrayWithTrim(data)
  );

  return {
    data: convertedData,
    name: filename,
    extension,
    templateFile: data.templateFile,
  };
}
