import { Workbook, Worksheet } from 'exceljs';
import copy from 'fast-copy';
import { getUniqueSheetName } from './getUniqueSheetName';

export function addClonedWorksheet(
  workbook: Workbook,
  originalSheet: Worksheet,
  sheetName: string
) {
  const uniqueSheetName = getUniqueSheetName(sheetName, workbook);
  const clonedSheet = workbook.addWorksheet(uniqueSheetName, originalSheet);

  originalSheet.columns.forEach((column, colIndex) => {
    const clonedColumn = clonedSheet.getColumn(colIndex + 1);
    clonedColumn.width = column.width;
    if (column.hidden != null) {
      clonedColumn.hidden = column.hidden;
    }
    if (column.style != null) {
      clonedColumn.style = copy(column.style);
    }
  });

  originalSheet.eachRow((row, rowNumber) => {
    const newRow = clonedSheet.getRow(rowNumber);
    newRow.height = row.height;
    newRow.hidden = row.hidden;
    newRow.model = copy(row.model);

    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const newCell = newRow.getCell(colNumber);
      newCell.value = cell.value;
      newCell.style = copy(cell.style);
      newCell.model = copy(cell.model);
    });
  });

  // NOTE(@이지원): 병합된 셀의 정보가 private으로 되어 있어서 타입추론이 안 됨
  // @example
  // {
  //   'Q1': { model: { top: 1, left: 17, bottom: 1, right: 18 } },
  // }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const merges = originalSheet._merges as Record<
    string,
    { model: { top: number; left: number; bottom: number; right: number } }
  >;

  Object.values(merges).forEach(({ model }) => {
    clonedSheet.mergeCells(model.left, model.top, model.right, model.bottom);
  });

  return clonedSheet;
}
