import { Workbook } from 'exceljs';
import { getUniqueSheetName } from './getUniqueSheetName';

export function addTableWorksheet({
  workbook,
  rows,
  sheetName: givenSheetName,
}: {
  workbook: Workbook;
  rows: string[][];
  sheetName: string;
}) {
  const sheetName = getUniqueSheetName(givenSheetName, workbook);
  const worksheet = workbook.addWorksheet(sheetName);
  worksheet.addRows(rows);

  const firstRow = worksheet.getRow(1);
  firstRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD3D3D3' },
    };
    cell.font = { bold: true };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });

  return worksheet;
}
