import { action, observable } from 'mobx';

export type Sort = {
  id: string | null;
  type: SortType;
};

export type SortType = 'ASC' | 'DESC' | null;

type AccessorFn = (row: any) => string | undefined | null;

export class TableFilterStore {
  @observable
  accessor sort: Sort = { id: null, type: null };

  @observable
  accessor removedFields: Record<string, string[] | undefined> = {};

  @observable
  accessor accessorFns: Record<string, AccessorFn | undefined | null> = {};

  isFiltered(id: string) {
    return (this.removedFields[id]?.length ?? 0) > 0;
  }

  constructor() {}

  @action.bound
  setSort(id: string | null, type: SortType) {
    this.sort = { id, type };
  }

  @action.bound
  setRemovedFields(id: string, values: string[]) {
    this.removedFields = { ...this.removedFields, [id]: values };
  }

  @action.bound
  setAccessorFn(id: string, accessorFn: AccessorFn | undefined | null) {
    this.accessorFns = { ...this.accessorFns, [id]: accessorFn };
  }

  @action.bound
  reset() {
    this.sort = { id: null, type: null };
    this.removedFields = {};
  }
}
