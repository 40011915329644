import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import { tryCatch } from '@sweep/utils';
import { BACKEND_URL } from 'src/env';
import ErrorReporter from 'src/third-parties/ErrorReporter';

const token = localStorage.getItem('@authToken');

export const instance = axios.create({
  baseURL: BACKEND_URL,
  headers: token != null ? { Authorization: token } : {},
});

instance.interceptors.request.use(function (config) {
  const token =
    localStorage.getItem('@authToken') ??
    localStorage.getItem('LocalStorageValue#User#authToken');

  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});

let isServerErrorAlert = false;
let isNetworkConnectErrorAlert = false;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!navigator.onLine) {
      if (!isNetworkConnectErrorAlert) {
        alert('네트워크 연결이 끊겼어요. 인터넷 연결을 확인해주세요.');
        isNetworkConnectErrorAlert = true;
      }
      return Promise.reject(error);
    }

    if (isAxiosError(error)) {
      const response = error.response;
      const isServerError = response != null && response.status >= 500;
      if (isServerError) {
        if (!isServerErrorAlert) {
          alert('서버에 문제가 발생했어요. 새로고침 후 다시 시도해주세요.');
          isServerErrorAlert = true;
        }
      }
    }

    return Promise.reject(error);
  }
);

const api = {
  get: async <Response = any, Params = any>(url: string, params?: Params) => {
    const response = await tryCatch(
      () => instance.get<Response>(url, { params }),
      (error) => {
        ErrorReporter.captureError(error);
      }
    );
    return response;
  },
  post: async <Response = any, Body = any>(
    url: string,
    data: Body,
    config?: AxiosRequestConfig
  ) => {
    const response = await tryCatch(
      () =>
        instance.post<Response, AxiosResponse<Response>, Body>(
          url,
          data,
          config
        ),
      (error) => {
        ErrorReporter.captureError(error);
      }
    );

    return response;
  },
  postWithError: async <Response = any, Body = any>(
    url: string,
    data: Body,
    config?: AxiosRequestConfig
  ) => {
    const response = await instance.post<
      Response,
      AxiosResponse<Response>,
      Body
    >(url, data, config);

    return response;
  },
  put: async <Response = any, Body = any>(url: string, data: Body) => {
    const response = await tryCatch(
      () => instance.put<Response, AxiosResponse<Response>, Body>(url, data),
      (error) => {
        ErrorReporter.captureError(error);
      }
    );

    return response;
  },
  delete: async <Response = any>(url: string) => {
    const response = await tryCatch(
      () => instance.delete<Response, AxiosResponse<Response>>(url),
      (error) => {
        ErrorReporter.captureError(error);
      }
    );
    return response;
  },
};

export default api;

export type Result<T = undefined, C extends string = string> =
  | { success: true; data: T }
  | { success: false; error: string; customErrorCode?: C };

export type APIResponse<T, C extends string = string> =
  | { success: true; data: T }
  | { success: false; error: string; customErrorCode?: C };
