import { Workbook } from 'exceljs';
import { LEGENDS } from '../constants';
import { getUniqueSheetName } from './getUniqueSheetName';

export function addLegendWorkSheet(workbook: Workbook) {
  const uniqueSheetName = getUniqueSheetName('범례', workbook);
  const worksheet = workbook.addWorksheet(uniqueSheetName);
  const legendKeys = Object.keys(LEGENDS) as (keyof typeof LEGENDS)[];

  worksheet.addRow(['색상', '의미']);
  legendKeys.forEach((key, index) => {
    const color = LEGENDS[key][0];
    const description = LEGENDS[key][1];

    const rowExcelIndex = index + 2;
    worksheet.getCell(`A${rowExcelIndex}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };
    worksheet.getCell(`B${rowExcelIndex}`).value = description;
  });
}
