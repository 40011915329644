export function splitOption(optionString: string): string[] {
  if (optionString === '') {
    return [];
  }

  const options = `, ${optionString}`
    .split(/, 옵션\d+:|, 선택\d+:/)
    .map((option) => option.trim())
    .filter((option) => option !== '' && option !== '-');

  return options;
}
