import { forwardRef, useState } from 'react';
import { cva } from '@sweep/tailwind';
import { imageAliLandingPageForm } from 'src/images';
import { preRegister } from 'src/network/user';
import Checkbox from 'src/screens/landing-page/components/Checkbox';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import { getUtmInfoString } from 'src/utils';

interface PreRegistrationFormProps {
  hasEnoughHeight: boolean;
}

function PreRegistrationForm(
  { hasEnoughHeight }: PreRegistrationFormProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const utmInfo = getUtmInfoString();

  const [companyName, setCompanyName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isAgeChecked, setIsAgeChecked] = useState<boolean>(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.target.value);
  };

  const toggleAll = () => {
    if (isAgeChecked && isPrivacyChecked) {
      setIsAgeChecked(false);
      setIsPrivacyChecked(false);
    } else {
      setIsAgeChecked(true);
      setIsPrivacyChecked(true);
    }
  };

  const toggleIsAgeChecked = () => {
    setIsAgeChecked(!isAgeChecked);
  };

  const toggleIsPrivacyChecked = () => {
    setIsPrivacyChecked(!isPrivacyChecked);
  };

  const resetForm = () => {
    setCompanyName('');
    setName('');
    setPhoneNumber('');
    setIsAgeChecked(false);
    setIsPrivacyChecked(false);
  };

  const handleSubmit = async () => {
    if (companyName === '' || name === '' || phoneNumber === '') {
      return;
    }
    try {
      const response = await preRegister({
        from: '알리 무료체험 신청' + utmInfo,
        companyName,
        name,
        phoneNumber,
      });
      if (response.status === 200) {
        resetForm();
        amplitude.track('[LP] Submit', {
          companyName,
        });
        window.gtag_report_conversion();
        toast.success('성공적으로 접수되었습니다.');
        return;
      }
      toast.error('오류가 발생하였습니다. 다시 시도해주세요.');
    } catch {
      toast.error('오류가 발생하였습니다. 다시 시도해주세요.');
    }
  };

  return (
    <div
      ref={ref}
      className="flex h-[960px] w-full items-center justify-center gap-[60px]"
    >
      <div className={container({ hasEnoughHeight })}>
        <div className={textContainer({ hasEnoughHeight })}>
          <p className="text-[32px] font-medium leading-[48px] tracking-tight text-gray-600">
            솔루션을 써도 여전히 수기 작업을 하고 계신가요?
          </p>
          <p className="text-[48px] font-bold leading-[64px] tracking-tight">
            <span className="bg-gradient-to-r from-[#FF7458] to-[#FB370B] bg-clip-text text-transparent">
              알리익스프레스
            </span>{' '}
            주문 처리는 무조건{' '}
            <span className="bg-gradient-to-r from-[#1891FF] to-[#0F53FF] bg-clip-text text-transparent">
              스윕
            </span>
            입니다
          </p>
        </div>
        <div className="relative">
          <img
            src={imageAliLandingPageForm}
            alt="landing-page-form-right"
            className="h-[570px]"
          />
          <div className="absolute left-1/2 top-1/2 flex h-[474px] w-[622px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-[18px] border border-white bg-gradient-to-b from-[rgba(255,255,255,0.96)] to-[rgba(255,255,255,0.69)] p-[40px] text-[16px] font-medium leading-[21px] text-gray-700">
            <div className="flex h-[394px] w-[542px] flex-col justify-between">
              <div className="flex w-full flex-col gap-[12px]">
                <div className="flex h-[40px] w-full items-center gap-[32px]">
                  <p className="w-[54px]">
                    회사명 <span className="text-orange-500">*</span>
                  </p>
                  <input
                    type="text"
                    value={companyName}
                    onChange={handleCompanyNameChange}
                    placeholder="회사 이름을 입력해주세요."
                    className="h-[40px] grow rounded-[8px] border border-gray-300 bg-white px-[16px] focus:outline-blue-500"
                  />
                </div>
                <div className="flex h-[40px] w-full items-center gap-[32px]">
                  <p className="w-[54px]">
                    성함 <span className="text-orange-500">*</span>
                  </p>
                  <input
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="성함을 입력해주세요."
                    className="h-[40px] grow rounded-[8px] border border-gray-300 bg-white px-[16px] focus:outline-blue-500"
                  />
                </div>
                <div className="flex h-[40px] w-full items-center gap-[32px]">
                  <p className="w-[54px]">
                    연락처 <span className="text-orange-500">*</span>
                  </p>
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="연락처를 입력해주세요."
                    className="h-[40px] grow rounded-[8px] border border-gray-300 bg-white px-[16px] focus:outline-blue-500"
                  />
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[12px]">
                <div className="flex gap-[8px]" onClick={toggleAll}>
                  <Checkbox checked={isAgeChecked && isPrivacyChecked} />
                  <p>모두 동의합니다.</p>
                </div>
                <hr className="w-full" />
                <div className="flex gap-[8px]" onClick={toggleIsAgeChecked}>
                  <Checkbox checked={isAgeChecked} />
                  <p>[필수] 만 14세 이상입니다.</p>
                </div>
                <div
                  className="flex gap-[8px]"
                  onClick={toggleIsPrivacyChecked}
                >
                  <Checkbox checked={isPrivacyChecked} />
                  <p>
                    [필수]{' '}
                    <a
                      href="https://sweepoms.notion.site/587bc67c6247485eab6b6972bef1a586?pvs=4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span
                        className="cursor-pointer text-blue-500"
                        onClick={(e) => e.stopPropagation()}
                      >
                        개인정보 처리방침
                      </span>
                    </a>
                    을 확인하였습니다.
                  </p>
                </div>
              </div>
              <button
                disabled={isButtonDisabled({
                  companyName,
                  name,
                  phoneNumber,
                  isAgeChecked,
                  isPrivacyChecked,
                })}
                className={button({
                  disabled: isButtonDisabled({
                    companyName,
                    name,
                    phoneNumber,
                    isAgeChecked,
                    isPrivacyChecked,
                  }),
                })}
                onClick={handleSubmit}
              >
                무료로 시작하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const isButtonDisabled = ({
  companyName,
  name,
  phoneNumber,
  isAgeChecked,
  isPrivacyChecked,
}: {
  companyName: string;
  name: string;
  phoneNumber: string;
  isAgeChecked: boolean;
  isPrivacyChecked: boolean;
}) => {
  return (
    companyName.length === 0 ||
    name.length === 0 ||
    phoneNumber.length === 0 ||
    !isAgeChecked ||
    !isPrivacyChecked
  );
};

const container = cva('flex flex-col items-center', {
  variants: {
    hasEnoughHeight: {
      true: 'gap-[60px]',
      false: 'gap-[20px]',
    },
  },
});

const textContainer = cva('flex flex-col items-start', {
  variants: {
    hasEnoughHeight: {
      true: ' gap-[8px]',
      false: 'gap-0',
    },
  },
});

const button = cva(
  'h-[49px] w-full rounded-[8px] px-[22px] py-[14px] text-[18px] font-bold tracking-tight text-white',
  {
    variants: {
      disabled: {
        true: 'bg-gray-300',
        false: 'bg-gradient-to-r from-[#FF7458] to-[#FB370B]',
      },
    },
  }
);

export default forwardRef(PreRegistrationForm);
