import { z } from 'zod';
import { c } from '../internal/contract';

export const userOpinionRouterContract = c.router({
  create: {
    method: 'POST',
    path: '/user-opinion',
    responses: {
      201: z.boolean(),
    },
    body: z.object({
      companyName: z.string().optional(),
      name: z.string().optional(),
      phoneNumber: z.string().optional(),
      email: z.string().optional(),
      opinion: z.string(),
    }),
  },
});
