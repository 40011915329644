import { ShoppingMall } from '@sweep/contract';
import { isNotNil, NULL_STRING, sortBy, sum } from '@sweep/utils';
import { SettlementOrder, ShoppingMallSettlement } from '../interface';

export function getShoppingMallSettlements(
  shoppingMalls: ShoppingMall[],
  ordersByShoppingMall: Record<string, SettlementOrder[]>
) {
  const shoppingMallById: Record<string, ShoppingMall | undefined> =
    Object.fromEntries(
      shoppingMalls.map((shoppingMall) => [shoppingMall._id, shoppingMall])
    );

  const ordersByAllShoppingMall: Record<string, SettlementOrder[]> =
    Object.fromEntries([
      ...shoppingMalls.map((shoppingMall) => [
        shoppingMall._id,
        ordersByShoppingMall[shoppingMall._id] ?? [],
      ]),
      [NULL_STRING, ordersByShoppingMall[NULL_STRING] ?? []],
    ]);

  const shoppingMallSettlements = Object.entries(ordersByAllShoppingMall)
    .map(([shoppingMallId, orders]): ShoppingMallSettlement | null => {
      const totalPrice = sum(orders.map((order) => order.totalSellingPrice));
      if (shoppingMallId === NULL_STRING) {
        return null;
      }
      const shoppingMall = shoppingMallById[shoppingMallId];
      return shoppingMall != null
        ? {
            shoppingMall,
            orders,
            totalPrice,
          }
        : null;
    })
    .filter(isNotNil);

  const sortedShoppingMallSettlements = sortBy(shoppingMallSettlements, [
    ({ totalPrice }) => (totalPrice > 0 ? -1 : 0),
    ({ shoppingMall }) => shoppingMall?.name ?? '',
  ]);

  return sortedShoppingMallSettlements;
}
