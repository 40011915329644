import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { amplitude } from 'src/third-parties/amplitude';
import PCLandingPage from './landing-page/PC/PCLandingPage';
import FullPageScreen from './NewLandingPagewithFullPager';

function LandingPage() {
  useEffect(() => {
    amplitude.track('[LP] View Landing Page');
  }, []);

  const isPC = useMediaQuery({
    query: '(min-width: 1099px) and (orientation: landscape)',
  });

  return isPC ? <PCLandingPage /> : <FullPageScreen />;
}

export default LandingPage;
