import { DateRange } from '@sweep/utils';
import { Selecting } from '../interface';

export function getHoveredDateRange(
  range: DateRange,
  selection: Selecting,
  hovered: Date | null
) {
  if (hovered == null) {
    return range;
  }

  const { from, to } = range;

  switch (selection) {
    case 'first-from':
      return { from: hovered, to };
    case 'first-to':
      return { from, to: hovered };
    case 'second-from':
      return { from: hovered, to };
    case 'second-to':
      return { from, to: hovered };
    case null:
      return range;
  }
}
