import { z } from 'zod';

export const OrderStatus = {
  ///////////////////////////////////////////
  //////////       결제 완료         //////////
  ///////////////////////////////////////////
  paymentComplete: '결제 완료',
  paymentCompletePartialDelivery: '결제 완료(일부 배송 완료)',

  //////////////////////////////////////////
  //////////      상품 준비 중       //////////
  //////////////////////////////////////////
  productPreparing: '상품 준비 중',
  productPreparingPartialDelivery: '상품 준비 중(일부 배송 완료)',
  partialDelivery: '일부 배송 완료',

  //////////////////////////////////////////
  //////////         배송 중        //////////
  //////////////////////////////////////////
  inDelivery: '배송 중',

  //////////////////////////////////////////
  //////////       취소 요청        //////////
  //////////////////////////////////////////
  cancelRequest: '취소 요청',
  cancelRequestPaymentComplete: '취소 요청(결제 완료)',

  //////////////////////////////////////////
  //////////         기타          //////////
  //////////////////////////////////////////
  // 교환
  exchange: '교환',
  exchangeRequest: '교환 요청',
  exchangeInDelivery: '교환 반송 중',
  exchangeRedelivery: '교환 재배송 중',
  exchangeHold: '교환 보류',

  // 환불
  refundRequest: '환불 요청',
  refundExpected: '환불 예정',
  refundRequestWaiting: '환불 요청 대기',

  // 반품
  returnRequest: '반품 요청',
  returnInDelivery: '반품 반송 중',
  returnInWarehouse: '반품 입고 완료',

  // NOTE(@이지원): 알리
  fundProcessing: 'FUND_PROCESSING',
  riskControl: 'RISK_CONTROL',
  inIssue: 'IN_ISSUE',
  inFrozen: 'IN_FROZEN',
  waitSellerExamineMoney: 'WAIT_SELLER_EXAMINE_MONEY',

  //////////////////////////////////////////
  //////////        Hidden        //////////
  //////////////////////////////////////////
  // 미결제
  unpaid: '미결제',
  unpaidCancel: '미결제 취소',

  // 배송 완료
  deliveryComplete: '배송 완료',
  purchaseConfirmation: '구매 확정',

  // 취소
  cancelComplete: '취소 완료',
  cancelRejectComplete: '취소 거부 완료',

  // 반품
  returnComplete: '반품 완료',

  // 환불
  refundComplete: '환불 완료',

  complete: '완료',
  collectionProcessing: '수거 처리 중',
  teamRecruitment: '팀 모집 중',
  paymentWaiting: '결제 대기 중',
  returnPaymentCancelComplete: '반품 결제 취소 완료',
  returnRefundComplete: '반품 환불 완료',
  returnSendComplete: '반품 반송 완료',

  // NOTE(@이지원): 11번가
  deliveryAddressInputWaiting: '배송지입력대기',

  // NOTE(@이지원): Cafe24
  beforePayment: '입금 전',
  cancelBeforePayment: '입금 전 취소',
  deliveryHold: '배송 보류',
  cancelSubmit: '취소 접수',
  cancelSubmitReject: '취소 접수 거부',
  cancelCompleteBeforeRefund: '취소 완료 - 환불 전',
  cancelCompleteWaitingRefund: '취소 완료 - 환불 요청 중',
  cancelCompleteHoldRefund: '취소 완료 - 환불 보류',
  returnSubmit: '반품 접수',
  returnCollect: '반품 수거',
  exchangeSubmit: '교환 접수',
  exchangeSubmitReject: '교환 접수 거부',
  exchangePrepare: '교환 준비',

  // NOTE(@경한) 카카오메이커스
  returnApprove: '반품 승인',
  returnProcessing: '반품 처리 중',
  returnReject: '반품 반려',
  returnHold: '반품 보류',
  returnWithdraw: '반품 철회',

  cancelApprove: '취소 승인',
  cancelProcessing: '취소 처리 중',
  cancelReject: '취소 반려',
  cancelHold: '취소 보류',
  cancelWithdraw: '취소 철회',

  redeliveryRequest: '재배송 요청',
  redeliveryApprove: '재배송 승인',
  redeliveryProcessing: '재배송 처리 중',
  redeliveryComplete: '재배송 완료',
  redeliveryReject: '재배송 반려',
  redeliveryHold: '재배송 보류',
  redeliveryWithdraw: '재배송 철회',

  // NOTE(@경한) 샵바이
  exchangeProcess: '교환 처리 중',
  exchangeComplete: '교환 완료',

  // NOTE(@형준): 쿠팡
  // NOTE(@형준): requestCouponCheck 값은 쿠팡 확인 요청 값
  // - 문의 답변으로 OPEN API 상에서는 지원하지 않는 값이라고 함 (https://developers.coupangcorp.com/hc/ko/requests/423536874)
  // - edge case 라고 판단하고 일단 별도의 상태값으로 저장
  // - 해당 주문은 직접 처리 불가능하고 상태 확인하고 쿠팡에 문의해서 처리 필요한것으로 보임, 따라서 etc 상태에서 보이게 처리
  requestCouponCheck: '쿠팡 확인 요청',
} as const;

export const OrderStatusSchema = z.custom<OrderStatus>((value) => {
  return Object.values(OrderStatus).includes(value);
});

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

type _OrderStatus =
  | 'paymentComplete'
  | 'productPrepare'
  | 'inDelivery'
  | 'cancelRequest'
  | 'etc'
  | 'hidden';

const ORDER_STATUS_MAP: Record<OrderStatus, _OrderStatus> = {
  [OrderStatus.paymentComplete]: 'paymentComplete',
  [OrderStatus.paymentCompletePartialDelivery]: 'paymentComplete',
  [OrderStatus.productPreparing]: 'productPrepare',
  [OrderStatus.productPreparingPartialDelivery]: 'productPrepare',
  [OrderStatus.partialDelivery]: 'productPrepare',
  [OrderStatus.inDelivery]: 'inDelivery',
  [OrderStatus.cancelRequest]: 'cancelRequest',
  [OrderStatus.cancelRequestPaymentComplete]: 'cancelRequest',
  [OrderStatus.exchange]: 'etc',
  [OrderStatus.exchangeRequest]: 'etc',
  [OrderStatus.exchangeInDelivery]: 'etc',
  [OrderStatus.exchangeRedelivery]: 'etc',
  [OrderStatus.exchangeHold]: 'etc',
  [OrderStatus.refundRequest]: 'etc',
  [OrderStatus.refundExpected]: 'etc',
  [OrderStatus.refundRequestWaiting]: 'etc',
  [OrderStatus.returnRequest]: 'etc',
  [OrderStatus.returnInDelivery]: 'etc',
  [OrderStatus.returnInWarehouse]: 'etc',
  [OrderStatus.fundProcessing]: 'etc',
  [OrderStatus.riskControl]: 'etc',
  [OrderStatus.inIssue]: 'etc',
  [OrderStatus.inFrozen]: 'etc',
  [OrderStatus.waitSellerExamineMoney]: 'etc',
  [OrderStatus.requestCouponCheck]: 'etc',
  [OrderStatus.unpaid]: 'hidden',
  [OrderStatus.unpaidCancel]: 'hidden',
  [OrderStatus.deliveryComplete]: 'hidden',
  [OrderStatus.complete]: 'hidden',
  [OrderStatus.cancelComplete]: 'hidden',
  [OrderStatus.returnComplete]: 'hidden',
  [OrderStatus.refundComplete]: 'hidden',
  [OrderStatus.collectionProcessing]: 'hidden',
  [OrderStatus.purchaseConfirmation]: 'hidden',
  [OrderStatus.teamRecruitment]: 'hidden',
  [OrderStatus.paymentWaiting]: 'hidden',
  [OrderStatus.returnPaymentCancelComplete]: 'hidden',
  [OrderStatus.returnRefundComplete]: 'hidden',
  [OrderStatus.returnSendComplete]: 'hidden',
  [OrderStatus.deliveryAddressInputWaiting]: 'hidden',
  [OrderStatus.beforePayment]: 'hidden',
  [OrderStatus.cancelBeforePayment]: 'hidden',
  [OrderStatus.deliveryHold]: 'hidden',
  [OrderStatus.cancelSubmit]: 'hidden',
  [OrderStatus.cancelSubmitReject]: 'hidden',
  [OrderStatus.cancelCompleteBeforeRefund]: 'hidden',
  [OrderStatus.cancelCompleteWaitingRefund]: 'hidden',
  [OrderStatus.cancelCompleteHoldRefund]: 'hidden',
  [OrderStatus.returnSubmit]: 'hidden',
  [OrderStatus.returnCollect]: 'hidden',
  [OrderStatus.exchangeSubmit]: 'hidden',
  [OrderStatus.exchangeSubmitReject]: 'hidden',
  [OrderStatus.exchangePrepare]: 'hidden',
  [OrderStatus.returnApprove]: 'hidden',
  [OrderStatus.returnProcessing]: 'hidden',
  [OrderStatus.returnReject]: 'hidden',
  [OrderStatus.returnHold]: 'hidden',
  [OrderStatus.returnWithdraw]: 'hidden',
  [OrderStatus.cancelApprove]: 'hidden',
  [OrderStatus.cancelProcessing]: 'hidden',
  [OrderStatus.cancelReject]: 'hidden',
  [OrderStatus.cancelHold]: 'hidden',
  [OrderStatus.cancelWithdraw]: 'hidden',
  [OrderStatus.redeliveryRequest]: 'hidden',
  [OrderStatus.redeliveryApprove]: 'hidden',
  [OrderStatus.redeliveryProcessing]: 'hidden',
  [OrderStatus.redeliveryComplete]: 'hidden',
  [OrderStatus.redeliveryReject]: 'hidden',
  [OrderStatus.redeliveryHold]: 'hidden',
  [OrderStatus.redeliveryWithdraw]: 'hidden',
  [OrderStatus.exchangeProcess]: 'hidden',
  [OrderStatus.exchangeComplete]: 'hidden',
  [OrderStatus.cancelRejectComplete]: 'hidden',
};

export function isPaymentComplete(status: OrderStatus) {
  return ORDER_STATUS_MAP[status] === 'paymentComplete';
}

export function isProductPreparing(status: OrderStatus) {
  return ORDER_STATUS_MAP[status] === 'productPrepare';
}

export function isInDelivery(status: OrderStatus) {
  return ORDER_STATUS_MAP[status] === 'inDelivery';
}

export function isCancelRequest(status: OrderStatus) {
  return ORDER_STATUS_MAP[status] === 'cancelRequest';
}

export function isEtc(status: OrderStatus) {
  return ORDER_STATUS_MAP[status] === 'etc';
}

export function isHidden(status: OrderStatus) {
  return ORDER_STATUS_MAP[status] === 'hidden';
}
