import { BeatLoader } from 'react-spinners';

const LoadingIndicator = () => {
  return (
    /* eslint-disable tailwindcss/migration-from-tailwind-2 */
    <div className="z-overlay flex-center fixed inset-0 flex overflow-hidden bg-black bg-opacity-20">
      <BeatLoader size={35} color="#4880EE" loading={true} />
    </div>
  );
};

export default LoadingIndicator;
