import { Partnership } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';

interface TabGroup {
  title: string;
  tabs: {
    icon?: React.ReactNode;
    title: string;
    href: string;
    beta?: boolean;
  }[];
}

export const getTabGroupsForGuestRetailer = ({
  isAdmin,
  partnerships,
}: {
  isAdmin: boolean;
  partnerships: Partnership[];
}): TabGroup[] => {
  const commonTabs: TabGroup[] = [
    {
      title: '공급사',
      tabs: [
        ...partnerships.map((partnership) => ({
          title: partnership.supplier.name,
          href: `/partnership/${partnership.partnershipUUID}/purchase-order`,
        })),
      ],
    },
    isAdmin
      ? {
          title: '관리자',
          tabs: [
            {
              title: '플러그인 설정',
              href: '/setting/plugin',
            },
            {
              title: 'QA 설정',
              href: '/setting/qa',
            },
            {
              title: '통합 엑셀 양식 관리',
              href: '/setting/integrated-excel-format',
            },
          ],
        }
      : null,
  ].filter(isNotNil);

  return commonTabs;
};
