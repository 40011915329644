import { ExcelFileRecord, Order } from '@sweep/contract';
import { createOrderExcelFromTemplate } from './createOrderExcelFromTemplate';

// TODO(@hungjoon): fileRecord 타입을 받아서 처리하는 함수가 필요해서 추가한 wrapper 함수
export async function createOrderExcelFromTemplateV2(
  fileRecord: ExcelFileRecord
): Promise<ArrayBuffer | null> {
  const { headers, columnMappings, orders, supplierCustomizedSettings } =
    fileRecord;

  const isTemplateFileEnabled =
    supplierCustomizedSettings?.isTemplateFileEnabled === true;

  if (!isTemplateFileEnabled) {
    return null;
  }

  if (
    supplierCustomizedSettings?.templateFile == null ||
    supplierCustomizedSettings?.headerRowIndex == null ||
    supplierCustomizedSettings?.minRowIndex == null
  ) {
    return null;
  }

  if (orders.length === 0) {
    return null;
  }

  return await createOrderExcelFromTemplate(
    headers,
    columnMappings,
    orders as Order[],
    {
      templateFile: supplierCustomizedSettings.templateFile,
      headerRowIndex: supplierCustomizedSettings.headerRowIndex,
      minRowIndex: supplierCustomizedSettings.minRowIndex,
    }
  );
}
