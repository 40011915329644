import { IconNaviMoney } from '@sweep/asset/icons';
import { Badge } from '@sweep/sds';

interface TotalPriceProps {
  type: 'supplier' | 'salesChannel';
  value: number;
}

function TotalPrice({ type, value }: TotalPriceProps) {
  const title = type === 'supplier' ? '총 공급금액' : '총 판매금액';

  return (
    <div className="gap-12px flex items-center justify-center">
      <div className="gap-6px flex items-center">
        <IconNaviMoney className="text-gray-400" />
        <p className="text-semibold-s text-gray-500">{title}</p>
      </div>
      <Badge color="blue" variant="soft">
        {value.toLocaleString()}원
      </Badge>
    </div>
  );
}

export default TotalPrice;
