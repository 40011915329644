import { useMemo } from 'react';
import { uniq } from '@sweep/utils';
import { filterRows } from '../services/filterRows';
import { TableFilterStore } from '../TableFilterStore';

export function useFilterField<T extends Record<string, unknown>>(options: {
  store: TableFilterStore;
  rows: T[];
  id: string;
  accessorFn?: (row: T) => string | undefined | null;
  search: string;
}) {
  const { store, rows, id, accessorFn, search } = options;

  const fieldOptions = useMemo(() => {
    const { [id]: _, ...otherRemovedFields } = store.removedFields;
    const filteredRows = filterRows(
      rows,
      otherRemovedFields,
      store.accessorFns
    );
    const fieldOptions = filteredRows.map((row) => accessorFn?.(row) ?? '');
    return uniq(fieldOptions).sort((a, b) => a.localeCompare(b));
  }, [id, accessorFn, store.removedFields, rows, store.accessorFns]);

  const filteredFieldOptions = fieldOptions.filter((value) =>
    value.toLowerCase().includes(search.toLowerCase())
  );

  const removedFieldValues = store.removedFields[id];

  const isNotChecked = (value: string) =>
    removedFieldValues?.includes(value) === true;

  const handleField = (field: string) => {
    if (isNotChecked(field)) {
      store.setRemovedFields(
        id,
        removedFieldValues?.filter((v) => v !== field) ?? []
      );
      return;
    }

    store.setRemovedFields(id, [...(removedFieldValues ?? []), field]);
  };

  const isNotAllChecked = filteredFieldOptions.some(isNotChecked);

  const handleAllField = () => {
    if (isNotAllChecked) {
      store.setRemovedFields(
        id,
        removedFieldValues?.filter((v) => !filteredFieldOptions.includes(v)) ??
          []
      );
      return;
    }

    store.setRemovedFields(id, [
      ...(removedFieldValues ?? []),
      ...filteredFieldOptions,
    ]);
  };

  return {
    fields: filteredFieldOptions,
    isNotChecked,
    onFieldClick: handleField,
    isNotAllChecked,
    onAllFieldClick: handleAllField,
  };
}
