import { z } from 'zod';

export const AnyAddressSchema = z.object({
  address: z.string().nullish(),
  postCode: z.string().nullish(),
});

export type AnyAddress = z.infer<typeof AnyAddressSchema>;

export const NormalizedAddressSchema = z.object({
  result: z.enum(['okay', 'fail', 'fixed']),
  data: z.string(),
  main_address: z.string(),
  detail_address: z.string(),
  message: z.array(z.string()).optional(),
  tried_data: z.string().optional(),
});

export type NormalizedAddress = z.infer<typeof NormalizedAddressSchema>;
