export const removeSpaces = (str: number | string | null | undefined) => {
  if (typeof str === 'number') {
    return str.toString();
  }

  if (typeof str === 'string') {
    return str.replace(/\s+/g, '');
  }
  return '';
};

// 유효성 검사 함수
export function isValid(value: any) {
  // value !== value 는 NaN을 체크하기 위함
  if (value === undefined || value === null || value !== value) {
    return false;
  }
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }
  if (
    typeof value === 'object' &&
    value.constructor === Map &&
    value.size === 0
  ) {
    return false;
  }
  if (
    typeof value === 'object' &&
    value.constructor === Object &&
    Object.keys(value).length === 0
  ) {
    return false;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return false;
  }
  return true;
}

function deepObjectEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  }
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepObjectEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}

export function areArraysEqual(arr1: any[], arr2: any[]) {
  //[...a,null,null] 과 [...a] 가 같다고 판단해야함

  // 먼저 배열 길이를 확인하여 다르면 false 반환
  if (arr1.length !== arr2.length) {
    return false;
  }

  // 배열의 모든 요소를 순회하며 비교
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    if (!isValid(arr1[i]) && !isValid(arr2[i])) {
      continue;
    }

    if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
      if (!deepObjectEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else {
      if (arr1[i] !== arr2[i]) {
        return false;
      } // 요소가 다르면 false 반환
    }
  }

  // 모든 요소가 일치하면 true 반환
  return true;
}

export const areObjectsEqual = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const areMutualSubsequences = (arr1: any, arr2: any) => {
  if (arr1.length === 0 || arr2.length === 0) {
    return false;
  }

  const isSubsequence = (subsequence: any, sequence: any) => {
    let subIdx = 0;
    let seqIdx = 0;

    while (subIdx < subsequence.length && seqIdx < sequence.length) {
      if (subsequence[subIdx] === sequence[seqIdx]) {
        subIdx++;
      }
      seqIdx++;
    }

    return subIdx === subsequence.length;
  };

  return isSubsequence(arr1, arr2) || isSubsequence(arr2, arr1);
};

export const isNumeric = (value: number | string) => {
  if (typeof value === 'number') {
    return true;
  }

  if (typeof value === 'string') {
    const isInteger = /^\d+$/.test(value);
    const isFraction = /^\d+\/\d+$/.test(value);
    const isDecimal = /^\d*\.\d+$/.test(value);

    return isInteger || isFraction || isDecimal;
  }
};

export const isSubsequenceArray = (subsequence: any[], sequence: any[]) => {
  let sequenceIdx = -1;

  sequenceIdx = sequence.findIndex((el) => el === subsequence[0]);
  if (sequenceIdx === -1) {
    return false;
  }

  for (const subElement of subsequence) {
    if (
      sequenceIdx >= sequence.length ||
      subElement !== sequence[sequenceIdx]
    ) {
      return false;
    }

    sequenceIdx++;
  }

  return true;
};
