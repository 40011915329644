import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';
import { createCollection } from '../Collection';

const [Collection, useCollection] = createCollection<
  HTMLDivElement,
  {
    value: string[];
    label: string;
  }
>('MultiSelect');
export {
  Collection as MultiSelectCollection,
  useCollection as useMultiSelectCollection,
};

export interface MultiSelectContextValue {
  open: boolean;
  value: string[];
  onChange: (value: string[]) => void;
}

const multiSelectContext = createContext<MultiSelectContextValue>({
  open: false,
  value: [],
  onChange: noop,
});

export const MultiSelectContextProvider = multiSelectContext.Provider;

export const useMultiSelectContext = () => useContext(multiSelectContext);
