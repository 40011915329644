import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Divider } from '@sweep/sds';
import { DateRange, formatDate, groupBy, NULL_STRING, sum } from '@sweep/utils';
import { useMount } from 'src/hooks/useMount';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { amplitude } from 'src/third-parties/amplitude';
import SettlementRangeSelect from '../components/SettlementRangeSelect';
import SettlementSupplierItem from '../components/SettlementSupplierItem';
import TotalPrice from '../components/TotalPrice';
import { SupplierSettlement } from '../interface';
import { downloadSupplierSettlementExcel } from '../services/downloadSupplierSettlementExcel';
import { getSupplierSettlements } from '../services/getSupplierSettlements';
import { SettlementStore } from '../store/SettlementStore';
import { SupplierSettlementScreenStore } from '../store/SupplierSettlementScreenStore';

function SupplierSettlementScreen() {
  useMount(() => amplitude.track('Pageview settlement-supplier'));

  const oms = useOMSStore();
  const settlement = oms.getStore(SettlementStore);
  const store = oms.getStore(SupplierSettlementScreenStore, settlement);

  useEffect(() => {
    oms.loading.batch(() => store.ensureOrders(store.range));
  }, [oms.loading, store]);

  const handleRangeChange = async (range: DateRange) => {
    amplitude.track('Click settlement-filter', {
      Period: `${formatDate(range.from, 'yyyy-MM-dd')} ~ ${formatDate(range.to, 'yyyy-MM-dd')}`,
      From: 'settlement-supplier',
    });
    store.setRange(range);
    oms.loading.batch(() => store.ensureOrders(range));
  };

  const ordersBySupplier = groupBy(
    store.orders,
    (order) => order.supplierId ?? NULL_STRING
  );
  const suppliers = oms.supplier.suppliers;
  const supplierSettlements = getSupplierSettlements(
    suppliers,
    ordersBySupplier
  );

  const totalPrice = sum(store.orders.map((order) => order.totalSupplierPrice));

  const onDownload = (settlement: SupplierSettlement) => {
    amplitude.track('Click download-settlement-supplier-detail', {
      'Supplier name': settlement.supplier?.name ?? '기타',
      From: 'settlement-supplier',
    });

    downloadSupplierSettlementExcel(
      oms,
      settlement.supplier?.name ?? '기타',
      store.range,
      settlement.orders
    );
  };

  return (
    <div className="py-20px gap-28px px-40px flex min-h-screen flex-col bg-gray-100">
      <p className="text-extrabold-l text-gray-700">공급사 정산 관리</p>
      <div className="gap-24px p-20px rounded-8px shadow-line flex items-center bg-white">
        <SettlementRangeSelect
          value={store.range}
          onChange={handleRangeChange}
        />
        <Divider type="vertical" className="bg-gray-200" />
        <TotalPrice type="supplier" value={totalPrice} />
      </div>
      <div className="gap-20px p-20px shadow-line rounded-8px flex flex-col bg-white ">
        <div className="gap-4px flex items-center">
          <p className="text-medium-s text-gray-500">공급사</p>
          <p className="text-medium-s text-gray-500">
            (<span className="text-blue-500">{supplierSettlements.length}</span>
            곳)
          </p>
        </div>
        <div className="gap-24px grid grid-cols-3">
          {supplierSettlements.map((settlement) => (
            <SettlementSupplierItem
              key={settlement.supplier?._id ?? NULL_STRING}
              supplier={settlement.supplier}
              price={settlement.totalPrice}
              onDownload={() => onDownload(settlement)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default observer(SupplierSettlementScreen);
