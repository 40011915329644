import { z } from 'zod';
import { c } from '../internal/contract';
import { PreRegistrationSchema } from '../type/user/PreRegistration';
import { UserSetting, userSettingSchema } from '../type/user/UserSetting';

// GET /user/custom-excel
export const GET_EXCEL_SETTING_URL = '/user/custom-excel';

export type GetExcelSettingResponse = UserSetting;

// PUT /user/setting
export const UPDATE_USER_SETTING_URL = '/user/setting';

export const ZUpdateUserSettingDTO = userSettingSchema
  .omit({
    _id: true,
  })
  .partial();

export type UpdateUserSettingDTO = z.infer<typeof ZUpdateUserSettingDTO>;

export type UpdateUserSettingResponse = UserSetting;

export const userRouterContract = c.router({
  preRegister: {
    method: 'POST',
    path: '/user/pre-register',
    responses: {
      200: z.boolean(),
    },
    body: PreRegistrationSchema,
  },
});
