import { ComponentProps } from 'react';
import { CustomComponents } from 'react-day-picker';
import { cva } from '@sweep/tailwind';
import { addMonths, isSameMonth, subMonths } from '@sweep/utils';
import { useRangePicker } from '../contexts/RangePickerContext';
import { getRangeType } from '../services/getRangeType';

interface DayProps extends ComponentProps<CustomComponents['Day']> {
  section: 'left' | 'right';
}

const Day = ({
  section,
  day,
  onMouseEnter,
  onMouseLeave,
  onClick,
  children,
  modifiers,
  className,
  ...rest
}: DayProps) => {
  const { range, onHoveredChange, leftMonth, rightMonth, onDayClick } =
    useRangePicker();

  const month = section === 'left' ? leftMonth : rightMonth;
  const prevMonth = subMonths(month, 1);
  const nextMonth = addMonths(month, 1);

  const isPrevMonth = isSameMonth(day.date, prevMonth);
  const isNextMonth = isSameMonth(day.date, nextMonth);

  const hideRange =
    (isNextMonth && section === 'left') || (isPrevMonth && section === 'right');

  const rangeType = getRangeType({
    date: day.date,
    range,
  });

  const handleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    onDayClick(day.date);
    onClick?.(event);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLTableCellElement>) => {
    onHoveredChange(day.date);
    onMouseEnter?.(event);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLTableCellElement>) => {
    onHoveredChange(null);
    onMouseLeave?.(event);
  };

  return (
    <td
      className={container({ className })}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <button className="py-2px">
        <div
          className={dayClass({
            rangeType,
            hideRange,
            isPrevOrNextMonth: isPrevMonth || isNextMonth,
          })}
        >
          {day.date.getDate()}
        </div>
      </button>
    </td>
  );
};

const container = cva('size-fit cursor-pointer p-0');

const dayClass = cva(
  'w-36px h-32px text-medium-xs flex-center flex p-0 text-gray-600',
  {
    variants: {
      rangeType: {
        start: '',
        middle: '',
        end: '',
        'start-end': '',
      },
      hideRange: {
        true: 'rounded-8px hover:bg-gray-50',
        false: '',
      },
      isPrevOrNextMonth: {
        true: 'text-gray-400',
        false: '',
      },
    },
    compoundVariants: [
      {
        rangeType: 'start',
        hideRange: false,
        className: 'rounded-l-8px text-medium-xs bg-blue-600 text-white',
      },
      {
        rangeType: 'end',
        hideRange: false,
        className: 'rounded-r-8px text-medium-xs bg-blue-600 text-white',
      },
      {
        rangeType: 'middle',
        hideRange: false,
        className: 'text-medium-xs bg-blue-50 text-gray-600',
      },
      {
        rangeType: 'start-end',
        hideRange: false,
        className: 'rounded-8px text-medium-xs bg-blue-600 text-white',
      },
    ],
  }
);

export const createDay =
  (section: 'left' | 'right'): CustomComponents['Day'] =>
  (props) => <Day {...props} section={section} />;
