export const LEGENDS: Record<
  'fail' | 'fixed' | 'postcode' | 'multiple',
  [string, string]
> = {
  fail: ['FFA6A6', '주소가 틀렸습니다. 이유는 각 셀의 메모를 참조하세요.'],
  fixed: [
    'C6FDC6',
    '다양한 이유로 수정한 주소입니다. 이유는 각 셀의 메모를 참조하세요.',
  ],
  postcode: [
    'FFCF86',
    '고객이 입력한 주소와 우편번호가 일치하지 않습니다. 메모에 상세 주소가 적혀있습니다.',
  ],
  multiple: [
    'C2A9DD',
    '하나의 지번 주소에 해당되는 도로명 주소가 여러 개 입니다. 메모에 주소 목록이 적혀 있습니다.',
  ],
};

export const LEGEND_DESCRIPTION = {
  fail: ['FFA6A6', '잘못된 양식의 주소'],
  postcode: ['FFCF86', '우편번호와 주소가 상이'],
  multiple: ['C2A9DD', '구주소 선택 필요'],
  fixed: ['C6FDC6', '수정됨'],
};

export const ADDRESS_VALIDATOR_USAGE_DATES_KEY = 'address-validate-used-dates';
export const ADDRESS_VALIDATOR_USAGE_DATE_FORMAT = 'yyyy-MM-dd';
export const TRIAL_DAYS_LIMIT = 7;
