import { Brand, BRANDS } from './BrandLogo';

const brandRecord: Record<Brand, string[]> = {
  ali: ['알리'],
  alwayz: ['올웨이즈'],
  auction: ['옥션'],
  cafe24: ['카페24'],
  coupang: ['쿠팡'],
  eleven: ['11번가'],
  emart: ['이마트'],
  gmarket: ['지마켓'],
  kakao: ['카카오'],
  mpoint: ['포인트'],
  naver: ['네이버', '스마트스토어'],
  ohouse: ['오늘의집'],
  tmon: ['티몬'],
  cashdeal: ['캐시딜'],
  tdeal: ['티딜'],
  kdeal: ['케이딜'],
  lotteon: ['롯데온'],
  imweb: ['아임웹'],
  shop: [],
};

export function getLogoMallBrand(name: string): Brand {
  const lowerCaseName = name.toLocaleLowerCase();

  for (const brand of BRANDS) {
    if (lowerCaseName.includes(brand)) {
      return brand;
    }
  }

  for (const [brand, brandNames] of Object.entries(brandRecord)) {
    for (const brandName of brandNames) {
      if (lowerCaseName.includes(brandName)) {
        return brand as Brand;
      }
    }
  }

  return 'shop';
}
