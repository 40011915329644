import { isNil, isNotNil } from 'es-toolkit';
import { NormalizedOrder, Order } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { createDebug } from 'src/third-parties/createDebug';
import { toast } from 'src/third-parties/toast';
import { openRetryFulfillPurchaseOrderFileModal } from '../../components/RetryFulfillPurchaseOrderFileModal/openRetryFulfillPurchaseOrderFileModal';
import { splitAndDownloadPurchaseOrder } from '../split-and-download-purchase-order/splitAndDownloadPurchaseOrder';
import { downloadFailReasonStep } from './steps/downloadFailReasonStep';
import { requestAutoDispatchStep } from './steps/requestAutoDispatchStep';
import { requestManualDispatchStep } from './steps/requestManualDispatchStep';
import { requestShippingOrderStatusUpdateStep } from './steps/requestShippingOrderStatusUpdateStep';
import { validateAndPrepareStep } from './steps/validateAndPrepareStep';

const debug = createDebug('processShippingOrderToShipped');

export async function processShippingOrderToShipped(params: {
  shippingOrders: NormalizedOrder[];
  oms: OMSStore;
}) {
  const { shippingOrders, oms } = params;

  const { updateValidOriginalOrders, updateInvalidShippingOrders } =
    await validateAndPrepareStep({
      originalOrders: oms.order.mergedOrders,
      shippingOrders,
    });

  const autoDispatchRequiredOriginalOrders = updateValidOriginalOrders.filter(
    isAutoDispatchRequired
  );
  const autoDispatchSkippedOriginalOrders = updateValidOriginalOrders.filter(
    isAlreadyAutoDispatch
  );
  const manualDispatchOriginalOrders =
    updateValidOriginalOrders.filter(isManualDispatch);

  const {
    successOrders: autoDispatchSuccessOrders,
    failedOrders: autoDispatchFailedOrders,
  } = await requestAutoDispatchStep(autoDispatchRequiredOriginalOrders);

  const {
    successOrders: manualDispatchSuccessOrders,
    failedOrders: manualDispatchFailedOrders,
  } = await requestManualDispatchStep(manualDispatchOriginalOrders);

  const dispatchCompleteOriginalOrders = [
    ...autoDispatchSuccessOrders,
    ...manualDispatchSuccessOrders,
    ...autoDispatchSkippedOriginalOrders,
  ];

  const isDispatchStepComplete =
    autoDispatchFailedOrders.length === 0 &&
    manualDispatchFailedOrders.length === 0;

  const dispatchCompleteUniqueCodes = new Set(
    dispatchCompleteOriginalOrders.map((order) => order.uniqueCode)
  );

  const dispatchCompleteShippingOrders = shippingOrders.filter((order) =>
    order.uniqueCode
      .split(',')
      .map((code) => code.trim())
      .some((code) => dispatchCompleteUniqueCodes.has(code))
  );

  const {
    successOrders: statusUpdateSuccessOrders,
    failedOrders: statusUpdateFailedOrders,
  } = await requestShippingOrderStatusUpdateStep(
    dispatchCompleteShippingOrders
  );

  if (dispatchCompleteOriginalOrders.length > 0) {
    const dispatchCompleteKeyByUniqueCode = Object.fromEntries(
      dispatchCompleteOriginalOrders.map((order) => [order.uniqueCode, order])
    );

    const updatedOriginalOrders = oms.order.mergedOrders.map((order) => {
      const updatedOriginalOrder =
        dispatchCompleteKeyByUniqueCode[order.uniqueCode];

      if (updatedOriginalOrder == null) {
        return order;
      }

      return {
        ...order,
        ...updatedOriginalOrder,
      };
    });

    oms.order.setMergedOrders(updatedOriginalOrders);
  }

  if (statusUpdateSuccessOrders.length > 0) {
    const updatedShippingOrderKeyByUniqueCodeSub = Object.fromEntries(
      statusUpdateSuccessOrders.map((order) => [
        order.uniqueCodeAfterCustomization,
        order,
      ])
    );

    const updatedShippingOrders = oms.order.normalizedOrders.map((order) => {
      const updatedShippingOrder =
        updatedShippingOrderKeyByUniqueCodeSub[
          order.uniqueCodeAfterCustomization
        ];

      if (updatedShippingOrder == null) {
        return order;
      }

      return {
        ...order,
        ...updatedShippingOrder,
      };
    });

    oms.order.setNormalizedOrders(updatedShippingOrders);
  }

  const { fulfilledASNDocuments } = await splitAndDownloadPurchaseOrder({
    oms,
    shippingOrders: statusUpdateSuccessOrders,
  });

  // TODO(@형준): 이 로직 현재 맥락에서 분리 필요 ??
  oms.supplierOrderTransaction.clearOutgoingTransactionProgress();

  for (const [partnershipUUID, { filename, buffer }] of Object.entries(
    fulfilledASNDocuments
  )) {
    await oms.supplierOrderTransaction.sendFulfilledPurchaseOrderFile({
      partnershipUUID,
      buffer,
      filename,
    });
  }

  const failedUploadCount =
    oms.supplierOrderTransaction.outgoingTransactionProgress.filter(
      (fileDeliveryState) => !fileDeliveryState.isSuccess
    ).length;

  debug(oms.supplierOrderTransaction.outgoingTransactionProgress);
  debug('failedUploadCount', failedUploadCount);

  if (failedUploadCount > 0) {
    openRetryFulfillPurchaseOrderFileModal();
  }

  const failedCount =
    updateInvalidShippingOrders.length +
    statusUpdateFailedOrders.length +
    autoDispatchFailedOrders.length +
    manualDispatchFailedOrders.length;

  if (failedCount > 0) {
    downloadFailReasonStep({
      updateInvalidShippingOrders,
      statusUpdateFailedOrders,
      autoDispatchFailedOrders,
      manualDispatchFailedOrders,
      shippingOrders,
      oms,
    });
  }

  // NOTE(@형준): 우선순위 높은 토스트 1개만 보여준다.
  if (!isDispatchStepComplete) {
    const failedCount =
      autoDispatchFailedOrders.length + manualDispatchFailedOrders.length;
    const errorMessage = `운송장 등록에 실패한 주문 건이 있습니다. (${failedCount}건) 실패 사유는 엑셀 파일을 확인해주세요.`;
    toast.error(errorMessage);
    return;
  }

  if (failedCount > 0) {
    const errorMessage = `주문서 파일 생성에 실패했습니다. 다시 시도해주세요. (${failedCount}건)`;
    toast.error(errorMessage);
    return;
  }

  toast.success('운송장이 등록되었고, 주문서가 생성되었습니다.');
}

export function isAutoDispatchRequired(order: Order): boolean {
  return isNotNil(order.shoppingMallId) && isNil(order.autoFulfilled);
}

export function isAlreadyAutoDispatch(order: Order): boolean {
  return isNotNil(order.shoppingMallId) && isNotNil(order.autoFulfilled);
}

export function isManualDispatch(order: Order): boolean {
  return isNil(order.shoppingMallId);
}
