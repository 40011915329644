import { isNil, keyBy } from 'es-toolkit';
import {
  CreateOrderShippingProcessHistoryDTO,
  ORDER_SHIPPING_STATUS,
  UpdateShippingOrderPayload,
} from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { formatDate } from '@sweep/utils';
import { bulkUpdateShippingOrder } from 'src/network/order-shipping-process/bulkUpdateShippingOrder';
import { createOrderExcel } from 'src/services/file/excel/create';
import { isProtected as checkIsFileEncrypted } from 'src/services/file/excel/read/isProtected';
import { promptForPassword } from 'src/services/file/excel/read/readProtectedExcel';
import { readExcelV3 } from 'src/services/file/excel/readExcel-v3';
import { toast } from 'src/third-parties/toast';
import { processFileHeaderMapping } from './processFileHeaderMapping';
import { initializeProcessingItem } from './processors/initializeProcessingItem';
import { passThrough } from './processors/passThrough';
import { processDefaultShippingCompany } from './processors/processDefaultShippingCompany';
import { processDuplicateKeyError } from './processors/processDuplicateKeyError';
import { ProcessingContext, ProcessingItem } from './processors/processor.type';
import { processShippingInfoUpdate } from './processors/processShippingInfoUpdate';
import { processShippingInfoValidation } from './processors/processShippingInfoValidation';
import { saveOrderShippingProcessHistory } from './processors/saveOrderShippingProcessHistory';
import {
  ProcessShippingOrderFileContext,
  ProcessShippingOrderFileParams,
} from './processShippingOrderFile.type';
import { getFailedShippingOrderWithReason } from './shared/getFailedShippingOrderWithReason';
import reportFallbackUsage from './shared/reportFallbackUsage';

export async function processShippingOrderFileForRetailer(
  params: ProcessShippingOrderFileParams,
  context: ProcessShippingOrderFileContext
): Promise<void> {
  const { file } = params;
  const { oms } = context;
  const filename = file.name;

  const isFileEncrypted = await checkIsFileEncrypted(file);
  const password = isFileEncrypted
    ? await promptForPassword({
        filename,
        messageType: 'FIRST_ATTEMPT',
      })
    : undefined;

  const rawExcelData = await readExcelV3(file, {
    password,
    tagUploadSource: 'shipping-order-retailer',
  });

  if (rawExcelData == null) {
    const errorMessage = `엑셀 파일 읽기 실패: ${filename}`;
    toast.error(errorMessage);
    return;
  }

  const {
    isSuccess,
    shippingOrders,
    defaultShippingCompany,
    matchDetails: matchResultHistory,
  } = await processFileHeaderMapping(
    { rawSpreadsheetData: rawExcelData, filename },
    context
  );

  if (!isSuccess) {
    const errorMessage = '운송장 파일 업로드에 실패했습니다.';
    toast.error(errorMessage);
    return;
  }

  const existingOrders = oms.order.normalizedOrders;
  const existingOrdersByUniqueCode = keyBy(
    existingOrders,
    (order) => order.uniqueCodeAfterCustomization
  );

  const processingContext: ProcessingContext = {
    shippingOrderMap: existingOrdersByUniqueCode,
    alreadyProcessedKeys: new Set(),
    defaultShippingCompany: defaultShippingCompany ?? undefined,
  };

  const shouldApplyDefaultShippingCompany = defaultShippingCompany != null;

  const processingSteps: Array<
    (item: ProcessingItem, context: ProcessingContext) => ProcessingItem
  > = [
    shouldApplyDefaultShippingCompany
      ? processDefaultShippingCompany
      : passThrough,
    processShippingInfoValidation,
    processShippingInfoUpdate,
    processDuplicateKeyError,
  ];
  const appliedProcessorNames = processingSteps.map((fn) => fn.name);

  const initialProcessingItems = shippingOrders.flatMap((order, index) =>
    initializeProcessingItem(order, index)
  );

  const processedItems = initialProcessingItems.map((item) =>
    processingSteps.reduce((acc, fn) => fn(acc, processingContext), item)
  );

  const shippingOrderUpdatePayloads = processedItems
    .filter((item) => item.isProcessable)
    .map(
      (item): UpdateShippingOrderPayload => ({
        uniqueCode: item.value.uniqueCode,
        uniqueCodeAfterCustomization: item.key,
        shippingNumber: item.value.shippingNumber,
        shippingCompany: item.value.shippingCompany,
        shippingStatus: item.value.shippingStatus,
      })
    );

  const updateResults = await bulkUpdateShippingOrder({
    orders: shippingOrderUpdatePayloads,
  });
  const updateFailedShippingOrderKeys = new Set(
    updateResults
      .filter((result) => !result.isSuccess)
      .map((result) => result.data.uniqueCodeAfterCustomization)
  );

  // NOTE(@형준): API 요청 실패한 item 의 status 업데이트
  const updatedProcessedItems = processedItems.map((item) => {
    const isUpdateNotRequested = item.isProcessable === false;
    if (isUpdateNotRequested) {
      return item;
    }

    const shippingOrderKey = item.key;
    const isUpdateRequestSuccess =
      !updateFailedShippingOrderKeys.has(shippingOrderKey);
    if (isUpdateRequestSuccess) {
      return item;
    }

    const nextStatus =
      SHIPPING_ORDER_PROCESS_STATUS_CODE.API_BULK_UPDATE_SHIPPING_INFO_FAILED;
    const nextHistories = [...item.histories, nextStatus];
    const nextShippingOrder = {
      ...item.value,
      shippingStatus: ORDER_SHIPPING_STATUS.processing,
    };

    return {
      ...item,
      isProcessable: false,
      status: nextStatus,
      histories: nextHistories,
      value: nextShippingOrder,
    };
  });

  const updatedShippingOrders = existingOrders.map((order) => {
    const key = order.uniqueCodeAfterCustomization;
    const isUpdateFailed = updateFailedShippingOrderKeys.has(key);

    if (isUpdateFailed) {
      return { ...order };
    }

    const updateItem = updatedProcessedItems.find(
      (item) => item.isProcessable === true && item.key === key
    );

    if (isNil(updateItem)) {
      return { ...order };
    }

    const { shippingNumber, shippingCompany, shippingStatus } =
      updateItem.value;

    return {
      ...order,
      shippingNumber,
      shippingCompany,
      shippingStatus,
    };
  });

  oms.order.setNormalizedOrders(updatedShippingOrders);

  reportFallbackUsage(updatedProcessedItems);

  const failedItems = updatedProcessedItems.filter(
    (item) => item.isProcessable === false
  );

  const isShippingInvoiceProcessSuccess = failedItems.length === 0;
  if (isShippingInvoiceProcessSuccess) {
    toast.success('운송장이 입력되었습니다.');
    return;
  }

  const updateFailedShippingOrdersWithReason =
    getFailedShippingOrderWithReason(failedItems);

  const failedItemCount = failedItems.length;
  const errorMessage = `운송장 입력에 실패한 주문이 있습니다.  (${failedItemCount}건) 실패 사유는 엑셀 파일을 확인해주세요.`;
  toast.error(errorMessage);

  await createOrderExcel(
    oms,
    updateFailedShippingOrdersWithReason,
    formatDate(new Date(), 'yy.MM.dd 운송장 입력 실패 건'),
    ['실패 사유', ...oms.user.excelHeaders],
    { failReason: '실패 사유', ...oms.user.excelColumnMapping }
  );

  const userId = oms.user.userId!;

  const dto: CreateOrderShippingProcessHistoryDTO = {
    isSuccess: isShippingInvoiceProcessSuccess,
    userId,
    uploadFilename: filename,
    uploadFilePassword: password,
    fileHeaderMappingResult: matchResultHistory,
    appliedProcessors: appliedProcessorNames,
    processedItems: updatedProcessedItems,
  };

  saveOrderShippingProcessHistory(dto);
}
