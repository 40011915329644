import { z } from 'zod';
import { OrderTransactionHistory } from './OrderTransactionHistory';

export const OrderTransactionHistoryCreateDTO = OrderTransactionHistory.omit({
  _id: true,
  eventAt: true,
});

export type OrderTransactionHistoryCreateDTO = z.infer<
  typeof OrderTransactionHistoryCreateDTO
>;
