import { z } from 'zod';
import { c } from '../../internal/contract';
import { DispatchedOrder } from '../../type/order/DispatchedOrder';
import { OrderStatus } from '../../type/order/OrderStatus';
import {
  CommonErrorResponseSchema,
  ZodErrorResponseSchema,
} from '../commonErrorResponse';
import { NEXT_STEP_FLAG } from './nextStepFlag';

export type OrderWithShippingInvoice = DispatchedOrder & {
  shippingNumber: string;
  shippingCompany: string;
};

export const FulfillOrderSchema = z.object({
  uniqueCode: z.string(),
  shippingCompany: z.string(),
  shippingNumber: z.string(),
  orderStatus: z.nativeEnum(OrderStatus).optional(),
  nextStepFlag: z
    .enum([NEXT_STEP_FLAG.isNoop, NEXT_STEP_FLAG.isUpdateRequired])
    .optional(),
});

export type FulfillOrder = z.infer<typeof FulfillOrderSchema>;

export const FulfilledOrderSuccessResultSchema = FulfillOrderSchema.extend({
  success: z.literal(true),
});

export const FulfilledOrderFailResultSchema = FulfillOrderSchema.extend({
  success: z.literal(false),
  failReason: z.string(),
});

export type FulfilledOrderSuccessResult = z.infer<
  typeof FulfilledOrderSuccessResultSchema
>;

export type FulfilledOrderFailResult = z.infer<
  typeof FulfilledOrderFailResultSchema
>;

export const FulfilledOrderResultSchema = z.union([
  FulfilledOrderSuccessResultSchema,
  FulfilledOrderFailResultSchema,
]);

export type FulfilledOrderResult = z.infer<typeof FulfilledOrderResultSchema>;

// POST /fulfill-order/fulfill-orders
export const FULFILL_ORDERS_URL = '/fulfill-order/fulfill-orders';

export const FulfillOrdersBodySchema = z.object({
  orders: z.array(FulfillOrderSchema),
});
export type FulfillOrdersBody = z.infer<typeof FulfillOrdersBodySchema>;

export type FulfillOrdersResponse = FulfilledOrderResult[];

export const FulfillOrdersSuccessResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(FulfilledOrderResultSchema),
});

export type FulfillOrdersSuccessResponse = z.infer<
  typeof FulfillOrdersSuccessResponseSchema
>;
export const AUTO_DISPATCH_ORDERS_URL = '/fulfill-order/auto-dispatch-orders';

export const AutoDispatchOrderSchema = z.object({
  uniqueCode: z.string(),
  shippingCompany: z.string(),
  shippingNumber: z.string(),
  autoFulfilled: z.boolean(),
});
export type AutoDispatchOrder = z.infer<typeof AutoDispatchOrderSchema>;

export type AutoDispatchedOrderResult =
  | ({ success: true } & AutoDispatchOrder)
  | ({ success: false; failReason: string } & AutoDispatchOrder);

export const AutoDispatchOrdersBodySchema = z.object({
  orders: z.array(AutoDispatchOrderSchema),
});

export type AutoDispatchOrdersBody = z.infer<
  typeof AutoDispatchOrdersBodySchema
>;

export type AutoDispatchOrdersResponse = AutoDispatchedOrderResult[];

export const fulfillOrderRouterContract = c.router({
  fulfillOrders: {
    method: 'POST',
    path: FULFILL_ORDERS_URL,
    body: FulfillOrdersBodySchema,
    responses: {
      200: FulfillOrdersSuccessResponseSchema,
      400: ZodErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
});
