import { MultiSheetExcelFile } from './interface';

export function validateSpreadsheet(
  spreadsheet: MultiSheetExcelFile,
  options: {
    validExtensions?: string[];
    invalidFileNames?: string[];
  } = {}
): { isValid: boolean; invalidReason?: 'invalidExtension' | 'invalidName' } {
  console.debug('spreadsheet', options.invalidFileNames);
  const filename = spreadsheet.name.normalize();
  const extension = spreadsheet.extension;
  if (extension == null) {
    return {
      isValid: false,
      invalidReason: 'invalidExtension',
    };
  }

  const invalidFileNames = new Set(
    options.invalidFileNames?.map((filename) => filename.normalize())
  );

  const isValidExtension =
    options.validExtensions == null ||
    options.validExtensions.includes(extension);
  const isInvalidFilename = invalidFileNames.has(filename);

  if (!isValidExtension) {
    return {
      isValid: false,
      invalidReason: 'invalidExtension',
    };
  }

  if (isInvalidFilename) {
    return {
      isValid: false,
      invalidReason: 'invalidName',
    };
  }

  return {
    isValid: true,
  };
}
