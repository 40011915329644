import { CustomComponents } from 'react-day-picker';
import { IconArrowRightBtn } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';

export const RightNav: CustomComponents['Nav'] = ({
  previousMonth,
  nextMonth,
  onPreviousClick,
  onNextClick,
  className,
  ...rest
}) => (
  <div className={nextButton({ className })} {...rest}>
    <button onClick={onNextClick}>
      <IconArrowRightBtn />
    </button>
  </div>
);

const nextButton = cva(
  'right-0 !h-[27px] w-fit text-gray-400 hover:text-gray-700'
);
