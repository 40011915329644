import { formatDate } from '@sweep/utils';
import {
  ADDRESS_VALIDATOR_USAGE_DATE_FORMAT,
  ADDRESS_VALIDATOR_USAGE_DATES_KEY,
  TRIAL_DAYS_LIMIT,
} from './constants';

export function isTrialPeriodExceeded() {
  const today = formatDate(new Date(), ADDRESS_VALIDATOR_USAGE_DATE_FORMAT);
  const usedDates =
    localStorage.getItem(ADDRESS_VALIDATOR_USAGE_DATES_KEY)?.split(',') ?? [];

  const isTodayUsed = usedDates.includes(today);
  if (isTodayUsed) {
    return false;
  }

  return usedDates.length >= TRIAL_DAYS_LIMIT;
}
