import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconFire, IconMenu, IconXClose } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { Device, useDevice } from 'src/hooks/useDevice';
import { imageLogoSymbolTextHorizontal } from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';

const HOME_URL = '/';
const ALI_EXPRESS_URL = '/ali-express';
const ADDRESS_URL = '/address';
const EVENT_URL =
  'https://sweep-contents.oopy.io/?utm_source=sweep&utm_medium=gnb&utm_campaign=20250317';

interface HeaderProps {
  onOpen?: () => void;
  className?: string;
}

function Header(props: HeaderProps) {
  const device = useDevice();

  return device >= Device.DESKTOP ? (
    <HeaderDesktop {...props} />
  ) : (
    <HeaderMobile {...props} />
  );
}

function HeaderDesktop({ onOpen, className }: HeaderProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div
      className={desktopContainer({ className })}
      style={{
        borderBottom: '1px solid',
        borderImageSource:
          'radial-gradient(100% 100% at 0% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
        borderImageSlice: 1,
      }}
    >
      <div className="px-8px flex w-[1200px] items-center justify-between">
        <div className="gap-40px flex items-center">
          <a href={HOME_URL} onClick={trackGnbHomeClick}>
            <img
              src={imageLogoSymbolTextHorizontal}
              alt="sweep icon"
              className="h-32px w-auto"
            />
          </a>
          <div className="gap-8px flex">
            <a
              href={ALI_EXPRESS_URL}
              onClick={trackGnbAliClick}
              className={link({
                active: pathname === ALI_EXPRESS_URL,
                color: 'red',
              })}
            >
              알리 주문수집
            </a>
            <a
              href={ADDRESS_URL}
              onClick={trackGnbAddressClick}
              className={link({
                active: pathname === ADDRESS_URL,
                color: 'blue',
              })}
            >
              주소 변환기
            </a>
            <a
              href={EVENT_URL}
              onClick={trackGnbEventClick}
              className={link({ active: false })}
            >
              <div className="gap-4px flex">
                <IconFire />
                평생 무료 이벤트
              </div>
            </a>
          </div>
        </div>
        <div className="gap-10px flex">
          <Button
            variant="line"
            className="hover:bg-blue-50"
            onClick={() => navigate('/sign-in')}
          >
            로그인
          </Button>
          <Button
            variant="solid"
            className="hover:bg-blue-600"
            onClick={onOpen}
          >
            무료로 시작하기
          </Button>
        </div>
      </div>
    </div>
  );
}

const desktopContainer = cva(
  'h-80px fixed top-0 z-50 flex w-full justify-center bg-white bg-opacity-[80%] shadow-[0_0_4px_0_rgba(0,0,0,0.1)] backdrop-blur-[80px]'
);

const link = cva('text-semibold-m px-16px py-12px rounded-8px text-gray-600', {
  variants: {
    active: {
      true: '',
      false: 'hover:bg-gray-200',
    },
    color: {
      blue: '',
      red: '',
    },
  },
  compoundVariants: [
    {
      color: 'blue',
      active: true,
      className: 'bg-blue-50 text-blue-500',
    },
    {
      color: 'red',
      active: true,
      className: 'bg-red-100 text-red-500',
    },
  ],
});

function HeaderMobile({ className }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div
        className={mobileContainer({ className })}
        style={{
          backdropFilter: 'blur(80px)',
          boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <a href="/" onClick={trackGnbHomeClick}>
          <img
            src={imageLogoSymbolTextHorizontal}
            alt="sweep icon"
            className="h-20px w-auto"
          />
        </a>
        <button onClick={() => setIsMenuOpen(true)}>
          <IconMenu />
        </button>
      </div>

      {isMenuOpen && <MobileMenu onClose={() => setIsMenuOpen(false)} />}
    </>
  );
}

const mobileContainer = cva(
  'h-60px px-32px absolute inset-x-0 top-0 z-10 flex items-center justify-between'
);

interface MobileMenuProps {
  onClose: () => void;
}

function MobileMenu({ onClose }: MobileMenuProps) {
  const { pathname } = useLocation();

  return createPortal(
    <div className="z-overlay fixed inset-0">
      <div
        // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
        className="absolute inset-0 bg-black bg-opacity-40"
        onClick={onClose}
      />

      <div className="w-236px px-20px absolute right-0 top-0 h-full bg-white">
        <div className="h-64px px-12px flex justify-end">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <IconXClose className="text-gray-700" />
          </button>
        </div>

        <ul className="space-y-8px">
          <li>
            <a
              href={HOME_URL}
              onClick={trackGnbHomeClick}
              className={menuLink({
                active: pathname === HOME_URL,
                color: 'blue',
              })}
            >
              홈
            </a>
          </li>
          <li>
            <a
              href={ALI_EXPRESS_URL}
              onClick={trackGnbAliClick}
              className={menuLink({
                active: pathname === ALI_EXPRESS_URL,
                color: 'red',
              })}
            >
              알리 주문수집
            </a>
          </li>
          <li>
            <a
              href={ADDRESS_URL}
              onClick={trackGnbAddressClick}
              className={menuLink({
                active: pathname === ADDRESS_URL,
                color: 'blue',
              })}
            >
              주소 변환기
            </a>
          </li>
          <li>
            <a
              href={EVENT_URL}
              onClick={trackGnbEventClick}
              className={menuLink({ active: false })}
            >
              <div className="gap-4px flex">
                <IconFire />
                평생 무료 이벤트
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>,
    document.body
  );
}

const trackGnbHomeClick = () => amplitude.track('Click gnb-home');
const trackGnbAliClick = () => amplitude.track('Click gnb-ali');
const trackGnbAddressClick = () => amplitude.track('Click gnb-address');
const trackGnbEventClick = () => amplitude.track('Click gnb-event');

const menuLink = cva(
  'text-semibold-m px-16px py-12px rounded-8px p-16px flex text-gray-600',
  {
    variants: {
      active: {
        true: '',
        false: 'hover:bg-gray-100',
      },
      color: {
        blue: '',
        red: '',
      },
    },
    compoundVariants: [
      {
        color: 'blue',
        active: true,
        className: 'bg-blue-50 text-blue-500',
      },
      {
        color: 'red',
        active: true,
        className: 'bg-red-50 text-red-500',
      },
    ],
  }
);

export default Header;
