import type { FormulaeSpreadsheetTypes } from '@sweep/spreadsheet/types';

// POST /spreadsheet/parse
// POST /spreadsheet/guest/parse
export const PARSE_SPREADSHEET_URL = '/spreadsheet/parse';
export const PARSE_SPREADSHEET_GUEST_URL = '/spreadsheet/guest/parse';

export interface ParseSpreadsheetRequest {
  file: File;
  password?: string | null;
  includeRawData?: boolean;
  tagUploadSource?: string;
}

export type ParseSpreadsheetResponse =
  FormulaeSpreadsheetTypes.IntermediateData;

export type ParseSpreadsheetErrorCode =
  | 'SPREADSHEET_DECRYPT_ERROR'
  | 'SPREADSHEET_PARSE_ERROR';
