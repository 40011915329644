import { z } from 'zod';
import { FulfilledOrderTransactionSchema } from './FulfilledOrderTransaction';

export const FulfilledOrderTransactionCreateBodySchema = z.object({
  partnershipUUID: z.string(),
  fileUUID: z.string(),
  retailerUserId: z.string(),
  supplierUserId: z.string(),
});

export type FulfilledOrderTransactionCreateBody = z.infer<
  typeof FulfilledOrderTransactionCreateBodySchema
>;

export const FulfilledOrderTransactionCreateResponseSchema = z.object({
  success: z.boolean(),
  data: FulfilledOrderTransactionSchema,
});

export type FulfilledOrderTransactionCreateResponse = z.infer<
  typeof FulfilledOrderTransactionCreateResponseSchema
>;

export const FulfilledOrderTransactionFindAllQuerySchema = z.object({
  partnershipUUID: z.string().optional(),
  retailerUserId: z.string().optional(),
  supplierUserId: z.string().optional(),
  isConfirmed: z.enum(['true', 'false']).optional(),
  createdAtDate: z.string().optional(),
  createdAtAfter: z.string().optional(),
  createdAtBefore: z.string().optional(),
  lastTransactionId: z.string().optional(),
});

export type FulfilledOrderTransactionFindAllQuery = z.infer<
  typeof FulfilledOrderTransactionFindAllQuerySchema
>;

export const FulfilledOrderTransactionFindAllResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(FulfilledOrderTransactionSchema),
});

export type FulfilledOrderTransactionFindAllResponse = z.infer<
  typeof FulfilledOrderTransactionFindAllResponseSchema
>;

export const FulfilledOrderTransactionCountResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    count: z.number(),
  }),
});

export type FulfilledOrderTransactionCountResponse = z.infer<
  typeof FulfilledOrderTransactionCountResponseSchema
>;

export const FulfilledOrderTransactionConfirmPathParamsSchema = z.object({
  fulfilledOrderTransactionId: z.string(),
});

export type FulfilledOrderTransactionConfirmPathParams = z.infer<
  typeof FulfilledOrderTransactionConfirmPathParamsSchema
>;

export const FulfilledOrderTransactionConfirmBodySchema = z.object({});

export type FulfilledOrderTransactionConfirmBody = z.infer<
  typeof FulfilledOrderTransactionConfirmBodySchema
>;

export const FulfilledOrderTransactionConfirmResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    confirmedAt: z.string(),
  }),
});

export type FulfilledOrderTransactionConfirmResponse = z.infer<
  typeof FulfilledOrderTransactionConfirmResponseSchema
>;

export const FulfilledOrderTransactionDeleteResponseSchema = z.object({
  success: z.boolean(),
});

export type FulfilledOrderTransactionDeleteResponse = z.infer<
  typeof FulfilledOrderTransactionDeleteResponseSchema
>;

export const FulfilledOrderTransactionGetDownloadUrlResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    downloadUrl: z.string(),
  }),
});

export const FulfilledOrderTransactionGetPreprocessedFileResponseSchema =
  z.object({
    success: z.boolean(),
    data: z.object({
      filename: z.string(),
      extension: z.string(),
      raw: z.string(),
    }),
  });

export type FulfilledOrderTransactionGetPreprocessedFileResponse = z.infer<
  typeof FulfilledOrderTransactionGetPreprocessedFileResponseSchema
>;

export const FulfilledOrderTransactionCreateDTOSchema =
  FulfilledOrderTransactionSchema.omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    confirmedAt: true,
    deletedAt: true,
  });

export type FulfilledOrderTransactionCreateDTO = z.infer<
  typeof FulfilledOrderTransactionCreateDTOSchema
>;

export const FulfilledOrderTransactionUpdateDTOSchema =
  FulfilledOrderTransactionSchema.omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
  }).partial();

export type FulfilledOrderTransactionUpdateDTO = z.infer<
  typeof FulfilledOrderTransactionUpdateDTOSchema
>;
