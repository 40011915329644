import { action, computed, observable } from 'mobx';
import {
  DispatchedOrder,
  isInDelivery,
  isPaymentComplete,
  isProductPreparing,
  NEXT_STEP_FLAG,
  ORDER_SHIPPING_STATUS,
  OrderStatus,
} from '@sweep/contract';
import { isEmptyShippingInvoice } from '@sweep/domain/services/order';
import {
  compareDate,
  formatDate,
  getUnusedFilename,
  isNotNil,
  omitBy,
} from '@sweep/utils';
import {
  acceptCanceledOrders,
  rejectCanceledOrder,
} from 'src/network/order/cancelOrder';
import { confirmOrders } from 'src/network/order/confirmOrder';
import { createOrderExcel } from 'src/services/file/excel/create';
import { OMSStore } from 'src/stores/OMSStore';
import { toast } from 'src/third-parties/toast';
import { openAcceptCanceledOrderDialog } from '../components/dialogs/openAcceptCanceledOrderDialog';
import { openConfirmOrderDialog } from '../components/dialogs/openConfirmOrderDialog';
import { openConfirmOrderWithExclusionDialog } from '../components/dialogs/openConfirmOrderWithExclusionDialog';
import { openDispatchDialog } from '../components/dialogs/openDispatchDialog';
import { openDispatchSelectModal } from '../components/dialogs/openDispatchSelectModal';
import { openRejectCanceledOrderDialog } from '../components/dialogs/openRejectCanceledOrderDialog';
import {
  CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
  CANCEL_REQUESTED_ORDER_HEADERS,
} from '../constants';
import { OrderStatusType } from '../interface';
import { filterOrderStatus } from '../services/filterOrderStatus';
import { filterShoppingMall } from '../services/filterShoppingMall';
import { getBundleCancelRequestOrders } from '../services/getBundleCancelRequestOrders';
import { DispatchModalStore } from './DispatchModalStore';

export class OrderDispatchScreenStore {
  @observable
  accessor _shoppingMallDispatches: string[] | null = null;

  @observable
  accessor selectedOrders: DispatchedOrder[] = [];

  @observable
  accessor shoppingMallFilters: string[] = [];

  @observable
  accessor selectedOrderStatusType: OrderStatusType = 'PaymentComplete';

  @computed
  get shoppingMallDispatches() {
    return (
      this._shoppingMallDispatches ?? this.oms.shoppingMall.shoppingMallNames
    );
  }

  @computed
  get statusFilteredOrders() {
    return filterOrderStatus(
      this.oms.order.dispatch.dispatchedOrders,
      this.selectedOrderStatusType
    );
  }

  @computed
  get shoppingMallFilteredOrders() {
    const shoppingMallFilteredOrders = filterShoppingMall(
      this.statusFilteredOrders,
      this.shoppingMallFilters
    );

    // NOTE(@이지원): 쿠팡의 경우, 취소 요청이 들어온 주문을 묶어서 보여줘야 함.
    const bundleOrders =
      this.selectedOrderStatusType === 'CancelRequest'
        ? getBundleCancelRequestOrders(shoppingMallFilteredOrders)
        : shoppingMallFilteredOrders;

    return bundleOrders.slice().sort((a, b) => {
      const aOrder = Array.isArray(a) ? a.at(0) : a;
      const bOrder = Array.isArray(b) ? b.at(0) : b;
      return compareDate(bOrder?.orderDate, aOrder?.orderDate);
    });
  }

  constructor(private oms: OMSStore) {
    this._shoppingMallDispatches = this.oms.local.shoppingMallDispatches.value;
  }

  @action.bound
  setSelectedOrders = (orders: DispatchedOrder[]) => {
    this.selectedOrders = orders;
  };

  @action.bound
  setShoppingMallDispatches = (names: string[]) => {
    this._shoppingMallDispatches = names;
    this.oms.local.shoppingMallDispatches.setValue(names);
    this.shoppingMallFilters = [];
  };

  @action.bound
  setShoppingMallFilters = (filters: string[]) => {
    this.shoppingMallFilters = filters;
  };

  @action.bound
  setOrderStatus(orderStatus: OrderStatusType) {
    this.selectedOrderStatusType = orderStatus;
    this.selectedOrders = [];
  }

  openDispatchSelectModal = () => {
    if (this.oms.shoppingMall.isNotLinkedShoppingMall === true) {
      toast.error('연동된 쇼핑몰이 없습니다.');
      return;
    }

    openDispatchSelectModal(
      this.shoppingMallDispatches,
      async (shoppingMalls) => {
        const abortController = new AbortController();
        await this.dispatchOrder(shoppingMalls, abortController);
        if (abortController.signal.aborted) {
          return;
        }

        this.setShoppingMallDispatches(shoppingMalls);
      }
    );
  };

  dispatchSelectedShoppingMalls = async () => {
    if (this.oms.shoppingMall.isNotLinkedShoppingMall === true) {
      toast.error('연동된 쇼핑몰이 없습니다.');
      return;
    }

    const abortController = new AbortController();
    await this.dispatchOrder(this.shoppingMallDispatches, abortController);
    if (abortController.signal.aborted) {
      return;
    }

    this.setShoppingMallDispatches(this.shoppingMallDispatches);
  };

  dispatchOrder = async (
    shoppingMalls: string[],
    abortController: AbortController
  ) => {
    if (shoppingMalls.length === 0) {
      return null;
    }

    const store = new DispatchModalStore(
      this.oms.order.dispatch,
      shoppingMalls
    );

    openDispatchDialog(store, abortController);
    const orders = await store.getDispatchedOrders(
      shoppingMalls,
      abortController.signal
    );

    if (orders == null || abortController.signal.aborted) {
      return;
    }

    this.oms.order.dispatch.setDispatchedOrders(orders);
    this.setSelectedOrders([]);

    await this.syncWithOrderProcess(orders);

    return orders;
  };

  private syncWithOrderProcess = async (orders: DispatchedOrder[]) => {
    const shippingOrders = orders.filter((order) =>
      isInDelivery(order.orderStatus)
    );
    const shippingInvoiceByUniqueCode = new Map(
      shippingOrders.map((order) => [
        order.uniqueCode,
        {
          shippingCompany: order.shippingCompany,
          shippingNumber: order.shippingNumber,
        },
      ])
    );

    const updatedMergedOrders = this.oms.order.mergedOrders
      .map((order) => {
        const shippingInvoice = shippingInvoiceByUniqueCode.get(
          order.uniqueCode
        );
        return shippingInvoice != null && isEmptyShippingInvoice(order)
          ? { ...order, ...shippingInvoice }
          : null;
      })
      .filter(isNotNil);

    const updatedNormalizedOrders = this.oms.order.normalizedOrders
      .map((order) => {
        const uniqueCodes = order.uniqueCode.split(',');
        for (const uniqueCode of uniqueCodes) {
          const shippingInvoice = shippingInvoiceByUniqueCode.get(uniqueCode);
          const isNotShipped =
            order.shippingStatus !== ORDER_SHIPPING_STATUS.shipped;
          if (shippingInvoice != null && isNotShipped) {
            return {
              ...order,
              ...shippingInvoice,
              shippingStatus: ORDER_SHIPPING_STATUS.shipped,
            };
          }
        }
        return null;
      })
      .filter(isNotNil);

    await Promise.all([
      this.oms.order.upsertMergedOrders(updatedMergedOrders),
      this.oms.order.upsertNormalizedOrders(updatedNormalizedOrders),
    ]);
  };

  confirmSelectedOrders = async () => {
    const result = await this.confirmOrders(this.selectedOrders);
    if (result) {
      this.setSelectedOrders([]);
    }
  };

  confirmOrders = async (orders: DispatchedOrder[]) => {
    if (orders.length === 0) {
      toast.error('주문을 선택해주세요.');
      return false;
    }

    const KAKAOMAKERS_PREFIX = 'MAK-';
    const confirmableOrders = orders.filter(
      (order) => order.uniqueCode.startsWith(KAKAOMAKERS_PREFIX) === false
    );

    if (confirmableOrders.length === 0) {
      toast.error('카카오메이커스 주문은 확인할 수 없습니다.');
      return false;
    }

    const openDialog =
      confirmableOrders.length === orders.length
        ? openConfirmOrderDialog
        : openConfirmOrderWithExclusionDialog;
    const isConfirmed = await openDialog(confirmableOrders.length);
    if (!isConfirmed) {
      return false;
    }

    return this.oms.loading.batch(async () => {
      const response = await confirmOrders(confirmableOrders);

      if (response?.success !== true) {
        toast.error('주문 확인에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return false;
      }

      const succeedOrders = response.data.filter((order) => order.success);
      const failedOrders = response.data.filter(
        (order) => order.success === false
      );

      if (succeedOrders.length > 0) {
        const transformedOrders =
          await this.oms.order.dispatch.transformOrders(succeedOrders);

        this.oms.order.dispatch.pushDispatchedOrders(transformedOrders);
      }

      if (failedOrders.length > 0) {
        toast.error(
          '주문 확인에 실패한 건들이 있어요. 해당 실패 건의 사유를 확인해주세요.'
        );
        const formattedDate = formatDate(new Date(), 'yy.MM.dd');
        const orders = failedOrders
          .map((failedOrder) => {
            const dispatchedOrder = this.oms.order.dispatch.getDispatchedOrder(
              failedOrder.uniqueCode
            );
            return dispatchedOrder != null
              ? { ...dispatchedOrder, ...failedOrder }
              : null;
          })
          .filter(isNotNil);

        await createOrderExcel(
          this.oms,
          orders,
          `${formattedDate} 주문 확인 실패 건`,
          ['실패 사유', ...this.oms.user.excelHeaders],
          { failReason: '실패 사유', ...this.oms.user.excelColumnMapping }
        );
      } else {
        toast.success('주문 확인이 완료되었습니다.');
      }

      return true;
    });
  };

  acceptCanceledSelectedOrders = async () => {
    const result = await this.acceptCanceledOrders(this.selectedOrders);

    if (result) {
      this.setSelectedOrders([]);
    }
  };

  private acceptCanceledOrders = async (
    orders: DispatchedOrder[]
  ): Promise<boolean> => {
    if (orders.length === 0) {
      toast.error('주문을 선택해주세요.');
      return false;
    }

    const isConfirmed = await openAcceptCanceledOrderDialog(orders.length);
    if (!isConfirmed) {
      return false;
    }

    return this.oms.loading.batch(async () => {
      const response = await acceptCanceledOrders(orders);
      if (response?.success !== true) {
        toast.error('취소 승인에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return false;
      }

      const ordersByUniqueCode = new Map(
        orders.map((order) => [order.uniqueCode, order])
      );

      const succeedUniqueCodes = response.data
        .filter((order) => order.success)
        .map((order) => order.uniqueCode);

      if (succeedUniqueCodes.length > 0) {
        this.oms.order.dispatch.removeDispatchedOrdersByUniqueCodes(
          succeedUniqueCodes
        );
      }

      const failedOrders = response.data
        .filter((order) => !order.success)
        .map<DispatchedOrder | null>((order) => {
          const prevOrder = ordersByUniqueCode.get(order.uniqueCode);
          return prevOrder == null ? null : { ...prevOrder, ...order };
        })
        .filter(isNotNil);

      if (failedOrders.length === 0) {
        toast.success('취소 승인이 완료되었습니다.');
      }

      if (failedOrders.length > 0) {
        toast.error(
          '취소 승인에 실패한 건들이 있어요. 해당 실패 건의 사유를 확인해주세요.'
        );
        const formattedDate = formatDate(new Date(), 'yy.MM.dd');

        await createOrderExcel(
          this.oms,
          failedOrders,
          `${formattedDate} 취소 승인 실패 건`,
          ['실패 사유', ...this.oms.user.excelHeaders],
          {
            failReason: '실패 사유',
            ...this.oms.user.excelColumnMapping,
          }
        );
      }

      return true;
    });
  };

  openRejectCanceledSelectedOrdersDialog = async () => {
    if (this.selectedOrders.length === 0) {
      toast.error('주문을 선택해주세요.');
      return;
    }

    const result = await openRejectCanceledOrderDialog(
      this.selectedOrders,
      this.rejectCanceledSelectedOrders
    );

    if (result) {
      this.setSelectedOrders([]);
    }
  };

  private rejectCanceledSelectedOrders = async (
    rejectReason: string,
    orders: DispatchedOrder[]
  ): Promise<boolean> => {
    return this.oms.loading.batch(async () => {
      const response = await rejectCanceledOrder(orders, rejectReason);
      if (response?.success !== true) {
        toast.error('취소 거부에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return false;
      }

      const succeedOrders = response.data
        .filter((order) => order.success)
        .map<DispatchedOrder | null>((order) => {
          const prevOrder = this.oms.order.dispatch.getDispatchedOrder(
            order.uniqueCode
          );

          if (prevOrder == null) {
            return null;
          }

          if (order.nextStepFlag == null) {
            // Deprecated 로직, new 로직으로 migration 하면 제거되어야 함
            return {
              ...prevOrder,
              orderStatus: OrderStatus.inDelivery,
              shippingCompany: order.shippingCompany,
              shippingNumber: order.shippingNumber,
            };
          }

          const updatedValues = omitBy(order, (value) => value == null);

          if (order.nextStepFlag === NEXT_STEP_FLAG.isUpdateRequired) {
            return {
              ...prevOrder,
              ...updatedValues,
            };
          }

          return prevOrder;
        })
        .filter(isNotNil);

      const failedOrders = response.data
        .filter((order) => !order.success)
        .map<DispatchedOrder | null>((order) => {
          const prevOrder = this.oms.order.dispatch.getDispatchedOrder(
            order.uniqueCode
          );
          return prevOrder == null ? null : { ...prevOrder, ...order };
        })
        .filter(isNotNil);

      if (failedOrders.length > 0) {
        toast.error(
          '취소 거부에 실패한 건들이 있어요. 해당 실패 건의 사유를 확인해주세요.'
        );

        await createOrderExcel(
          this.oms,
          failedOrders,
          `${formatDate(new Date(), 'yy.MM.dd')} 취소 거부 실패 건`,
          ['실패 사유', ...CANCEL_REQUESTED_ORDER_HEADERS],
          { failReason: '실패 사유', ...CANCEL_REQUESTED_ORDER_COLUMN_MAPPING }
        );
      } else {
        toast.success('취소 거부가 완료되었습니다.');
      }

      if (succeedOrders.length > 0) {
        this.oms.order.dispatch.pushDispatchedOrders(succeedOrders);

        // NOTE(@이지원): 11번가 한정 로직. 한 장바구니에 담겨서 여러 주문이 한 번에 들어온 경우가 있음.
        // 해당 주문 중 하나를 "취소거부" 하면, 다른 주문들("결제 완료", "상품 준비 중"에 있는 주문들)도 "배송 중"으로 변경됨.
        const elevenOrdersByNumber = new Map(
          succeedOrders
            .filter((order) => order.uniqueCode.startsWith('ELV'))
            .map((order) => [order.orderNumber, order])
        );

        if (elevenOrdersByNumber.values.length > 0) {
          const elevenOrders = this.oms.order.dispatch.dispatchedOrders
            .map<DispatchedOrder | null>((order) => {
              const rejectedOrder = elevenOrdersByNumber.get(order.orderNumber);
              if (rejectedOrder == null) {
                return null;
              }

              const isNotPaymentComplete = !isPaymentComplete(
                order.orderStatus
              );
              const isNotProductPreparing = !isProductPreparing(
                order.orderStatus
              );
              if (isNotPaymentComplete || isNotProductPreparing) {
                return null;
              }

              return {
                ...order,
                orderStatus: OrderStatus.inDelivery,
                shippingCompany: rejectedOrder.shippingCompany,
                shippingNumber: rejectedOrder.shippingNumber,
              };
            })
            .filter(isNotNil);

          if (elevenOrders.length > 0) {
            this.oms.order.dispatch.pushDispatchedOrders(elevenOrders);
          }
        }
      }

      return true;
    });
  };

  mergedSelectedDispatchedOrders = async () => {
    this.mergeDispatchedOrders(this.selectedOrders);
  };

  mergeDispatchedOrders = async (orders: DispatchedOrder[]) => {
    if (orders.length === 0) {
      toast.error('주문을 선택해주세요.');
      return;
    }

    const mergedUniqueCodesSet = new Set(
      this.oms.order.mergedOrders.map((order) => order.uniqueCode)
    );
    const normalizedUniqueCodesSet = new Set(
      this.oms.order.normalizedOrders.map((order) => order.uniqueCode)
    );

    const filteredOrders = orders.filter(
      (order) =>
        !mergedUniqueCodesSet.has(order.uniqueCode) &&
        !normalizedUniqueCodesSet.has(order.uniqueCode)
    );

    if (filteredOrders.length === 0) {
      alert('이미 통합 엑셀에 추가된 주문 건입니다.');
      return;
    }

    const filename = this.getOrderDispatchFilename();
    const ordersWithFilename = filteredOrders.map((order) => ({
      ...order,
      originFile: filename,
    }));

    await this.oms.loading.batch(async () => {
      await this.oms.order.mergeDispatchedOrders(ordersWithFilename);
      await this.oms.order.draft.register([filename]);
    });

    toast.success(
      `${filteredOrders.length}건의 주문을 통합 엑셀에 추가하였습니다. (중복 건 제외)`
    );
    return;
  };

  getOrderDispatchFilename = () => {
    const filename = `주문수집_${formatDate(new Date(), 'yyyy년 M월 d일')}`;
    const fileNames = this.oms.order.normalizedOrders
      .map((order) => order.originFile)
      .filter(isNotNil);

    return getUnusedFilename(filename, fileNames);
  };
}
