import { z } from 'zod';
import { PurchaseOrderTransactionSchema } from './PurchaseOrderTransaction';

export const PurchaseOrderTransactionCreateBodySchema = z.object({
  partnershipUUID: z.string(),
  fileUUID: z.string(),
  preprocessedFileUUID: z.string(),
  retailerUserId: z.string(),
  supplierUserId: z.string(),
});

export type PurchaseOrderTransactionCreateBody = z.infer<
  typeof PurchaseOrderTransactionCreateBodySchema
>;

export const PurchaseOrderTransactionCreateResponseSchema = z.object({
  success: z.boolean(),
  data: PurchaseOrderTransactionSchema,
});

export type PurchaseOrderTransactionCreateResponse = z.infer<
  typeof PurchaseOrderTransactionCreateResponseSchema
>;

export const PurchaseOrderTransactionFindAllQuerySchema = z.object({
  partnershipUUID: z.string().optional(),
  retailerUserId: z.string().optional(),
  supplierUserId: z.string().optional(),
  isConfirmed: z.enum(['true', 'false']).optional(),
  createdAtDate: z.string().optional(),
  createdAtAfter: z.string().optional(),
  createdAtBefore: z.string().optional(),
  lastTransactionId: z.string().optional(),
});

export type PurchaseOrderTransactionFindAllQuery = z.infer<
  typeof PurchaseOrderTransactionFindAllQuerySchema
>;

export const PurchaseOrderTransactionFindAllResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(PurchaseOrderTransactionSchema),
});

export type PurchaseOrderTransactionFindAllResponse = z.infer<
  typeof PurchaseOrderTransactionFindAllResponseSchema
>;

export const PurchaseOrderTransactionCountResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    count: z.number(),
  }),
});

export type PurchaseOrderTransactionCountResponse = z.infer<
  typeof PurchaseOrderTransactionCountResponseSchema
>;

export const PurchaseOrderTransactionConfirmBodySchema = z.object({});

export type PurchaseOrderTransactionConfirmBody = z.infer<
  typeof PurchaseOrderTransactionConfirmBodySchema
>;

export const PurchaseOrderTransactionConfirmResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    confirmedAt: z.string(),
  }),
});

export type PurchaseOrderTransactionConfirmResponse = z.infer<
  typeof PurchaseOrderTransactionConfirmResponseSchema
>;

export const PurchaseOrderTransactionDeleteResponseSchema = z.object({
  success: z.boolean(),
});

export type PurchaseOrderTransactionDeleteResponse = z.infer<
  typeof PurchaseOrderTransactionDeleteResponseSchema
>;

export const PurchaseOrderTransactionGetDownloadUrlResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    downloadUrl: z.string(),
  }),
});

export const PurchaseOrderTransactionIdPathParamsSchema = z.object({
  purchaseOrderTransactionId: z.string(),
});

export type PurchaseOrderTransactionIdPathParams = z.infer<
  typeof PurchaseOrderTransactionIdPathParamsSchema
>;

export const PurchaseOrderTransactionGetPreprocessedContentResponseSchema =
  z.object({
    success: z.boolean(),
    data: z.object({
      content: z.string(),
    }),
  });

export type PurchaseOrderTransactionGetPreprocessedContentResponse = z.infer<
  typeof PurchaseOrderTransactionGetPreprocessedContentResponseSchema
>;

export const PurchaseOrderTransactionCreateDTOSchema =
  PurchaseOrderTransactionSchema.omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    confirmedAt: true,
    deletedAt: true,
  });

export type PurchaseOrderTransactionCreateDTO = z.infer<
  typeof PurchaseOrderTransactionCreateDTOSchema
>;

export const PurchaseOrderTransactionUpdateDTO =
  PurchaseOrderTransactionSchema.omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
  }).partial();

export type PurchaseOrderTransactionUpdateDTO = z.infer<
  typeof PurchaseOrderTransactionUpdateDTO
>;
