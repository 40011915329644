import { divide, parseNumber } from '@sweep/utils';
import { OPTION_KEY, PRICE_KEYS } from '../constants';
import { ExcelOrder, Result } from '../interface';
import { getRatios } from './getRatios';
import { splitOption } from './splitOption';

export function splitOrder(order: ExcelOrder): Result<ExcelOrder[]> {
  const optionValue = order[OPTION_KEY];

  if (!optionValue) {
    return {
      success: true,
      data: [order],
    };
  }

  const options = splitOption(optionValue);

  if (options.length === 0) {
    return {
      success: true,
      data: [order],
    };
  }

  const ratios = getRatios(options);

  const prices = PRICE_KEYS.map((priceKey) => {
    const price = order[priceKey];
    if (price === '') {
      return 0;
    }

    return parseNumber(price);
  });

  if (!isNotHasNullArray(prices)) {
    return {
      success: false,
      error: '가격열에 숫자로 변환할 수 없는 값이 있습니다.',
    };
  }

  const dividedPricesList = prices.map((price) => divide(price, ratios));

  const splitOrders = options.map((option, index) => {
    const prices = dividedPricesList.map(
      (dividedPrices) => dividedPrices[index]
    );
    const priceRecord = Object.fromEntries(
      PRICE_KEYS.map((priceKey, index) => [priceKey, prices[index].toString()])
    );

    return {
      ...order,
      ...priceRecord,
      [OPTION_KEY]: option,
    };
  });

  return {
    success: true,
    data: splitOrders,
  };
}

function isNotHasNullArray<T>(array: (T | null | undefined)[]): array is T[] {
  return !array.some((item) => item == null);
}
