import { client } from 'src/network/client';

export async function uploadSpreadsheetFile(file: File, password?: string) {
  return await client.spreadsheet.upload({
    body: {
      file,
      password: password ?? '',
    },
  });
}
