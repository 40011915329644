export type ExcelOrder = Record<string, string>;

export type Result<T> =
  | {
      success: true;
      data: T;
    }
  | {
      success: false;
      error: string;
    };

export function isSuccess<T>(result: Result<T>): result is {
  success: true;
  data: T;
} {
  return result.success;
}

export function isError<T>(result: Result<T>): result is {
  success: false;
  error: string;
} {
  return !result.success;
}
