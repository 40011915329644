import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { imageLogoSymbolTextHorizontal } from 'src/images';
import { TabGroup } from './services/getTabGroups';
import { SidebarItem } from './SidebarItem';

const Sidebar = ({ tabGroups }: { tabGroups: TabGroup[] }) => {
  return (
    <div
      className="w-280px fixed z-10 flex h-full flex-none shrink-0 flex-col items-start overflow-y-auto bg-white"
      style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.12)' }}
    >
      <div className="py-36px px-40px">
        <Link to="/order-dispatch">
          <img
            src={imageLogoSymbolTextHorizontal}
            alt="sweep icon"
            className="h-30px w-auto"
          />
        </Link>
      </div>
      <nav className="px-20px gap-24px flex w-full flex-col">
        {tabGroups.map((group) => (
          <div key={group.title}>
            <div className="h-32px px-20px flex items-center">
              <p className="text-semibold-xs text-gray-400">{group.title}</p>
            </div>
            {group.tabs.map(({ icon, title, href, beta }) => (
              <SidebarItem
                key={href}
                href={href}
                leftAccessory={icon}
                beta={beta}
              >
                {title}
              </SidebarItem>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default observer(Sidebar);
