import { useEffect, useRef, useState } from 'react';
import { IconHeaderLogotype } from '@sweep/asset/icons';
import {
  imageAliBack,
  imageDeco1,
  imageDeco3,
  imageFullLogoAliExpress,
  imageLogos,
} from 'src/images';
import videoAli from '../../assets/videos/video-ali.mp4';
import Chat from '../landing-page/components/Chat';
import Footer from '../landing-page/components/Footer';
import Header from '../landing-page/components/Header';
import Interviews from './components/Interviews';
import Card from './PCcomponents/Card';
import Details from './PCcomponents/Details';
import PreRegistrationForm from './PCcomponents/PreRegistrationForm';

function PCAliExpressScreen() {
  const [hasEnoughHeight, setHasEnoughHeight] = useState<boolean>(
    window.innerHeight >= 859
  );

  useEffect(() => {
    const handleResize = () => {
      setHasEnoughHeight(window.innerHeight >= 859);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current == null) {
          return;
        }
        if (entry.isIntersecting) {
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        }
      },
      { threshold: 0.5 }
    );
    if (videoRef.current != null) {
      observer.observe(videoRef.current);
    }
    return () => observer.disconnect();
  }, []);

  const formRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (formRef.current == null) {
      return;
    }

    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="relative w-full min-w-[1200px] text-center text-[#03183A]">
      <Header onOpen={handleScroll} />
      <Chat from="ali" />
      <div className="relative flex h-[1000px] w-full flex-col items-center justify-center overflow-hidden">
        <img
          src={imageAliBack}
          alt="ali-back"
          className="absolute top-0 z-0 h-auto w-full object-cover"
        />
        <div className="absolute top-[377px] z-10 flex h-[404px] w-[1292px] justify-between">
          <div className="flex h-full items-start">
            <img src={imageDeco1} alt="deco1" className="h-[220px] w-[304px]" />
          </div>
          <div className="flex h-full items-end">
            <img src={imageDeco3} alt="deco2" className="h-[315px] w-[272px]" />
          </div>
        </div>
        <div className="absolute top-[269px] z-10 flex flex-col items-center justify-center gap-[80px]">
          <div className="flex flex-col items-center justify-center gap-[8px] text-center text-[58px] font-bold tracking-tight text-[#03183A]">
            <div className="flex items-center gap-[16px]">
              <img
                src={imageFullLogoAliExpress}
                alt="ali-express"
                className="h-[56px] translate-y-[4px]"
              />
              <span>주문 처리,</span>
            </div>
            <div className="flex items-center">
              <IconHeaderLogotype className="h-[56px] w-auto text-blue-500" />
              <span>으로 한 번에 끝내기</span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <p className="text-[24px] font-bold tracking-[-0.02em] text-gray-700">
              주소 교정부터 수수료 반영까지
            </p>
            <button
              className="py-20px px-28px rounded-[12px] bg-gradient-to-r from-[#FF7458] to-[#FB370B] text-[24px] font-bold tracking-[-0.02em] text-white"
              onClick={handleScroll}
            >
              무료로 시작하기
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[160px]">
          <div className="flex flex-col items-center justify-center gap-[40px]">
            <div className="text-center text-[48px] font-extrabold leading-[64px] tracking-tight text-[#03183A]">
              100곳 이상의 고객사와 함께
              <br />
              <div className="flex items-center justify-center gap-[12px]">
                <img
                  src={imageFullLogoAliExpress}
                  alt="ali-express"
                  className="h-[48px] translate-y-[4px]"
                />
                <span>주문을 3분 만에 처리하세요.</span>
              </div>
              <img src={imageLogos} alt="logos" className="h-auto w-[984px]" />
            </div>
          </div>
          <Interviews size="lg" />
        </div>
      </div>
      <Details hasEnoughHeight={hasEnoughHeight} />
      <div className="flex h-[800px] w-full items-center justify-center">
        <div className="flex w-[1184px] gap-[16px]">
          <Card number={1} />
          <Card number={2} />
          <Card number={3} />
        </div>
      </div>
      <div className="flex h-[800px] w-full items-center justify-center">
        <video
          ref={videoRef}
          className="h-auto w-[1000px] rounded-[16px] shadow-[0px_4px_44px_0px_rgba(0,0,0,0.08)]"
          loop
          muted
          playsInline
        >
          <source src={videoAli} />
        </video>
      </div>
      <PreRegistrationForm hasEnoughHeight={hasEnoughHeight} ref={formRef} />
      <Footer />
    </div>
  );
}

export default PCAliExpressScreen;
