import { Link } from 'react-router-dom';
import { IconControlDownloadLarge } from '@sweep/asset/icons';
import { ShoppingMall } from '@sweep/contract';
import { BrandLogoByName, IconButton } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

interface SettlementSupplierItemProps {
  shoppingMall: ShoppingMall;
  price: number;
  onDownload: () => void;
}

function SettlementSupplierItem({
  shoppingMall,
  price,
  onDownload,
}: SettlementSupplierItemProps) {
  const onDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onDownload();
  };

  return (
    <Link
      to={`/settlement/sales-channel/shopping-mall/${shoppingMall._id}`}
      className={link()}
    >
      <div className="gap-16px flex min-w-0 items-center">
        <BrandLogoByName name={shoppingMall.name} />
        <div className="gap-4px flex min-w-0 flex-col justify-center">
          <p className="text-bold-xl truncate text-gray-700">
            {shoppingMall.name}
          </p>
          <div className="gap-8px flex items-center">
            <p className={priceClass({ active: price !== 0 })}>
              {price.toLocaleString()}원
            </p>
            <p className="text-medium-xs text-gray-500">판매금액</p>
          </div>
        </div>
      </div>
      {price !== 0 && (
        <IconButton
          color="lightGray"
          size="large"
          variant="line"
          className="bg-white"
          onClick={onDownloadClick}
        >
          <IconControlDownloadLarge />
        </IconButton>
      )}
    </Link>
  );
}

const link = cva([
  'px-24px py-20px flex items-center justify-between',
  'rounded-12px border border-gray-200 hover:bg-gray-100',
]);

const priceClass = cva('text-semibold-l', {
  variants: {
    active: {
      true: 'text-blue-600',
      false: 'text-gray-500',
    },
  },
});

export default SettlementSupplierItem;
