import { z } from 'zod';
import { BillingPlanTypeSchema } from '../billing/Billing';

export const UserDeploySchema = z.object({
  isAdvancedPurchaseOrderFileEnabled: z.boolean().optional(),
  cs: z.boolean().optional(),
  isCoupangDispatchEnabled: z.boolean().optional(),
  normalizeAddress: z.boolean().optional(),
});

export type UserDeploy = z.infer<typeof UserDeploySchema>;

export const USER_COMPANY_TYPE = {
  RETAILER: 'retailer',
  SUPPLIER: 'supplier',
  RETAILER_GUEST: 'retailer_guest',
} as const;

export type UserCompanyType =
  (typeof USER_COMPANY_TYPE)[keyof typeof USER_COMPANY_TYPE];

export const UserSchema = z.object({
  _id: z.string(),
  email: z.string(),
  password: z.string(),
  name: z.string(),
  companyType: z.enum([
    USER_COMPANY_TYPE.RETAILER,
    USER_COMPANY_TYPE.SUPPLIER,
    USER_COMPANY_TYPE.RETAILER_GUEST,
  ]),

  dispatchPlugins: z.array(z.string()).optional(),
  mergeDispatchPlugins: z.array(z.string()).optional(),
  plugins: z.array(z.string()).optional(),

  price: z.number().nullish(),
  planType: BillingPlanTypeSchema.nullish(),
  trialEndsAt: z.number().nullish(),

  deploy: UserDeploySchema.optional(),
  admin: z.boolean().optional(),
});

export type User = z.infer<typeof UserSchema>;

export const userUpdateDTOSchema = UserSchema.omit({ _id: true }).partial();

export type UpdateUserDTO = z.infer<typeof userUpdateDTOSchema>;
