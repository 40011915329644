import { z } from 'zod';

export const PARTNERSHIP_TYPE = {
  SUPPLY: 'supply',
  FULFILLMENT: 'fulfillment',
} as const;

export type PartnershipType =
  (typeof PARTNERSHIP_TYPE)[keyof typeof PARTNERSHIP_TYPE];

export const PartnershipSchema = z.object({
  _id: z.string(),
  partnershipUUID: z.string(),
  partnershipType: z.enum([
    PARTNERSHIP_TYPE.SUPPLY,
    PARTNERSHIP_TYPE.FULFILLMENT,
  ]),

  supplierUserId: z.string(),
  supplier: z.object({
    name: z.string(),
    email: z.string(),
    partnerId: z.string(),
  }),

  retailerUserId: z.string(),
  retailer: z.object({
    name: z.string(),
    email: z.string(),
    supplierId: z.string().optional(),
  }),

  createdAt: z.string(),
  updatedAt: z.string(),
});

export type Partnership = z.infer<typeof PartnershipSchema>;
