import { isDeepArrayEqual } from '@sweep/utils';
import { ExcelFile } from 'src/services/file/interface';
import { KAKAO_HEADERS } from './constants';
import { Result } from './interface';

export function parseExcel(file: ExcelFile): Result<Record<string, string>[]> {
  if (file.data == null || file.data.length === 0) {
    return {
      success: false,
      error: '엑셀 파일에 내용이 없습니다.',
    };
  }

  const headers = file.data[0];

  if (!isDeepArrayEqual(headers, KAKAO_HEADERS)) {
    return {
      success: false,
      error:
        '엑셀 파일의 형식이 올바르지 않습니다. 카카오 주문 엑셀 형식을 확인해주세요.',
    };
  }

  const records = file.data.slice(1).map((row) => {
    const record: Record<string, string> = {};
    headers.forEach((header, index) => {
      record[header] = row[index]?.toString() ?? '';
    });
    return record;
  });

  return {
    success: true,
    data: records,
  };
}
