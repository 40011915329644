import { useState } from 'react';
import { addMonths, DateRange, startOfMonth, subMonths } from '@sweep/utils';

export function useMonth(range: DateRange) {
  const { to } = range;
  const [month, setMonth] = useState(startOfMonth(to));

  const rightMonth = month;
  const leftMonth = subMonths(month, 1);

  const onRightMonthChange = (date: Date) => {
    setMonth(date);
  };
  const onLeftMonthChange = (date: Date) => {
    setMonth(addMonths(date, 1));
  };

  return {
    leftMonth,
    rightMonth,
    onLeftMonthChange,
    onRightMonthChange,
  };
}
