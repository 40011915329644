import { IconArrowRightLarge, IconCheckMedium } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { CheckedAddressResult } from 'src/network/fastapi';

interface CompleteResultSectionProps {
  addresses: string[];
  checkedAddressResults: CheckedAddressResult[];
}

function CompleteResultSection({
  addresses,
  checkedAddressResults,
}: CompleteResultSectionProps) {
  return (
    <div className="w-1200px gap-28px px-52px py-32px rounded-8px shadow-line flex flex-col justify-center bg-white">
      <div className="gap-8px flex items-center">
        <IconCheckMedium className="text-gray-500" />
        <p className="text-semibold-l text-gray-600">
          주소 열 내용이 이렇게 바뀌었어요.
        </p>
      </div>
      <div className="min-h-200px max-h-[50vh] w-full overflow-y-auto">
        <table className={table()}>
          <thead className={header()}>
            <tr className={headerLine()}>
              <td className={headerLineCell()}></td>
              <td className={headerLineCell()}></td>
              <td className={headerLineCell()}></td>
            </tr>
            <tr>
              <td className={headerCell({ position: 'left' })}>이전 주소</td>
              <td className={headerCell({ position: 'center' })}>
                <div className="flex-center flex">
                  <IconArrowRightLarge className="h-36px text-gray-400" />
                </div>
              </td>
              <td className={headerCell({ position: 'right' })}>변환된 주소</td>
            </tr>
            <tr className={headerLine()}>
              <td className={headerLineCell()}></td>
              <td className={headerLineCell()}></td>
              <td className={headerLineCell()}></td>
            </tr>
          </thead>
          <tbody>
            {checkedAddressResults.map((checkedAddressResult, index) => {
              const originalAddress = addresses[index];
              const validatedAddress = checkedAddressResult.address;
              const isAddressChanged =
                originalAddress.trim() !== validatedAddress.trim();
              return (
                <tr key={index}>
                  <td className={cell({ color: 'gray', borderRight: true })}>
                    {originalAddress}
                  </td>
                  <td className={cell({ borderRight: true })}></td>
                  <td
                    className={cell({
                      color: isAddressChanged ? 'blue' : 'black',
                    })}
                  >
                    {validatedAddress}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const table = cva('w-full border-x border-gray-200');

const header = cva('sticky top-0 z-10');

const headerLine = cva('h-1px bg-gray-200');
const headerLineCell = cva('p-0');

const headerCell = cva('border-gray-200 bg-gray-100', {
  variants: {
    position: {
      left: 'px-16px text-medium-s w-[47%] border-x py-[8.5px] text-gray-700',
      center: 'w-[6%]',
      right:
        'px-16px text-semibold-s w-[47%] border-x py-[8.5px] text-gray-700',
    },
  },
});

const cell = cva(
  ['text-medium-s border-b border-gray-200', 'px-16px py-[8.5px]'],
  {
    variants: {
      color: {
        gray: 'text-gray-700 opacity-60',
        black: 'text-gray-700',
        blue: 'text-blue-700',
      },
      borderRight: {
        true: 'border-r',
        false: '',
      },
    },
  }
);

export default CompleteResultSection;
