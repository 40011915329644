import { ShoppingMall } from '@sweep/contract';
import { BrandLogoByName } from '@sweep/sds';
import { BackLink } from '../components/BackLink';

interface ShoppingMallNotSupportScreenProps {
  shoppingMall: ShoppingMall;
}

function ShoppingMallNotSupportScreen({
  shoppingMall,
}: ShoppingMallNotSupportScreenProps) {
  return (
    <div className="px-40px py-20px gap-32px flex min-h-full flex-col bg-gray-100">
      <div className="gap-20px flex items-center">
        <BackLink to="/settlement/sales-channel" />
        <BrandLogoByName name={shoppingMall.name} />
        <p className="text-extrabold-l text-gray-700">{shoppingMall.name}</p>
      </div>
      <div className="gap-24px p-20px rounded-8px shadow-line flex flex-col bg-white">
        <div className="flex-center h-200px flex">
          <p className="text-medium-s text-center text-gray-500">
            해당 쇼핑몰은 현재 정산 기능을 지원하지 않습니다.
            <br />
            <br />
            정산 기능이 필요하신 경우, 언제든 매니저에게 연락해 주세요.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShoppingMallNotSupportScreen;
