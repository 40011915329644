import { useState } from 'react';
import { normalizeAddresses } from 'src/network/address';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';

const SAMPLE_ADDRESSES = [
  '대한민국、서울특별시、관악구、Nambusunhwan-ro、1838, CS tower 1201-ho',
  '대한민국、대전광역시、서구、갈마중로37번길 29 하늘빌라 999호',
  'Korea、경상북도、청송군、청송읍 금월로 209 유앤드림플러스 100동 9999호',
  'Korea、Gyeonggi-do 권선구 권중로136 신동아 대원아파트 504-306',
  'Korea、Gyeonggi-do、고양시 일산서구 일청로 45 현대3차홈타운 100동 9999호',
  'Korea、Gyeongsangbuk-do、북구 두호동 용두산길 74 동아금강APT 100동 999호',
  '인천광역시 미추홀구 405bungil inharo 11-28 201',
  'Seoul Jungnang-gu 동일로95나길 25-5 청운아트빌 999호',
  'Gyeonggi-do Hwaseong-si 동탄지성로 256 SK VIEW PARK2 100동9999호',
  'Chungcheongnam-do Dongnam-gu Cheonan-si 용곡동583 세광2차100동9999호',
  '월계로 59 203동 505호, Gwangsan-gu, Gwangju, Korea',
  'Busan Suyeong-gu 광서로16번길 42 오페라하우스 999호',
  'Chungcheongnam-do Seobuk-gu Cheonan-si 모시1길33-1 100동 999호',
  'Jeollanam-do Suncheon-si 지봉로 485 금당대주아파트 100동 9999호',
  'Gyeongsangbuk-do Buk-gu Pohang-si 장량로140번길 24-15 제니스b동 999호',
];

export function useConvertAddress() {
  const [value, setValue] = useState('');
  const [convertedValue, setConvertedValue] = useState('');

  const generate = () => {
    amplitude.track('Click fill-example-address');
    const sampleWithoutCurrent = SAMPLE_ADDRESSES.filter(
      (sample) => sample !== value
    );
    const randomIndex = Math.floor(Math.random() * sampleWithoutCurrent.length);
    setValue(sampleWithoutCurrent[randomIndex]);
    setConvertedValue('');
  };

  const convert = async () => {
    amplitude.track('Click convert-single-address');
    const response = await normalizeAddresses([{ address: value }]);
    if (response.status !== 200) {
      toast.error('주소 검증에 실패했습니다. 다시 시도해주세요.');
      return;
    }

    const checkedAddress = response.body[0];
    setConvertedValue(checkedAddress.data);
  };

  const copy = () => {
    amplitude.track('Click copy-address');
    navigator.clipboard.writeText(convertedValue);
    toast.success('주소가 복사되었습니다.');
  };

  return {
    value,
    onChange: setValue,
    convertedValue,
    generate,
    convert,
    copy,
  };
}
