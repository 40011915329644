import { z } from 'zod';

export const SpreadsheetFileUploadBody = z.object({
  file: z.custom<File>(),
  password: z.string().optional(),
});

export type SpreadsheetFileUploadBody = z.infer<
  typeof SpreadsheetFileUploadBody
>;

export const SpreadsheetFileUploadResponse = z.object({
  success: z.boolean(),
  data: z.object({
    fileUUID: z.string(),
    parsedFileUUID: z.string(),
  }),
});

export type SpreadsheetFileUploadResponse = z.infer<
  typeof SpreadsheetFileUploadResponse
>;
