import { c } from '../../internal/contract';
import {
  PartnershipFindAllQuerySchema,
  PartnershipFindAllResponseSchema,
  PartnershipFindByIdPathParamsSchema,
  PartnershipFindByIdResponseSchema,
  PartnershipRegisterRetailerBodySchema,
  PartnershipRegisterRetailerResponseSchema,
} from '../../type/partnership/PartnershipDTO';
import { CommonErrorResponseSchema } from '../commonErrorResponse';

export const PartnershipRouterContract = c.router({
  registerRetailer: {
    method: 'POST',
    path: '/partnership/register/retailer',
    body: PartnershipRegisterRetailerBodySchema,
    responses: {
      200: PartnershipRegisterRetailerResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  findAll: {
    method: 'GET',
    path: '/partnership/',
    query: PartnershipFindAllQuerySchema,
    responses: {
      200: PartnershipFindAllResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  findById: {
    method: 'GET',
    path: '/partnership/:partnershipId',
    pathParams: PartnershipFindByIdPathParamsSchema,
    responses: {
      200: PartnershipFindByIdResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
});
