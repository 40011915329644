import { readSheetNames } from 'read-excel-file';
import { readExcelMigration } from 'src/services/file/excel/readExcel-migration';
import { translateHeaderRowInEnglish } from './headerColumnMapping';

function _fillEmptyWithAbove(rows: any[]) {
  const specificExcelheader = [
    '발주일',
    '상품명',
    '수량',
    '수취인',
    '휴대전화',
    '주소',
    '배송 메모',
  ];

  const headerRow = rows[0];

  const headersCorrectOrder =
    headerRow.length === specificExcelheader.length &&
    specificExcelheader.every((header, index) => headerRow[index] === header);

  if (!headersCorrectOrder) {
    return;
  }

  const headersToCheck = ['발주일', '수취인', '휴대전화', '주소', '배송 메모'];

  const headerIndicesToCheck = headersToCheck.map((header) =>
    specificExcelheader.indexOf(header)
  );

  for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    const isEmptyRow = headerIndicesToCheck.every((index) => !row[index]);
    if (isEmptyRow) {
      const aboveIndex = rowIndex - 1;
      const aboveRow = rows[aboveIndex];

      const hasAboveRowValues = headerIndicesToCheck.every(
        (index) => aboveRow[index]
      );
      if (hasAboveRowValues) {
        _copyValuesFromAbove(rows, rowIndex, headerIndicesToCheck);
      }
    }
  }

  return rows;
}

function _copyValuesFromAbove(
  rows: any[],
  rowIndex: number,
  headerIndices: number[]
) {
  const aboveRow = rows[rowIndex - 1];
  headerIndices.forEach((index) => {
    if (!rows[rowIndex][index] && aboveRow[index]) {
      rows[rowIndex][index] = aboveRow[index];
    }
  });
}

//xlsx 파일을 읽어오는 함수
const readFile = async (
  file: File,
  settings: ReadExcelFileSettings = {
    isHeaderIndexNeeded: false,
    isOrderFile: true,
  }
): Promise<
  | {
      rows: any[];
      headerRowIndex?: number;
    }
  | any[]
> => {
  const { isHeaderIndexNeeded = false, isOrderFile = true } = settings;

  return new Promise(async (resolve, reject) => {
    const excelFile = await readExcelMigration(file);
    if (excelFile == null) {
      reject(new Error('failed to read excel file'));
      return;
    }

    const rows = excelFile.data;

    try {
      const headersToFind = ['name', 'address'];
      // 헤더 행 찾기
      let headerRowIndex = isOrderFile
        ? rows.findIndex((row: any) => {
            return headersToFind.every((header) => {
              return translateHeaderRowInEnglish(row).includes(header);
            });
          })
        : 0;

      if (headerRowIndex === -1) {
        const optionalHeaders = [
          'productName',
          'option',
          'quantity',
          'name',
          'contactNumber',
          'postCode',
          'address',
          'deliveryMessage',
          'shippingCompany',
          'shippingNumber',
          'note',
          'uniqueCode',
          'originFile',
          'orderNumber',
          'productCode',
          'platformCode',
          'price',
          'platformName',
        ];
        //rows 중 조건을 만족하는 것의 갯수 세기
        headerRowIndex = rows.findIndex((row: any) => {
          return (
            optionalHeaders.filter((header) =>
              translateHeaderRowInEnglish(row).includes(header)
            ).length > 1
          );
        });
      }

      if (headerRowIndex !== -1) {
        const newRows = rows.slice(headerRowIndex);
        _fillEmptyWithAbove(newRows);
        if (isHeaderIndexNeeded) {
          resolve({ rows: newRows, headerRowIndex });
        }
        resolve(newRows);
      } else {
        reject(new Error('Header row not found.'));
      }
    } catch (e) {
      reject(e);
    }
  });
};

interface ReadExcelFileSettings {
  isHeaderIndexNeeded?: boolean;
  isOrderFile?: boolean;
}

export const readExcelFile = async (
  file: File,
  settings: ReadExcelFileSettings = {
    isHeaderIndexNeeded: false,
    isOrderFile: true,
  }
) => {
  const fileName = file?.name.normalize('NFC') || '';
  const ext = (fileName.split('.').pop() as string).toLowerCase();

  const { isHeaderIndexNeeded = false, isOrderFile = true } = settings;

  let rows: any;

  if (ext === 'xlsx') {
    rows = await readFile(file, settings);
    //Date 타입의 데이터를 string으로 변환 년.월.일 형식으로 변환

    if (isHeaderIndexNeeded) {
      rows.rows = rows.rows.map((row: any[]) => {
        return row.map((cell) => {
          if (cell instanceof Date) {
            return cell.toLocaleDateString('ko-KR');
          }
          return cell;
        });
      });
    } else {
      rows = rows.map((row: any[]) => {
        return row.map((cell) => {
          if (cell instanceof Date) {
            return cell.toLocaleDateString('ko-KR');
          }
          return cell;
        });
      });
    }
  } else {
    const excelFile = await readExcelMigration(file);
    if (excelFile == null) {
      return [];
    }

    rows = excelFile.data;
    const headersToFind = ['name', 'address'];

    // 헤더 행 찾기
    let headerRowIndex = rows.findIndex((row: any[]) =>
      headersToFind.every((header) => {
        return translateHeaderRowInEnglish(row).includes(header);
      })
    );

    if (headerRowIndex !== -1) {
      rows = rows.slice(headerRowIndex);

      _fillEmptyWithAbove(rows);
    } else {
      const optionalHeaders = [
        'productName',
        'option',
        'quantity',
        'name',
        'contactNumber',
        'postCode',
        'address',
        'deliveryMessage',
        'shippingCompany',
        'shippingNumber',
        'note',
        'uniqueCode',
        'originFile',
        'orderNumber',
        'productCode',
        'platformCode',
        'price',
        'platformName',
      ];
      //rows중 조건을 만족하는 것의 갯수 세기
      headerRowIndex = rows.findIndex((row: any[]) => {
        return (
          optionalHeaders.filter((header) =>
            translateHeaderRowInEnglish(row).includes(header)
          ).length > 1
        );
      });
    }
    if (isHeaderIndexNeeded) {
      return { rows, headerRowIndex };
    }
  }

  return rows;
};

export async function isFilePasswordProtected(file: File) {
  try {
    // Excel 파일을 읽어옵니다. 비밀번호로 보호된 파일은 읽을 수 없습니다.
    await readSheetNames(file);
    // 비밀번호 보호가 걸리지 않았으므로 false 반환
    return false;
  } catch (error) {
    console.warn('file password protected :', error);
    // 비밀번호 보호로 인해 오류가 발생하면 true 반환
    return true;
  }
}
