import { ServerInferResponses } from '@ts-rest/core';
import { z } from 'zod';
import { c } from '../internal/contract';
import { UserDeploySchema, UserSchema } from '../type/user/User';

export const adminRouterContract = c.router({
  getUsers: {
    method: 'GET',
    path: '/admin-new/user/users',
    responses: {
      200: z.array(UserSchema),
    },
  },

  duplicateUser: {
    method: 'POST',
    path: '/admin-new/duplicate',
    body: z.object({
      userId: z.string(),
      testUserId: z.string(),
      options: z
        .object({
          duplicateShoppingMall: z.boolean().optional(),
          duplicateCompositionMatching: z.boolean().optional(),
          duplicateOptionCodeMatching: z.boolean().optional(),
          duplicateSpreadsheetHeaderMapping: z.boolean().optional(),
        })
        .optional(),
      deploy: UserDeploySchema.optional(),
    }),
    responses: {
      200: z.object({
        success: z.boolean(),
      }),
    },
  },
});

export type GetUsersResponses = ServerInferResponses<
  typeof adminRouterContract.getUsers
>;

export type DuplicateUserBody = z.infer<
  typeof adminRouterContract.duplicateUser.body
>;
