import { useId } from 'react';
import { SearchInput } from '@sweep/sds';

interface TextInputWithLabelProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onEnterKeyDown?: () => void;
}

function TextInputWithLabel({
  label,
  value,
  onChange,
  placeholder,
  onEnterKeyDown,
}: TextInputWithLabelProps) {
  const id = useId();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onEnterKeyDown?.();
    }
  };

  return (
    <div className="gap-16px flex items-center">
      <label
        htmlFor={id}
        className="w-50px text-medium-s whitespace-nowrap text-gray-500"
      >
        {label}
      </label>
      <SearchInput
        className="w-400px"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

export default TextInputWithLabel;
