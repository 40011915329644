import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import {
  IconArrowRight,
  IconControlDownload,
  IconTimeSmall,
} from '@sweep/asset/icons';
import {
  Button,
  MultiSelect,
  SearchInput,
  SelectableTable,
  TableSelectControl,
} from '@sweep/sds';
import { flatten, formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import useShippingUpload from 'src/hooks/useShippingUpload';
import { createOrderExcel } from 'src/services/file/excel/create';
import { amplitude } from 'src/third-parties/amplitude';
import OrderDispatchControl from './components/OrderDispatchControl';
import OrderStatusRadioGroup from './components/OrderStatusRadioGroup/OrderStatusRadioGroup';
import {
  DISPATCHED_ORDER_COLUMN_MAPPING,
  DISPATCHED_ORDER_HEADER,
  DISPATCHED_ORDER_HEADER_KEYS,
} from './constants';
import { useDispatchTableColumns } from './hooks/useDispatchTableColumns';
import { OrderStatusType } from './interface';
import { OrderDispatchScreenStore } from './store/OrderDispatchScreenStore';

function OrderDispatchScreen() {
  const oms = useOMSStore();
  const { mallUploadShippingInfo } = useShippingUpload();
  const store = oms.getStore(OrderDispatchScreenStore);

  useEffect(() => {
    store.setOrderStatus('PaymentComplete');
    store.setSelectedOrders([]);
    store.setShoppingMallFilters([]);
  }, [store]);

  const dispatchedOrders = oms.order.dispatch.dispatchedOrders;
  const latestDispatchedAt = oms.order.dispatch.latestDispatchedAt;
  const shoppingMalls = oms.shoppingMall.shoppingMallNames;
  const shoppingMallDispatches = store.shoppingMallDispatches;

  const [search, setSearch, filterSearch] = useSearchState({
    keys: DISPATCHED_ORDER_HEADER_KEYS,
  });
  const searchFilteredOrders = useMemo(
    () => filterSearch(store.shoppingMallFilteredOrders),
    [filterSearch, store.shoppingMallFilteredOrders]
  );

  const { columns, filteredOrders, tableFilterStore } = useDispatchTableColumns(
    store,
    searchFilteredOrders
  );
  const flattenFilteredOrders = flatten(filteredOrders);

  const handleOrderStatusChange = (status: OrderStatusType) => {
    store.setOrderStatus(status);
    tableFilterStore.reset();
  };

  const handleShippingFilesChange = async (files: FileList) => {
    amplitude.track('Upload Shipping-info', {
      From: 'order-dispatch',
    });

    oms.loading.batch(async () => {
      // NOTE(@이지원): 운송장 업로드 리팩토링 후, try-catch 제거
      try {
        await mallUploadShippingInfo(Array.from(files));
      } catch {
        alert('운송장 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
      } finally {
        store.setSelectedOrders([]);
      }
    });
  };

  const downloadOrdersToExcel = async () => {
    if (
      store.selectedOrders.length === 0 &&
      flattenFilteredOrders.length === 0
    ) {
      alert('주문을 불러온 후 다시 시도해주세요.');
      return;
    }

    const downloadedOrders =
      store.selectedOrders.length === 0
        ? flattenFilteredOrders
        : store.selectedOrders;

    oms.loading.batch(async () => {
      await createOrderExcel(
        oms,
        downloadedOrders,
        `${formatDate(new Date(), 'yy.MM.dd')} 주문수집`,
        DISPATCHED_ORDER_HEADER,
        DISPATCHED_ORDER_COLUMN_MAPPING
      );
    });
  };

  return (
    <div className="flex h-full flex-col gap-[32px] bg-gray-100 px-[40px] py-[20px]">
      <div className="h-42px flex items-center">
        <h1 className="text-extrabold-l text-gray-700">주문 수집하기</h1>
      </div>
      <div className="flex flex-col gap-[20px] rounded-[8px] bg-white p-[20px] shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="flex items-center gap-[20px]">
          <p className="text-medium-s text-gray-500">쇼핑몰</p>
          <button
            className="flex h-[40px] min-w-0 max-w-[400px] flex-1 items-center rounded-[8px] border border-gray-300 px-[16px] hover:bg-gray-100"
            onClick={store.openDispatchSelectModal}
          >
            <p className="text-ellipsis-nowrap text-medium-s min-w-0 flex-1 text-left text-gray-700">
              {shoppingMallDispatches.length === shoppingMalls.length
                ? '전체'
                : shoppingMallDispatches.join(', ')}
            </p>
            <IconArrowRight className="text-gray-700" />
          </button>
          <Button onClick={store.dispatchSelectedShoppingMalls}>
            주문 불러오기
          </Button>
          {latestDispatchedAt != null && (
            <div className="flex items-center gap-1">
              <IconTimeSmall className="text-gray-500" />
              <p className="text-regular-s text-gray-500">
                마지막 수집:{' '}
                {formatDate(new Date(latestDispatchedAt), 'yyyy. M. d. a h:mm')}
              </p>
            </div>
          )}
        </div>
        <OrderStatusRadioGroup
          orders={dispatchedOrders}
          value={store.selectedOrderStatusType}
          onChange={handleOrderStatusChange}
        />
      </div>
      <div className="flex flex-col gap-[16px] rounded-[8px] bg-white p-[20px] shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="flex items-center gap-[20px]">
          <p className="text-medium-s whitespace-nowrap text-gray-500">필터</p>
          <SearchInput
            value={search}
            onChange={(value) => setSearch(value)}
            placeholder="검색어를 입력하세요."
            className="max-w-[500px]"
          />
          <MultiSelect
            placeholder="쇼핑몰 필터"
            value={store.shoppingMallFilters}
            onChange={store.setShoppingMallFilters}
            className="w-220px"
            status={
              store.shoppingMallFilters.length === 0 ? 'default' : 'active'
            }
          >
            <MultiSelect.MultiOption value={shoppingMallDispatches}>
              전체
            </MultiSelect.MultiOption>
            {shoppingMallDispatches.map((mall) => (
              <MultiSelect.Option key={mall} value={mall}>
                <p className="text-ellipsis-nowrap">{mall}</p>
              </MultiSelect.Option>
            ))}
          </MultiSelect>
        </div>
        <hr />
        <div className="flex flex-col gap-[12px]">
          <div>
            <div className="flex items-center gap-[4px]">
              <p className="text-medium-s text-gray-500">목록</p>
              <p className="text-medium-s text-gray-500">
                (
                <span className="text-blue-500">
                  {flattenFilteredOrders.length}
                </span>
                건)
              </p>
            </div>
            <div className="flex h-[40px] items-end justify-between">
              <TableSelectControl
                count={store.selectedOrders.length}
                onClear={() => store.setSelectedOrders([])}
              />
              <div className="flex gap-[10px]">
                <Button
                  color="gray"
                  rightAccessory={<IconControlDownload />}
                  onClick={downloadOrdersToExcel}
                >
                  엑셀 파일
                </Button>
                <OrderDispatchControl
                  store={store}
                  onShippingFilesChange={handleShippingFilesChange}
                />
              </div>
            </div>
          </div>
          <If is={dispatchedOrders.length > 0}>
            <SelectableTable
              key={[
                store.selectedOrderStatusType,
                ...store.shoppingMallDispatches,
              ].join(',')}
              items={filteredOrders}
              columns={columns}
              selectedItems={store.selectedOrders}
              onSelect={store.setSelectedOrders}
              getKey={(order) => order.uniqueCode}
              className="max-h-[calc(100vh-300px)]"
            />
          </If>
        </div>
      </div>
    </div>
  );
}

export default observer(OrderDispatchScreen);
