import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

interface RemoveHyphenPluginConfig {
  keys: string[];
}

export class RemoveHyphenPlugin extends AbstractPlugin<RemoveHyphenPluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order): Order => {
    this.config.keys.forEach((key) => {
      order[key] = order[key]?.toString().replace(/-/g, '');
    });

    return order;
  };
}
