import { useRef } from 'react';
import { cva } from '@sweep/tailwind';
import { TableContextProvider } from '../context/TableContext';
import { useColumnWidth } from '../hooks/useColumnWidth';
import { ColumnDef } from '../interface';
import DefaultTableCell from './DefaultTableCell';
import DefaultTableFooterCell from './DefaultTableFooterCell';
import DefaultTableHeaderCell from './DefaultTableHeaderCell';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHeader } from './TableHeader';

export interface TableProps<T> {
  items: T[];
  columns: ColumnDef<T>[];
  getKey: (item: T) => string;
  className?: string;
}

export function Table<T>({ items, columns, getKey, className }: TableProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const context = useColumnWidth();

  const isFooterVisible = columns.some((column) => column.footer != null);

  return (
    <TableContextProvider value={context}>
      <div ref={containerRef} className={tableContainer({ className })}>
        <div className={table()}>
          <TableHeader columns={columns} />
          <TableBody
            items={items}
            columns={columns}
            getKey={getKey}
            containerRef={containerRef}
          />
          {isFooterVisible && <TableFooter columns={columns} />}
        </div>
      </div>
    </TableContextProvider>
  );
}

const tableContainer = cva('relative overflow-auto border border-gray-200');

const table = cva('relative w-max min-w-full');

Table.HeaderCell = DefaultTableHeaderCell;
Table.Cell = DefaultTableCell;
Table.Footer = DefaultTableFooterCell;
