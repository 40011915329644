import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openUploadShippingInvoiceConfirmDialog(props: {
  shippingInvoiceCount: number;
}) {
  const { shippingInvoiceCount } = props;

  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, unmount }) => (
      <Dialog open={isOpen} onClose={unmount}>
        <Dialog.Title className="mb-16px">
          <span className="text-blue-500">{shippingInvoiceCount}건</span>의
          주문에 운송장 정보를 등록할까요?
        </Dialog.Title>

        <Dialog.Description className="mb-28px">
          쇼핑몰에 운송장이 등록되고, 판매처 링크에 주문서 파일이 업로드됩니다.
        </Dialog.Description>

        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
