import { DateRange, isSameDay, max, min } from '@sweep/utils';

export function getRangeType({
  date,
  range,
}: {
  date: Date;
  range: DateRange;
}): 'start' | 'end' | 'middle' | 'start-end' | null {
  const minDate = min([range.from, range.to]);
  const maxDate = max([range.from, range.to]);

  const isStart = isSameDay(date, minDate);
  const isEnd = isSameDay(date, maxDate);

  if (isStart && isEnd) {
    return 'start-end';
  }

  if (isStart) {
    return 'start';
  }

  if (isEnd) {
    return 'end';
  }

  if (date > minDate && date < maxDate) {
    return 'middle';
  }

  return null;
}
