import {
  imageLogoMallAli,
  imageLogoMallAlwayz,
  imageLogoMallAuction,
  imageLogoMallCafe24,
  imageLogoMallCashdeal,
  imageLogoMallCoupang,
  imageLogoMallEleven,
  imageLogoMallEmart,
  imageLogoMallGmarket,
  imageLogoMallImweb,
  imageLogoMallKakao,
  imageLogoMallKdeal,
  imageLogoMallLotteOn,
  imageLogoMallMpoint,
  imageLogoMallNaverss,
  imageLogoMallOhouse,
  imageLogoMallShop,
  imageLogoMallTdeal,
  imageLogoMallTmon,
} from '@sweep/asset/images';
import { cva } from '@sweep/tailwind';

export const BRANDS = [
  'shop',
  'gmarket',
  'eleven',
  'tmon',
  'mpoint',
  'ohouse',
  'auction',
  'emart',
  'ali',
  'naver',
  'kakao',
  'coupang',
  'alwayz',
  'cafe24',
  'cashdeal',
  'tdeal',
  'kdeal',
  'lotteon',
  'imweb',
] as const;

export type Brand = (typeof BRANDS)[number];

export interface BrandLogoProps {
  brand: Brand;
  size?: 'md' | 'sm';
  className?: string;
}

export function BrandLogo({
  brand,
  size = 'md',
  className = '',
}: BrandLogoProps) {
  const alt = `${brand} 로고`;
  const image = IMAGE_MAP[brand];

  return (
    <img src={image} alt={alt} className={imageClass({ size, className })} />
  );
}

const imageClass = cva('rounded-full', {
  variants: {
    size: {
      md: 'size-[30px]',
      sm: 'size-[24px]',
    },
  },
});

const IMAGE_MAP: Record<Brand, string> = {
  shop: imageLogoMallShop,
  gmarket: imageLogoMallGmarket,
  eleven: imageLogoMallEleven,
  tmon: imageLogoMallTmon,
  mpoint: imageLogoMallMpoint,
  ohouse: imageLogoMallOhouse,
  auction: imageLogoMallAuction,
  emart: imageLogoMallEmart,
  ali: imageLogoMallAli,
  naver: imageLogoMallNaverss,
  kakao: imageLogoMallKakao,
  coupang: imageLogoMallCoupang,
  alwayz: imageLogoMallAlwayz,
  cafe24: imageLogoMallCafe24,
  cashdeal: imageLogoMallCashdeal,
  tdeal: imageLogoMallTdeal,
  kdeal: imageLogoMallKdeal,
  lotteon: imageLogoMallLotteOn,
  imweb: imageLogoMallImweb,
};
