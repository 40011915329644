import * as amplitude from '@amplitude/analytics-browser';
import { UserCompanyType } from '@sweep/contract';

function getUserFeatureFlags(featureFlags: Record<string, boolean>): string {
  return Object.entries(featureFlags)
    .filter(([_, isEnabled]) => isEnabled)
    .map(([featureFlag]) => featureFlag)
    .join(',');
}

export function initializeAmplitudeUser(user: {
  id: string;
  email: string;
  name: string;
  companyType: UserCompanyType;
  featureFlags?: Record<string, boolean>;
}) {
  const indentiryEvent = new amplitude.Identify()
    .set('Email', user.email)
    .set('Username', user.name)
    .set('Company Type', user.companyType);

  if (user.featureFlags) {
    const userFeatureFlags = getUserFeatureFlags(user.featureFlags);
    indentiryEvent.set('Feature Flags', userFeatureFlags);
  }

  amplitude.identify(indentiryEvent);
  amplitude.setUserId(user.id);
}

export { amplitude };
