import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { readExcelGuest } from 'src/services/file/excel/readExcelGuest';
import { ExcelFile } from 'src/services/file/interface';
import { toast } from 'src/third-parties/toast';
import FileUpload from './components/FileUpload';
import { createExcel } from './services/createExcel';
import { isError, isSuccess } from './services/interface';
import { parseExcel } from './services/parseExcel';
import { splitOrder } from './services/splitOrder/splitOrder';

function SeparateKakaoExcelScreen() {
  const oms = useOMSStore();

  const readExcel = async (file: File): Promise<ExcelFile | null> => {
    const excelFile = await oms.loading.batch(() => readExcelGuest(file));
    if (excelFile == null) {
      toast.error('엑셀 파일을 읽는데 실패했습니다.');
      return null;
    }

    return excelFile;
  };

  const handleExcelData = async (excelFile: ExcelFile) => {
    const result = parseExcel(excelFile);
    if (result.success === false) {
      toast.error(result.error);
      return;
    }

    const orders = result.data;
    const splittedOrdersResults = orders.map((order) => splitOrder(order));

    const errorResult = splittedOrdersResults.find((result) => isError(result));
    if (errorResult != null) {
      toast.error(errorResult.error);
      return;
    }

    const splittedOrders = splittedOrdersResults.flatMap((result) =>
      isSuccess(result) ? result.data : []
    );

    const filename = excelFile.name;
    const excelBuffer = await createExcel(splittedOrders);
    saveAs(new Blob([excelBuffer]), `${filename}_분리.xlsx`);
  };

  const handleFileUpload = async (file: File) => {
    const excelFile = await readExcel(file);
    if (excelFile == null) {
      return;
    }

    handleExcelData(excelFile);
  };

  return (
    <div className="p-40px min-h-screen bg-gray-100">
      <div className="mb-20px gap-12px flex flex-col">
        <h1 className="text-extrabold-l text-gray-700">카카오 엑셀 분리</h1>
        <p className="text-medium-m text-gray-500">
          카카오 엑셀 양식을 업로드해 주세요. 각 옵션별로 행을 분리하고, 비율에
          따라 가격을 나눕니다.
        </p>
      </div>
      <FileUpload onUpload={handleFileUpload} />
    </div>
  );
}

export default observer(SeparateKakaoExcelScreen);
