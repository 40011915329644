import { action, computed, observable, runInAction } from 'mobx';
import { DispatchOrderStore } from 'stores/order/DispatchOrderStore';
import { DispatchedOrder } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { getDispatchOrdersRequestId } from '../services/getDispatchedOrdersRequestId';
import { pollingDispatchedOrders } from '../services/pollingDispatchedOrders';

export type LoadingState = 'loading' | 'success' | 'fail';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const DAY = MINUTE * 60 * 24;
const MONTH = DAY * 30;

export class DispatchModalStore {
  @observable
  accessor currentLoadingShoppingMallNames: string[] | null = null;

  @observable
  accessor loadings: Record<string, LoadingState | undefined> = {};

  @observable
  accessor isDispatchLoading = false;

  @observable
  accessor isTransformLoading = false;

  @computed
  get isSuccessful() {
    return this.shoppingMallNames.every(
      (name) => this.loadings[name] === 'success'
    );
  }

  @computed
  get failedShoppingMallNames() {
    return Object.entries(this.loadings)
      .filter(([_, status]) => status === 'fail')
      .map(([name]) => name);
  }

  @computed
  get succeedShoppingMallNames() {
    return Object.entries(this.loadings)
      .filter(([_, status]) => status === 'success')
      .map(([name]) => name);
  }

  constructor(
    private dispatch: DispatchOrderStore,
    private shoppingMallNames: string[]
  ) {
    this.currentLoadingShoppingMallNames = shoppingMallNames;
  }

  getLoadingState(shoppingMallName: string) {
    return this.loadings[shoppingMallName];
  }

  @action.bound
  async getDispatchedOrders(
    shoppingMallNames: string[],
    signal: AbortSignal
  ): Promise<DispatchedOrder[] | null> {
    this.currentLoadingShoppingMallNames = shoppingMallNames;
    this.isDispatchLoading = true;
    this.dispatch.removeRejectedShoppingMallName(shoppingMallNames);
    this.loadings = {
      ...this.loadings,
      ...Object.fromEntries(shoppingMallNames.map((name) => [name, 'loading'])),
    };

    const now = new Date().getTime();
    const orders = await Promise.all(
      shoppingMallNames.map((name) =>
        this.getDispatchedOrdersByShoppingMallName(name, now, signal)
      )
    );

    if (signal.aborted) {
      return null;
    }

    const dispatchedOrders = orders.filter(isNotNil).flat();

    this.isTransformLoading = true;

    const transformedOrders =
      await this.dispatch.transformOrders(dispatchedOrders);

    runInAction(() => {
      this.isTransformLoading = false;
    });

    if (signal.aborted) {
      return null;
    }

    const updatedAt = new Date().getTime();

    shoppingMallNames.forEach((name) => {
      this.dispatch.updateLastDispatchedAt(name, updatedAt);
    });

    orders.forEach((order, index) => {
      const shoppingMallName = shoppingMallNames[index];
      if (shoppingMallName == null) {
        return null;
      }
      if (order == null) {
        this.dispatch.pushRejectedShoppingMallName(shoppingMallName);
        return null;
      }
      this.dispatch.updateLastDispatchedAt(shoppingMallName, now);
    });

    runInAction(() => {
      this.isDispatchLoading = false;
    });

    if (signal.aborted) {
      this.dispatch.clearLastDispatchedAts();
      return null;
    }

    return transformedOrders;
  }

  @action.bound
  async getDispatchedOrdersByShoppingMallName(
    name: string,
    endTime: number,
    signal: AbortSignal
  ): Promise<DispatchedOrder[] | null> {
    const startTime = endTime - MONTH;

    this._updatePolling(name, 'loading');

    const requestId = await getDispatchOrdersRequestId(
      startTime,
      endTime,
      name
    );

    if (requestId == null) {
      this._updatePolling(name, 'fail');
      return null;
    }

    const orders = await pollingDispatchedOrders(requestId, signal, {
      timeout: MINUTE * 5,
      interval: SECOND * 5,
    });

    if (orders == null) {
      this._updatePolling(name, 'fail');
      return null;
    }

    this._updatePolling(name, 'success');

    return orders;
  }

  @action.bound
  _updatePolling(shoppingMallName: string, status: LoadingState) {
    this.loadings = {
      ...this.loadings,
      [shoppingMallName]: status,
    };
  }
}
