import { useMediaQuery } from 'react-responsive';

export enum Device {
  DESKTOP = 3,
  TABLET = 2,
  MOBILE = 1,
}

export function useDevice() {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 1099px)',
  });

  return isMobile ? Device.MOBILE : isTablet ? Device.TABLET : Device.DESKTOP;
}
