import { Partner } from '@sweep/contract';
import { isNotNil, NULL_STRING, sortBy, sum } from '@sweep/utils';
import { PartnerSettlement, SettlementOrder } from '../interface';

export function getPartnerSettlements(
  partners: Partner[],
  ordersByPartner: Record<string, SettlementOrder[]>
) {
  const partnerById: Record<string, Partner | undefined> = Object.fromEntries(
    partners.map((partner) => [partner._id, partner])
  );

  const ordersByAllPartner: Record<string, SettlementOrder[]> =
    Object.fromEntries([
      ...partners.map((partner) => [
        partner._id,
        ordersByPartner[partner._id] ?? [],
      ]),
      [NULL_STRING, ordersByPartner[NULL_STRING] ?? []],
    ]);

  const partnerSettlements = Object.entries(ordersByAllPartner)
    .map(([partnerId, orders]): PartnerSettlement | null => {
      const totalPrice = sum(orders.map((order) => order.totalSellingPrice));
      if (partnerId === NULL_STRING) {
        return null;
      }
      const partner = partnerById[partnerId];
      return partner != null
        ? {
            partner,
            orders,
            totalPrice,
          }
        : null;
    })
    .filter(isNotNil);

  const sortedPartnerSettlements = sortBy(partnerSettlements, [
    ({ totalPrice }) => (totalPrice > 0 ? -1 : 0),
    ({ partner }) => partner?.name ?? '',
  ]);

  return sortedPartnerSettlements;
}
