import { sortBy } from '@sweep/utils';

export function getLabel(
  value: string[],
  options: { value: string[]; label: string }[]
): string {
  const sortedOptions = sortBy(options, [(option) => -option.value.length]);

  let selectedValue: string[] = value;
  const labels: string[] = [];

  for (const option of sortedOptions) {
    if (option.value.every((v) => selectedValue.includes(v))) {
      selectedValue = selectedValue.filter((v) => !option.value.includes(v));
      labels.push(option.label);
    }
  }

  return labels.join(', ');
}
