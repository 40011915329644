import { useEffect } from 'react';
import { amplitude } from 'src/third-parties/amplitude';

const redirectMap = {
  aliFee:
    'https://blog.sweepingoms.com/%EC%95%8C%EB%A6%AC-%EC%9D%B5%EC%8A%A4%ED%94%84%EB%A0%88%EC%8A%A4-%EC%88%98%EC%88%98%EB%A3%8C-%EA%B3%84%EC%82%B0%ED%95%98%EA%B8%B0-%EC%8B%A4%EC%82%AC%EC%9A%A9-%EA%B0%80%EB%8A%A5%ED%95%9C-%EC%97%91%EC%85%80-%EA%B3%84%EC%82%B0%EA%B8%B0-%EC%A0%9C%EA%B3%B5-41293',
};

interface RedirectProps {
  to: keyof typeof redirectMap;
}

function Redirect({ to }: RedirectProps) {
  useEffect(() => {
    amplitude.track('Redirect', {
      to,
    });
    window.location.replace(redirectMap[to]);
  }, []);

  return null;
}

export default Redirect;
