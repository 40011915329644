import { observer } from 'mobx-react-lite';
import { SearchOrderStatus } from '@sweep/contract';
import { cva } from '@sweep/tailwind';

interface OrderStatusInputProps {
  value: SearchOrderStatus | null;
  onChange: (value: SearchOrderStatus | null) => void;
  className?: string;
}

function OrderStatusInput({
  value,
  onChange,
  className,
}: OrderStatusInputProps) {
  return (
    <div className={container({ className })}>
      <button
        className={button({ selected: value == null, color: 'skyBlue' })}
        onClick={() => onChange(null)}
      >
        전체 주문
      </button>
      <button
        className={button({
          selected: value === 'paymentComplete',
          color: 'blue',
        })}
        onClick={() => onChange('paymentComplete')}
      >
        결제 완료
      </button>
      <button
        className={button({
          selected: value === 'productPrepare',
          color: 'blue',
        })}
        onClick={() => onChange('productPrepare')}
      >
        상품 준비 중
      </button>
      <button
        className={button({ selected: value === 'inDelivery', color: 'blue' })}
        onClick={() => onChange('inDelivery')}
      >
        배송 중
      </button>
      <button
        className={button({
          selected: value === 'deliveryComplete',
          color: 'blue',
        })}
        onClick={() => onChange('deliveryComplete')}
      >
        배송 완료
      </button>
      <button
        className={button({
          selected: value === 'cancelRequest',
          color: 'red',
        })}
        onClick={() => onChange('cancelRequest')}
      >
        취소 요청
      </button>
      <button
        className={button({
          selected: value === 'cancelComplete',
          color: 'red',
        })}
        onClick={() => onChange('cancelComplete')}
      >
        취소 완료
      </button>
      <button
        className={button({ selected: value === 'etc', color: 'gray' })}
        onClick={() => onChange('etc')}
      >
        기타
      </button>
    </div>
  );
}

const container = cva('gap-12px flex justify-between');

const button = cva(
  [
    'px-4px py-10px rounded-8px flex-center flex flex-1 border-[1.5px]',
    'text-medium-s text-gray-700',
    'transition-colors',
  ],
  {
    variants: {
      selected: {
        true: '',
        false: 'border-gray-300 bg-white',
      },
      color: {
        skyBlue: '',
        blue: '',
        red: '',
        gray: '',
      },
    },
    compoundVariants: [
      {
        selected: false,
        color: 'skyBlue',
        className: 'hover:border-blue-200',
      },
      {
        selected: false,
        color: 'blue',
        className: 'hover:border-blue-400',
      },
      {
        selected: false,
        color: 'red',
        className: 'hover:border-red-400',
      },
      {
        selected: false,
        color: 'gray',
        className: 'hover:border-gray-500',
      },
      {
        selected: true,
        color: 'skyBlue',
        className: 'bg-blue-10 border-blue-400',
      },
      {
        selected: true,
        color: 'blue',
        className: 'border-blue-600 bg-blue-50',
      },
      {
        selected: true,
        color: 'red',
        className: 'border-red-600 bg-red-50',
      },
      {
        selected: true,
        color: 'gray',
        className: 'border-gray-600 bg-gray-50',
      },
    ],
  }
);

export default observer(OrderStatusInput);
