export const SHIPPING_COMPANY = {
  CJ: 'CJ대한통운',
  LOTTE: '롯데택배',
  HANJIN: '한진택배',
  LOGEN: '로젠택배',
  POST_OFFICE: '우체국',
  KYUNGDONG: '경동택배',
  CHUNIL: '천일택배',
  DAESIN: '대신택배',
  ILYANG: '일양로지스',
  DHL: 'DHL',
  ETC: '기타',
} as const;

export type ShippingCompany =
  (typeof SHIPPING_COMPANY)[keyof typeof SHIPPING_COMPANY];
