import { Workbook } from 'exceljs';

export function assignCircularSheetOrder(workbook: Workbook) {
  const worksheets = workbook.worksheets;
  const worksheetLength = worksheets.length;
  worksheets.forEach((worksheet, index) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    worksheet.orderNo = (index + 1) % worksheetLength;
  });
}
