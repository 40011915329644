import { overlay } from 'overlay-kit';
import RetryFulfillPurchaseOrderFileModal from './RetryFulfillPurchaseOrderFileModal';

export async function openRetryFulfillPurchaseOrderFileModal() {
  return new Promise((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <RetryFulfillPurchaseOrderFileModal
        open={isOpen}
        onClose={() => {
          close();
          unmount();
          resolve(true);
        }}
      />
    ))
  );
}
