import {
  PurchaseOrderTransactionConfirmBody,
  PurchaseOrderTransactionCreateBody,
  PurchaseOrderTransactionFindAllQuery,
} from '@sweep/contract';
import { client } from '../client';

export async function createPurchaseOrderTransaction(
  data: PurchaseOrderTransactionCreateBody
) {
  return await client.purchaseOrderTransaction.create({
    body: data,
  });
}

export async function findAllPurchaseOrderTransactions(
  query?: PurchaseOrderTransactionFindAllQuery
) {
  return await client.purchaseOrderTransaction.findAll({
    query,
  });
}

export async function confirmPurchaseOrderTransaction(
  purchaseOrderTransactionId: string,
  data: PurchaseOrderTransactionConfirmBody = {}
) {
  return await client.purchaseOrderTransaction.confirm({
    params: {
      purchaseOrderTransactionId,
    },
    body: data,
  });
}

export async function deletePurchaseOrderTransaction(
  purchaseOrderTransactionId: string
) {
  return await client.purchaseOrderTransaction.delete({
    params: {
      purchaseOrderTransactionId,
    },
  });
}

export async function countPurchaseOrderTransactions(
  query?: PurchaseOrderTransactionFindAllQuery
) {
  return await client.purchaseOrderTransaction.count({
    query,
  });
}

export async function getPurchaseOrderTransactionDownloadUrl(
  purchaseOrderTransactionId: string
) {
  return await client.purchaseOrderTransaction.getDownloadUrl({
    params: {
      purchaseOrderTransactionId,
    },
  });
}

export async function getPurchaseOrderTransactionPreprocessedDownloadUrl(
  purchaseOrderTransactionId: string
) {
  return await client.purchaseOrderTransaction.getPreprocessedDownloadUrl({
    params: {
      purchaseOrderTransactionId,
    },
  });
}

export async function getPurchaseOrderTransactionPreprocessedContent(
  purchaseOrderTransactionId: string
) {
  return await client.purchaseOrderTransaction.getPreprocessedContent({
    params: {
      purchaseOrderTransactionId,
    },
  });
}
