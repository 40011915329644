import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

export async function saveExcelAs(workbook: Workbook, filename: string) {
  const buffer = await workbook.xlsx.writeBuffer();
  const EXCEL_MIME_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const blob = new Blob([buffer], { type: EXCEL_MIME_TYPE });
  saveAs(blob, filename);
}
