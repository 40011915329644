import { action, computed, observable, runInAction } from 'mobx';
import { ShoppingMall } from '@sweep/contract';
import { getShoppingMalls } from 'src/network/order/dispatchOrder';
import { OMSStore } from './OMSStore';

export class ShoppingMallStore {
  @observable
  accessor shoppingMalls: ShoppingMall[] = [];

  @computed
  get shoppingMallNames() {
    return this.shoppingMalls.map((shoppingMall) => shoppingMall.name);
  }

  @computed
  get inquiryShoppingMallNames() {
    return this.shoppingMalls
      .filter(
        (shoppingMall) =>
          shoppingMall.name.startsWith('쿠팡') ||
          shoppingMall.name.startsWith('스마트스토어')
      )
      .map((shoppingMall) => shoppingMall.name);
  }

  @computed
  get isNotLinkedShoppingMall() {
    return this.shoppingMallNames.length === 0;
  }

  getShoppingMall(id: string): ShoppingMall | null {
    return (
      this.shoppingMalls.find((shoppingMall) => shoppingMall._id === id) ?? null
    );
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await this.loadShoppingMalls();
  }

  @action.bound
  async loadShoppingMalls() {
    const response = await getShoppingMalls();

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.shoppingMalls = response.data;
    });
  }
}
