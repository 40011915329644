import { translateHeaderRowInEnglish } from 'src/utils/headerColumnMapping';

export function getAddressIndex(headers: string[]) {
  const headerKeys = translateHeaderRowInEnglish(headers);
  const addressFoundedIndex = headerKeys.findIndex(
    (header) => header.toLowerCase() === 'address'
  );
  const addressIndex = addressFoundedIndex !== -1 ? addressFoundedIndex : null;
  const postCodeFoundedIndex = headerKeys.findIndex(
    (header) => header.toLowerCase() === 'postcode'
  );
  const postCodeIndex =
    postCodeFoundedIndex !== -1 ? postCodeFoundedIndex : undefined;

  return {
    addressIndex,
    postCodeIndex,
  };
}
