import { z } from 'zod';
import { DispatchedOrder, DispatchedOrderSchema } from './DispatchedOrder';
import { UploadedOrderSchema } from './UploadedOrder';

export const OrderProcessSchema = z.enum(['pending', 'shipping']);
/**
 * @deprecated use shippingStatus instead
 */
export type OrderProcess = z.infer<typeof OrderProcessSchema>;

export const ORDER_SHIPPING_STATUS = {
  pending: 'pending',
  processing: 'processing',
  reviewing: 'reviewing',
  shipped: 'shipped',
} as const;
export type OrderShippingStatus =
  | 'pending'
  | 'processing'
  | 'reviewing'
  | 'shipped';
export const OrderShippingStatusSchema = z.custom<OrderShippingStatus>(
  (value) => Object.values(ORDER_SHIPPING_STATUS).includes(value)
);

export const OrderSchema = z.union([
  DispatchedOrderSchema,
  UploadedOrderSchema,
]);
export type Order = z.infer<typeof OrderSchema>;

export type PluginOrder<P extends Record<string, unknown>> = Order & {
  plugin?: P;
};

export const OrderExtendedFieldsSchema = z.object({
  uniqueCodeAfterCustomization: z.string(),
  /**
   * @deprecated use shippingStatus instead
   */
  process: OrderProcessSchema.optional(),
  shippingStatus: OrderShippingStatusSchema.optional(),
  shippingOrderId: z.string().optional(),
});
export type OrderExtendedFields = z.infer<typeof OrderExtendedFieldsSchema>;

export const NormalizedOrderSchema = z.union([
  DispatchedOrderSchema.extend(OrderExtendedFieldsSchema.shape),
  UploadedOrderSchema.extend(OrderExtendedFieldsSchema.shape),
]);
export type NormalizedOrder = z.infer<typeof NormalizedOrderSchema>;

export function isDispatchedOrder(order: Order): order is DispatchedOrder {
  return order.orderStatus != null;
}

export function isNormalizedOrder(order: Order): order is NormalizedOrder {
  return order.uniqueCodeAfterCustomization != null;
}
