import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Inquiry } from '@sweep/contract';
import { Divider } from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { SwitchCase } from '@sweep/utils/react';
import { openConfirmDialog } from 'src/components/openConfirmDialog';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { InquiryScreenStore } from '../InquiryScreenStore';
import InquiryReplyInput from './InquiryReplyInput/InquiryReplyInput';
import InquiryReplyResult from './InquiryReplyResult/InquiryReplyResult';
import { Markdown } from './Markdown';

interface InquiryDetailProps {
  store: InquiryScreenStore;
  inquiry: Inquiry;
}

function InquiryDetail({ store, inquiry }: InquiryDetailProps) {
  const oms = useOMSStore();
  const inquiryStatus = inquiry.inquiryStatus;

  const inquiryRows = [
    {
      label: '상품',
      value: (inquiry.productNames ?? inquiry.productIds)?.join(', '),
    },
    {
      label: '제목',
      value: inquiry.title,
    },
    {
      label: '내용',
      value: inquiry.content,
    },
  ];

  const [replyContentById, setReplyContentById] = useState<
    Record<string, string | undefined>
  >({});
  const handleReplyContentChange = (content: string) => {
    setReplyContentById((prev) => ({
      ...prev,
      [inquiry.inquiryUniqueCode]: content,
    }));
  };

  const replyContent = replyContentById[inquiry.inquiryUniqueCode];

  const replyInquiry = async () => {
    if (inquiry.replyType === 'WRITE' || inquiry.replyType === 'CONFIRM') {
      const message =
        inquiry.replyType === 'WRITE'
          ? '작성한 답변을 쇼핑몰에 등록할까요?'
          : '문의를 확인 완료 처리할까요?';
      const confirmed = await openConfirmDialog(message);
      if (!confirmed) {
        return;
      }
    }

    await oms.loading.batch(() =>
      store.reply(inquiry.inquiryUniqueCode, replyContent)
    );
  };

  return (
    <div className="gap-24px px-28px py-20px rounded-8px shadow-line flex bg-white">
      <div className="gap-12px flex w-1/2 flex-col">
        {inquiryRows.map((row) => (
          <div key={row.label} className="gap-16px flex">
            <div className="p-8px flex justify-end">
              <p className="text-medium-m text-gray-500">{row.label}</p>
            </div>
            <div className="py-8px px-16px rounded-8px flex min-w-0 flex-1 bg-gray-50">
              <Markdown>{row.value}</Markdown>
            </div>
          </div>
        ))}
        {inquiry.inquiryDate != null && (
          <div className="flex justify-end">
            <p className="text-medium-s text-gray-500">
              접수일시:{' '}
              {formatDate(new Date(inquiry.inquiryDate), 'yyyy-MM-dd HH:mm:ss')}
            </p>
          </div>
        )}
      </div>
      <Divider type="vertical-full" />
      <div className="gap-24px flex w-1/2">
        <div className="flex-center flex h-[45px]">
          <p className="text-medium-m whitespace-nowrap text-gray-500">답변</p>
        </div>
        <SwitchCase
          value={inquiryStatus}
          caseBy={{
            UNANSWERED: (
              <InquiryReplyInput
                inquiry={inquiry}
                content={replyContent}
                onChange={handleReplyContentChange}
                onSubmit={replyInquiry}
              />
            ),
            ANSWERED: <InquiryReplyResult inquiry={inquiry} />,
          }}
        />
      </div>
    </div>
  );
}

export default observer(InquiryDetail);
