import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { DispatchedOrder } from '@sweep/contract';
import { ColumnDef, SelectableTable, Table } from '@sweep/sds';
import { formatPhoneNumber } from '@sweep/utils';
import {
  TableFilterHeaderCell,
  TableFilterStore,
} from 'src/components/TableFilter';
import { amplitude } from 'src/third-parties/amplitude';
import { getShippingCompanyUrl } from '../services/getShippingCompanyUrl';

interface OrderDispatchSearchTableProps {
  store: TableFilterStore;
  orders: DispatchedOrder[];
  filteredOrders: DispatchedOrder[];
  selectedOrders: DispatchedOrder[];
  onSelect: (orders: DispatchedOrder[]) => void;
  className?: string;
}

function OrderDispatchSearchTable({
  store,
  orders,
  filteredOrders,
  selectedOrders,
  onSelect,
  className,
}: OrderDispatchSearchTableProps) {
  const columns = useMemo(
    () =>
      ORDER_SEARCH_COLUMNS.map<ColumnDef<DispatchedOrder>>(
        ({ header, accessorFn, ...rest }) => ({
          header: (context) => (
            <TableFilterHeaderCell
              store={store}
              rows={orders}
              accessorFn={accessorFn}
              context={context}
            >
              {header(context)}
            </TableFilterHeaderCell>
          ),
          accessorFn,
          ...rest,
        })
      ),
    [orders, store]
  );

  return (
    <SelectableTable
      getKey={(order) => order.uniqueCode}
      items={filteredOrders}
      columns={columns}
      className={className}
      selectedItems={selectedOrders}
      onSelect={onSelect}
    />
  );
}

const ORDER_SEARCH_COLUMNS: ColumnDef<DispatchedOrder>[] = [
  {
    header: () => '주문시간',
    accessorFn: (order) => order.orderDate,
  },
  {
    header: () => '결제시간',
    accessorFn: (order) => order.paymentDate,
  },
  {
    header: () => '주문상태',
    accessorFn: (order) => order.orderStatus,
  },
  {
    header: () => '상품명',
    accessorFn: (order) => order.productName,
  },
  {
    header: () => '상품옵션',
    accessorFn: (order) => order.option,
  },
  {
    header: () => '상품관리코드',
    accessorFn: (order) => order.optionCode,
  },
  {
    header: () => '수량',
    accessorFn: (order) => order.quantity?.toString(),
  },
  {
    header: () => '수령인',
    accessorFn: (order) => order.name,
  },
  {
    header: () => '휴대폰번호',
    accessorFn: (order) => formatPhoneNumber(order.contactNumber ?? ''),
  },
  {
    header: () => '전화번호',
    accessorFn: (order) => formatPhoneNumber(order.telephoneNumber ?? ''),
  },
  {
    header: () => '주소',
    accessorFn: (order) => order.address,
  },
  {
    header: () => '우편번호',
    accessorFn: (order) => order.postCode,
  },
  {
    header: () => '배송요청사항',
    accessorFn: (order) => order.deliveryMessage,
  },
  {
    header: () => '주문번호',
    accessorFn: (order) => order.orderNumber,
  },
  {
    header: () => '상세주문번호',
    accessorFn: (order) => order.childOrderNumber,
  },
  {
    header: () => '정산예정금액',
    accessorFn: (order) => order.price?.toString(),
  },
  {
    header: () => '배송비',
    accessorFn: (order) => order.shippingPrice?.toString(),
  },
  {
    header: () => '배송업체',
    accessorFn: (order) => order.sweepShippingCompany,
  },
  {
    header: () => '상품코드(쇼핑몰)',
    accessorFn: (order) => order.productCode,
  },
  {
    header: () => '쇼핑몰',
    accessorFn: (order) => order.shoppingMall,
  },
  {
    header: () => '주문자id',
    accessorFn: (order) => order.buyerId,
  },
  {
    header: () => '운송장번호',
    accessorFn: (order) => order.shippingNumber,
    cell: (context) => {
      const { row: order } = context;
      const shippingNumber = order.shippingNumber;
      const shippingCompany = order.sweepShippingCompany;
      const url =
        shippingNumber != null && shippingCompany != null
          ? getShippingCompanyUrl(shippingNumber, shippingCompany)
          : null;

      if (url == null) {
        return <Table.Cell context={context}>{shippingNumber}</Table.Cell>;
      }

      return (
        <Table.Cell context={context}>
          <a
            onClick={() => amplitude.track('Click Shipping-info')}
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-medium-s text-blue-500 underline"
          >
            {shippingNumber}
          </a>
        </Table.Cell>
      );
    },
  },
  {
    header: () => '스윕고유번호',
    accessorFn: (order) => order.uniqueCode,
  },
];

export default observer(OrderDispatchSearchTable);
