import { overlay } from 'overlay-kit';
import PartnershipSupplierInboundFileTransactionModal from './PartnershipSupplierInboundFileTransactionModal';

export async function openPartnershipSupplierInboundFileTransactionModal() {
  return new Promise((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <PartnershipSupplierInboundFileTransactionModal
        open={isOpen}
        onClose={() => {
          close();
          unmount();
          resolve(true);
        }}
      />
    ))
  );
}
