import { Inquiry } from '@sweep/contract';
import { MINUTE_IN_MS } from '@sweep/utils';
import { getPollingInquiries } from 'src/network/inquiry';

export async function getInquiries(
  requestId: string
): Promise<Inquiry[] | null> {
  const TIMEOUT_MS = MINUTE_IN_MS;
  const DELAY_MS = 1000;

  let errorCount = 0;
  const startTime = Date.now();

  return new Promise((resolve) => {
    const intervalId = setInterval(async () => {
      const currentTime = Date.now();
      if (currentTime - startTime >= TIMEOUT_MS) {
        clearInterval(intervalId);
        resolve(null);
        return;
      }

      const response = await getPollingInquiries(requestId);
      if (response?.success !== true) {
        errorCount++;

        if (errorCount >= 3) {
          clearInterval(intervalId);
          resolve(null);
          return;
        }

        return;
      }

      if (response.data.status === 'FINISH') {
        clearInterval(intervalId);
        resolve(response.data.inquiries ?? null);
        return;
      }

      if (response.data.status === 'FAILED') {
        clearInterval(intervalId);
        resolve(null);
      }
    }, DELAY_MS);
  });
}
