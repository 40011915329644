import { parseNumber } from '@sweep/utils';

export function getRatios(options: string[]): number[] {
  if (options.length === 0) {
    return [];
  }

  const boxCounts = options.map(getBoxCount);
  const isAllBoxFormat = boxCounts.every((boxCount) => boxCount != null);

  if (isAllBoxFormat) {
    return boxCounts;
  }

  return options.map((_, index) => (index === 0 ? 1 : 0));
}

function getBoxCount(option: string): number | null {
  const match = option.match(/(\d+)BOX$/);
  return match ? parseNumber(match[1]) : null;
}
