import { memo } from 'react';
import { cva } from '@sweep/tailwind';
import { ColumnDef } from '../interface';
import { TableFooterCellRenderer } from './TableFooterCellRenderer';

interface TableFooterProps<T> {
  columns: ColumnDef<T>[];
}

function _TableFooter<T>({ columns }: TableFooterProps<T>) {
  return (
    <div className={footer()}>
      {columns.map(({ footer }, index) => (
        <TableFooterCellRenderer
          key={index}
          footer={footer}
          context={{
            lastColumn: index === columns.length - 1,
            columnIndex: index,
          }}
        />
      ))}
    </div>
  );
}

const footer = cva('min-h-36px sticky bottom-0 left-0 z-[1] flex w-full');

export const TableFooter = memo(_TableFooter) as typeof _TableFooter;
