import { NormalizedOrder } from '@sweep/contract';
import { ColumnDef, Table } from '@sweep/sds';
import { isNil } from '@sweep/utils';
import { SettlementOrder } from '../../interface';
import { createSalesChannelSettlementColumns } from '../../services/createSalesChannelSettlementColumns';
import { getSalesChannelTotalPrice } from '../../services/getSalesChannelTotalPrice';

export function createSalesChannelSettlementTableColumns(
  orders: SettlementOrder[],
  headerKeys: string[],
  columnMapping: Record<string, string>
): ColumnDef<NormalizedOrder>[] {
  const columns = createSalesChannelSettlementColumns(
    headerKeys,
    columnMapping
  );
  const footers = createFooters(orders);
  return columns.map<ColumnDef<NormalizedOrder>>((column) => {
    return {
      header: (context) =>
        column.type === 'string' ? (
          (column.header ?? '')
        ) : (
          <Table.HeaderCell context={context} className="justify-end">
            {column.header}
          </Table.HeaderCell>
        ),
      accessorFn:
        column.type === 'string'
          ? (row) => row[column.key]?.toString() ?? ''
          : undefined,
      cell: (context) =>
        column.type === 'number' ? (
          <Table.Cell context={context} className="justify-end">
            {context.row[column.key]?.toLocaleString() ?? '-'}
          </Table.Cell>
        ) : undefined,
      footer: footers[column.key],
    };
  });
}

const createFooters = (
  orders: SettlementOrder[]
): Record<string, ColumnDef<SettlementOrder>['footer']> => {
  const {
    totalQuantity,
    totalSellingPrice,
    totalSellingShippingPrice,
    totalPrice,
  } = getSalesChannelTotalPrice(orders);

  const sellingPrices = orders.map((order) => order.sellingPrice);
  const isSellingPriceNil = sellingPrices.every(isNil);
  const sellingShippingPrices = orders.map(
    (order) => order.sellingShippingPrice
  );
  const isSellingShippingPriceNil = sellingShippingPrices.every(isNil);

  return {
    sellingOrderDate: (context) => (
      <Table.Footer context={context} className="text-bold-s">
        총 합계
      </Table.Footer>
    ),
    quantity: (context) => (
      <Table.Footer context={context} className="text-bold-s justify-end">
        {totalQuantity.toLocaleString()}
      </Table.Footer>
    ),
    sellingPrice: (context) => (
      <Table.Footer context={context} className="text-bold-s justify-end">
        {isSellingPriceNil ? '-' : totalSellingPrice.toLocaleString()}
      </Table.Footer>
    ),
    sellingShippingPrice: (context) => (
      <Table.Footer context={context} className="text-bold-s justify-end">
        {isSellingShippingPriceNil
          ? '-'
          : totalSellingShippingPrice.toLocaleString()}
      </Table.Footer>
    ),
    totalSellingPrice: (context) => (
      <Table.Footer
        context={context}
        className="text-bold-s justify-end text-blue-600"
      >
        {totalPrice.toLocaleString()}
      </Table.Footer>
    ),
  };
};
