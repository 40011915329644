import { z } from 'zod';

export const SEND_ORDER_TRANSACTION_PROGRESS_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
} as const;

export type SendOrderTransactionProgressStatus =
  (typeof SEND_ORDER_TRANSACTION_PROGRESS_STATUS)[keyof typeof SEND_ORDER_TRANSACTION_PROGRESS_STATUS];

export const SendOrderTransactionProgressSchema = z.object({
  isSuccess: z.boolean(),
  partnershipUUID: z.string(),
  statuses: z.array(
    z.enum([
      SEND_ORDER_TRANSACTION_PROGRESS_STATUS.PENDING,
      SEND_ORDER_TRANSACTION_PROGRESS_STATUS.SUCCESS,
      SEND_ORDER_TRANSACTION_PROGRESS_STATUS.FAILED,
    ])
  ),
  payload: z.object({
    partnershipUUID: z.string(),
    filename: z.string(),
    buffer: z.instanceof(Buffer),
  }),
  extra: z.object({
    partnerName: z.string(),
  }),
  key: z.string(),
});

export type SendOrderTransactionProgress = z.infer<
  typeof SendOrderTransactionProgressSchema
>;
