import { z } from 'zod';

export const PARTNERSHIP_HISTORY_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
} as const;

export const PartnershipHistorySchema = z.object({
  partnershipId: z.string(),
  actionType: z.enum([
    PARTNERSHIP_HISTORY_TYPE.CREATE,
    PARTNERSHIP_HISTORY_TYPE.UPDATE,
  ]),
  actionUserId: z.string(),
  actionAt: z.date(),
  payload: z.record(z.string(), z.any()).nullable(),
});

export type PartnershipHistory = z.infer<typeof PartnershipHistorySchema>;
