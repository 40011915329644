import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { DispatchedOrder } from '@sweep/contract';
import { Button, ColumnDef, Table } from '@sweep/sds';
import { flatten, formatPhoneNumber, isNotNil } from '@sweep/utils';
import {
  TableFilterHeaderCell,
  useTableFilter,
} from 'src/components/TableFilter';
import { useOMSStore } from 'src/hooks/useOMSStore';
import useShippingUpload from 'src/hooks/useShippingUpload';
import { isEmptyString } from 'src/utils/string';
import { openShippingUploadFormModal } from '../components/ShippingUploadFormModal/openShippingUploadFormModal';
import { OrderDispatchScreenStore } from '../store/OrderDispatchScreenStore';

export function useDispatchTableColumns(
  store: OrderDispatchScreenStore,
  orders: (DispatchedOrder | DispatchedOrder[])[]
) {
  const selectedOrderStatusType = store.selectedOrderStatusType;
  const { store: tableFilterStore, filterOrders } = useTableFilter();
  const filteredOrders = useMemo(
    () => filterOrders(orders),
    [filterOrders, orders]
  );

  const createHeader = useCallback(
    (key: string, header: string): ColumnDef<DispatchedOrder>['header'] =>
      (context) => (
        <TableFilterHeaderCell
          store={tableFilterStore}
          rows={flatten(orders)}
          accessorFn={(row) => row[key]?.toString()}
          context={context}
        >
          {header}
        </TableFilterHeaderCell>
      ),
    [orders, tableFilterStore]
  );

  const createColumn = useCallback(
    (key: string, header: string): ColumnDef<DispatchedOrder> => ({
      header: createHeader(key, header),
      accessorFn: (order) => `${order[key] ?? ''}`,
    }),
    [createHeader]
  );

  const columns = useMemo<ColumnDef<DispatchedOrder>[]>(() => {
    const columns: Array<ColumnDef<DispatchedOrder> | null> = [
      selectedOrderStatusType === 'CancelRequest'
        ? createColumn('cancelDate', '취소요청시간')
        : null,
      createColumn('orderDate', '주문시간'),
      createColumn('paymentDate', '결제시간'),
      createColumn('orderStatus', '주문상태'),
      createColumn('productName', '상품명'),
      createColumn('option', '상품옵션'),
      createColumn('optionCode', '상품관리코드'),
      {
        header: createHeader('quantity', '수량'),
        accessorFn: (order) => order.quantity?.toString(),
      },
      createColumn('name', '수령인'),
      {
        header: createHeader('contactNumber', '휴대폰번호'),
        accessorFn: (order) => formatPhoneNumber(order.contactNumber ?? ''),
      },
      {
        header: createHeader('telephoneNumber', '전화번호'),
        accessorFn: (order) => formatPhoneNumber(order.telephoneNumber ?? ''),
      },
      createColumn('address', '주소'),
      createColumn('postCode', '우편번호'),
      createColumn('deliveryMessage', '배송요청사항'),
      createColumn('orderNumber', '주문번호'),
      createColumn('childOrderNumber', '상세주문번호'),
      {
        header: createHeader('price', '정산예정금액'),
        accessorFn: (order) => order.price?.toString(),
      },
      {
        header: createHeader('shippingPrice', '배송비'),
        accessorFn: (order) => order.shippingPrice?.toString(),
      },
      createColumn('sweepShippingCompany', '배송업체'),
      createColumn('productCode', '상품코드(쇼핑몰)'),
      createColumn('marketName', '쇼핑몰'),
      createColumn('buyerId', '주문자id'),
      {
        header: createHeader('shippingNumber', '운송장번호'),
        accessorFn: (order) => order.shippingNumber,
        cell: (context) => {
          const { row } = context;
          const shippingNumber = row.shippingNumber;
          const isEmptyShippingNumber = isEmptyString(shippingNumber);
          const isAbleToUpload =
            selectedOrderStatusType === 'ProductPreparing' ||
            selectedOrderStatusType === 'InDelivery';

          if (isEmptyShippingNumber && isAbleToUpload) {
            return (
              <Table.Cell className="flex-center" context={context}>
                <UploadShippingInvoiceButton store={store} order={row} />
              </Table.Cell>
            );
          }
        },
      },
      createColumn('uniqueCode', '스윕고유번호'),
    ];

    return columns.filter(isNotNil);
  }, [createColumn, createHeader, selectedOrderStatusType, store]);

  return { columns, filteredOrders, tableFilterStore };
}

function InnerUploadShippingInvoiceButton({
  store,
  order,
}: {
  store: OrderDispatchScreenStore;
  order: DispatchedOrder;
}) {
  const { mallUploadShippingInfoManually } = useShippingUpload();
  const oms = useOMSStore();

  async function handleClick() {
    const shippingInvoice = await openShippingUploadFormModal();
    if (shippingInvoice == null) {
      return;
    }

    await oms.loading.batch(async () => {
      const { successOrders } = await mallUploadShippingInfoManually([
        { ...order, ...shippingInvoice },
      ]);

      const isUploadSuccess = successOrders.length > 0;

      if (isUploadSuccess) {
        const remainingSelectedOrders = store.selectedOrders.filter(
          (selectedOrder) => selectedOrder.uniqueCode !== order.uniqueCode
        );

        store.setSelectedOrders(remainingSelectedOrders);
      }
    });
  }

  return (
    <Button color="gray" size="xsmall" onClick={handleClick}>
      운송장 등록
    </Button>
  );
}

const UploadShippingInvoiceButton = observer(InnerUploadShippingInvoiceButton);
