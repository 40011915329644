import { useRef } from 'react';
import { IconFileLarge } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import useUpload from 'src/hooks/useUpload';
import LoadingStore from 'src/stores/LoadingStore';

const FileDragAndDropSection = ({ onUpload }: any) => {
  const dropRef = useRef(null);

  const { validateFiles } = useUpload();

  const handleUpload = async (e: any) => {
    const validFiles = await validateFiles(e);
    LoadingStore?.setIsLoading(true);
    if (validFiles.length > 0) {
      await onUpload({
        target: {
          files: validFiles,
        },
      });
    }
    e.target.value = '';
    LoadingStore?.setIsLoading(false);
    // // To do: 오류 발생 시 로딩 상태를 비활성화하는 것을 보장
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <div
      className="flex w-full flex-col px-[40px]"
      ref={dropRef}
      onDrop={handleUpload}
      onDragOver={handleDragOver}
    >
      <div className="shadow-line flex flex-col items-center justify-center rounded-[16px] bg-white py-[28px]">
        <div className="flex flex-col items-center gap-[20px]">
          <IconFileLarge className="text-gray-400" />
          <p className="text-semibold-m text-gray-700">
            <>
              파트너사에게 보낼{' '}
              <span className="text-semibold-m text-blue-500">발주 파일</span>을
              여기에 끌어다 놓으세요.
            </>
          </p>
        </div>

        <div className="pt-16px flex flex-col items-center gap-[8px]">
          <p className="text-semibold-s text-gray-400">또는</p>
          <Button color="blue" size="medium" asChild>
            <label>
              <input
                type="file"
                className="hidden"
                multiple
                onChange={handleUpload}
                accept=".xlsx, .xls, .csv"
              />
              파일 올리기
            </label>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FileDragAndDropSection;
