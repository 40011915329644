import { initClient } from '@ts-rest/core';
import { AxiosError, AxiosResponse, isAxiosError, Method } from 'axios';
import { contract } from '@sweep/contract';
import { BACKEND_URL } from 'src/env';
import { instance } from './api';

export const client = initClient(contract, {
  baseUrl: BACKEND_URL,
  api: async ({ path, method, headers, body }) => {
    try {
      const result = await instance.request({
        method: method as Method,
        url: path,
        headers,
        data: body,
      });

      return {
        status: result.status,
        body: result.data,
        // NOTE(@이지원): https://github.com/ts-rest/ts-rest/issues/320#issuecomment-2330216005
        headers: new Headers(JSON.parse(JSON.stringify(result.headers))),
      };
    } catch (e: Error | AxiosError | any) {
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        const response = error.response as AxiosResponse;
        return {
          status: response.status,
          body: response.data,
          headers: new Headers(JSON.parse(JSON.stringify(response.headers))),
        };
      }
      throw e;
    }
  },
});
