import { Link } from 'react-router-dom';

function SupplierNotFoundSettlementScreen() {
  return (
    <div className="flex-center gap-12px flex h-full flex-col bg-gray-100">
      <p className="text-semibold-l text-gray-700">
        해당 공급사를 찾을 수 없습니다.
      </p>
      <Link
        to="/settlement/supplier"
        className="text-medium-m text-blue-500 underline"
      >
        공급사 정산 페이지로 돌아가기
      </Link>
    </div>
  );
}

export default SupplierNotFoundSettlementScreen;
