import { ReactNode } from 'react';
import { cva } from '@sweep/tailwind';
import { getReactNodeString } from './services/getReactNodeString';
import {
  MultiSelectCollection,
  useMultiSelectContext,
} from './MultiSelectContext';
import SelectIcon from './SelectIcon';

interface MultiSelectOptionProps {
  value: string;
  className?: string;
  children?: ReactNode;
}

function MultiSelectOption({
  value,
  className,
  children,
}: MultiSelectOptionProps) {
  const { value: contextValue, onChange } = useMultiSelectContext();
  const selected = contextValue.includes(value);

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (selected) {
      onChange(contextValue.filter((v) => v !== value));
      return;
    }

    onChange([...contextValue, value]);
  };

  return (
    <MultiSelectCollection.ItemSlot
      value={[value]}
      label={getReactNodeString(children) ?? value}
    >
      <div onClick={handleClick} className={option({ selected, className })}>
        <SelectIcon selected={selected} />
        {children}
      </div>
    </MultiSelectCollection.ItemSlot>
  );
}

const option = cva(
  'text-medium-s h-44px gap-4px px-12px flex w-full shrink-0 select-none items-center text-gray-700',
  {
    variants: {
      selected: {
        true: 'bg-gray-100',
        false: 'bg-white hover:bg-gray-100',
      },
    },
  }
);

export default MultiSelectOption;
