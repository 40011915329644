import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { isValid } from '../../utils/utils';
import EditableCell from './EditableCell';

interface DailySettlementSummaryTableProps {
  settlementData: any;
  setSettlementData: any;
}

const DailySettlementSummaryTable = observer(
  ({ settlementData, setSettlementData }: DailySettlementSummaryTableProps) => {
    const oms = useOMSStore();
    // 받아와야 하는 것들
    // 업체 별 상품 mapping
    // 상품 별 상품명, 키워드 mapping
    // 행사가 및 상시가로 구분된 단가

    const [focusedCell, setFocusedCell] = useState<any>(null);

    const handleValueChange = (index: number, field: string, value: any) => {
      const updatedData = [...settlementData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      setSettlementData(updatedData);
    };

    const getPartnerName = (partnerId: string) => {
      const partnerInfo = oms.partner.partners.find((p) => p._id === partnerId);
      return partnerInfo ? partnerInfo.name : '기타';
    };

    const hasPartnerData = settlementData.some((item: any) => item.partnerId);

    const renderTableBody = () => {
      if (hasPartnerData) {
        const groupedData = settlementData.reduce(
          (acc: any, item: any, index: number) => {
            // 파트너사 ID를 키로 사용하여 데이터 그룹화
            acc[item.partnerId] = acc[item.partnerId] || [];
            acc[item.partnerId].push({ ...item, index: index });
            return acc;
          },
          {}
        );
        const totalQuantity = settlementData.reduce(
          (acc: any, item: any) => acc + parseInt(item.quantity, 10),
          0
        );

        return (
          <>
            {Object.values(groupedData).map((group: any) => {
              return (
                <>
                  {group.map((item: any, index: number) => (
                    <tr
                      key={index}
                      className={`border-b border-gray-200 ${
                        index === group.length - 1 ? 'border-b-2' : ''
                      }`}
                    >
                      {index === 0 && (
                        <td
                          rowSpan={group.length}
                          className="border-r border-gray-300 px-4 py-2 text-left"
                        >
                          {getPartnerName(item.partnerId)}
                        </td>
                      )}
                      <td
                        className={`w-[420px] max-w-[420px] px-4 py-2 text-left  ${
                          focusedCell === `productName-${item.index}`
                            ? 'border border-blue-400'
                            : 'border-r border-gray-300'
                        }`}
                      >
                        <EditableCell
                          value={item.productName}
                          onValueChange={(value: string) =>
                            handleValueChange(item.index, 'productName', value)
                          }
                          onFocused={(isFocused: boolean) =>
                            setFocusedCell(
                              isFocused ? `productName-${item.index}` : null
                            )
                          }
                        />
                      </td>
                      <td
                        className={`px-4 py-2 text-left  ${
                          focusedCell === `unit-${item.index}`
                            ? 'border-2 border-blue-400'
                            : 'border-r border-gray-300'
                        }
                    `}
                      >
                        <EditableCell
                          value={item.unit}
                          onValueChange={(value: string) =>
                            handleValueChange(item.index, 'unit', value)
                          }
                          onFocused={(isFocused: boolean) =>
                            setFocusedCell(
                              isFocused ? `unit-${item.index}` : null
                            )
                          }
                        />
                      </td>
                      <td
                        className={`px-4 py-2 text-right  ${
                          focusedCell === `quantity-${item.index}`
                            ? 'border-2 border-blue-400'
                            : 'border-r border-gray-300'
                        }`}
                      >
                        <EditableCell
                          value={item.quantity}
                          onValueChange={(value: string) =>
                            handleValueChange(item.index, 'quantity', value)
                          }
                          onFocused={(isFocused: boolean) =>
                            setFocusedCell(
                              isFocused ? `quantity-${item.index}` : null
                            )
                          }
                          className="w-[80px]"
                        />
                      </td>
                    </tr>
                  ))}
                </>
              );
            })}
            {isValid(settlementData) && (
              <tr className={`border-b border-gray-200`}>
                <td className="border-r border-gray-300 px-4 py-2 text-left">
                  총 합계
                </td>
                <td
                  className={`border-r border-gray-300 px-4 py-2 text-left`}
                />
                <td
                  className={`border-r border-gray-300 px-4 py-2 text-left`}
                />
                <td className={`border-r border-gray-300 px-4 py-2 text-right`}>
                  {totalQuantity}
                </td>
              </tr>
            )}
          </>
        );
      } else {
        const totalQuantity = settlementData.reduce(
          (acc: any, item: any) => acc + parseInt(item.quantity, 10),
          0
        );

        return (
          <>
            {settlementData?.map((item: any, index: number) => (
              <tr key={index} className="border-b border-gray-200">
                <td
                  className={`px-4 py-2 text-left  ${
                    focusedCell === `productName-${index}`
                      ? 'border-2 border-blue-400'
                      : 'border-r border-gray-300'
                  }`}
                >
                  <EditableCell
                    value={item.productName}
                    onValueChange={(value: string) =>
                      handleValueChange(index, 'productName', value)
                    }
                    onFocused={(isFocused: boolean) =>
                      setFocusedCell(isFocused ? `productName-${index}` : null)
                    }
                  />
                </td>
                <td
                  className={`px-4 py-2 text-left  ${
                    focusedCell === `unit-${index}`
                      ? 'border-2 border-blue-400'
                      : 'border-r border-gray-300'
                  }`}
                >
                  <EditableCell
                    value={item.unit}
                    onValueChange={(value: string) =>
                      handleValueChange(index, 'unit', value)
                    }
                    onFocused={(isFocused: boolean) =>
                      setFocusedCell(isFocused ? `unit-${index}` : null)
                    }
                  />
                </td>
                <td
                  className={`px-4 py-2 text-right  ${
                    focusedCell === `quantity-${index}`
                      ? 'border-2 border-blue-400'
                      : 'border-r border-gray-300'
                  }`}
                >
                  <EditableCell
                    value={item.quantity}
                    onValueChange={(value: string) =>
                      handleValueChange(index, 'quantity', value)
                    }
                    onFocused={(isFocused: boolean) =>
                      setFocusedCell(isFocused ? `quantity-${index}` : null)
                    }
                  />
                </td>
              </tr>
            ))}
            {isValid(settlementData) && (
              <tr className={`border-b border-gray-200`}>
                <td className={`border-r border-gray-300 px-4 py-2 text-left`}>
                  총 합계
                </td>
                <td
                  className={`border-r border-gray-300 px-4 py-2 text-left`}
                />
                <td className={`border-r border-gray-300 px-4 py-2 text-right`}>
                  {totalQuantity}
                </td>
              </tr>
            )}
          </>
        );
      }
    };

    return (
      <div className="w-full">
        <table className="table-auto border-collapse border border-gray-300">
          <thead className="sticky top-0 z-10 p-0">
            <tr>
              {hasPartnerData && (
                <th className="table-header border-r border-gray-300">
                  파트너사
                </th>
              )}
              <th className="table-header w-[35%] max-w-[420px] border-r border-gray-300">
                상품명
              </th>
              <th className="table-header w-1/5 border-r border-gray-300">
                구성
              </th>
              <th className="table-header w-[10%] border-r border-gray-300">
                개수
              </th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>
    );
  }
);

export default DailySettlementSummaryTable;
