import { Button, ButtonProps } from '../Button';
import { useModalContext } from './context/ModalContext';

export type ModalCloseProps = ButtonProps;

export function ModalClose({ children, onClick, ...rest }: ModalCloseProps) {
  const { onClose } = useModalContext();

  return (
    <Button
      onClick={(event) => {
        onClose();
        onClick?.(event);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
