import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openSuggestionDialog() {
  return overlay.open(({ isOpen, unmount }) => (
    <Dialog open={isOpen} onClose={unmount}>
      <Dialog.Title className="mb-16px">
        주소 변환, 자동으로 처리하고 싶다면?
      </Dialog.Title>
      <Dialog.Description className="mb-28px">
        알리 주문처리 서비스, 지금 신청하면 2주 무료로 쓸 수 있어요.
      </Dialog.Description>
      <Dialog.Footer>
        <Dialog.Close color="lightGray">취소</Dialog.Close>
        <Dialog.Close
          onClick={() =>
            window.open('/ali-express?from=address&section=modal', '_blank')
          }
        >
          더 알아보기
        </Dialog.Close>
      </Dialog.Footer>
    </Dialog>
  ));
}
