import { autoPlacement, offset } from '@floating-ui/react';
import { RefObject } from 'react';
import { useDialog } from '@sweep/sds';

interface CalendarDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  reference: RefObject<HTMLButtonElement>;
}

export function useCalendarDialog({
  open,
  onOpenChange,
  reference,
}: CalendarDialogProps) {
  return useDialog({
    open,
    onOpenChange,
    reference,
    middleware: [
      offset(8),
      autoPlacement({
        allowedPlacements: ['bottom-start', 'bottom-end'],
      }),
    ],
    placement: 'bottom-start',
  });
}
