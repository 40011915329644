import {
  AUTO_DISPATCH_ORDERS_URL,
  AutoDispatchOrder,
  AutoDispatchOrdersBody,
  AutoDispatchOrdersResponse,
} from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function autoDispatchOrder(orders: AutoDispatchOrder[]) {
  const response = await api.post<
    APIResponse<AutoDispatchOrdersResponse>,
    AutoDispatchOrdersBody
  >(AUTO_DISPATCH_ORDERS_URL, { orders });

  return response?.data;
}
