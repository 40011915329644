import { Workbook } from 'exceljs';
import { KAKAO_HEADERS } from './constants';
import { ExcelOrder } from './interface';

export async function createExcel(orders: ExcelOrder[]): Promise<ArrayBuffer> {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.addRow(KAKAO_HEADERS);

  orders.forEach((order) => {
    const rowValues = KAKAO_HEADERS.map((header) => order[header] ?? '');
    worksheet.addRow(rowValues);
  });

  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true };

  worksheet.columns.forEach((column) => {
    column.width = 15;
  });

  return workbook.xlsx.writeBuffer();
}
