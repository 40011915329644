import { DispatchedOrder } from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import { LocalStorageValue } from '@sweep/utils/mobx';
import { hashToNumber } from '../random/hashToNumber';
import { randomDate } from '../random/randomDate';
import { randomFromArray } from '../random/randomFromArray';
import { randomName } from '../random/randomName';
import { randomPhoneNumber } from '../random/randomPhoneNumber';

const productNames = ['반숙란', '구운란', '맛있는 반숙란'];
const productOptions = ['20구', '30구'];
const ADDRESSES: { address: string; postCode?: string }[] = [
  { address: '충청북도 청주시 서원구 사직동 254-9 2층' },
  { address: '경기도 화성시 산척동 718 더레이크시티부영3단지 2564동503호' },
  { address: '부산광역시 사상구 괘법동 522-3 괘법엘에이치아파트 103-505' },
  { address: '인천광역시 서구 오류동 1722-1 우방아이유쉘아파트 105동 301호' },
  { address: '충청남도 당진시 수청동 999 G1부경파크빌 917호' },
  { address: '서울특별시 성동구 성수일로 44 (성수동2가)', postCode: '4782' },
];

const MONTH = 1000 * 60 * 60 * 24 * 30;

export type AddressType = 'id' | 'jibun';
export const addressTypeStorage = new LocalStorageValue<AddressType>({
  key: 'OrderFactory/address-type',
  defaultValue: 'id',
});

export class OrderFactory {
  static pk = 0;

  static createDispatchedOrder(
    shoppingMall: string,
    index: number,
    partial?: Partial<DispatchedOrder>
  ): DispatchedOrder {
    const uniqueCodePrefix = getUniqueCodePrefix(shoppingMall);
    const number = hashToNumber(shoppingMall) + index;
    const optionNumber = number % productOptions.length;
    const productName = productNames[number % productNames.length];
    const optionCode = `code-${productName}-${productOptions[optionNumber]}`;

    const address =
      addressTypeStorage.value === 'id'
        ? { address: `address-${index}(${shoppingMall})` }
        : randomFromArray(ADDRESSES);
    const date = randomDate({ range: MONTH });

    OrderFactory.pk += 1;
    return {
      orderNumber: `order-${index}(${shoppingMall})`,
      buyerId: `buyer-${index}(${shoppingMall})`,
      buyerName: randomName(),
      orderStatus: '결제 완료',

      orderDate: formatDate(date, 'yyyy-MM-dd HH:mm:ss'),
      paymentDate: formatDate(date, 'yyyy-MM-dd HH:mm:ss'),
      productName,
      option: productOptions[optionNumber],
      optionCode,
      quantity: randomFromArray([1, 2, 3, 4, 5]),
      contactNumber: randomPhoneNumber(),
      name: randomName(),

      shoppingMall,
      shoppingMallId: shoppingMall,
      uniqueCode: `${uniqueCodePrefix}-${OrderFactory.pk.toString(36)}`,
      address: address.address,
      postCode: address.postCode,
      updatedAt: new Date().getTime(),
      ...partial,
    };
  }
}

function getUniqueCodePrefix(shoppingMall: string) {
  if (shoppingMall.startsWith('NAVER')) {
    return 'NSS';
  }

  if (shoppingMall.startsWith('ALI')) {
    return 'ALI';
  }

  if (shoppingMall.startsWith('11번가')) {
    return 'ELV';
  }

  return shoppingMall;
}
