import { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Time } from '@sweep/asset/lotties';
import { cva } from '@sweep/tailwind';
import { videoFull } from 'src/assets/videos';
import useCountUp from 'src/hooks/useCountUp';
import useGoogleTag from 'src/hooks/useGoogleTag';
import { useView } from 'src/hooks/useView';
import {
  imageDeco1,
  imageDeco2,
  imageLandingPageBack1,
  imageLandingPageBack2,
  imageLogos,
} from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';
import Card from '../components/Card';
import Chat from '../components/Chat';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Interviews from '../components/Interviews';
import Details from './screens/Details';
import PreRegistrationForm from './screens/PreRegistrationForm';

function PCLandingPage() {
  useGoogleTag('AW-16638455187');

  const [hasEnoughHeight, setHasEnoughHeight] = useState<boolean>(
    window.innerHeight >= 859
  );

  useEffect(() => {
    const handleResize = () => {
      setHasEnoughHeight(window.innerHeight >= 859);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current != null) {
      videoRef.current.playbackRate = 1.5;
    }

    const mainDivTimer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    const videoTimer = setTimeout(() => {
      if (videoRef.current != null) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }, 1500);

    return () => {
      clearTimeout(mainDivTimer);
      clearTimeout(videoTimer);
    };
  }, []);

  const startDate = new Date('2024-04-01');
  const now = new Date();
  const dateCount =
    (now.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  const savedTime = Math.floor(dateCount * 378);
  const countUpRef = useRef<HTMLSpanElement>(null);
  const [isCountUpVisible, setIsCountUpVisible] = useState(false);

  const splitCountUp = useCountUp(savedTime, 1000, isCountUpVisible)
    .toLocaleString('en-US')
    .split(',');

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsCountUpVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const formRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (formRef.current == null) {
      return;
    }

    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    amplitude.track('[LP] Click CTA');
  };

  const fullVideoRef = useView<HTMLDivElement>(() =>
    amplitude.track('[LP] View Full Video')
  );
  const logosRef = useView<HTMLDivElement>(() =>
    amplitude.track('[LP] View Logos')
  );
  const savedTimeRef = useView<HTMLDivElement>(() =>
    amplitude.track('[LP] View Saved Time')
  );
  const cardRef = useView<HTMLDivElement>(() =>
    amplitude.track('[LP] View Card')
  );

  return (
    <div className="relative w-full min-w-[1200px] text-center text-[#03183A]">
      <Header onOpen={handleScroll} />
      <Chat />
      <div className="relative flex h-[2973px] w-full flex-col items-center justify-center overflow-x-hidden">
        <img
          src={imageLandingPageBack1}
          alt="main-back1"
          className="absolute top-0 z-0 h-[1920px] w-full object-cover"
        />
        <img
          src={imageLandingPageBack2}
          alt="main-back2"
          className="absolute bottom-0 z-0 h-[960px] object-cover"
        />
        <div className={main({ isVisible })}>
          <div
            id="main1"
            className="flex w-full flex-col items-center gap-[120px]"
          >
            <div className="absolute top-[92px] z-10 h-[404px] w-[1292px]">
              <img
                src={imageDeco1}
                alt="deco1"
                className="absolute left-0 top-0 h-[220px] w-[304px]"
              />
              <img
                src={imageDeco2}
                alt="deco2"
                className="absolute bottom-0 right-0 h-[315px] w-[272px]"
              />
            </div>
            <div className="z-20 flex w-full flex-col items-center gap-[40px]">
              <h1 className="text-[64px] font-bold leading-[76.38px] tracking-tight">
                클릭 9번으로
                <br />
                5분 만에 끝내는 발주 업무
              </h1>
              <div className="flex h-[80px] flex-col items-center justify-end gap-[11px]">
                <button
                  className="py-14px px-22px rounded-[16px] bg-gradient-to-r from-[#1891FF] to-[#0F53FF] text-[24px] font-bold tracking-tight text-white"
                  onClick={handleScroll}
                >
                  무료로 시작하기
                </button>
              </div>
            </div>
            <div
              className="p-16px rounded-[32px] bg-gradient-to-b from-[#03183A] to-[#03183A]/30"
              ref={fullVideoRef}
            >
              <video
                className={video({ isVisible })}
                ref={videoRef}
                loop
                muted
                playsInline
              >
                <source src={videoFull} />
              </video>
            </div>
          </div>
          <div
            id="main2"
            className="flex w-full flex-col items-center gap-[40px]"
            ref={logosRef}
          >
            <h2 className="text-center text-[48px] font-bold leading-[64px] tracking-tight">
              발주 업무는{' '}
              <span className="bg-gradient-to-r from-[#1891FF] to-[#0E53FF] bg-clip-text text-transparent">
                스윕
              </span>
              에게 맡기고,
              <br />더 중요한 일에 집중하세요.
            </h2>
            <img
              src={imageLogos}
              alt="logos"
              className="w-full bg-white object-cover"
            />
          </div>
          <div
            id="main3"
            className="flex w-full flex-col items-center gap-[61px]"
          >
            <div
              className="flex flex-col items-center gap-[24px]"
              ref={savedTimeRef}
            >
              <p className="text-center text-[32px] font-bold leading-[48px] tracking-tight">
                100곳 이상의 고객사에서 스윕을 통해 절약한 발주 업무 시간
              </p>
              <div className="flex items-center gap-0">
                <div className="flex size-[80px] items-center justify-center">
                  <Lottie
                    width="53px"
                    height="53px"
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: Time,
                    }}
                    style={{ pointerEvents: 'none' }}
                  />
                </div>
                <div className="flex items-center gap-[4px]">
                  <span
                    ref={countUpRef}
                    className="w-[365px] bg-gradient-to-r from-[#1891FF] to-[#0E53FF] bg-clip-text text-end text-[96px] leading-[80px] tracking-tight text-transparent"
                  >
                    {splitCountUp.map((num, index) => (
                      <>
                        <span key={index} className="font-bold">
                          {num}
                        </span>
                        {index !== splitCountUp.length - 1 && ','}
                      </>
                    ))}
                  </span>
                  <span className="text-center text-[48px] font-bold leading-[53px] tracking-tight text-[#1243FF]">
                    시간
                  </span>
                </div>
              </div>
            </div>
            <Interviews />
          </div>
        </div>
      </div>
      <Details hasEnoughHeight={hasEnoughHeight} />
      <div
        className="flex h-screen w-full items-center justify-center pt-[40px]"
        ref={cardRef}
      >
        <div className="flex w-[1184px] gap-[16px]">
          <Card number={1} />
          <Card number={2} />
          <Card number={3} />
        </div>
      </div>
      <PreRegistrationForm hasEnoughHeight={hasEnoughHeight} ref={formRef} />
      <Footer />
    </div>
  );
}

const main = cva(
  'relative flex w-[1186px] flex-col gap-[120px] transition-all duration-1000',
  {
    variants: {
      isVisible: {
        true: '',
        false: 'translate-y-[64px] opacity-0',
      },
    },
  }
);

const video = cva('rounded-[16px] object-cover transition-all duration-1000', {
  variants: {
    isVisible: {
      true: 'w-[952px]',
      false: 'w-[635px]',
    },
  },
});

export default PCLandingPage;
