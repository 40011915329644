import { IconCalendar } from '@sweep/asset/icons';
import { RangePicker, RangeSelect } from '@sweep/sds';
import {
  DateRange,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from '@sweep/utils';

interface SettlementRangeSelectProps {
  value: DateRange;
  onChange: (value: DateRange) => void;
}

function SettlementRangeSelect({
  value,
  onChange,
}: SettlementRangeSelectProps) {
  return (
    <div className="gap-16px flex items-center">
      <div className="gap-6px flex items-center">
        <IconCalendar className="text-gray-400" />
        <p className="text-medium-s text-gray-500">조회기간</p>
      </div>
      <RangeSelect
        value={value}
        onChange={onChange}
        options={getRangeSelectOptions()}
        className="w-212px"
      />
      <RangePicker value={value} onChange={onChange} />
    </div>
  );
}

function getRangeSelectOptions() {
  return [
    {
      value: { from: startOfDay(new Date()), to: endOfDay(new Date()) },
      label: '오늘',
    },
    {
      value: {
        from: startOfDay(startOfWeek(new Date(), { weekStartsOn: 1 })),
        to: endOfDay(endOfWeek(new Date(), { weekStartsOn: 1 })),
      },
      label: '이번 주',
    },
    {
      value: {
        from: startOfDay(startOfMonth(new Date())),
        to: endOfDay(endOfMonth(new Date())),
      },
      label: '이번 달',
    },
  ];
}
export default SettlementRangeSelect;
