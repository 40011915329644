import { action, observable, runInAction } from 'mobx';
import { DispatchedOrder, SearchOrderStatus } from '@sweep/contract';
import {
  endOfDay,
  isBefore,
  startOfDay,
  subDays,
  subMonths,
} from '@sweep/utils';
import { searchDispatchedOrders } from 'src/network/order/dispatchOrder';
import { OMSStore } from 'src/stores/OMSStore';
import { toast } from 'src/third-parties/toast';
import { isEmptyString } from 'src/utils/string';

export class OrderDispatchSearchScreenStore {
  @observable
  accessor shoppingMalls: string[];

  @observable
  accessor keyword: string | null = null;

  @observable
  accessor range: { from: Date; to: Date } = {
    from: subDays(startOfDay(new Date()), 7),
    to: startOfDay(new Date()),
  };

  @observable
  accessor rangeStatus: 'default' | 'error' = 'default';

  @observable
  accessor orderStatus: SearchOrderStatus | null = null;

  @observable
  accessor selectedOrders: DispatchedOrder[] = [];

  @observable
  accessor orders: DispatchedOrder[] | null = null;

  constructor(private oms: OMSStore) {
    this.shoppingMalls = oms.shoppingMall.shoppingMallNames;
  }

  @action.bound
  setShoppingMalls(shoppingMalls: string[]) {
    this.shoppingMalls = shoppingMalls;
  }

  @action.bound
  setKeyword(keyword: string) {
    this.keyword = isEmptyString(keyword) ? null : keyword;
  }

  @action.bound
  setRange(range: { from: Date; to: Date }) {
    const sixMonthsAgo = subMonths(range.to, 6);
    if (isBefore(range.from, sixMonthsAgo)) {
      this.rangeStatus = 'error';
      toast.error('최대 6개월까지 조회할 수 있습니다.');
      return;
    }

    this.rangeStatus = 'default';
    this.range = range;
  }

  @action.bound
  setOrderStatus(orderStatus: SearchOrderStatus | null) {
    this.orderStatus = orderStatus;
  }

  @action.bound
  setSelectedOrders(selectedOrders: DispatchedOrder[]) {
    this.selectedOrders = selectedOrders;
  }

  @action.bound
  async search() {
    this.selectedOrders = [];

    const allShoppingMalls = this.oms.shoppingMall.shoppingMallNames;
    const isAllSelected = this.shoppingMalls.length === allShoppingMalls.length;

    const { from, to } = this.range;
    const orders = await this.oms.loading.batch(async () => {
      const { status, body } = await searchDispatchedOrders({
        shoppingMalls: isAllSelected ? undefined : this.shoppingMalls,
        orderStatus: this.orderStatus ?? undefined,
        startDate: startOfDay(from).toISOString(),
        endDate: endOfDay(to).toISOString(),
        keyword: this.keyword ?? undefined,
      });

      if (status !== 200) {
        toast.error('주문 조회에 실패했습니다.');
        return [];
      }

      const orders = body.dispatchedOrders;
      if (orders.length === 0) {
        return [];
      }

      const transformedOrders =
        await this.oms.order.dispatch.transformOrders(orders);
      const sortedOrders = transformedOrders.sort(
        (a, b) =>
          new Date(b.orderDate ?? 0).getTime() -
          new Date(a.orderDate ?? 0).getTime()
      );

      return sortedOrders;
    });

    runInAction(() => {
      this.orders = orders;
    });
  }
}
