import { observer } from 'mobx-react-lite';
import { useId } from 'react';
import { UserDeploy } from '@sweep/contract';
import { Checkbox } from '@sweep/sds';

interface UserDeployInputProps {
  value: UserDeploy;
  onChange: (value: UserDeploy) => void;
}

function UserDeployInput({ value, onChange }: UserDeployInputProps) {
  const handleDeployChange = (key: keyof UserDeploy) => (itemValue: boolean) =>
    onChange({ ...value, [key]: itemValue });

  return (
    <div className="flex flex-col gap-3">
      <p className="text-semibold-2xl">User Deploy</p>
      <div className="flex flex-col gap-[12px]">
        <UserDeployCheckbox
          label="발주서 이메일 발송"
          checked={value.isAdvancedPurchaseOrderFileEnabled ?? false}
          onCheckedChange={handleDeployChange(
            'isAdvancedPurchaseOrderFileEnabled'
          )}
        />
        <UserDeployCheckbox
          label="CS 관리"
          checked={value.cs ?? false}
          onCheckedChange={handleDeployChange('cs')}
        />
        <UserDeployCheckbox
          label="쿠팡 주문수집 개선"
          checked={value.isCoupangDispatchEnabled ?? false}
          onCheckedChange={handleDeployChange('isCoupangDispatchEnabled')}
        />
        <UserDeployCheckbox
          label="주소 변환 뉴로직"
          checked={value.normalizeAddress ?? false}
          onCheckedChange={handleDeployChange('normalizeAddress')}
        />
      </div>
    </div>
  );
}

interface UserDeployCheckboxProps {
  label: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

function UserDeployCheckbox({
  label,
  checked,
  onCheckedChange,
}: UserDeployCheckboxProps) {
  const id = useId();

  return (
    <label htmlFor={id} className="flex cursor-pointer items-center gap-2">
      <Checkbox id={id} checked={checked} onCheckedChange={onCheckedChange} />
      <p className="text-medium-m">{label}</p>
    </label>
  );
}

export default observer(UserDeployInput);
