import { action, observable } from 'mobx';
import {
  DateRange,
  endOfDay,
  getStartDays,
  groupBy,
  NULL_STRING,
  startOfDay,
} from '@sweep/utils';

export class RangeValueStore<T> {
  @observable
  accessor values: Record<string, T[]> = {};

  constructor(
    private readonly findValues: (range: DateRange) => Promise<T[]>,
    private readonly getDate: (value: T) => Date | null
  ) {}

  @action.bound
  setValuesByRange(newValues: T[], range: DateRange) {
    const allGroupedValues = groupBy(newValues, (value) => {
      const date = this.getDate(value);
      if (date == null) {
        return NULL_STRING;
      }

      return startOfDay(date).toISOString();
    });
    const days = getStartDays(range);
    const groupedValues: Record<string, T[]> = Object.fromEntries(
      days.map((day) => {
        const dateKey = startOfDay(day).toISOString();
        const values = allGroupedValues[dateKey] ?? [];
        return [dateKey, values];
      })
    );

    this.values = { ...this.values, ...groupedValues };
  }

  async ensure(range: DateRange) {
    const days = getStartDays(range);
    const missingDays = days.filter(
      (day) => this.values[startOfDay(day).toISOString()] == null
    );

    if (missingDays.length === 0) {
      return;
    }

    const missingRange: DateRange = {
      from: startOfDay(missingDays[0]),
      to: endOfDay(missingDays[missingDays.length - 1]),
    };
    const values = await this.findValues(missingRange);
    this.setValuesByRange(values, missingRange);
  }
}
