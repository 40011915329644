import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconControlDownload } from '@sweep/asset/icons';
import { ShoppingMall } from '@sweep/contract';
import { BrandLogoByName, Button, Divider, SearchInput } from '@sweep/sds';
import { DateRange, sortBy, sum } from '@sweep/utils';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { amplitude } from 'src/third-parties/amplitude';
import { BackLink } from '../components/BackLink';
import SettlementRangeSelect from '../components/SettlementRangeSelect';
import SalesChannelSettlementTable from '../components/SettlementTable/SalesChannelSettlementTable';
import TotalPrice from '../components/TotalPrice';
import { createSalesChannelSettlementColumns } from '../services/createSalesChannelSettlementColumns';
import { downloadSalesChannelSettlementExcel } from '../services/downloadSalesChannelSettlementExcel';
import { isSupportShoppingMall } from '../services/isSupportShoppingMall';
import { SalesChannelSettlementScreenStore } from '../store/SalesChannelSettlementScreenStore';
import { SettlementStore } from '../store/SettlementStore';
import ShoppingMallNotFoundSettlementScreen from './ShoppingMallNotFoundSettlementScreen';
import ShoppingMallNotSupportScreen from './ShoppingMallNotSupportScreen';

function ShoppingMallDetailSettlementScreen() {
  const { shoppingMallId } = useParams();
  const oms = useOMSStore();

  if (shoppingMallId == null) {
    return <ShoppingMallNotFoundSettlementScreen />;
  }

  const shoppingMall = oms.shoppingMall.getShoppingMall(shoppingMallId);

  if (shoppingMall == null) {
    return <ShoppingMallNotFoundSettlementScreen />;
  }

  if (!isSupportShoppingMall(shoppingMall)) {
    return <ShoppingMallNotSupportScreen shoppingMall={shoppingMall} />;
  }

  return (
    <InnerShoppingMallDetailSettlementScreen shoppingMall={shoppingMall} />
  );
}

const InnerShoppingMallDetailSettlementScreen = observer(
  InnerShoppingMallDetailSettlementScreen_
);

function InnerShoppingMallDetailSettlementScreen_({
  shoppingMall,
}: {
  shoppingMall: ShoppingMall;
}) {
  const oms = useOMSStore();
  const settlement = oms.getStore(SettlementStore);
  const store = oms.getStore(SalesChannelSettlementScreenStore, settlement);

  useEffect(() => {
    oms.loading.batch(() => store.ensureOrders(store.range));
  }, [oms.loading, store]);

  const [range, setRange] = useState<DateRange>(store.range);
  const handleRangeChange = async (range: DateRange) => {
    setRange(range);
    await oms.loading.batch(() => store.ensureOrders(range));
  };

  const shoppingMallId = shoppingMall._id;
  const shoppingMallName = shoppingMall.name;

  const columns = createSalesChannelSettlementColumns(
    oms.user.excelHeaderKeys,
    oms.user.excelColumnMapping
  );
  const headerKeys = columns.map((column) => column.key);
  const [search, setSearch, filterSearch] = useSearchState({
    keys: headerKeys,
  });
  const handleSearchChange = (search: string) => {
    setSearch(search);
    amplitude.track('Search settlement-supplier-detail', {
      Term: search,
    });
  };

  const orders = store
    .getOrders(range)
    .filter((order) => order.shoppingMallId === shoppingMallId);
  const filteredOrders = filterSearch(orders);
  const sortedOrders = sortBy(filteredOrders, ['productName', 'option']);
  const totalPrice = sum(sortedOrders.map((order) => order.totalSellingPrice));

  const handleDownload = () => {
    downloadSalesChannelSettlementExcel(
      oms,
      shoppingMallName,
      range,
      sortedOrders
    );
  };

  return (
    <div className="px-40px py-20px gap-32px flex min-h-full flex-col bg-gray-100">
      <div className="gap-20px flex items-center">
        <BackLink to="/settlement/sales-channel" />
        <BrandLogoByName name={shoppingMallName} />
        <p className="text-extrabold-l text-gray-700">{shoppingMallName}</p>
      </div>
      <div className="gap-24px p-20px rounded-8px shadow-line flex flex-col bg-white">
        <div className="gap-24px flex items-center">
          <SettlementRangeSelect value={range} onChange={handleRangeChange} />
          <Divider type="vertical" />
          <TotalPrice type="supplier" value={totalPrice} />
        </div>
        <Divider type="horizontal" />
        <div className="gap-20px flex flex-col">
          <div className="flex justify-between">
            <div className="gap-20px flex items-center">
              <div className="gap-4px flex items-center justify-center">
                <p className="text-medium-s text-gray-500">발주 내역</p>
                <p className="text-medium-s text-gray-500">
                  {'('}
                  <span className="text-semibold-s text-blue-600">
                    {sortedOrders.length}
                  </span>
                  {'건)'}
                </p>
              </div>
            </div>
            <div className="gap-20px flex">
              <SearchInput
                value={search}
                onChange={handleSearchChange}
                className="w-400px"
                placeholder="검색어를 입력하세요."
              />
              <Button
                color="gray"
                rightAccessory={<IconControlDownload />}
                onClick={handleDownload}
              >
                엑셀 파일
              </Button>
            </div>
          </div>
          <SalesChannelSettlementTable
            key={JSON.stringify(range)}
            orders={sortedOrders}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(ShoppingMallDetailSettlementScreen);
