import { z } from 'zod';
import { c } from '../internal/contract';
import { DispatchedOrderSchema } from '../type/order/DispatchedOrder';
import {
  NormalizedOrder,
  NormalizedOrderSchema,
  Order,
} from '../type/order/Order';

export const orderRouterContract = c.router({
  findProcessedNormalizedOrdersByDateRange: {
    method: 'GET',
    path: '/order/normalized/processed/search',
    responses: {
      200: z.object({
        normalizedOrders: z.array(NormalizedOrderSchema),
      }),
    },
    query: z.object({
      from: z.string(),
      to: z.string(),
    }),
  },
  searchDispatchedOrders: {
    method: 'GET',
    path: '/order/dispatched/search',
    responses: {
      200: z.object({
        dispatchedOrders: z.array(DispatchedOrderSchema),
      }),
    },
    query: z.object({
      shoppingMalls: z.array(z.string()).optional(),
      keyword: z.string().optional(),
      orderStatus: z
        .enum([
          'paymentComplete',
          'productPrepare',
          'inDelivery',
          'deliveryComplete',
          'cancelRequest',
          'cancelComplete',
          'etc',
        ])
        .optional(),
      startDate: z.string(),
      endDate: z.string(),
    }),
  },
});

export type FindProcessedNormalizedOrdersByDateRange =
  typeof orderRouterContract.findProcessedNormalizedOrdersByDateRange;
export type FindProcessedNormalizedOrdersByDateRangeResponse = z.infer<
  FindProcessedNormalizedOrdersByDateRange['responses'][200]
>;

type SearchDispatchedOrders = typeof orderRouterContract.searchDispatchedOrders;
export type SearchDispatchedOrdersQuery = z.infer<
  SearchDispatchedOrders['query']
>;
export type SearchDispatchedOrdersResponse = z.infer<
  SearchDispatchedOrders['responses'][200]
>;
export type SearchOrderStatus = NonNullable<
  SearchDispatchedOrdersQuery['orderStatus']
>;

const ORDER_URL = '/order';

// GET /order
export const GET_ORDERS_URL = ORDER_URL;
export type GetOrdersResponse = Order[];

// GET /order/normalized
export const GET_NORMALIZED_ORDERS_URL = `${ORDER_URL}/normalized`;
export type GetNormalizedOrdersResponse = NormalizedOrder[];

// PUT /order
export const UPSERT_ORDERS_URL = `${ORDER_URL}`;
export type UpsertOrdersBody = {
  orders: Order[];
};
export type UpsertOrdersResponse = Order[];

// PUT /order/normalized
export const UPSERT_NORMALIZED_ORDERS_URL = `${ORDER_URL}/normalized`;

export type UpsertNormalizedOrdersBody = {
  orders: NormalizedOrder[];
};

// PUT /order/replace
export const REPLACE_ORDERS_URL = `${ORDER_URL}/replace`;

export type ReplaceOrdersBody = {
  orders: Order[];
};

// PUT /order/normalized/replace
export const REPLACE_NORMALIZED_ORDERS_URL = `${ORDER_URL}/normalized/replace`;

export type ReplaceNormalizedOrdersBody = {
  orders: NormalizedOrder[];
};

// PUT /order/all/replace
export const REPLACE_ALL_ORDERS_URL = `${ORDER_URL}/all/replace`;

export type ReplaceAllOrdersBody = {
  orders: Order[];
  normalizedOrders: NormalizedOrder[];
};
