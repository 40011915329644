import { convertFormulaeTo2DArrayWithTrim } from '@sweep/spreadsheet/utils';
import { parseSpreadsheetGuest } from 'src/network/spreadsheet';
import { getFileExtension } from '../getFileExtension';
import { getFilename } from '../getFilename';
import { ExcelFile } from '../interface';

export async function readExcelGuest(file: File): Promise<ExcelFile | null> {
  const response = await parseSpreadsheetGuest({
    file,
  });

  if (response.success === false) {
    return null;
  }
  const filename = getFilename(file);
  const extension = getFileExtension(file) ?? '';

  return {
    data: convertFormulaeTo2DArrayWithTrim(response.data.sheets[0].data),
    name: filename,
    extension,
  };
}
