import { z } from 'zod';
import { ExcelModelSchema } from './ExcelModel';

const ExcelMemoSchema = z.object({
  text: z.string(),
  font: z
    .object({
      bold: z.boolean().optional(),
    })
    .optional(),
});
export type ExcelMemo = z.infer<typeof ExcelMemoSchema>;

const OrderProductSchema = z.object({
  productId: z.string(),
  productName: z.string(),
  unit: z.string().nullish(),
  quantity: z.number(),
  supplierId: z.string().optional(),
});
export type OrderProduct = z.infer<typeof OrderProductSchema>;

export const BaseOrderSchema = z
  .object({
    uniqueCode: z.string(),
    uniqueCodeAfterCustomization: z.string().nullish(),

    productName: z.string().nullish(),
    option: z.string().nullish(),
    optionCode: z.string().nullish(),
    quantity: z.union([z.string(), z.number()]).nullish(),

    shippingPrice: z.union([z.string(), z.number()]).nullish(),
    price: z.union([z.string(), z.number()]).nullish(),
    sellingPrice: z.number().nullish(),
    paymentPrice: z.number().nullish(),
    settlementPrice: z.number().nullish(),

    supplierShippingPrice: z.number().nullish(),
    supplierPrice: z.number().nullish(),

    name: z.string().nullish(),
    contactNumber: z.string().nullish(),
    telephoneNumber: z.string().nullish(),

    address: z.string().nullish(),
    postCode: z.string().nullish(),
    deliveryMessage: z.string().nullish(),
    shippingCompany: z.string().nullish(),
    shippingNumber: z.string().nullish(),

    note: z.string().nullish(),

    // NOTE(@이지원): 주소 검증 관련 필드
    isAddressValid: z
      .enum(['okay', 'fixed', 'multiple', 'postcode', 'fail'])
      .optional(),
    mainAddress: z.string().nullish(),
    detailAddress: z.string().nullish(),
    memo: z.array(ExcelMemoSchema).nullish(),

    isCombinedOrder: z.boolean().nullish(),

    // NOTE(@이지원): plugin을 거치기 전, 기존 데이터를 저장하는 필드
    origName: z.string().nullish(),
    origOption: z.string().nullish(),
    origQuantity: z.union([z.string(), z.number()]).nullish(),

    originFile: z.string().nullish(),
    originFilIndex: z.number().nullish(),
    purchaseOrderFile: z.string().nullish(),

    partnerId: z.string().nullish(),
    matchingId: z.string().nullish(),

    supplierId: z.string().nullish(),
    supplierName: z.string().nullish(),

    excelModel: ExcelModelSchema.nullish(), // ExcelModel type needs to be defined

    data: z.array(OrderProductSchema).optional(),
    plugin: z.record(z.string(), z.unknown()).optional(),

    registeredAt: z.number().nullish(),
    updatedAt: z.number().nullish(),
  })
  .passthrough();

export type BaseOrder = z.infer<typeof BaseOrderSchema>;
