import { overlay } from 'overlay-kit';
import { IconAlert } from '@sweep/asset/icons';
import { Dialog } from '@sweep/sds';

export function openSkipMatchingDialog(skipCount: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <Dialog
        open={isOpen}
        onClose={() => {
          close();
          unmount();
        }}
      >
        <Dialog.Title className="mb-16px gap-8px flex items-center">
          <IconAlert className="text-blue-500" />
          상품 매칭을 하지 않고 넘어갈까요?
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          매칭을 건너뛰면 <span className="text-blue-500">{skipCount}개</span>의
          매칭이 전부 반영되지 않습니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            돌아가기
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>건너뛰기</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
