import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { createDebug } from 'src/third-parties/createDebug';

const debug = createDebug('partnership-purchase-order');

function PartnershipScreen() {
  const navigate = useNavigate();
  const oms = useOMSStore();

  useEffect(() => {
    debug('useEffect');
    // 처음 접근하면 가장 첫번째 partnership 을 선택시켜준다.
    const selectedPartnershipUUID =
      oms.retailerOrderTransaction.selectedPartnershipUUID;

    if (selectedPartnershipUUID == null) {
      const firstPartnership = oms.partnership.partnerships
        .values()
        .next().value;

      if (firstPartnership != null) {
        oms.retailerOrderTransaction.selectedPartnershipUUID =
          firstPartnership.partnershipUUID;
        navigate(
          `/partnership/${firstPartnership.partnershipUUID}/purchase-order`
        );
      }
    }
  }, [navigate, oms]);

  return <div className="relative w-full min-w-[1200px] text-[#03183A]"></div>;
}

export default observer(PartnershipScreen);
