import {
  isAliExpressUniqueCode,
  isDispatchedOrder,
  isHidden,
  isKakaoUniqueCode,
  NormalizedAddress,
  Order,
} from '@sweep/contract';
import { normalizeAddresses } from 'src/network/address';
import { checkAddresses, toCheckedAddressResult } from 'src/network/fastapi';
import { AbstractPlugin } from '../../interface';

interface CheckAddressPluginConfig {
  fixInvalidAddress?: boolean;
}

export class CheckAddressPlugin extends AbstractPlugin<
  CheckAddressPluginConfig | undefined
> {
  transform = async (orders: Order[]): Promise<Order[]> => {
    const shouldCheckOrderIndexes =
      CheckAddressPlugin.findShouldCheckOrderIndexes(orders);

    const shouldCheckOrders = shouldCheckOrderIndexes.map(
      (index) => orders[index]
    );

    if (shouldCheckOrders.length === 0) {
      return orders;
    }

    let normalizedAddress: NormalizedAddress[] = [];
    const isNormalizeAddress = this.oms.user.deploy?.normalizeAddress === true;
    if (isNormalizeAddress) {
      const response = await normalizeAddresses(shouldCheckOrders);
      if (response.status !== 200) {
        return orders;
      }

      normalizedAddress = response.body;
    } else {
      const response = await checkAddresses(shouldCheckOrders);
      const isFailed =
        response == null ||
        response.success === false ||
        response.data.length !== shouldCheckOrders.length;

      if (isFailed) {
        return orders;
      }

      normalizedAddress = response.data;
    }

    const newOrders = [...orders];

    shouldCheckOrderIndexes.forEach((originalIndex, arrayIndex) => {
      newOrders[originalIndex] = CheckAddressPlugin.fixOrderAddress(
        orders[originalIndex],
        normalizedAddress[arrayIndex],
        this.config
      );
    });

    return newOrders;
  };

  static fixOrderAddress(
    order: Order,
    checkedAddress: NormalizedAddress,
    config?: CheckAddressPluginConfig
  ) {
    const result = toCheckedAddressResult(checkedAddress, config);

    return {
      ...order,
      ...result,
    };
  }

  static findShouldCheckOrderIndexes(orders: Order[]): number[] {
    const indexes: number[] = [];
    orders.forEach((order, index) => {
      if (!isDispatchedOrder(order) || isHidden(order.orderStatus)) {
        return;
      }

      const isAliExpressOrder = isAliExpressUniqueCode(order.uniqueCode);
      const isKakaoOrder = isKakaoUniqueCode(order.uniqueCode);
      if (isAliExpressOrder || isKakaoOrder) {
        indexes.push(index);
      }
    });

    return indexes;
  }
}
