import { ExcelFileRecord, UserSetting } from '@sweep/contract';
import { createUserCustomizedSettings } from '@sweep/domain/services/purchase-order-file';
import { Partner } from 'src/models/Partner';
import { createOrderExcelBuffer } from 'src/services/file/excel/create/createOrderExcelBuffer';
import { OMSStore } from 'src/stores/OMSStore';
import { addMissingRequiredColumns } from './addMissingRequiredColumns';
import { AdvancedShippingNoticeDocument } from './createAdvancedShippingNoticeDocuments';
import { getFilename } from './getFilename';

export async function createExcelFromAdvancedShippingNoticeDocumentForMall(
  AdvancedShippingNoticeDocument: AdvancedShippingNoticeDocument,
  partners: Partner[],
  oms: OMSStore
): Promise<
  | {
      isSuccess: true;
      buffer: Buffer;
      filename: string;
    }
  | {
      isSuccess: false;
      failedReason: string;
    }
> {
  // const firstSampledOrder = AdvancedShippingNoticeDocument.orders[0];

  try {
    const userExcelSetting = await oms.user.setting;

    const userColumnKeys = userExcelSetting?.columnOrder ?? [];
    const userColumnMapping = userExcelSetting?.columnTranslation ?? {};
    const userColumnNames =
      userExcelSetting?.columnOrder.map(
        (key) => userColumnMapping?.[key] || key
      ) ?? [];

    const filename = getFilename(AdvancedShippingNoticeDocument.documentName);
    const { columnNames, columnMapping } = addMissingRequiredColumns(
      userColumnKeys,
      userColumnNames,
      userColumnMapping
    );

    const separateAddressEnabled =
      userExcelSetting?.preprocessSettings?.separateAddress?.enabled ?? false;

    const userSetting = userExcelSetting as UserSetting;
    const userExcelSettingFields = createUserCustomizedSettings(userSetting);

    const excelFileRecord: ExcelFileRecord = {
      filename,
      fileExtension: 'xlsx',
      orders: AdvancedShippingNoticeDocument.orders,
      headers: columnNames,
      headerKeys: userColumnKeys,
      columnMappings: columnMapping as Record<string, string>,
      separateAddressEnabled,
      userExcelSettingFields,
    };

    const buffer = await createOrderExcelBuffer(excelFileRecord);

    return {
      isSuccess: true,
      buffer: buffer as Buffer,
      filename,
    };
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Unknown error';
    return {
      isSuccess: false,
      failedReason: errorMessage,
    };
  }
}
