import _ from 'lodash';
import {
  FulfilledOrderResult,
  FulfillOrder,
  isDispatchedOrder,
  NEXT_STEP_FLAG,
  Order,
  ORDER_SHIPPING_STATUS,
  OrderStatus,
} from '@sweep/contract';
import { isEmptyShippingInvoice } from '@sweep/domain/services/order';
import { isNotNil, partition } from '@sweep/utils';
import { fulfillOrder } from 'src/network/order/fullfillOrder';
import {
  CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
  CANCEL_REQUESTED_ORDER_HEADERS,
} from 'src/screens/dispatch/constants';
import { createOrderExcel } from 'src/services/file/excel/create';
import { toast } from 'src/third-parties/toast';
import {
  extractEnglishShippingHeaders,
  mapKeysByColumnMapping,
  removeEndNulls,
} from '../utils/headerColumnMapping';
import { shoppingMallTemps } from '../utils/mappingArrays';
import { readExcelFile } from '../utils/readExcelFile';
import { areArraysEqual, areMutualSubsequences, isValid } from '../utils/utils';
import { detectEnglishByKoreanHeader } from './fileHandling/utils/fileHandlePartnerUtils';
import { useOMSStore } from './useOMSStore';

interface ShippingInvoice {
  shippingCompany: string;
  shippingNumber: string;
}

const useShippingUpload = () => {
  const oms = useOMSStore();

  const extractShippingInfo = async (files: File[]) => {
    let orders: { [key: string]: string | number | undefined }[] = [];

    for (const file of files) {
      let rows = [];

      const readExcelSettings = {
        isOrderFile: false,
      };
      rows = await readExcelFile(file, readExcelSettings);

      let koreanHeaderRow = removeEndNulls(rows[0]) || [];
      let englishHeaderRow: (string | null)[] =
        extractEnglishShippingHeaders(koreanHeaderRow) || [];

      const userCustomExcelFormat = [...oms.user.excelHeaderKeys];

      const shippingExcelTemplate =
        oms.user.setting?.shippingExcelTemplate || {};

      if (
        _.isEqual(
          koreanHeaderRow,
          userCustomExcelFormat.map(
            (english) => oms.user.excelColumnMapping[english]
          )
        )
      ) {
        englishHeaderRow = userCustomExcelFormat;
      } else if (
        isValid(shippingExcelTemplate) &&
        areMutualSubsequences(koreanHeaderRow, shippingExcelTemplate.headers)
      ) {
        englishHeaderRow = mapKeysByColumnMapping(
          koreanHeaderRow,
          shippingExcelTemplate.headerMatchingMap
        );
      }

      for (const partnerInfo of oms.partner.partners) {
        const temp = detectEnglishByKoreanHeader(koreanHeaderRow, partnerInfo);
        if (temp) {
          englishHeaderRow = temp;
          break;
        }
      }

      for (const shoppingMallTemp of shoppingMallTemps) {
        if (areArraysEqual(koreanHeaderRow, shoppingMallTemp.headers)) {
          if (['랭킹닭컴', '웰스토리'].includes(shoppingMallTemp.mallName)) {
            rows.splice(1, 1);
            koreanHeaderRow = shoppingMallTemp.realHeaders || [];
          }

          englishHeaderRow = mapKeysByColumnMapping(
            koreanHeaderRow,
            shoppingMallTemp.headerTranslationMap
          );
        }
      }

      const newOrders = rows.slice(1).map((row: string[]) => {
        const entry: { [key: string]: string | number } = {};
        row.forEach((cell, i) => {
          const key = englishHeaderRow[i];
          if (key && isValid(cell)) {
            entry[key] = cell;
          }

          const defaultShippingCompany: string =
            oms.user.setting?.defaultShippingCompany || '';

          if (isValid(defaultShippingCompany)) {
            entry['shippingCompany'] = defaultShippingCompany;
          }
        });

        return entry;
      });

      orders = [...orders, ...newOrders];
    }

    return orders;
  };

  const mallUploadShippingInfo = async (files: File[]) => {
    // extractOrdersFromFiles
    let shippingInfos = await extractShippingInfo(files);

    // shippingInfos[0]의 key 중에 shippingNumber, shippingCompany, uniqueCode가 없으면 return
    if (
      shippingInfos.length === 0 ||
      !shippingInfos[0].shippingNumber ||
      !shippingInfos[0].shippingCompany ||
      (!shippingInfos[0].uniqueCode && !shippingInfos[0].orderNumber)
    ) {
      toast.error(
        '운송장번호, 택배사, 스윕고유번호 또는 주문번호가 포함된 엑셀 파일을 업로드해주세요.'
      );
      return;
    }
    shippingInfos = shippingInfos.flatMap((info: any) => {
      if (!info.uniqueCode && !info.orderNumber) {
        return [];
      }
      if (isValid(info.uniqueCode)) {
        return info.uniqueCode.split(',').map((uniqueCode: string) => ({
          ...info,
          uniqueCode,
          shippingNumber: info.shippingNumber?.toString()?.split(',')?.[0],
        }));
      } else {
        return info;
      }
    });

    //uniqueCode가 중복되는 경우 제거
    shippingInfos = shippingInfos.filter(
      (info: any, index: number, self: any[]) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.uniqueCode === info.uniqueCode &&
            t.orderNumber === info.orderNumber
        )
    );

    await updateAndHandleShippingInfo(shippingInfos);
  };

  const mallUploadShippingInfoManually = async (shippingInfos: any[]) => {
    // shippingNumber 내용 없으면 return
    if (!shippingInfos[0].shippingNumber) {
      toast.error('운송장번호를 입력해주세요.');
      return {
        successOrders: [],
        failedOrders: [],
      };
    }

    const { successOrders, failedOrders } =
      await updateAndHandleShippingInfo(shippingInfos);

    return { successOrders, failedOrders };
  };

  const updateAndHandleShippingInfo = async (shippingInfos: any[]) => {
    try {
      const { successOrders, failedOrders } =
        await updateShippingInfoToMall(shippingInfos);

      await handleShippingInfoResponse(successOrders, failedOrders);

      return { successOrders, failedOrders };
    } catch (error) {
      console.error('Error updating shipping info:', error);
      toast.error('업로드 중 오류가 발생했습니다.');
      return {
        successOrders: [],
        failedOrders: [],
      };
    }
  };

  const handleShippingInfoResponse = async (
    successOrders: FulfilledOrderResult[],
    failedOrders: FulfilledOrderResult[]
  ) => {
    if (isValid(failedOrders)) {
      const date = new Date();
      const year = String(date.getFullYear()).slice(-2);
      const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(date.getDate()).padStart(2, '0')}`;

      await createOrderExcel(
        oms,
        failedOrders as unknown as Order[],
        `${formattedDate} 운송장 등록 실패 건`,
        ['실패 사유', ...CANCEL_REQUESTED_ORDER_HEADERS],
        { failReason: '실패 사유', ...CANCEL_REQUESTED_ORDER_COLUMN_MAPPING }
      );
    }

    const composedOrders = [...successOrders, ...failedOrders];

    const ordersWithUpdateRequiredFlags = composedOrders.filter(
      (order) => order.nextStepFlag === NEXT_STEP_FLAG.isUpdateRequired
    );

    if (ordersWithUpdateRequiredFlags.length > 0) {
      // handle V2
      const { shippingInvoiceByUniqueCode, shippingInvoiceByOrderNumber } =
        getShippingInvoiceMap(ordersWithUpdateRequiredFlags);

      const updatedDispatchedOrders = oms.order.dispatchedOrders.map(
        (order) => {
          const shippingInvoice =
            shippingInvoiceByUniqueCode.get(order.uniqueCode) ??
            (order.orderNumber != null
              ? shippingInvoiceByOrderNumber.get(order.orderNumber)
              : null);

          if (shippingInvoice == null) {
            return order;
          }

          if (
            shippingInvoice.nextStepFlag === NEXT_STEP_FLAG.isUpdateRequired
          ) {
            const orderStatus =
              shippingInvoice.orderStatus ?? order.orderStatus;

            return {
              ...order,
              shippingCompany: shippingInvoice.shippingCompany,
              shippingNumber: shippingInvoice.shippingNumber,
              orderStatus,
            };
          }

          return order;
        }
      );

      oms.order.dispatch.setDispatchedOrders(updatedDispatchedOrders);

      await syncWithOrderProcess({
        shippingInvoiceByUniqueCode,
        shippingInvoiceByOrderNumber,
      });

      return;
    }

    if (successOrders.length === 0) {
      return;
    }

    // NOTE(@형준): deprecated, 주문 수집이 nextStepFlag 사용하도록 전체 변경되면 제거한다. - begin
    const { shippingInvoiceByUniqueCode, shippingInvoiceByOrderNumber } =
      getShippingInvoiceMap(successOrders);

    const updatedDispatchedOrders = oms.order.dispatchedOrders.map((order) => {
      const shippingInvoice =
        shippingInvoiceByUniqueCode.get(order.uniqueCode) ??
        (order.orderNumber != null
          ? shippingInvoiceByOrderNumber.get(order.orderNumber)
          : null);

      if (shippingInvoice == null) {
        return order;
      }

      if (shippingInvoice.nextStepFlag == null) {
        return {
          ...order,
          shippingCompany: shippingInvoice.shippingCompany,
          shippingNumber: shippingInvoice.shippingNumber,
          orderStatus: OrderStatus.inDelivery,
        };
      }

      if (shippingInvoice.nextStepFlag === NEXT_STEP_FLAG.isUpdateRequired) {
        const orderStatus = shippingInvoice.orderStatus ?? order.orderStatus;

        return {
          ...order,
          shippingCompany: shippingInvoice.shippingCompany,
          shippingNumber: shippingInvoice.shippingNumber,
          orderStatus,
        };
      }

      return order;
    });

    oms.order.dispatch.setDispatchedOrders(updatedDispatchedOrders);

    await syncWithOrderProcess({
      shippingInvoiceByUniqueCode,
      shippingInvoiceByOrderNumber,
    });
    // NOTE(@형준): deprecated, 주문 수집이 nextStepFlag 사용하도록 전체 변경되면 제거한다. - end
  };

  const getShippingInvoiceMap = (fulfilledOrders: FulfilledOrderResult[]) => {
    const { shippingInvoiceByUniqueCode, shippingInvoiceByOrderNumber } =
      fulfilledOrders.reduce(
        (acc, order) => {
          const shippingInvoice: FulfillOrder = {
            shippingCompany: order.shippingCompany,
            shippingNumber: order.shippingNumber,
            uniqueCode: order.uniqueCode,
            orderStatus: order.orderStatus,
            nextStepFlag: order.nextStepFlag,
          };

          if (order.uniqueCode != null) {
            acc.shippingInvoiceByUniqueCode.set(
              order.uniqueCode,
              shippingInvoice
            );
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          if (order.orderNumber != null) {
            acc.shippingInvoiceByOrderNumber.set(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              order.orderNumber,
              shippingInvoice
            );
          }
          return acc;
        },
        {
          shippingInvoiceByUniqueCode: new Map<string, FulfillOrder>(),
          shippingInvoiceByOrderNumber: new Map<string, FulfillOrder>(),
        }
      );

    return { shippingInvoiceByUniqueCode, shippingInvoiceByOrderNumber };
  };

  const syncWithOrderProcess = async ({
    shippingInvoiceByUniqueCode,
    shippingInvoiceByOrderNumber,
  }: {
    shippingInvoiceByUniqueCode: Map<string, FulfillOrder>;
    shippingInvoiceByOrderNumber: Map<string, FulfillOrder>;
  }) => {
    const updatedOrders = oms.order.mergedOrders
      .map((order) => {
        const shippingInvoice = getShippingInvoice(order, {
          shippingInvoiceByUniqueCode,
          shippingInvoiceByOrderNumber,
        });

        const isUpdateRequired =
          shippingInvoice != null &&
          isEmptyShippingInvoice(order) &&
          shippingInvoice.nextStepFlag === NEXT_STEP_FLAG.isUpdateRequired;

        if (isUpdateRequired) {
          return {
            ...order,
            shippingCompany: shippingInvoice.shippingCompany,
            shippingNumber: shippingInvoice.shippingNumber,
            orderStatus: shippingInvoice.orderStatus ?? order.orderStatus,
          };
        }

        return null;
      })
      .filter(isNotNil);

    const updatedNormalizedOrders = oms.order.normalizedOrders
      .map((order) => {
        const shippingInvoice = getShippingInvoice(order, {
          shippingInvoiceByUniqueCode,
          shippingInvoiceByOrderNumber,
        });

        const isUpdateRequired =
          shippingInvoice != null &&
          isEmptyShippingInvoice(order) &&
          shippingInvoice.nextStepFlag === NEXT_STEP_FLAG.isUpdateRequired;

        const isShippingStatusShipped =
          shippingInvoice?.orderStatus === OrderStatus.inDelivery;

        if (isShippingStatusShipped) {
          return {
            ...order,
            shippingCompany: shippingInvoice.shippingCompany,
            shippingNumber: shippingInvoice.shippingNumber,
            orderStatus: shippingInvoice.orderStatus ?? order.orderStatus,
            shippingStatus: ORDER_SHIPPING_STATUS.shipped,
          };
        }

        if (isUpdateRequired) {
          return {
            ...order,
            shippingCompany: shippingInvoice.shippingCompany,
            shippingNumber: shippingInvoice.shippingNumber,
            orderStatus: shippingInvoice.orderStatus ?? order.orderStatus,
          };
        }

        return null;
      })
      .filter(isNotNil);

    await Promise.all([
      oms.order.upsertMergedOrders(updatedOrders),
      oms.order.upsertNormalizedOrders(updatedNormalizedOrders),
    ]);
  };

  const getShippingInvoice = (
    order: Order,
    shippingInvoices: {
      shippingInvoiceByUniqueCode: Map<string, FulfillOrder>;
      shippingInvoiceByOrderNumber: Map<string, FulfillOrder>;
    }
  ) => {
    const { shippingInvoiceByUniqueCode, shippingInvoiceByOrderNumber } =
      shippingInvoices;

    const uniqueCodes = order.uniqueCode?.split(',') ?? [];
    for (const uniqueCode of uniqueCodes) {
      if (shippingInvoiceByUniqueCode.has(uniqueCode)) {
        return shippingInvoiceByUniqueCode.get(uniqueCode);
      }
    }

    if (
      isDispatchedOrder(order) &&
      order.orderNumber != null &&
      shippingInvoiceByOrderNumber.has(order.orderNumber)
    ) {
      return shippingInvoiceByOrderNumber.get(order.orderNumber);
    }

    return null;
  };

  const updateShippingInfoToMall = async (orders: FulfillOrder[]) => {
    orders = orders.filter((order) => Object.keys(order).length > 0);
    const response = await fulfillOrder({ orders });

    const isSuccess = response.status === 200 && response.body.success;
    if (isSuccess) {
      const responseOrders = response.body.data;
      const [successOrders, failedOrders] = partition(
        responseOrders,
        (order) => order.success === true
      );

      const isPartialErrors = failedOrders.length > 0;
      if (isPartialErrors) {
        toast.error(
          '운송장 등록에 실패한 주문 건들이 있습니다. 해당 건들의 실패 사유를 확인해 주세요.'
        );
      } else {
        toast.success('운송장이 등록되었습니다.');
      }

      return { successOrders, failedOrders };
    }

    const isValidationError =
      response.status === 400 && response.body.name === 'ZodError';
    if (isValidationError) {
      toast.error(
        '운송장번호, 택배사, 스윕고유번호 또는 주문번호가 포함된 엑셀 파일을 업로드해주세요.'
      );

      return { successOrders: [], failedOrders: [] };
    }

    toast.error('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
    return { successOrders: [], failedOrders: [] };
  };

  return {
    mallUploadShippingInfo,
    mallUploadShippingInfoManually,
    extractShippingInfo,
  };
};

export default useShippingUpload;
