import {
  DISPATCH_ORDER_POllING_URL,
  DispatchOrderPollingQuery,
  DispatchOrderPollingResponse,
  GET_SHOPPING_MALLS_URL,
  GetShoppingMallsResponse,
  SearchDispatchedOrdersQuery,
  SearchOrderStatus,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import { amplitude } from 'src/third-parties/amplitude';
import api, { APIResponse } from '../api';
import { client } from '../client';

export async function getShoppingMalls() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetShoppingMallsResponse>>(GET_SHOPPING_MALLS_URL)
  );

  return response?.data;
}

export type GetFetchOrdersRes =
  | {
      message: 'Success';
      result: {
        requestId: string;
      };
    }
  | {
      message: 'Failed';
      result: { err: string };
    };

export async function requestDispatchOrders(
  startTime: number,
  endTime: number,
  shoppingMall: string
) {
  const url = '/dispatch-order/fetch-orders';
  const response = await api.get<GetFetchOrdersRes>(url, {
    startTime,
    endTime,
    shoppingMall,
  });

  if (response?.data?.message === 'Success') {
    amplitude.track('Dispatch Order (Server)', { 'Store Name': shoppingMall });
  }

  return response?.data;
}

export async function getDispatchedOrdersPolling(
  query: DispatchOrderPollingQuery
) {
  const response = await api.get<APIResponse<DispatchOrderPollingResponse>>(
    DISPATCH_ORDER_POllING_URL,
    query
  );

  return response?.data;
}

export async function searchDispatchedOrders(
  query: SearchDispatchedOrdersQuery
) {
  const response = await client.order.searchDispatchedOrders({ query });

  if (response.status === 200) {
    amplitude.track('Fetch Order (Server)', {
      Store: query.shoppingMalls?.join(',') ?? 'all',
      Keyword: query.keyword,
      Period: `${query.startDate} ~ ${query.endDate}`,
      Status: toOrderStatusName(query.orderStatus),
    });
  }

  return response;
}

function toOrderStatusName(status?: SearchOrderStatus): string {
  if (status == null) {
    return '전체 주문';
  }

  switch (status) {
    case 'paymentComplete':
      return '결제 완료';
    case 'productPrepare':
      return '상품 준비중';
    case 'inDelivery':
      return '배송중';
    case 'deliveryComplete':
      return '배송 완료';
    case 'cancelRequest':
      return '취소 요청';
    case 'cancelComplete':
      return '취소 완료';
    case 'etc':
      return '기타';
  }
}
