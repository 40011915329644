import { UserCustomizedSettings, UserSetting } from '@sweep/contract';

export function createUserCustomizedSettings(
  userSetting: UserSetting
): UserCustomizedSettings {
  const userCustomizedSettings = userSetting.excel;

  if (userCustomizedSettings == null) {
    return {
      fields: [],
    };
  }

  return userCustomizedSettings;
}
