import { z } from 'zod';
import { DispatchedOrder } from '../../type/order/DispatchedOrder';
import { ShoppingMall } from '../../type/shopping-mall/ShoppingMall';

const DISPATCH_ORDER_URL = '/dispatch-order';

// GET /dispatch-order/fetch-orders
export const DISPATCH_ORDER_FETCH_ORDERS_URL = '/dispatch-order/fetch-orders';

export const dispatchOrderFetchOrdersQuerySchema = z.object({
  startTime: z.string(),
  endTime: z.string(),
  shoppingMall: z.string(),
});

export type DispatchOrderFetchOrdersQuery = z.infer<
  typeof dispatchOrderFetchOrdersQuerySchema
>;

// GET /dispatch-order/fetch-orders-polling
export const DISPATCH_ORDER_POllING_URL = `/dispatch-order/fetch-orders-polling`;

export const DispatchOrderPollingQuery = z.object({
  requestId: z.string(),
});
export type DispatchOrderPollingQuery = z.infer<
  typeof DispatchOrderPollingQuery
>;

export type DispatchOrderPollingResponse =
  | { status: 'ON_GOING' }
  | {
      status: 'FINISH';
      data: DispatchedOrder[];
    };

// GET /dispatch-order/shopping-malls
export const GET_SHOPPING_MALLS_URL = `${DISPATCH_ORDER_URL}/shopping-malls`;

export type GetShoppingMallsResponse = ShoppingMall[];
