import { z } from 'zod';
import { DispatchedOrder, OrderStatus } from '@sweep/contract';
import { NEXT_STEP_FLAG } from './nextStepFlag';

const CancelOrderSchema = z.object({
  uniqueCode: z.string(),
  shippingCompany: z.string().nullish(),
  shippingNumber: z.string().nullish(),
  sweepShippingCompany: z.string().nullish(),
  orderStatus: z.nativeEnum(OrderStatus).optional(),
  nextStepFlag: z
    .enum([NEXT_STEP_FLAG.isNoop, NEXT_STEP_FLAG.isUpdateRequired])
    .optional(),
});

export type CancelOrder = z.infer<typeof CancelOrderSchema>;

export const CancelOrderSuccessResultSchema = CancelOrderSchema.extend({
  success: z.literal(true),
});

export const CancelOrderFailResultSchema = CancelOrderSchema.extend({
  success: z.literal(false),
  failReason: z.string(),
});

export type CancelOrderSuccessResult = z.infer<
  typeof CancelOrderSuccessResultSchema
>;

export type CancelOrderFailResult = z.infer<typeof CancelOrderFailResultSchema>;

export type CancelOrderResult =
  | CancelOrderSuccessResult
  | CancelOrderFailResult;

/**
 * @deprecated
 */
export type CanceledOrderResult =
  | CancelOrderSuccessResult
  | CancelOrderFailResult;

// POST /cancel-order/accept-cancel
export const ACCEPT_CANCEL_ORDERS_URL = '/cancel-order/accept-cancel';
export interface AcceptCancelOrdersBody {
  orders: DispatchedOrder[];
}
export type AcceptCancelResponse = CanceledOrderResult[];

// POST /cancel-order/reject-cancel
export const REJECT_CANCEL_ORDERS_URL = '/cancel-order/reject-cancel';
export interface RejectCancelOrdersBody {
  orders: DispatchedOrder[];
  rejectReason: string;
}
export type RejectCancelResponse = CanceledOrderResult[];
