import { overlay } from 'overlay-kit';
import { useState } from 'react';
import { Button, Modal, TextInput } from '@sweep/sds';
import { isEmptyString, PHONE_NUMBER_REGEX } from '@sweep/utils';
import { preRegister } from 'src/network/user';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import { getUtmInfoString } from 'src/utils';

interface TrialExpiredRegisterModalProps {
  open: boolean;
  onClose: () => void;
}

function TrialExpiredRegisterModal({
  open,
  onClose,
}: TrialExpiredRegisterModalProps) {
  const utmInfo = getUtmInfoString();

  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const disabled =
    isEmptyString(companyName) ||
    isEmptyString(name) ||
    isEmptyString(phoneNumber);

  const handleSubmit = async () => {
    if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      toast.error('올바른 전화번호 형식으로 입력해주세요.');
      return;
    }

    amplitude.track('Submit inquiry (Server)', {
      CompanyName: companyName,
      Name: name,
      PhoneNumber: phoneNumber,
      From: 'address',
      Section: 'address-trial-ended',
    });

    await preRegister({
      companyName,
      name,
      phoneNumber,
      from: '(알리) 주소 변환기' + utmInfo,
    });
    toast.success('성공적으로 신청되었습니다.');
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title className="mb-8px">
        바로 체험해보세요.
        <br />
        세팅은 단 3분이면 완료됩니다.
      </Modal.Title>
      <p className="text-semibold-m mb-24px text-gray-500">
        이 화면에서 문의하시면{' '}
        <span className="text-semibold-m text-blue-500">무료 체험 2주</span>가{' '}
        추가로 제공됩니다.
      </p>
      <div className="gap-24px p-40px rounded-8px flex-center flex flex-col bg-gray-100">
        <div className="gap-12px flex flex-col">
          <div className="gap-12px flex items-center">
            <p className="text-semibold-s w-38px text-gray-500">회사명</p>
            <TextInput
              placeholder="스윕컴퍼니"
              className="w-200px"
              value={companyName}
              onChange={setCompanyName}
            />
          </div>
          <div className="gap-12px flex items-center">
            <p className="text-semibold-s w-38px text-gray-500">성함</p>
            <TextInput
              placeholder="김스윕"
              className="w-200px"
              value={name}
              onChange={setName}
            />
          </div>
          <div className="gap-12px flex items-center">
            <p className="text-semibold-s w-38px text-gray-500">연락처</p>
            <TextInput
              placeholder="010-0000-0000"
              className="w-200px"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>
        </div>
      </div>
      <div className="flex-center pt-20px flex bg-white">
        <Button className="w-240px" onClick={handleSubmit} disabled={disabled}>
          신청하고 무료 체험 하기
        </Button>
      </div>
    </Modal>
  );
}

export function openTrialExpiredRegisterModal() {
  return overlay.open(({ isOpen, close, unmount }) => (
    <TrialExpiredRegisterModal
      open={isOpen}
      onClose={() => {
        close();
        unmount();
      }}
    />
  ));
}
