import { NormalizedOrder } from '@sweep/contract';
import { DateRange, formatDate } from '@sweep/utils';
import { createOrderExcel } from 'src/services/file/excel/create';
import { OMSStore } from 'src/stores/OMSStore';
import { SettlementOrder } from '../interface';
import { createSupplierSettlementColumns } from './createSupplierSettlementColumns';
import { getSupplierTotalPrice } from './getSupplierTotalPrice';

export const downloadSupplierSettlementExcel = (
  oms: OMSStore,
  supplierName: string,
  range: DateRange,
  orders: SettlementOrder[]
) => {
  const {
    totalQuantity,
    totalSupplierPrice,
    totalSupplierShippingPrice,
    totalPrice,
  } = getSupplierTotalPrice(orders);

  const columns = createSupplierSettlementColumns(
    oms.user.excelHeaderKeys,
    oms.user.excelColumnMapping
  );
  const headers = columns.map((column) => column.header);
  const columnMapping = Object.fromEntries(
    columns.map((column) => [column.key, column.header])
  );

  const from = formatDate(range.from, 'yyyy-MM-dd');
  const to = formatDate(range.to, 'yyyy-MM-dd');
  const date = from === to ? from : `${from}_${to}`;

  createOrderExcel(
    oms,
    [
      ...orders,
      {
        supplyOrderDate: '총 합계',
        quantity: totalQuantity,
        supplierPrice: totalSupplierPrice,
        supplierShippingPrice: totalSupplierShippingPrice,
        totalPrice,
      } as unknown as NormalizedOrder,
    ],
    `${date} ${supplierName} 정산내역`,
    headers,
    columnMapping
  );
};
