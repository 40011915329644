import { useCallback, useMemo, useState } from 'react';

interface ColumnWidthState {
  cell?: number;
  header?: number;
  footer?: number;
  adjusted?: number | null;
}

const INITIAL_MAX_WIDTH = 400;

export interface UseColumnWidthReturn {
  isHeaderMounted: boolean;
  isCellMounted: boolean;
  isFooterMounted: boolean;
  columnWidths: Record<number, number | null>;
  onWidthChange: (index: number, width: number) => void;
  onAdjustedWidthChange: (index: number, width: number) => void;
  onHeaderWidthChange: (index: number, width: number) => void;
  onFooterWidthChange: (index: number, width: number) => void;
  onFitWidth: (index: number) => void;
}

export function useColumnWidth(): UseColumnWidthReturn {
  const [widthStates, setWidthStates] = useState<
    Record<number, ColumnWidthState>
  >({});

  const isHeaderMounted = useMemo(() => {
    return Object.values(widthStates).some((state) => state.header != null);
  }, [widthStates]);

  const isCellMounted = useMemo(() => {
    return Object.values(widthStates).some((state) => state.cell != null);
  }, [widthStates]);

  const isFooterMounted = useMemo(() => {
    return Object.values(widthStates).some((state) => state.footer != null);
  }, [widthStates]);

  const columnWidths = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(widthStates).map((index) => [
          index,
          getColumnWidth(widthStates, Number(index)),
        ])
      ),
    [widthStates]
  );

  const handleWidthChange = useCallback((index: number, width: number) => {
    setWidthStates((prev) => {
      const currentCell = prev[index]?.cell ?? 0;
      if (width <= currentCell) {
        return prev;
      }

      return {
        ...prev,
        [index]: {
          ...prev[index],
          cell: width,
        },
      };
    });
  }, []);

  const handleHeaderWidthChange = useCallback(
    (index: number, width: number) => {
      setWidthStates((prev) => {
        if (prev[index]?.header === width) {
          return prev;
        }

        return {
          ...prev,
          [index]: {
            ...prev[index],
            header: width,
          },
        };
      });
    },
    []
  );

  const handleAdjustedWidthChange = useCallback(
    (index: number, width: number | null) => {
      setWidthStates((prev) => {
        if (prev[index]?.adjusted === width) {
          return prev;
        }

        return {
          ...prev,
          [index]: {
            ...prev[index],
            adjusted: width,
          },
        };
      });
    },
    []
  );

  const handleFooterWidthChange = useCallback(
    (index: number, width: number) => {
      setWidthStates((prev) => {
        return { ...prev, [index]: { ...prev[index], footer: width } };
      });
    },
    []
  );

  const handleFitWidth = useCallback(
    (index: number) => {
      const naturalWidth = getRenderedWidth(widthStates, index);
      handleAdjustedWidthChange(index, naturalWidth);
    },
    [widthStates, handleAdjustedWidthChange]
  );

  return {
    isHeaderMounted,
    isCellMounted,
    isFooterMounted,
    columnWidths,
    onWidthChange: handleWidthChange,
    onAdjustedWidthChange: handleAdjustedWidthChange,
    onHeaderWidthChange: handleHeaderWidthChange,
    onFooterWidthChange: handleFooterWidthChange,
    onFitWidth: handleFitWidth,
  };
}

function getColumnWidth(
  widthStates: Record<number, ColumnWidthState>,
  index: number
) {
  const { adjusted, header, footer } = widthStates[index] ?? {};
  if (adjusted != null) {
    return Math.max(adjusted, header ?? 0, footer ?? 0);
  }

  const renderedWidth = getRenderedWidth(widthStates, index);
  if (renderedWidth == null) {
    return null;
  }

  return Math.min(renderedWidth, INITIAL_MAX_WIDTH);
}

function getRenderedWidth(
  widthStates: Record<number, ColumnWidthState>,
  index: number
) {
  const { cell, header, footer } = widthStates[index] ?? {};
  return Math.max(header ?? 0, cell ?? 0, footer ?? 0);
}
