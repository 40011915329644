import { forwardRef } from 'react';
import { cva } from '@sweep/tailwind';
import { TableCellProps } from '../interface';

function DefaultTableCell<T>(
  { context, className: givenClassName, children, ...rest }: TableCellProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { lastColumn = false, lastRow = false } = context;
  const className = cellClass({
    lastColumn,
    lastRow,
    className: givenClassName,
  });

  if (typeof children !== 'string') {
    return (
      <div ref={ref} className={className} {...rest}>
        {children}
      </div>
    );
  }

  return (
    <div ref={ref} className={className} {...rest}>
      <span className="text-ellipsis-nowrap">{children}</span>
    </div>
  );
}

const cellClass = cva(
  [
    'flex h-[37px] items-center',
    'px-16px bg-white py-0',
    'text-medium-s text-left text-gray-700',
  ],
  {
    variants: {
      lastColumn: {
        true: '',
        false: 'border-r border-gray-200',
      },
      lastRow: {
        true: '',
        false: 'border-b border-gray-200',
      },
    },
  }
);

export default forwardRef(DefaultTableCell);
