import { IconCheckMedium } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { imageExcelGrey } from 'src/images';
import { toast } from 'src/third-parties/toast';
import { isTrialPeriodExceeded } from '../../services/isTrialPeriodExceeded';
import { openTrialExpiredModal } from '../TrialExpiredModal';

interface InitialUploadSectionProps {
  onFileChange: (file: File) => void;
}

function InitialUploadSection({ onFileChange }: InitialUploadSectionProps) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isTrialPeriodExceeded()) {
      event.preventDefault();
      openTrialExpiredModal();
      return;
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files == null || files.length === 0) {
      toast.error('파일을 찾을 수 없습니다.');
      return;
    }

    if (files.length > 1) {
      toast.error('파일은 하나씩만 업로드 가능합니다.');
      return;
    }

    const file = files[0];
    onFileChange(file);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (isTrialPeriodExceeded()) {
      openTrialExpiredModal();
      return;
    }

    const files = event.dataTransfer.files;
    if (files == null || files.length === 0) {
      toast.error('파일을 찾을 수 없습니다.');
      return;
    }

    if (files.length > 1) {
      toast.error('파일은 하나씩만 업로드 가능합니다.');
      return;
    }

    const file = files[0];
    onFileChange(file);
  };

  return (
    <div className="w-412px h-444px px-24px py-28px shadow-line rounded-8px flex flex-col items-start bg-white">
      <div className="gap-8px flex-center flex">
        <IconCheckMedium className="text-blue-400" />
        <p className="text-semibold-m text-gray-600">파일 업로드하기</p>
      </div>
      <div
        className="flex-center flex w-full flex-1 flex-col"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <div className="gap-4px mb-32px flex flex-col items-center justify-center">
          <img
            src={imageExcelGrey}
            alt="drag-and-drop icon"
            className=" h-[112px] w-[112x]"
          />
          <p className="text-medium-m text-gray-700">
            주소가 포함된 파일을 끌어다 놓으세요.
          </p>
          <p className="text-semibold-s text-gray-400">
            양식 상관없이 주소 열만 있으면 변환이 가능해요.
          </p>
        </div>
        <Button
          color="blue"
          variant="solid"
          size="large"
          className="w-200px mb-8px"
          onClick={handleClick}
          asChild
        >
          <label>
            <input
              type="file"
              className="hidden"
              onChange={handleChange}
              accept=".xlsx, .xls, .csv"
            />
            파일 올리기
          </label>
        </Button>

        <span className="text-[14px] font-medium text-[#9FB1C1]">
          (최대 3MB)
        </span>
      </div>
    </div>
  );
}

export default InitialUploadSection;
