import {
  PartnershipFindAllQuery,
  PartnershipRegisterRetailerBody,
} from '@sweep/contract';
import { client } from '../client';

export async function registerRetailerPartnership(
  data: PartnershipRegisterRetailerBody
) {
  return await client.partnership.registerRetailer({
    body: data,
  });
}

export async function findAllPartnerships(query?: PartnershipFindAllQuery) {
  return await client.partnership.findAll({
    query,
  });
}

export async function findPartnershipById(partnershipId: string) {
  return await client.partnership.findById({
    params: {
      partnershipId,
    },
  });
}
