export function filterRows<T extends Record<string, unknown>>(
  rows: (T | T[])[],
  removedFields: Record<string, string[] | undefined>,
  accessorFns: Record<
    string,
    ((row: T) => string | undefined | null) | undefined | null
  >
): (T | T[])[] {
  return rows.filter((row) =>
    Array.isArray(row)
      ? row.some((o) => filterRow(o, removedFields, accessorFns))
      : filterRow(row, removedFields, accessorFns)
  );
}

function filterRow<T extends Record<string, unknown>>(
  row: T,
  removedFilters: Record<string, string[] | undefined>,
  accessorFns: Record<
    string,
    ((row: T) => string | undefined | null) | undefined | null
  >
) {
  return Object.keys(removedFilters).every((key) => {
    const removedFieldValues = removedFilters[key];
    if (removedFieldValues == null) {
      return true;
    }

    const value = accessorFns[key]?.(row) ?? '';
    return removedFieldValues.every((field) => value !== field);
  });
}
