import Button2 from 'components/buttons/Button';
import { overlay } from 'overlay-kit';
import { Fragment, useEffect, useState } from 'react';
import { SpreadsheetHeaderMapping } from '@sweep/contract';
import { Modal } from '@sweep/sds';
import WarningBanner from 'src/components/WarningBanner';
import { useOMSStore } from 'src/hooks/useOMSStore';
import SpreadsheetHeaderMappingsInput from './partials/SpreadsheetHeaderMappingsInput';

type FileHeaderInfo = {
  headers: string[];
  filename: string;
  exampleContents: string[][];
};

export async function openCreateSpreadsheetHeaderMappings(
  fileHeaderInfos: FileHeaderInfo[]
): Promise<SpreadsheetHeaderMapping[] | null> {
  return new Promise((resolve) => {
    overlay.open(({ isOpen, close, unmount }) => (
      <SpreadsheetHeaderMappingsForm
        open={isOpen}
        fileHeaderInfos={fileHeaderInfos}
        onSubmit={(
          userSpreadsheetHeaderMappings: SpreadsheetHeaderMapping[] | null
        ) => {
          close();
          unmount();
          resolve(userSpreadsheetHeaderMappings);
        }}
      />
    ));
  });
}

type SpreadsheetHeaderMappingsFormProps = {
  fileHeaderInfos: FileHeaderInfo[];
  open: boolean;
  onSubmit: (
    userSpreadsheetHeaderMappings: SpreadsheetHeaderMapping[] | null
  ) => void;
};

function SpreadsheetHeaderMappingsForm({
  open,
  fileHeaderInfos,
  onSubmit,
}: SpreadsheetHeaderMappingsFormProps) {
  const oms = useOMSStore();

  const [isAllColumnMappingsValid, setIsAllColumnMappingsValid] =
    useState(false);
  const [allColumnMappings, setAllColumnMappings] = useState<
    Record<string, string>[]
  >(fileHeaderInfos.map(() => ({})));

  const handleClose = () => {
    onSubmit(null);
  };

  const handleChangeColumnMappings = (
    index: number,
    columnMapping: Record<string, string>
  ) => {
    setAllColumnMappings((prev) => {
      const newColumnMappings = [...prev];
      newColumnMappings[index] = columnMapping;

      return newColumnMappings;
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await oms.loading.batch(async () => {
      for (const [index, fileHeaderInfo] of fileHeaderInfos.entries()) {
        const headers = fileHeaderInfo.headers;
        const columnMappings = allColumnMappings[index];
        const userId = oms.user.userId ?? '';
        const originalFilename = fileHeaderInfos[index].filename;

        await oms.spreadsheetHeaderMapping.createSpreadsheetHeaderMapping({
          headers,
          columnMappings,
          userId,
          originalFilename,
        });
      }

      onSubmit(oms.spreadsheetHeaderMapping.spreadsheetHeaderMappings);
    });
  };

  useEffect(() => {
    if (allColumnMappings.length === 0) {
      return;
    }

    const isAllColumnMappingsValid =
      allColumnMappings.every(isValidColumnMapping);

    setIsAllColumnMappingsValid(isAllColumnMappingsValid);
  }, [allColumnMappings]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      dismiss={{
        outsidePress: false,
        escapeKey: true,
      }}
      className="max-h-720px w-max"
    >
      <div className="px-32px">
        <Modal.Title>운송장 파일 매칭하기</Modal.Title>
        <form onSubmit={handleSubmit} className="pt-32px">
          <WarningBanner>
            운송장 파일 양식을 인식할 수 없습니다. 확인 후 매칭을 완료해주세요.
          </WarningBanner>

          <div className="mt-32px flex">
            <div className="px-20px py-10px flex flex-1 items-center bg-gray-100">
              <p className="text-bold-s">파일명</p>
            </div>
          </div>

          {fileHeaderInfos.map((fileHeaderInfo, index) => {
            const { filename, headers, exampleContents } = fileHeaderInfo;
            return (
              <Fragment key={filename}>
                <div className="pb-8px pt-16px flex items-center">
                  <span className="px-20px pb-28px flex-1">{filename}</span>
                </div>
                <SpreadsheetHeaderMappingsInput
                  key={filename}
                  index={index}
                  headers={headers}
                  onUpdate={handleChangeColumnMappings}
                  exampleContents={exampleContents}
                />
              </Fragment>
            );
          })}
          <Button2
            name="적용"
            type="submit"
            className="mr-40px float-right"
            disabled={!isAllColumnMappingsValid}
          />
        </form>
      </div>
    </Modal>
  );
}

function isValidColumnMapping(columnMapping: Record<string, string>) {
  if (columnMapping == null) {
    return false;
  }

  const isUniqueCodeValid =
    'uniqueCode' in columnMapping &&
    columnMapping['uniqueCode'] != null &&
    columnMapping['uniqueCode'] !== '';

  const isShippingNumberValid =
    'shippingNumber' in columnMapping &&
    columnMapping['shippingNumber'] != null &&
    columnMapping['shippingNumber'] !== '';

  return isUniqueCodeValid && isShippingNumberValid;
}
