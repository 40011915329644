import { observer } from 'mobx-react-lite';
import TextareaAutoSize from 'react-textarea-autosize';
import { Button } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

interface InquiryReplyWriteInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

function InquiryReplyWriteInput({
  value,
  onChange,
  onSubmit,
}: InquiryReplyWriteInputProps) {
  const error = value.length > 1000;
  const disabled = error || value.length === 0 || value.trim() === '';

  return (
    <div className="gap-8px flex w-full flex-col">
      <div className={replyTextareaContainer({ error })}>
        <TextareaAutoSize
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={replyTextarea()}
          placeholder="답변을 작성해주세요."
        />
        <div className={replyTextLengthContainer()}>
          <p className={replyTextLength({ error })}>
            <span className="text-semibold-m text-blue-500">
              {value.length}
            </span>{' '}
            / 1000
          </p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          color="blue"
          className="w-160px"
          disabled={disabled}
          onClick={onSubmit}
        >
          등록
        </Button>
      </div>
    </div>
  );
}

const replyTextareaContainer = cva('rounded-8px relative border-[1.5px]', {
  variants: {
    error: {
      true: 'border-red-500',
      false: 'border-gray-200',
    },
  },
});

const replyTextarea = cva(
  'pt-12px px-16px min-h-250px rounded-8px w-full min-w-0 resize-none text-gray-700 focus:border-blue-500 focus:outline-none'
);

const replyTextLengthContainer = cva('pb-12px pr-16px flex justify-end');

const replyTextLength = cva('text-medium-m', {
  variants: {
    error: {
      true: 'text-red-500',
      false: 'text-gray-500',
    },
  },
});

export default observer(InquiryReplyWriteInput);
