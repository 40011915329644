import { Link } from 'react-router-dom';
import {
  IconControlDownloadLarge,
  IconFactory,
  IconFactoryGray,
} from '@sweep/asset/icons';
import { Supplier } from '@sweep/contract';
import { IconButton } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { NULL_STRING } from '@sweep/utils';

interface SettlementSupplierItemProps {
  supplier: Supplier | null;
  price: number;
  onDownload: () => void;
}

function SettlementSupplierItem({
  supplier,
  price,
  onDownload,
}: SettlementSupplierItemProps) {
  const onDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onDownload();
  };

  return (
    <Link
      to={`/settlement/supplier/${supplier?._id ?? NULL_STRING}`}
      className={link()}
    >
      <div className="gap-16px flex min-w-0 items-center">
        {price === 0 ? <IconFactoryGray /> : <IconFactory />}
        <div className="gap-4px flex min-w-0 flex-col justify-center">
          <p className="text-bold-xl truncate text-gray-700">
            {supplier?.name ?? '기타'}
          </p>
          <div className="gap-8px flex items-center">
            <p className={priceClass({ active: price !== 0 })}>
              {price.toLocaleString()}원
            </p>
            <p className="text-medium-xs text-gray-500">공급금액</p>
          </div>
        </div>
      </div>
      {price !== 0 && (
        <IconButton
          color="lightGray"
          size="large"
          variant="line"
          className="bg-white"
          onClick={onDownloadClick}
        >
          <IconControlDownloadLarge />
        </IconButton>
      )}
    </Link>
  );
}

const link = cva([
  'px-24px py-20px flex items-center justify-between',
  'rounded-12px border border-gray-200 hover:bg-gray-100',
]);

const priceClass = cva('text-semibold-l', {
  variants: {
    active: {
      true: 'text-blue-600',
      false: 'text-gray-500',
    },
  },
});

export default SettlementSupplierItem;
