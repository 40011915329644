import { Workbook } from 'exceljs';
import { Result } from 'src/network/api';
import { CheckedAddressResult } from 'src/network/fastapi';
import { ExcelFile } from 'src/services/file/interface';
import { addClonedWorksheet } from './addClonedWorksheet';
import { addLegendWorkSheet } from './addLegendWorkSheet';
import { addTableWorksheet } from './addTableWorksheet';
import { assignCircularSheetOrder } from './assignCircularSheetOrder';
import { saveExcelAs } from './saveExcelAs';
import { updateCheckedAddressResultToSheet } from './updateCheckedAddressResultToSheet';

export async function saveCheckedAddressExcel({
  checkedAddressResults,
  excelFile,
  file,
  addressIndex,
}: {
  checkedAddressResults: CheckedAddressResult[];
  excelFile: ExcelFile;
  file: File;
  addressIndex: number;
}): Promise<Result<null>> {
  const rows = excelFile.data;
  const isXlsx = excelFile.extension === 'xlsx';

  const workbook = new Workbook();
  if (isXlsx) {
    await workbook.xlsx.load(await file.arrayBuffer());

    const originalSheet = workbook.worksheets.at(0);
    if (originalSheet == null) {
      return {
        success: false,
        error: '엑셀 파일 내부의 시트를 찾을 수 없습니다.',
      };
    }

    const clonedSheet = addClonedWorksheet(
      workbook,
      originalSheet,
      `${originalSheet.name} 주소 변환`
    );
    updateCheckedAddressResultToSheet(
      clonedSheet,
      checkedAddressResults,
      addressIndex
    );
    assignCircularSheetOrder(workbook);
  } else {
    const clonedSheet = addTableWorksheet({
      workbook,
      rows,
      sheetName: '변환된 주소',
    });
    updateCheckedAddressResultToSheet(
      clonedSheet,
      checkedAddressResults,
      addressIndex
    );
    addTableWorksheet({
      workbook,
      rows,
      sheetName: '기존 주소',
    });
  }

  addLegendWorkSheet(workbook);
  await saveExcelAs(workbook, `주소변환_${file.name}.xlsx`);

  return {
    success: true,
    data: null,
  };
}
