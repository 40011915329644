import {
  AutoDispatchOrder,
  AutoDispatchOrdersResponse,
  Order,
} from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { autoDispatchOrder } from 'src/network/order/autoDispatchOrder';
import { createDebug } from 'src/third-parties/createDebug';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import { FailedOriginalOrderWithFailReason } from '../types';

const debug = createDebug('services:process-shipping-order-to-shipped');

const AUTO_DISPATCH_ERROR = {
  MISSING_SHIPPING_INFO: '운송장 번호 또는 택배사가 없습니다.',
  UNKNOWN: '원본 주문 자동 발송에 실패했습니다.',
} as const;

type AutoDispatchStepResult = {
  successOrders: Order[];
  failedOrders: FailedOriginalOrderWithFailReason<Order>[];
};

export async function requestAutoDispatchStep(
  orders: Order[]
): Promise<AutoDispatchStepResult> {
  if (orders.length === 0) {
    return createEmptyResult();
  }

  try {
    const ordersMap = createOrdersMap(orders);
    const dispatchReadyOrders = extractDispatchReadyOrders(orders);
    const missingShippingInfoOrders =
      extractOrdersWithMissingShippingInfo(orders);

    const dispatchResult = await autoDispatchOrder(dispatchReadyOrders);

    const isDispatchFailed = dispatchResult == null || !dispatchResult.success;
    if (isDispatchFailed) {
      return createDispatchFailedResult(
        missingShippingInfoOrders,
        dispatchReadyOrders
      );
    }

    const successOrders = processSuccessOrders(dispatchResult.data, ordersMap);
    const failedDispatchOrders = processFailedOrders(
      dispatchResult.data,
      ordersMap
    );

    return {
      successOrders,
      failedOrders: [...missingShippingInfoOrders, ...failedDispatchOrders],
    };
  } catch (error) {
    ErrorReporter.captureError(error);
    debug('requestAutoDispatchStep failed', error);
    return createFailedResult(orders);
  }
}

function createEmptyResult(): AutoDispatchStepResult {
  return {
    successOrders: [],
    failedOrders: [],
  };
}

function createOrdersMap(orders: Order[]): Map<string, Order> {
  return new Map(orders.map((order) => [order.uniqueCode, order]));
}

function extractDispatchReadyOrders(orders: Order[]): AutoDispatchOrder[] {
  return orders
    .map(selectFirstShippingNumber)
    .filter(hasValidShippingInfo)
    .map(toAutoDispatchOrder);
}

function extractOrdersWithMissingShippingInfo(
  orders: Order[]
): FailedOriginalOrderWithFailReason<Order>[] {
  return orders
    .filter((order) => !hasValidShippingInfo(order))
    .map(createFailedOrderWithReason);
}

function hasValidShippingInfo(order: Order): boolean {
  const hasShippingNumber = order.shippingNumber?.trim() !== '';
  const hasShippingCompany = order.shippingCompany?.trim() !== '';

  return hasShippingNumber && hasShippingCompany;
}

function toAutoDispatchOrder(order: Order): AutoDispatchOrder {
  return {
    uniqueCode: order.uniqueCode,
    shippingNumber: order.shippingNumber!,
    shippingCompany: order.shippingCompany!,
    autoFulfilled: Boolean(order.autoFulfilled),
  };
}

function createFailedOrderWithReason(
  order: Order
): FailedOriginalOrderWithFailReason<Order> {
  return {
    originalOrder: order,
    statusCode:
      SHIPPING_ORDER_PROCESS_STATUS_CODE.STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR,
    failReason: AUTO_DISPATCH_ERROR.MISSING_SHIPPING_INFO,
  };
}

function createFailedResult(orders: Order[]): AutoDispatchStepResult {
  return {
    successOrders: [],
    failedOrders: orders.map((order) => ({
      originalOrder: order,
      statusCode:
        SHIPPING_ORDER_PROCESS_STATUS_CODE.STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR,
      failReason: AUTO_DISPATCH_ERROR.UNKNOWN,
    })),
  };
}

function createDispatchFailedResult(
  missingShippingInfoOrders: FailedOriginalOrderWithFailReason<Order>[],
  dispatchReadyOrders: AutoDispatchOrder[]
): AutoDispatchStepResult {
  const requestFailedOrders = dispatchReadyOrders.map((order) => ({
    originalOrder: order,
    statusCode:
      SHIPPING_ORDER_PROCESS_STATUS_CODE.STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR,
    failReason: AUTO_DISPATCH_ERROR.UNKNOWN,
  }));

  return {
    successOrders: [],
    failedOrders: [...missingShippingInfoOrders, ...requestFailedOrders],
  };
}

function processSuccessOrders(
  responseData: AutoDispatchOrdersResponse,
  ordersMap: Map<string, Order>
): Order[] {
  if (responseData == null) {
    return [];
  }

  return responseData
    .filter((result) => result.success)
    .map((result) => {
      const { uniqueCode, shippingNumber, shippingCompany, autoFulfilled } =
        result;
      const originalOrder = ordersMap.get(uniqueCode);

      if (originalOrder == null) {
        return null;
      }

      return {
        ...originalOrder,
        shippingNumber,
        shippingCompany,
        autoFulfilled,
      };
    })
    .filter((order): order is NonNullable<typeof order> => order != null);
}

function processFailedOrders(
  responseData: AutoDispatchOrdersResponse,
  ordersMap: Map<string, Order>
): FailedOriginalOrderWithFailReason<Order>[] {
  if (responseData == null) {
    return [];
  }

  return responseData
    .filter((result) => !result.success)
    .map((result) => {
      const { uniqueCode, failReason } = result;
      const originalOrder = ordersMap.get(uniqueCode);

      const isInvalidOrder = originalOrder == null || failReason == null;
      if (isInvalidOrder) {
        return null;
      }

      return {
        originalOrder,
        failReason,
        statusCode:
          SHIPPING_ORDER_PROCESS_STATUS_CODE.STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR,
      };
    })
    .filter((order): order is NonNullable<typeof order> => order != null);
}

function selectFirstShippingNumber(originalOrder: Order): Order {
  const shippingNumbers = originalOrder.shippingNumber?.split(',') ?? [];
  const firstShippingNumber =
    shippingNumbers[0] ?? originalOrder.shippingNumber;

  return {
    ...originalOrder,
    shippingNumber: firstShippingNumber,
  };
}
