import { Order } from '@sweep/contract';
import { Sort } from '../TableFilterStore';

export function sortOrders<T extends Order>(
  orders: (T | T[])[],
  sort: Sort,
  accessorFns: Record<
    string,
    ((row: T) => string | undefined | null) | undefined | null
  >
): (T | T[])[] {
  if (sort.id === null || sort.type === null) {
    return orders;
  }

  const { id, type } = sort;
  return orders.sort((a, b) => {
    const aItem = Array.isArray(a) ? a.at(0) : a;
    const bItem = Array.isArray(b) ? b.at(0) : b;
    const aValue = aItem != null ? (accessorFns[id]?.(aItem) ?? '') : '';
    const bValue = bItem != null ? (accessorFns[id]?.(bItem) ?? '') : '';

    if (aValue === bValue) {
      return 0;
    }

    if (aValue == null) {
      return 1;
    }

    if (bValue == null) {
      return -1;
    }

    if (type === 'ASC') {
      return aValue.localeCompare(bValue);
    }

    return bValue.localeCompare(aValue);
  });
}
