import { cva } from '@sweep/tailwind';
import { imageCardBack1, imageCardBack2, imageCardBack3 } from 'src/images';

interface CardProps {
  number: number;
}

const srcByNumber: Record<number, string> = {
  1: imageCardBack1,
  2: imageCardBack2,
  3: imageCardBack3,
};

const buttonTextByNumber: Record<number, string> = {
  1: '혜택 1',
  2: '혜택 2',
  3: '혜택 3',
};

const bgColorByNumber: Record<number, string> = {
  1: 'bg-[#4EBCC1]',
  2: 'bg-[#6B9CDF]',
  3: 'bg-[#C575BC]',
};

const headerTextByNumber: Record<number, React.ReactNode> = {
  1: <>쉽고 빠른 세팅</>,
  2: <>저렴한 비용</>,
  3: <>2주 무료 체험</>,
};

const descriptionTextByNumber: Record<number, React.ReactNode> = {
  1: (
    <>
      바로 사용하실 수 있도록
      <br />
      자동 세팅을 지원합니다.
    </>
  ),
  2: (
    <>
      스윕 기존 서비스 대비 절반의 비용으로
      <br />
      사용하실 수 있습니다.
      <br />
      <br />
      <p className="text-[16px] leading-[25px] tracking-tight font-medium text-[#03183A]/50">
        - 월 주문 1만 건 미만: ₩49,000/월
        <br />
        - 월 주문 1만 건 이상 3만 건 미만: ₩99,000/월
        <br />
        - 월 주문 3만 건 이상: ₩199,000/월
        <br />* VAT 별도
      </p>
    </>
  ),
  3: (
    <>
      주문 수에 관계 없이 2주 무료 체험 후
      <br />
      결정할 수 있습니다.
    </>
  ),
};

function Card({ number }: CardProps) {
  const src = srcByNumber[number];
  const buttonText = buttonTextByNumber[number];

  return (
    <div className="relative">
      <img src={src} alt="card-back1" className="h-[512px] w-[384px]" />
      <div className="absolute left-1/2 top-1/2 flex h-[424px] w-[296px] -translate-x-1/2 -translate-y-1/2 flex-col items-start justify-between">
        <div className="flex w-full flex-col items-start gap-[32px] text-start">
          <button className={badge({ className: bgColorByNumber[number] })}>
            {buttonText}
          </button>
          <p className="text-start text-[48px] font-bold leading-[60px] tracking-tight text-[#03183A]">
            {headerTextByNumber[number]}
          </p>
          <p className="text-[18px] font-medium leading-[29px] tracking-tight text-[#03183A] opacity-[76%]">
            {descriptionTextByNumber[number]}
          </p>
        </div>
      </div>
    </div>
  );
}

const badge = cva(
  'rounded-[8px] border border-[rgba(255,255,255,0.05)] px-[12px] py-[5px] text-[16px] font-semibold leading-[24px] tracking-tighter text-white'
);

export default Card;
