import { chunk, partition } from 'es-toolkit';
import { ExcelMemo, NormalizedAddress } from '@sweep/contract';
import { assert, retry } from '@sweep/utils';
import ErrorReporter from '../third-parties/ErrorReporter';
import api from './api';

const CHUNK_SIZE = 3000;
const MAX_RETRY_COUNT = 3;
const RETRY_DELAY_MS = 1000;

export interface Address {
  address?: string | null;
  postCode?: string | null;
}

export type CheckedAddress =
  | {
      result: 'okay';
      data: string;
      main_address: string;
      detail_address: string;
    }
  | {
      result: 'fixed';
      data: string;
      orig_data: string;
      main_address: string;
      detail_address: string;
      message: string[];
      tried_data: string;
      tried_tried_data: string[];
    }
  | {
      result: 'fail';
      data: string;
      orig_data: string;
      main_address: string;
      detail_address: string;
      message: string[];
      tried_data: string;
      tried_tried_data: string[];
    };

type CheckOrdersAddressResultSuccess = {
  success: true;
  data: CheckedAddress[];
};

type CheckOrdersAddressResultFailed = {
  success: false;
};

export type CheckOrdersAddressResponse =
  | CheckOrdersAddressResultSuccess
  | CheckOrdersAddressResultFailed;

export async function checkAddresses(
  addresses: Address[]
): Promise<CheckOrdersAddressResponse | null> {
  try {
    const chunks = chunk(addresses, CHUNK_SIZE);
    const results: CheckOrdersAddressResponse[] = [];

    for (const [index, currentChunk] of chunks.entries()) {
      try {
        const result = await _checkAddressesWithResult(currentChunk);
        results.push(result);
      } catch {
        reportCheckAddressFailError(
          addresses,
          chunks,
          `index (${index}) 에서 실패`
        );
        return null;
      }
    }

    const [successResult, failedResult] = partitionAPIResult(results);

    const successChunkCount = successResult.length;
    const failedChunkCount = failedResult.length;
    if (failedChunkCount > 0) {
      reportCheckAddressFailError(
        addresses,
        chunks,
        `일부 요청이 FAILED: (successChunkCount: ${successChunkCount}, failedChunkCount: ${failedChunkCount})`
      );
      return null;
    }

    const successfulResults = successResult.map((result) => result.data).flat();

    return {
      success: true,
      data: successfulResults,
    };
  } catch (e) {
    ErrorReporter.captureError(e, {
      extra: {
        addressCount: addresses.length,
      },
    });

    return null;
  }
}

async function _checkAddressesWithResult(
  addresses: Address[]
): Promise<CheckOrdersAddressResponse> {
  return retry(() => _checkAddresses(addresses), {
    retries: MAX_RETRY_COUNT,
    interval: RETRY_DELAY_MS,
    onError: (error) => {
      reportCheckAddressApiFailError({
        error,
        addresses,
      });
    },
    onRetry: () => {
      ErrorReporter.addBreadcrumb({
        message: `Address check API failed after MAX retries`,
        extra: {
          addressCount: addresses.length,
        },
      });
    },
  });
}

async function _checkAddresses(
  addresses: Address[]
): Promise<CheckOrdersAddressResponse> {
  const url = '/fastapi/correct_orders';
  const response = await api.post<CheckOrdersAddressResponse>(url, addresses);
  assert(response != null, 'response is null');

  return response.data;
}

function partitionAPIResult(
  results: CheckOrdersAddressResponse[]
): [CheckOrdersAddressResultSuccess[], CheckOrdersAddressResultFailed[]] {
  const [successResult, failedResult] = partition(
    results,
    (result) => result.success
  ) as [CheckOrdersAddressResultSuccess[], CheckOrdersAddressResultFailed[]];

  return [successResult, failedResult];
}

function reportCheckAddressApiFailError(params: {
  error: unknown;
  addresses: Address[];
}) {
  try {
    const { error, addresses } = params;

    ErrorReporter.captureMessage(
      `[SWP-629] Address check API failed: ${error instanceof Error ? error.message : String(error)}`,
      {
        extra: {
          addressCount: addresses.length,
        },
      }
    );
  } catch (e) {
    // 로직과 무관한 모니터링 로직이라서 예외처리 시킴
    ErrorReporter.captureError(e);
  }
}

function reportCheckAddressFailError(
  addresses: Address[],
  chunks: Address[][],
  note: string
) {
  // NOTE(@형준): SWP-629 문제 상황 모니터링을 위해서 추가한 임시 에러 리포트, 문제 상황 모니터링 종료 후 삭제 필요
  try {
    const addressCount = addresses.length;
    const requestCountTotal = chunks.length;
    const errorMessageForSWP629 = `[SWP-629] 일부 주문 주소 검증이 실패했습니다. 주문 수: ${addressCount}, 요청수: ${requestCountTotal}, ${note}`;
    ErrorReporter.captureMessage(errorMessageForSWP629, {
      extra: {
        addressCount,
        requestCountTotal,
      },
    });
  } catch (error) {
    // 로직과 무관한 모니터링 로직이라서 예외처리 시킴
    ErrorReporter.captureError(error);
  }
}

export interface CheckedAddressResult {
  isAddressValid: 'okay' | 'fixed' | 'multiple' | 'postcode' | 'fail';
  address: string;
  mainAddress: string;
  detailAddress: string;
  memo?: ExcelMemo[];
}

export function toCheckedAddressResult(
  checkedAddress: NormalizedAddress,
  config?: {
    fixInvalidAddress?: boolean;
  }
): CheckedAddressResult {
  const isFixInvalidAddress = config?.fixInvalidAddress ?? false;
  const checkedAddressResult = checkedAddress.result;

  const result: CheckedAddressResult = {
    isAddressValid: checkedAddressResult,
    address: checkedAddress.data,
    mainAddress: checkedAddress.main_address,
    detailAddress: checkedAddress.detail_address,
  };

  const triedData = checkedAddress.tried_data ?? checkedAddress.data;
  switch (checkedAddressResult) {
    case 'okay': {
      return result;
    }
    case 'fixed': {
      if (isFixInvalidAddress) {
        return {
          ...result,
          address: triedData,
          memo: [{ text: '기존주소:' + checkedAddress.data + '\n' }],
        };
      }

      return {
        ...result,
        isAddressValid: 'fail',
      };
    }
    case 'fail': {
      result.address = isFixInvalidAddress ? triedData : checkedAddress.data;
    }
  }

  if (result.isAddressValid !== 'fail') {
    return result;
  }

  const message = checkedAddress.message ?? [];
  result.memo = [];
  if (message.includes('지번 주소입니다.')) {
    if (message.length > 2 && message[2].includes('해당하는 도로명 주소가')) {
      result.isAddressValid = 'multiple';
      result.memo.push({
        text: message[2] + '\n',
      });

      return result;
    }

    result.memo = result.memo.concat(
      message.slice(2).map((item) => ({ text: item + '\n' }))
    );
  } else {
    const postCodeMessage = message.find((message) =>
      message.includes('고객이 입력한 우편번호')
    );

    if (postCodeMessage != null) {
      result.isAddressValid = 'postcode';
      result.memo.push({
        text: postCodeMessage + '\n',
      });
      return result;
    }
  }

  if (result.isAddressValid !== 'fail') {
    return result;
  }

  result.memo = result.memo.concat(
    message.map((item) => ({
      text: item + '\n',
    }))
  );

  return result;
}
