export function chunkByInterval(
  startDate: Date,
  endDate: Date,
  interval: number
) {
  if (interval <= 0) {
    throw new Error('Interval must be greater than zero');
  }

  const length = Math.ceil(
    (endDate.getTime() - startDate.getTime()) / interval
  );
  const dates = Array.from({ length }, (_, i) => {
    const startTime = startDate.getTime() + i * interval;
    const endTime = Math.min(startTime + interval, endDate.getTime());

    return { start: new Date(startTime), end: new Date(endTime) };
  });

  return dates;
}
