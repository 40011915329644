import { observer } from 'mobx-react-lite';
import { Table } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { SettlementOrder } from '../../interface';
import { createSalesChannelSettlementTableColumns } from './createSalesChannelSettlementTableColumns';

interface SalesChannelSettlementTableProps {
  orders: SettlementOrder[];
}

function SalesChannelSettlementTable({
  orders,
}: SalesChannelSettlementTableProps) {
  const oms = useOMSStore();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnMapping = oms.user.excelColumnMapping;

  const tableColumns = createSalesChannelSettlementTableColumns(
    orders,
    headerKeys,
    columnMapping
  );

  return (
    <Table
      items={orders}
      columns={tableColumns}
      getKey={(item) => item.uniqueCodeAfterCustomization}
      className="max-h-572px"
    />
  );
}

export default observer(SalesChannelSettlementTable);
