import { cva } from '@sweep/tailwind';
import { useMultiSelectCollection } from './MultiSelectContext';
import { getLabel } from './services/getLabel';

interface MultiSelectValueProps {
  value: string[];
  placeholder?: string;
}

function MultiSelectValue({ value, placeholder }: MultiSelectValueProps) {
  const getOptions = useMultiSelectCollection();
  const options = getOptions();
  const isValueEmpty = value.length === 0;

  return (
    <p className={labelClass({ isValueEmpty })}>
      {isValueEmpty ? placeholder : getLabel(value, options)}
    </p>
  );
}

const labelClass = cva(
  'text-medium-s flex-1 select-none overflow-hidden text-ellipsis text-nowrap',
  {
    variants: {
      isValueEmpty: {
        true: 'text-gray-400',
        false: 'text-gray-700',
      },
    },
  }
);

export default MultiSelectValue;
