import { useEffect, useRef } from 'react';
import { Slot } from '../../../Slot';
import { useTableContext } from '../context/TableContext';
import { ColumnDef, FooterCellContext } from '../interface';
import DefaultTableFooterCell from './DefaultTableFooterCell';

interface TableFooterCellRendererProps<T> {
  footer: ColumnDef<T>['footer'];
  context: FooterCellContext;
}

const BORDER_WIDTH = 1;

export function TableFooterCellRenderer<T>({
  footer,
  context,
}: TableFooterCellRendererProps<T>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { columnWidths, onFooterWidthChange, isFooterMounted } =
    useTableContext();
  const columnWidth = columnWidths[context.columnIndex];
  const width =
    columnWidth != null && isFooterMounted ? `${columnWidth}px` : undefined;
  const style: React.CSSProperties = {
    width,
    maxWidth: width,
  };

  useEffect(() => {
    if (!isFooterMounted && ref.current) {
      onFooterWidthChange(
        context.columnIndex,
        ref.current.clientWidth + BORDER_WIDTH
      );
    }
  }, [context.columnIndex, onFooterWidthChange, isFooterMounted]);

  const footerComponent = footer?.(context);

  if (footerComponent == null || typeof footerComponent === 'string') {
    return (
      <DefaultTableFooterCell ref={ref} context={context} style={style}>
        {footerComponent}
      </DefaultTableFooterCell>
    );
  }

  return (
    <Slot key={context.columnIndex} ref={ref} style={style}>
      {footerComponent}
    </Slot>
  );
}
