import { observer } from 'mobx-react-lite';
import { forwardRef, useId, useState } from 'react';
import { HeaderCellContext, SortIcon, Table } from '@sweep/sds';
import { TableFilterStore } from '../TableFilterStore';
import TableFilterDropdown from './TableFilterDropdown';

interface TableFilterHeadercellProps<T>
  extends React.HTMLAttributes<HTMLDivElement> {
  store: TableFilterStore;
  rows: T[];
  accessorFn?: (row: T) => string | undefined | null;
  context: HeaderCellContext;
  children?: React.ReactNode;
}

function TableFilterHeaderCell<T extends Record<string, unknown>>(
  {
    store,
    accessorFn,
    rows,
    context,
    children,
    ...rest
  }: TableFilterHeadercellProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const id = useId();
  const [prevId, setPrevId] = useState<string | null>(null);
  const { id: sortId, type: sortType } = store.sort;

  if (prevId !== id) {
    store.setAccessorFn(id, accessorFn);

    if (prevId != null) {
      const prevSort = store.sort;
      if (prevSort.id === prevId) {
        store.setSort(id, prevSort.type);
      }

      const prevRemovedFields = store.removedFields[prevId];
      if (prevRemovedFields != null) {
        store.setRemovedFields(id, prevRemovedFields);
      }

      store.setSort(prevId, null);
      store.setRemovedFields(prevId, []);
      store.setAccessorFn(prevId, null);
    }
    setPrevId(id);
  }

  return (
    <Table.HeaderCell ref={ref} context={context} {...rest}>
      <div className="flex w-full justify-between">
        <div className="gap-4px flex items-center">
          {children}
          <SortIcon sort={sortId === id ? sortType : null} />
        </div>
        <TableFilterDropdown
          store={store}
          rows={rows}
          id={id}
          accessorFn={accessorFn}
        />
      </div>
    </Table.HeaderCell>
  );
}

export default observer(
  forwardRef(TableFilterHeaderCell)
) as typeof TableFilterHeaderCell;
