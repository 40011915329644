import { NormalizedOrder } from '@sweep/contract';
import {
  formatDate,
  formatPhoneNumber,
  isNotEmptyString,
  isNotNil,
  parseNumber,
  sum,
} from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { SettlementOrder } from '../interface';
import { getPriceFromHistory } from './getPriceFromHistory';

export function toSettlementOrder(
  oms: OMSStore,
  order: NormalizedOrder
): SettlementOrder {
  const orderProducts = order.data ?? [];

  const price = parseNumber(order.price) ?? null;
  const quantity = parseNumber(order.quantity) ?? 1;

  const prices = orderProducts
    .map((orderProduct) => {
      const product = oms.product.getProduct(orderProduct.productId);
      if (product == null) {
        return null;
      }

      const option = isNotEmptyString(orderProduct.unit)
        ? product.units?.find((unit) => unit.unit === orderProduct.unit)
        : null;

      const item = option ?? product;
      const sellingPrice = getPriceFromHistory(
        item.sellingPrice ?? 0,
        item.sellingPriceHistory ?? [],
        order.registeredAt ?? 0
      );
      const sellingShippingPrice = item.sellingShippingPrice ?? 0;
      const supplierPrice = getPriceFromHistory(
        item.supplierPrice ?? 0,
        item.supplierPriceHistory ?? [],
        order.registeredAt ?? 0
      );
      const supplierShippingPrice = item.supplierShippingPrice ?? 0;

      return {
        sellingPrice,
        sellingShippingPrice,
        supplierPrice,
        supplierShippingPrice,
        quantity: orderProduct.quantity,
      };
    })
    .filter(isNotNil);

  const sellingPrice =
    sum(prices.map(({ sellingPrice, quantity }) => sellingPrice * quantity)) *
    quantity;
  const sellingShippingPrice =
    prices.length > 0
      ? Math.max(
          ...prices.map(({ sellingShippingPrice }) => sellingShippingPrice)
        )
      : 0;
  const supplierPrice =
    sum(prices.map(({ supplierPrice, quantity }) => supplierPrice * quantity)) *
    quantity;
  const supplierShippingPrice =
    prices.length > 0
      ? Math.max(...prices.map((price) => price.supplierShippingPrice))
      : 0;

  return {
    ...order,
    sellingOrderDate: getSellingOrderDate(order),
    supplyOrderDate: getSupplyOrderDate(order),

    contactNumber: formatPhoneNumber(order.contactNumber ?? ''),
    contactTelephoneNumber: formatPhoneNumber(order.telephoneNumber ?? ''),

    sellingPrice: price != null ? undefined : sellingPrice,
    sellingShippingPrice: price != null ? undefined : sellingShippingPrice,
    totalSellingPrice: price ?? sellingPrice + sellingShippingPrice,

    supplierPrice,
    supplierShippingPrice,
    totalSupplierPrice: supplierPrice + supplierShippingPrice,
  };
}

function getSellingOrderDate(order: NormalizedOrder) {
  const orderDate = order.orderDate;
  if (typeof orderDate === 'string') {
    return formatDate(new Date(orderDate), 'yyyy-MM-dd');
  }

  const registeredAt = order.registeredAt;
  if (registeredAt != null) {
    return formatDate(new Date(registeredAt), 'yyyy-MM-dd');
  }

  return null;
}

function getSupplyOrderDate(order: NormalizedOrder) {
  const registeredAt = order.registeredAt;
  if (registeredAt != null) {
    return formatDate(new Date(registeredAt), 'yyyy-MM-dd');
  }

  return null;
}
