import { action, observable } from 'mobx';
import {
  ErrorCodeNaver,
  getShoppingMallNameByUniqueCode,
  Inquiry,
} from '@sweep/contract';
import { replyInquiry } from 'src/network/inquiry';
import { amplitude } from 'src/third-parties/amplitude';
import { OMSStore } from './OMSStore';

export class InquiryStore {
  @observable
  accessor inquiries: Inquiry[] = [];

  constructor(private oms: OMSStore) {}

  @action.bound
  setInquiries(inquiries: Inquiry[]) {
    this.inquiries = inquiries;
  }

  @action.bound
  async reply(
    inquiryUniqueCode: string,
    content?: string
  ): Promise<{
    success: boolean;
    data: Inquiry[];
    errorMessage?: string;
  }> {
    const response = await replyInquiry({ inquiryUniqueCode, content });
    if (response?.status === 200) {
      amplitude.track('Reply cs (Server)', {
        storeName: getShoppingMallNameByUniqueCode(inquiryUniqueCode),
      });
      const data = response.body.data;
      this._updateInquiries(data);
    }

    if (response?.status === 400 || response?.status === 500) {
      const customErrorCode = response.body.customErrorCode as ErrorCodeNaver;
      const errorMessage =
        ERROR_MESSAGES?.[customErrorCode] ?? DEFAULT_ERROR_MESSAGE;

      return {
        success: false,
        data: [],
        errorMessage,
      };
    }

    return {
      success: false,
      data: [],
      errorMessage: DEFAULT_ERROR_MESSAGE,
    };
  }

  @action.bound
  _updateInquiries(inquiries: Inquiry[]) {
    const inquiryMap = new Map<string, Inquiry>();
    for (const inquiry of inquiries) {
      inquiryMap.set(inquiry.inquiryUniqueCode, inquiry);
    }

    this.inquiries = this.inquiries.map<Inquiry>(
      (inquiry) => inquiryMap.get(inquiry.inquiryUniqueCode) ?? inquiry
    );
  }
}

// NOTE(@형준): 오류 메세지 데이터 어디에서 관리할지 고민
const DEFAULT_ERROR_MESSAGE = '답변 등록에 실패했습니다.';
const ERROR_MESSAGES: Record<ErrorCodeNaver, string> = {
  [ErrorCodeNaver.FailedToReplyInquiry_NotFound]:
    '삭제된 문의이므로 답변을 등록할 수 없습니다.',
  [ErrorCodeNaver.FailedToReplyInquiry_AlreadyAnswered]:
    '이미 답변이 등록된 문의이므로 답변을 등록할 수 없습니다.',
  [ErrorCodeNaver.FailedToReplyInquiry_Unknown]: DEFAULT_ERROR_MESSAGE,
};
