import { Link } from 'react-router-dom';
import { IconBack } from '@sweep/asset/icons';

interface BackLinkProps {
  to: string;
}

export function BackLink({ to }: BackLinkProps) {
  return (
    <Link
      to={to}
      className="shadow-line size-42px rounded-8px flex-center flex bg-white"
    >
      <IconBack className="text-gray-500" />
    </Link>
  );
}
