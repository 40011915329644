import { isNotNil } from '@sweep/utils';
import { SettlementColumn } from '../interface';

export function createSupplierSettlementColumns(
  headerKeys: string[],
  columnMapping: Record<string, string>
): SettlementColumn[] {
  const hasProductName = headerKeys.includes('productName');
  const hasOption = headerKeys.includes('option');
  const hasQuantity = headerKeys.includes('quantity');

  const columns: (SettlementColumn | null)[] = [
    {
      header: '발주일자',
      key: 'supplyOrderDate',
      type: 'string',
    },
    {
      header: '주문번호',
      key: 'orderNumber',
      type: 'string',
    },
    hasProductName
      ? {
          header: columnMapping['productName'] ?? '',
          key: 'productName',
          type: 'string',
        }
      : null,
    hasOption
      ? {
          header: columnMapping['option'] ?? '',
          key: 'option',
          type: 'string',
        }
      : null,
    hasQuantity
      ? {
          header: columnMapping['quantity'] ?? '',
          key: 'quantity',
          type: 'number',
        }
      : null,
    {
      header: '가격',
      key: 'supplierPrice',
      type: 'number',
    },
    {
      header: '배송비',
      key: 'supplierShippingPrice',
      type: 'number',
    },
    {
      header: '합계',
      key: 'totalSupplierPrice',
      type: 'number',
    },
    {
      header: '수령인',
      key: 'name',
      type: 'string',
    },
    {
      header: '휴대폰번호',
      key: 'contactNumber',
      type: 'string',
    },
    {
      header: '전화번호',
      key: 'telephoneNumber',
      type: 'string',
    },
    {
      header: '주소',
      key: 'address',
      type: 'string',
    },
    {
      header: '우편번호',
      key: 'postCode',
      type: 'string',
    },
    {
      header: '배송업체',
      key: 'shippingCompany',
      type: 'string',
    },
    {
      header: '운송장번호',
      key: 'shippingNumber',
      type: 'string',
    },
  ];

  return columns.filter(isNotNil);
}
