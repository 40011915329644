import InitialTestSection from './InitialTestSection';
import { InitialTitleSection } from './InitialTitleSection';
import InitialUploadSection from './InitialUploadSection';

interface InitialSectionProps {
  onFileChange: (file: File) => void;
}

function InitialSection({ onFileChange }: InitialSectionProps) {
  return (
    <div className="gap-40px pt-160px flex flex-1 flex-col">
      <InitialTitleSection />
      <div className="flex">
        <InitialTestSection className="mr-32px" />
        <InitialUploadSection onFileChange={onFileChange} />
      </div>
    </div>
  );
}

export default InitialSection;
