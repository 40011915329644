export type CalendarSection = 'left' | 'right';

export type Selecting =
  | 'first-from'
  | 'first-to'
  | 'second-from'
  | 'second-to'
  | null;

export function isSelectingFrom(selection: Selecting) {
  return selection === 'first-from' || selection === 'second-from';
}

export function isSelectingTo(selection: Selecting) {
  return selection === 'first-to' || selection === 'second-to';
}

export function isSelectingFirst(selection: Selecting) {
  return selection === 'first-from' || selection === 'first-to';
}
