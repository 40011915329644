import { Order } from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import { AbstractPlugin } from '../../interface';
import { extractDate } from './extractDate';

/**
 * @example
 * {
 *  inputFormats: [
 *    '발송일:{month} 월{day}일 ({dayOfWeek})',
 *    '지정일 선택:{month} 월{day}일 ({dayOfWeek})',
 *  ],
 *  outputFormat: 'MM.dd EEEE 발송'
 * }
 */
export interface ExtractShippingDatePluginConfig {
  inputFormats: string[];
  outputFormat: string;
}

export class ExtractDateFromNameOptionPlugin extends AbstractPlugin<ExtractShippingDatePluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(
      orders.map((order) =>
        ExtractDateFromNameOptionPlugin.transformOrder(order, this.config)
      )
    );
  };

  static transformOrder = (
    order: Order,
    config: ExtractShippingDatePluginConfig
  ): Order => {
    const { inputFormats, outputFormat } = config;
    const targetValues = [order.origOption, order.origName].filter(
      (value) => typeof value === 'string'
    );

    for (const value of targetValues) {
      for (const inputFormat of inputFormats) {
        const date = extractDate(value, inputFormat);
        if (date != null) {
          return {
            ...order,
            shippingDate: formatDate(date, outputFormat),
          };
        }
      }
    }

    return order;
  };
}
