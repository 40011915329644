import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconControlDownload, IconFactory } from '@sweep/asset/icons';
import { Supplier } from '@sweep/contract';
import { Button, Divider, SearchInput } from '@sweep/sds';
import { DateRange, formatDate, NULL_STRING, sortBy, sum } from '@sweep/utils';
import { useMount } from 'src/hooks/useMount';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import { BackLink } from '../components/BackLink';
import SettlementRangeSelect from '../components/SettlementRangeSelect';
import SupplierSettlementTable from '../components/SettlementTable/SupplierSettlementTable';
import TotalPrice from '../components/TotalPrice';
import { createSupplierSettlementColumns } from '../services/createSupplierSettlementColumns';
import { downloadSupplierSettlementExcel } from '../services/downloadSupplierSettlementExcel';
import { SettlementStore } from '../store/SettlementStore';
import { SupplierSettlementScreenStore } from '../store/SupplierSettlementScreenStore';
import SupplierNotFoundSettlementScreen from './SupplierNotFoundSettlementScreen';

function SupplierDetailSettlementScreen() {
  const { supplierId } = useParams();
  const oms = useOMSStore();
  if (supplierId == null) {
    return <SupplierNotFoundSettlementScreen />;
  }
  if (supplierId === NULL_STRING) {
    return <InnerSupplierDetailSettlementScreen supplier={null} />;
  }

  const supplier = oms.supplier.getSupplier(supplierId);
  if (supplier == null) {
    return <SupplierNotFoundSettlementScreen />;
  }

  return <InnerSupplierDetailSettlementScreen supplier={supplier} />;
}

const InnerSupplierDetailSettlementScreen = observer(
  InnerSupplierDetailSettlementScreen_
);

function InnerSupplierDetailSettlementScreen_({
  supplier,
}: {
  supplier: Supplier | null;
}) {
  const oms = useOMSStore();
  const settlement = oms.getStore(SettlementStore);
  const store = oms.getStore(SupplierSettlementScreenStore, settlement);

  useMount(() =>
    amplitude.track('Pageview settlement-supplier-detail', {
      'Supplier name': supplier?.name ?? '기타',
    })
  );

  useEffect(() => {
    oms.loading.batch(() => store.ensureOrders(store.range));
  }, [oms.loading, store]);

  const [range, setRange] = useState<DateRange>(store.range);
  const handleRangeChange = async (range: DateRange) => {
    amplitude.track('Click settlement-filter', {
      Period: `${formatDate(range.from, 'yyyy-MM-dd')} ~ ${formatDate(range.to, 'yyyy-MM-dd')}`,
      From: 'settlement-supplier-detail',
    });
    setRange(range);
    await oms.loading.batch(() => store.ensureOrders(range));
  };

  const supplierId = supplier?._id;
  const supplierName = supplier?.name;

  const columns = createSupplierSettlementColumns(
    oms.user.excelHeaderKeys,
    oms.user.excelColumnMapping
  );
  const headerKeys = columns.map((column) => column.key);
  const [search, setSearch, filterSearch] = useSearchState({
    keys: headerKeys,
  });
  const handleSearchChange = (search: string) => {
    amplitude.track('Search settlement', {
      Term: search,
      From: 'settlement-supplier-detail',
    });
    setSearch(search);
  };

  const orders = store
    .getOrders(range)
    .filter((order) => order.supplierId === supplierId);
  const filteredOrders = filterSearch(orders);
  const sortedOrders = sortBy(filteredOrders, ['productName', 'option']);
  const totalPrice = sum(sortedOrders.map((order) => order.totalSupplierPrice));

  const onDownload = () => {
    if (filteredOrders.length === 0) {
      toast.error('선택된 주문이 없습니다.');
      return;
    }

    amplitude.track('Click download-settlement-supplier-detail', {
      'Supplier name': supplierName ?? '기타',
      From: 'settlement-supplier-detail',
    });

    downloadSupplierSettlementExcel(
      oms,
      supplierName ?? '기타',
      range,
      filteredOrders
    );
  };

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <div className="gap-20px flex items-center">
        <BackLink to="/settlement/supplier" />
        <IconFactory />
        <p className="text-extrabold-l text-gray-700">
          {supplierName ?? '기타'}
        </p>
      </div>
      <div className="p-20px gap-24px rounded-8px shadow-line flex flex-col bg-white">
        <div className="gap-24px flex items-center">
          <SettlementRangeSelect value={range} onChange={handleRangeChange} />
          <Divider type="vertical" />
          <TotalPrice type="supplier" value={totalPrice} />
        </div>
        <Divider type="horizontal-full" />
        <div className="gap-20px flex flex-col">
          <div className="flex justify-between">
            <div className="gap-20px flex items-center">
              <div className="gap-4px flex items-center justify-center">
                <p className="text-medium-s text-gray-500">발주 내역</p>
                <p className="text-medium-s text-gray-500">
                  {'('}
                  <span className="text-semibold-s text-blue-600">
                    {sortedOrders.length}
                  </span>
                  {'건)'}
                </p>
              </div>
            </div>
            <div className="gap-20px flex">
              <SearchInput
                value={search}
                onChange={handleSearchChange}
                className="w-400px"
                placeholder="검색어를 입력하세요."
              />
              <Button
                color="gray"
                rightAccessory={<IconControlDownload />}
                onClick={onDownload}
              >
                엑셀 파일
              </Button>
            </div>
          </div>
          <SupplierSettlementTable
            key={JSON.stringify(range)}
            orders={sortedOrders}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(SupplierDetailSettlementScreen);
