import { c } from '../../internal/contract';
import {
  PurchaseOrderTransactionConfirmBodySchema,
  PurchaseOrderTransactionConfirmResponseSchema,
  PurchaseOrderTransactionCountResponseSchema,
  PurchaseOrderTransactionCreateBodySchema,
  PurchaseOrderTransactionCreateResponseSchema,
  PurchaseOrderTransactionDeleteResponseSchema,
  PurchaseOrderTransactionFindAllQuerySchema,
  PurchaseOrderTransactionFindAllResponseSchema,
  PurchaseOrderTransactionGetDownloadUrlResponseSchema,
  PurchaseOrderTransactionGetPreprocessedContentResponseSchema,
  PurchaseOrderTransactionIdPathParamsSchema,
} from '../../type/partnership/PurchaseOrderTransactionDTO';
import { CommonErrorResponseSchema } from '../commonErrorResponse';

export const PurchaseOrderTransactionRouterContract = c.router({
  create: {
    method: 'POST',
    path: '/purchase-order-transaction',
    body: PurchaseOrderTransactionCreateBodySchema,
    responses: {
      200: PurchaseOrderTransactionCreateResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  findAll: {
    method: 'GET',
    path: '/purchase-order-transaction',
    query: PurchaseOrderTransactionFindAllQuerySchema,
    responses: {
      200: PurchaseOrderTransactionFindAllResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  confirm: {
    method: 'PUT',
    path: '/purchase-order-transaction/:purchaseOrderTransactionId/confirm',
    pathParams: PurchaseOrderTransactionIdPathParamsSchema,
    body: PurchaseOrderTransactionConfirmBodySchema,
    responses: {
      200: PurchaseOrderTransactionConfirmResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  delete: {
    method: 'DELETE',
    path: '/purchase-order-transaction/:purchaseOrderTransactionId',
    pathParams: PurchaseOrderTransactionIdPathParamsSchema,
    responses: {
      200: PurchaseOrderTransactionDeleteResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  count: {
    method: 'GET',
    path: '/purchase-order-transaction/count',
    query: PurchaseOrderTransactionFindAllQuerySchema,
    responses: {
      200: PurchaseOrderTransactionCountResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  getDownloadUrl: {
    method: 'GET',
    path: '/purchase-order-transaction/:purchaseOrderTransactionId/original-download-url',
    pathParams: PurchaseOrderTransactionIdPathParamsSchema,
    responses: {
      200: PurchaseOrderTransactionGetDownloadUrlResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  getPreprocessedDownloadUrl: {
    method: 'GET',
    path: '/purchase-order-transaction/:purchaseOrderTransactionId/preprocessed-download-url',
    pathParams: PurchaseOrderTransactionIdPathParamsSchema,
    responses: {
      200: PurchaseOrderTransactionGetDownloadUrlResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
  getPreprocessedContent: {
    method: 'GET',
    path: '/purchase-order-transaction/:purchaseOrderTransactionId/preprocessed-content',
    pathParams: PurchaseOrderTransactionIdPathParamsSchema,
    responses: {
      200: PurchaseOrderTransactionGetPreprocessedContentResponseSchema,
      400: CommonErrorResponseSchema,
      403: CommonErrorResponseSchema,
      404: CommonErrorResponseSchema,
      500: CommonErrorResponseSchema,
    },
  },
});
