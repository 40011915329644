import { formatDate, uniq } from '@sweep/utils';
import {
  ADDRESS_VALIDATOR_USAGE_DATE_FORMAT,
  ADDRESS_VALIDATOR_USAGE_DATES_KEY,
} from './constants';

export function saveUsageDate() {
  const today = formatDate(new Date(), ADDRESS_VALIDATOR_USAGE_DATE_FORMAT);
  const usedDates =
    localStorage.getItem(ADDRESS_VALIDATOR_USAGE_DATES_KEY)?.split(',') ?? [];

  localStorage.setItem(
    ADDRESS_VALIDATOR_USAGE_DATES_KEY,
    uniq([...usedDates, today]).join(',')
  );
}
