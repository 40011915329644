import { FloatingPortal } from '@floating-ui/react';
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconControlArrowDown } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { sleep } from '@sweep/utils';
import { SearchSelectProvider } from './context/SearchSelectContext';
import { useIsOpenOptions } from './hooks/useIsOpenOptions';
import { useSearchSelectFloating } from './hooks/useSearchSelectFloating';
import { useSearchSelectKeyDown } from './hooks/useSearchSelectKeyDown';
import { useSearchSelectMouseMove } from './hooks/useSearchSelectMouseMove';
import { SearchSelectFooter } from './SearchSelectFooter';
import SearchSelectOption from './SearchSelectOption';

interface SearchSelectProps {
  value: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  options: SearchSelectOption[];
  footer?: ReactNode;
  error?: boolean;
  className?: string;
}

export interface SearchSelectOption {
  value: string | null;
  label: string;
  className?: string;
}

export function SearchSelect({
  value,
  onChange,
  options,
  placeholder: givenPlaceholder,
  footer,
  error,
  className,
}: SearchSelectProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useSearchSelectMouseMove({
    optionRefs,
    onSelectedIndexChange: setSelectedIndex,
  });

  const getLabel = useCallback(
    (value: string | null) =>
      options.find((option) => option.value === value)?.label,
    [options]
  );

  const [searchValue, setSearchValue] = useState(getLabel(value) ?? '');
  useEffect(() => {
    setSearchValue(getLabel(value) ?? '');
  }, [getLabel, value]);

  const filteredOptions = options.filter(filterBySearchValue(searchValue));

  const { isOpenOptions, openOptions, closeOptions } = useIsOpenOptions({
    inputRef,
    onSelectedIndexChange: setSelectedIndex,
    onClose: () => {
      setSearchValue(getLabel(value) ?? '');
    },
  });

  const { refs, floatingStyles } = useSearchSelectFloating({
    open: isOpenOptions,
    onOpenChange: (open) => {
      if (open === false) {
        closeOptions();
        return;
      }

      openOptions();
    },
    options: {
      withFooter: footer != null,
    },
  });

  const handleValue = (value: string | null) => {
    setSearchValue(getLabel(value) ?? '');
    onChange(value);
  };

  const { onKeyDown } = useSearchSelectKeyDown({
    closeOptions,
    options: filteredOptions,
    selectedIndex,
    onSelectedIndexChange: setSelectedIndex,
    onChange: handleValue,
    optionRefs,
  });

  const handleFocus = async (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();

    const currentIndex = options.findIndex((option) => option.value === value);
    const index = Math.max(currentIndex, 0);

    inputRef.current?.scrollIntoView({ block: 'nearest' });
    // NOTE(@이지원): input으로 scroll 되면서 options가 바로 닫히는 현상을 방지하기 위함
    await sleep(8);

    setSelectedIndex(index);
    setSearchValue('');
    openOptions();
    await sleep(8);
    optionRefs.current.at(index)?.scrollIntoView({ block: 'nearest' });
  };

  const handleSearchValueChange = (searchValue: string) => {
    setSearchValue(searchValue ?? '');
    setSelectedIndex(0);
  };

  const handleOptionClick = (value: string | null) => {
    handleValue(value);
    closeOptions();
  };

  const focusInput = () => inputRef.current?.focus({ preventScroll: true });

  const handleIconMouseDown = () => {
    if (isOpenOptions) {
      closeOptions();
      return;
    }
    focusInput();
  };

  const placeholder = getLabel(value) ?? givenPlaceholder;

  return (
    <SearchSelectProvider
      value={{ isOpen: isOpenOptions, onClose: closeOptions }}
    >
      <div className={containerClass({ className })}>
        <div ref={refs.setReference} className={inputContainer({ error })}>
          <input
            type="text"
            ref={inputRef}
            value={searchValue}
            onChange={(event) => handleSearchValueChange(event.target.value)}
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            className={input()}
            placeholder={placeholder}
          />
          <IconControlArrowDown
            className={icon()}
            onMouseDown={handleIconMouseDown}
          />
        </div>
        <FloatingPortal>
          {isOpenOptions && (
            <ul
              ref={refs.setFloating}
              className={optionContainer()}
              style={floatingStyles}
            >
              <div className="flex-1 overflow-y-auto">
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option, index) => (
                    <Fragment key={option.value}>
                      <SearchSelectOption
                        ref={(element) => (optionRefs.current[index] = element)}
                        active={
                          selectedIndex === index || option.value === value
                        }
                        className={option.className}
                        onMouseDown={() => handleOptionClick(option.value)}
                      >
                        {option.label}
                      </SearchSelectOption>
                      {index !== filteredOptions.length - 1 && (
                        <hr className="h-1px border-0 bg-gray-200" />
                      )}
                    </Fragment>
                  ))
                ) : (
                  <li className="h-36px px-16px flex items-center">
                    <span className="text-medium-s select-none text-gray-400">
                      검색 결과 없음
                    </span>
                  </li>
                )}
              </div>
              {footer}
            </ul>
          )}
        </FloatingPortal>
      </div>
    </SearchSelectProvider>
  );
}

function filterBySearchValue(searchValue: string) {
  return (option: SearchSelectOption) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase()) &&
    option.label !== '';
}

const containerClass = cva('relative');

const inputContainer = cva(
  [
    'h-40px rounded-8px flex w-full items-center overflow-hidden border border-gray-300',
    'focus-within:border-blue-400',
  ],
  {
    variants: {
      error: {
        true: 'border-red-400',
      },
    },
  }
);

const input = cva(
  'pl-16px pr-40px text-medium-s h-full min-w-0 flex-1 text-gray-700 outline-none placeholder:text-gray-400'
);

const icon = cva('right-16px absolute text-gray-700');

const optionContainer = cva([
  'z-dialogDropdown relative flex w-full flex-col overflow-hidden rounded-lg border bg-white',
  'shadow-[0_4px_12px_rgb(0,0,0,0.12)]',
]);

SearchSelect.Footer = SearchSelectFooter;
