import { ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

export interface Interview {
  logo: string;
  companyName: string;
  position: string;
  title: string;
  content: ReactNode;
  description: string;
}

interface InterviewCardProps {
  size: 'lg' | 'sm';
  rotated: boolean;
  visible: boolean;
  interview?: Interview;
}

function InterviewCard({
  size,
  rotated,
  visible,
  interview,
}: InterviewCardProps) {
  return (
    <div className={container({ rotated, visible, size })}>
      {interview != null && (
        <div className="flex size-full flex-col justify-between text-left">
          <div className={header({ size })}>
            <img
              className={logoImage({ size })}
              src={interview.logo}
              alt="profile1"
            />
            <p className={headerTitle({ size })}>
              <span className="text-blue-600">{interview.companyName}</span>{' '}
              {interview.position}
            </p>
          </div>
          <div className={contentContainer({ size })}>
            {size === 'lg' && (
              <p className="text-[18px] font-bold leading-[29px] tracking-tight">
                {interview.title}
              </p>
            )}
            <p className={content()}>
              {size === 'sm' && <span>ㅤ</span>}
              {interview.content}
            </p>
          </div>
          <p className={description({ size })}>{interview.description}</p>
        </div>
      )}
    </div>
  );
}

const container = cva(
  'flex flex-col items-center justify-center gap-[10px] rounded-[32px] border border-white bg-gradient-to-b from-[rgba(255,255,255,0.96)] to-[rgba(255,255,255,0.69)] px-[30px] py-[36px] shadow-[0_0_40px_0_#00000012] transition-all duration-1000',
  {
    variants: {
      size: {
        lg: 'h-[409px] w-[512px]',
        sm: 'h-[240px] w-[270px]',
      },
      rotated: {
        true: 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[-15deg]',
        false: 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
      },
      visible: {
        true: '',
        false: 'opacity-0',
      },
    },
  }
);

const header = cva('flex items-center', {
  variants: {
    size: {
      lg: 'gap-[16px]',
      sm: 'gap-[8px]',
    },
  },
});

const logoImage = cva('', {
  variants: {
    size: {
      lg: 'size-[60px]',
      sm: 'size-[40px]',
    },
  },
});

const headerTitle = cva('font-bold tracking-tight', {
  variants: {
    size: {
      lg: 'text-[18px] leading-[24px]',
      sm: 'text-[15px] leading-[20px]',
    },
  },
});

const contentContainer = cva('flex flex-col gap-[12px]', {
  variants: {
    size: {
      lg: 'gap-[12px]',
      sm: 'gap-[8px]',
    },
  },
});

const content = cva('text-[16px] font-medium leading-[25px] tracking-tight');

const description = cva('font-medium tracking-tight text-gray-600', {
  variants: {
    size: {
      lg: 'text-[16px] leading-[24px]',
      sm: 'text-[13px] leading-[20px]',
    },
  },
});

export default InterviewCard;
