import { RemoteStorageFileType } from '@sweep/contract';
import { client } from '../client';

export async function upload(file: File, fileType: RemoteStorageFileType) {
  return await client.remoteStorageFile.upload({
    params: {
      fileType,
    },
    body: {
      file,
    },
  });
}
