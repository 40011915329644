import { BrandLogoByName, MultiSelect } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface ShoppingMallMultiSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
}

function ShoppingMallMultiSelect({
  value,
  onChange,
  className,
}: ShoppingMallMultiSelectProps) {
  const oms = useOMSStore();
  const shoppingMalls = oms.shoppingMall.shoppingMallNames;

  return (
    <MultiSelect value={value} onChange={onChange} className={className}>
      <MultiSelect.MultiOption value={shoppingMalls}>
        전체
      </MultiSelect.MultiOption>
      {shoppingMalls.map((shoppingMall) => (
        <MultiSelect.Option key={shoppingMall} value={shoppingMall}>
          <div className="gap-6px flex items-center">
            <BrandLogoByName size="sm" name={shoppingMall} />
            {shoppingMall}
          </div>
        </MultiSelect.Option>
      ))}
    </MultiSelect>
  );
}

export default ShoppingMallMultiSelect;
