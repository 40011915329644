import { CellValue, Workbook, Worksheet } from 'exceljs';
import { findHeaderKeys } from 'services/column-mapping/findHeaderKeys';
import { Order } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';

const EXCEL_EMPTY_CELL = ' ';

export interface CreateOrderExcelFromTemplateOptions {
  templateFile: string;
  headerRowIndex: number;
  minRowIndex: number;
}

export async function createOrderExcelFromTemplate(
  header: string[],
  columnMapping: ColumnMapping,
  orders: Order[],
  options: CreateOrderExcelFromTemplateOptions
): Promise<ArrayBuffer | null> {
  const workbook = await createWorkbook(options.templateFile);
  if (workbook == null) {
    return null;
  }

  const worksheetIndex = getVisibleWorksheetIndex(workbook);
  if (worksheetIndex === -1) {
    return null;
  }
  const worksheet = workbook.worksheets[worksheetIndex];

  const headerWithoutMerged = findHeaderWithoutMerged(
    header,
    options.headerRowIndex,
    worksheet
  );

  const orderRows = createOrderRows(
    headerWithoutMerged,
    columnMapping,
    orders,
    options
  );

  const writedWorksheet = writeWorksheet(worksheet, orderRows, options);

  workbook.worksheets[worksheetIndex] = writedWorksheet;

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
}

async function createWorkbook(templateFile: string) {
  const templateArray = new Uint8Array(JSON.parse(templateFile));
  const templateBuffer = templateArray.buffer;

  const workbook = new Workbook();
  try {
    await workbook.xlsx.load(templateBuffer);
  } catch {
    return null;
  }

  workbook.worksheets.forEach((worksheet) => {
    worksheet.removeConditionalFormatting(null);
  });

  // NOTE(@이지원): https://sweepoms.slack.com/archives/C06GP1FDYRY/p1727786980618789
  const isNotExistView = workbook.views == null || workbook.views.length === 0;
  if (isNotExistView) {
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 1,
        activeTab: 0,
        visibility: 'visible',
      },
    ];
  }
  workbook.views[0].activeTab = 1;
  workbook.views[0].firstSheet = 0;

  return workbook;
}

function getVisibleWorksheetIndex(workbook: Workbook) {
  return workbook.worksheets.findIndex(
    (worksheet) => worksheet.state === 'visible'
  );
}

function findHeaderWithoutMerged(
  header: string[],
  headerRowIndex: number,
  worksheet: Worksheet
) {
  const headerWithoutMerged = header.map((headerItem, index) => {
    const cell = worksheet.getRow(headerRowIndex).getCell(index + 1);
    return cell.isMerged ? null : headerItem;
  });

  while (
    headerWithoutMerged.length > 0 &&
    headerWithoutMerged[headerWithoutMerged.length - 1] === null
  ) {
    headerWithoutMerged.pop();
  }

  return headerWithoutMerged.map((headerItem) => headerItem ?? '');
}

function createOrderRows(
  header: string[],
  columnMapping: ColumnMapping,
  orders: Order[],
  options: CreateOrderExcelFromTemplateOptions
) {
  const headerKeys = findHeaderKeys(header, columnMapping);
  const orderRows = orders.map((order) => {
    return headerKeys.map<string | number>((key) => {
      if (key == null) {
        return '';
      }

      const value = order[key];
      if (typeof value === 'number') {
        // NOTE(@이지원): 주문번호, 전화번호 와 같은 숫자는 string으로 표시
        const isStringDisplayed = value > 1000000000;
        return isStringDisplayed ? value.toString() : value;
      }

      const stringValue = value?.toString() ?? '';
      return stringValue === '' ? EXCEL_EMPTY_CELL : stringValue;
    });
  });

  for (let i = options.headerRowIndex + 1; i <= options.minRowIndex; i++) {
    orderRows.push(Array(header.length).fill(' '));
  }

  return orderRows;
}

function writeWorksheet(
  worksheet: Worksheet,
  orderRows: (string | number)[][],
  options: CreateOrderExcelFromTemplateOptions
) {
  const prevCellValue: {
    [key: number]: CellValue | undefined;
  } = {};

  orderRows.forEach((orderRow, rowIndex) => {
    const row = worksheet.getRow(rowIndex + options.headerRowIndex + 1);

    orderRow.forEach((cell, cellIndex) => {
      const excelIndex = cellIndex + 1;

      if (cell === '') {
        // NOTE(@이지원): 기존 로직이라 옮겨두었는데, 정확히 어떤 케이스에서 필요한지 알아보기
        prevCellValue[cellIndex] =
          prevCellValue[cellIndex] ?? row.getCell(excelIndex).value;
        row.getCell(excelIndex).value = prevCellValue[cellIndex];
        return;
      }

      row.getCell(excelIndex).value = cell;
    });
  });

  return worksheet;
}
