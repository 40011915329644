import { useRecatchScript } from 'hooks/useRecatchScript';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { amplitude } from 'src/third-parties/amplitude';
import Button from '../components/buttons/Button';
import NewFooter from '../components/footer/NewFooter';
import examplePDF from '../files/ex.pdf';
import useGoogleTag from '../hooks/useGoogleTag';
import closeIcon from '../icons/close.png';
import JuSo from '../icons/juso';
import {
  imageLogoSymbolTextHorizontal,
  imgsection2bg,
  imgsection3floating,
  imgsection4mobile,
  imgsection4tablet,
  imgsection5floating,
  imgTablet,
  imgTabletFloating,
  imgWorking,
} from '../images';
import dim from '../images/dim.png';
import dim2 from '../images/dim2.png';
import company from '../images/ic-company.png';
import screen3_ic from '../images/ic-section3.png';
import screen4_ic from '../images/ic-section4.png';
import screen5_ic from '../images/ic-section5.png';
import screen6_ic_A from '../images/ic-section6-A.png';
import screen6_ic_B from '../images/ic-section6-B.png';
import screen6_ic_C from '../images/ic-section6-C.png';
import screen7_ic from '../images/ic-section7.png';
import mobilepic from '../images/mobileScreen1.png';
import screen_2_gradation from '../images/screen_2_back_gradation.png';
import screen_8 from '../images/screen-8.png';
import screen2mobile from '../images/screen2mobile.png';
import screen3_left from '../images/screen3-left.png';
import screen3_main from '../images/screen3-main.png';
import screen3_rightlower from '../images/screen3-rightlower.png';
import screen5_left from '../images/screen5-left.png';
import screen5_main from '../images/screen5-main.png';
import screen5_right from '../images/screen5-right.png';
import screen8dim from '../images/screen8-dim.png';
import section1left2 from '../images/section1left2.png';
import section1right1 from '../images/section1right1.png';
import section1right2 from '../images/section1right2.png';
import Header from './landing-page/components/Header';

const App = () => {
  useGoogleTag('AW-16638455187');
  useRecatchScript();

  const planScroll = useRef(null);
  const featureScroll = useRef(null);
  const scrollToRight = (ref) => {
    if (ref.current != null) {
      ref.current.scrollLeft = Math.min(
        ref.current.scrollWidth,
        ref.current.scrollLeft + 300
      );
    }
  };

  const preRegistrationLink = `${window.location.origin}/pre-registration`;
  const isMobile = useMediaQuery({
    query: '(max-width: 1099px) or (orientation: portrait)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 820px) and (orientation: portrait)',
  });

  const feedbackOn = false;
  const goto = (page) => {
    if (page === 'dispatch') {
      navigate('/introduce-order-dispatch');
    }
  };
  useEffect(() => {
    if (scrollRef.current != null) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    new fp('#fullpage', {
      autoScrolling: true,
      navigation: !isMobile || isTablet,
      lockAnchors: false,
      anchors: [
        'home',
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'footer',
      ],
      normalScrollElements: '.scrollable-content',
      navigationPosition: 'right',
      showActiveTooltip: true,
      scrollOverflow: false,
      // sectionsColor: ['#ff5f45', '#0798ec', '#fc6c7c'],
      menu: '#menu',
      // responsiveHeight: 500,
      scrollingSpeed: 700,
      loopBottom: true,
      beforeLeave: (origin, destination, direction, trigger) => {
        if (!isMobile) {
          return true;
        }
        if (
          origin.index === 5 &&
          destination.index === 6 &&
          featureScroll.current.scrollLeft === 0
        ) {
          scrollToRight(featureScroll);
          return false;
        }
        if (
          origin.index === 6 &&
          destination.index === 7 &&
          planScroll.current.scrollLeft === 0
        ) {
          scrollToRight(planScroll);
          return false;
        }
      },
      afterLoad: (origin, destination, direction) => {
        window.scrollTo(0, 1);
        setCurrentPage(destination.index);
      },
      onLeave: (origin, destination, direction) => {
        setDestinationPage(destination.index);
        if (direction === 'down') {
          setShowHeader(false);
        } else if (direction === 'up') {
          setShowHeader(true);
        }
        if (destination.index === 0) {
          setShowHeader(true);
        }
      },
    });

    // window.addEventListener('resize', handleResize);

    return () => {
      // window.removeEventListener('resize', handleResize);
      if (window.fullpage_api != null) {
        window.fullpage_api.destroy('all');
      }
    };
  }, [isMobile, isTablet]);

  const [iframeLoading, setIframeLoading] = useState(true);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };
  const scrollRef = useRef(null);
  const fp = window.fullpage;
  const [showHeader, setShowHeader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [destinationPage, setDestinationPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(true);
  // 마우스가 슬라이더 진입 시 호출, false값으로 애니메이션 중지
  const onStop = () => setAnimate(false);
  // 마우스가 슬라이더 떠날 때 호출, true값으로 애니메이션 재시작
  const onRun = () => setAnimate(true);

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = examplePDF;
    link.setAttribute('download', '[스윕] 발주관리서비스소개서.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [isWorkingOnModalOpen, setIsWorkingOnModalOpen] = useState(false);
  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount === 3 ? 1 : prevCount + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const userFeedback = [
    {
      name: '주식회사 프링글스',
      content:
        '스윕을 도입하기 이전에는 프링글스를 일일히 배송했어야 하는데 일정 양식에 맟주어 발주해야하거나 발주양식이 상이할때 스윕이 정말 최고에요!',
    },
    {
      name: '주식회사 프링글스',
      content:
        '스윕을 도입하기 이전에는 프링글스를 일일히 배송했어야 하는데 일정 양식에 맟주어 발주해야하거나 발주양식이 상이할때 스윕이 정말 최고에요!',
    },
    {
      name: '주식회사 프링글스',
      content:
        '스윕을 도입하기 이전에는 프링글스를 일일히 배송했어야 하는데 일정 양식에 맟주어 발주해야하거나 발주양식이 상이할때 스윕이 정말 최고에요!',
    },
    {
      name: '주식회사 프링글스',
      content:
        '스윕을 도입하기 이전에는 프링글스를 일일히 배송했어야 하는데 일정 양식에 맟주어 발주해야하거나 발주양식이 상이할때 스윕이 정말 최고에요!',
    },
    {
      name: '주식회사 프링글스',
      content:
        '스윕을 도입하기 이전에는 프링글스를 일일히 배송했어야 하는데 일정 양식에 맟주어 발주해야하거나 발주양식이 상이할때 스윕이 정말 최고에요!',
    },
    {
      name: '주식회사 프링글스',
      content:
        '스윕을 도입하기 이전에는 프링글스를 일일히 배송했어야 하는데 일정 양식에 맟주어 발주해야하거나 발주양식이 상이할때 스윕이 정말 최고에요!',
    },
  ];
  const plans = [
    {
      name: 'Basic Plan',
      korName: '베이직 플랜',
      origPrice: '₩199,900/월',
      price: '₩99,000/월',
      discount: '50%',

      features: (
        <>
          <span>월 주문 건수 1만건 미만</span>
          <span>발주 파일 통합 및 분리</span>
          <span>상품정보 설정 및 자동 매칭</span>
          <span>일일, 기간별, 파트너사별 정산관리</span>
          <span>합/분리배송 처리 및 기타 엑셀업무</span>
        </>
      ),
      color: 'bg-[#F2F6FA]',
      textColor: 'text-[#263142]',
      desColor: 'text-[#9FB1C1]',
    },
    {
      name: 'Standard Plan',
      korName: '스탠다드 플랜',
      origPrice: '₩399,900/월',
      price: '₩199,000/월',
      discount: '50%',
      features: (
        <>
          <span>Basic Plan의 모든 기능</span>
          <span className="text-blue-500">
            오픈마켓, 폐쇄몰 주문통합 (24.05~)
          </span>
          <span className="text-blue-500">
            발주 메일 송수신 자동화 (24.08/예정)
          </span>

          <span className="text-blue-500">
            세금계산서 발행 자동화 (24.09/예정)
          </span>
        </>
      ),
      color: 'bg-blue-500',
      textColor: 'text-white',
      desColor: 'text-white',
    },
    {
      name: 'Enterprise Plan',
      korName: '엔터프라이즈 플랜',
      origPrice: '서울대출신 개발자 4명 상시대기',
      price: '₩499,000/월',
      discount: '',
      new: true,
      features: (
        <>
          <span>Standard Plan의 모든 기능</span>
          <span className="text-blue-500">커스텀 기능 개발</span>
          <span className="text-blue-500">초기 세팅 담당 매니저 배정</span>

          <span className="text-blue-500">추가 요청사항 담당 매니저 배정</span>
        </>
      ),
      color: 'bg-custom-gradient-blue-to-white',
      textColor: 'text-white',
      desColor: 'text-white',
    },
  ];

  return (
    <div className="flex flex-col">
      {isTablet && (
        <>
          <header
            className={`font-pretendard fixed  z-10 flex w-full flex-col items-center border-b border-[#E0E7ED] bg-white transition-transform duration-300 ${
              showHeader ? 'translate-y-0' : '-translate-y-full'
            }`}
          >
            <div
              className={`flex w-full flex-col items-center justify-between px-[100px] py-4 md:flex-row`}
            >
              <div
                className="logo mx-auto md:mx-0"
                onClick={() => navigate('/')}
              >
                <img
                  src={imageLogoSymbolTextHorizontal}
                  alt="service icon"
                  className="h-auto w-[95px]"
                />
              </div>
              <div className="flex items-center gap-[16px]">
                <div
                  onClick={() => navigate('/sign-in')}
                  className="text-md cursor-pointer rounded-lg px-[16px] py-[10px] text-[16px] font-semibold text-blue-500 hover:bg-blue-100"
                >
                  로그인
                </div>
                <div className="hidden md:block">
                  <Button
                    name="도입 문의하기"
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    className="text-sm"
                  />
                </div>
              </div>
            </div>
          </header>
          <div className="flex flex-col" id="fullpage">
            <div className="section" id="screen1">
              <section
                className={`relative mt-[76px] flex h-screen justify-center`}
              >
                <img
                  src={imgTablet}
                  alt="absolute background"
                  className="z-2 absolute min-w-[1024px] object-cover"
                />
                {currentPage === 0 && (
                  <>
                    <img
                      className="animate-slideindown5 z-3 absolute top-0 min-w-[1024px]"
                      src={imgTabletFloating}
                      alt="rightupper"
                    />
                  </>
                )}

                <div className="relative z-10 flex size-full p-[100px] ">
                  {currentPage === 0 && (
                    <div className="animate-slideindown30delay flex w-full flex-col opacity-0">
                      <div className="font-pretendard mb-[16px] text-[40px] font-bold text-[#343D4B]">
                        <p className="whitespace-nowrap leading-tight">
                          발주 관리 자동화를 위한
                          <br />
                          가장 앞선 솔루션
                        </p>
                      </div>

                      <div className="preserve-line-breaks mb-[50px] text-[20px] font-semibold text-[#708AA2]">
                        <p className="max-w-[650px] text-[18px]">
                          스윕은 반복되는 발주 관리 업무에서 벗어나 중요한
                          업무에 집중할 수 있도록 <br />
                          <span className="mr-1 text-blue-500">
                            단 몇 번의 클릭만으로, 쉽게
                          </span>
                          발주 관리 업무를 자동화합니다.
                        </p>
                      </div>
                      <div className="font-pretendard absolute bottom-4 mb-[50px] mt-auto flex w-full space-x-[8px]">
                        <button
                          type="button"
                          className="flex h-[42px] w-full cursor-pointer items-center justify-center rounded-lg bg-blue-500 text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                          onClick={() => setIsModalOpen(true)}
                        >
                          도입 문의하기
                        </button>
                        <button
                          type="button"
                          onClick={downloadPDF}
                          className=" flex h-[42px] w-full cursor-pointer items-center justify-center rounded-lg bg-[#343D4B] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                        >
                          제품 소개서 받기
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
            <div className="section">
              <section
                className={`relative flex h-screen flex-col justify-center overflow-hidden ${showHeader ? 'mt-[60px]' : 'mt-0'}`}
              >
                <img
                  src={imgsection2bg}
                  alt="absolute background"
                  className="absolute z-0 size-full object-cover"
                />
                <div className=" flex flex-col">
                  <div className=" flex flex-col items-center">
                    <div className="py-[20px]">
                      {currentPage === 1 && (
                        <div
                          className={`animate-slideindown20 z-10 transition-opacity duration-500 ${destinationPage === 1 ? 'opacity-100' : 'opacity-0'}  font-pretendard flex w-full flex-col items-center text-[36px] font-bold leading-tight text-[#263142]`}
                        >
                          <span>이미 수많은 위탁판매업체, 공급업체들이</span>
                          <span>스윕을 통해 발주 관리 업무를</span>
                          <span>자동화하고 있습니다.</span>
                        </div>
                      )}
                    </div>
                    <div className="flex w-[834px] justify-center space-x-[40px] p-[60px]">
                      {currentPage === 1 && (
                        <>
                          <div className="animate-slideindown40 flex flex-col">
                            <span className="font-pretendard text-[40px] font-bold text-[#343D4B]">
                              200+
                            </span>
                            <span className="font-pretendard text-[16px] font-semibold text-[#343D4B]">
                              <span className="text-blue-500">
                                월당 200만건 이상의 주문
                              </span>
                              이<br /> 스윕을 통해 안정적으로
                              <br /> 처리되고 있습니다.
                            </span>
                          </div>
                          <div className="animate-slideindown40delay flex flex-col opacity-0">
                            <span className="font-pretendard text-[40px] font-bold text-[#343D4B]">
                              50+
                            </span>
                            <span className="font-pretendard text-[16px] font-semibold text-[#343D4B]">
                              이미{' '}
                              <span className="text-blue-500">
                                50개 이상의 업체
                              </span>
                              에서
                              <br /> 스윕 솔루션을 도입해 <br />
                              발주관리업무를 처리하고 있습니다.
                            </span>
                          </div>
                          <div className="animate-slideindown40delaydelay flex flex-col opacity-0">
                            <span className="font-pretendard text-[40px] font-bold text-[#343D4B]">
                              300+
                            </span>
                            <span className="font-pretendard text-[16px] font-semibold text-[#343D4B]">
                              <span className="text-blue-500">
                                연매출 300억원 이상의 <br />
                                업체도{' '}
                              </span>
                              스윕 솔루션을 도입해 <br />
                              사용중입니다.
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {currentPage === 1 && (
                    <div
                      className={`animate-fadeIn1 relative my-[80px] flex items-center`}
                    >
                      {' '}
                      <img
                        src={screen_2_gradation}
                        alt="absolute background"
                        className="z-1 absolute min-w-[1920px]"
                      />
                      <img
                        src={dim}
                        alt="absolute background "
                        className="absolute right-0 z-50"
                      />
                      <img
                        src={dim2}
                        alt="absolute background "
                        className="absolute left-0 z-50"
                      />
                      <div
                        className={
                          'flex skidrow items-center' +
                          ((feedbackOn ?? false) ? '' : ' hidden')
                        }
                        onMouseEnter={onStop}
                        onMouseLeave={onRun}
                      >
                        <div
                          className={
                            'flex items-center skid original' +
                            (animate ? '' : ' stop')
                          }
                        >
                          {userFeedback.map((feedback, i) => (
                            <div
                              key={i}
                              className={`z-10 mx-4 flex h-[280px] w-[300px] flex-none flex-col space-y-[12px] rounded-[16px] bg-white px-[40px] py-[28px] transition-all duration-500 ease-in-out hover:scale-125`}
                              style={{
                                boxShadow:
                                  '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                              }}
                            >
                              <div className="flex w-full">
                                <img
                                  src={company}
                                  className="size-[36px]"
                                ></img>
                                <span className="font-pretendard flex items-center justify-center px-[12px] py-[6px] text-[18px] font-bold text-[#343D4B]">
                                  {feedback.name}
                                </span>
                              </div>
                              <div className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                                {feedback.content}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          className={
                            'flex items-center skid clone' +
                            (animate ? '' : ' stop')
                          }
                        >
                          {userFeedback.map((feedback, i) => (
                            <div
                              key={i}
                              className={`z-10 mx-4 flex h-[280px] w-[300px] flex-none flex-col space-y-[12px] rounded-[16px] bg-white px-[40px] py-[28px] transition-all duration-500 ease-in-out hover:scale-125`}
                              style={{
                                boxShadow:
                                  '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                              }}
                            >
                              <div className="flex w-full">
                                <img
                                  src={company}
                                  className="size-[36px]"
                                ></img>
                                <span className="font-pretendard flex items-center justify-center px-[12px] py-[6px] text-[18px] font-bold text-[#343D4B]">
                                  {feedback.name}
                                </span>
                              </div>
                              <div className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                                {feedback.content}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
            <div className="section">
              <section
                className={`flex h-screen flex-col items-center bg-[#EBF5FF]`}
              >
                <div className="flex w-[834px] flex-col">
                  {currentPage === 2 && (
                    <div className="animate-slideindown30delaydelay flex flex-col justify-center px-[100px] pb-[40px] pt-[30px] opacity-0">
                      <div className="my-[50px] flex">
                        <img
                          className="w-[68px] rounded-[13.6px] shadow-md"
                          src={screen3_ic}
                          alt="icon"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-pretendard mb-[16px] whitespace-nowrap text-[35px] font-bold leading-tight text-[#263142]">
                          <p>주문 수집부터</p>
                          <p>판매처 송장 입력까지</p>
                          <p>주문처리 전 과정을 자동으로</p>
                        </div>
                        <div className="font-pretendard text-[18px] font-medium text-[#708AA2]">
                          <p>판매자 페이지에 로그인 할 필요 없이</p>
                          <p>주문 수집, 취소 건 관리, 송장 입력까지</p>
                          <p>
                            <span className="text-blue-500">
                              쇼핑몰 주문처리 과정을 자동으로 처리
                            </span>
                            합니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentPage === 2 && (
                    <div className="relative flex h-[517px] items-center justify-center">
                      <img
                        className="animate-slideindown20 w-[634px]  rounded-[8px] shadow-md"
                        src={screen3_main}
                        alt="main"
                      />
                      <img
                        className="animate-slideindowndelay absolute top-[15px] w-[834px] opacity-0"
                        src={imgsection3floating}
                        alt="rightupper"
                      />
                    </div>
                  )}
                  {currentPage === 2 && (
                    <div className="animate-slideindown30delaydelay flex px-[100px]  py-[40px] opacity-0">
                      <button
                        type="button"
                        className="flex h-[42px] w-[313px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                        onClick={() => goto('dispatch')}
                      >
                        쇼핑몰 통합 관리하기
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </div>
            <div className="section">
              <section
                className={`flex h-screen flex-col items-center bg-[#EBF5FF]`}
              >
                <div className="flex w-[834px] flex-col">
                  {currentPage === 3 && (
                    <div className="animate-slideindown30delaydelay flex flex-col justify-center px-[100px] pb-[40px] pt-[30px] opacity-0">
                      <div className="my-[50px] flex">
                        <img
                          className="w-[68px] rounded-[13.6px] shadow-md"
                          src={screen4_ic}
                          alt="icon"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-pretendard mb-[16px] whitespace-nowrap text-[35px] font-bold leading-tight text-[#263142]">
                          <p>번거로운 복사 붙여넣기 작업 없이</p>
                          <p>쉽고 간편한 원클릭 발주통합</p>
                        </div>
                        <div className="font-pretendard text-[18px] font-medium text-[#708AA2]">
                          <p>
                            파트너사별로 상이한 주문서 엑셀 형식 및 상품명을
                          </p>
                          <p>
                            직접 하나의 엑셀 파일로 변환했던 작업 방식에서
                            벗어나
                          </p>
                          <p>
                            주문 엑셀 파일 입력 시{' '}
                            <span className="text-blue-500">
                              원하는 통합 엑셀 양식
                            </span>
                            으로
                          </p>
                          <p>
                            <span className="text-blue-500">자동 변환</span>
                            됩니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentPage === 3 && (
                    <div className="relative flex h-[517px] items-center  justify-center">
                      <img
                        className="animate-slideindown20 w-[634px] "
                        src={imgsection4tablet}
                        alt="main"
                      />
                    </div>
                  )}
                  {currentPage === 3 && (
                    <div className="animate-slideindown30delaydelay flex px-[100px]  py-[40px] opacity-0">
                      <button
                        type="button"
                        className="flex h-[42px] w-[313px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                        onClick={() => navigate('/introduce-order-manage')}
                      >
                        발주 관리하기
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </div>
            <div className="section">
              <section
                className={`bg-custom-gradient-lightblue-to-white flex h-screen flex-col items-center`}
              >
                <div className="flex w-[834px] flex-col ">
                  {currentPage === 4 && (
                    <div className="animate-slideindown30delaydelay flex flex-col justify-center px-[100px] pb-[40px] pt-[30px] opacity-0">
                      <div className="my-[50px] flex">
                        <img
                          className="w-[68px] rounded-[13.6px] shadow-md"
                          src={screen5_ic}
                          alt="icon"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-pretendard mb-[16px] whitespace-nowrap text-[35px] font-bold leading-tight text-[#263142]">
                          <p>통합된 발주서에 기반해</p>
                          <p>일일 정산, 업체 및 기간별</p>
                          <p>정산도 자동으로</p>
                        </div>
                        <div className="font-pretendard text-[18px] font-medium text-[#708AA2]">
                          <p>
                            수기로 일일 및 기간별 정산관리, 파트어사 및 공급사별
                          </p>
                          <p>
                            정산관리 했던 방식에서 벗어나{' '}
                            <span className="text-blue-500">
                              스윕 사용 과정
                            </span>
                            에서
                          </p>
                          <p>
                            <span className="text-blue-500">
                              저장된 주문 정보를 바탕으로 정산파일을 생성
                            </span>
                            해줍니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentPage === 4 && (
                    <div className="relative flex h-[517px] items-center justify-center">
                      <img
                        className="animate-slideindown20 w-[634px]  rounded-[8px] shadow-md"
                        src={screen5_main}
                        alt="main"
                      />
                      <img
                        className="animate-slideindowndelay absolute top-[15px] w-[834px] opacity-0"
                        src={imgsection5floating}
                        alt="rightupper"
                      />
                    </div>
                  )}
                  {currentPage === 4 && (
                    <div className="animate-slideindown30delaydelay flex px-[100px]  py-[40px] opacity-0">
                      <button
                        type="button"
                        className="flex h-[42px] w-[313px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                        onClick={() => setIsWorkingOnModalOpen(true)}
                      >
                        정산 관리하기
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </div>

            <div className="section">
              <section className="flex h-screen flex-col items-center bg-white ">
                <div
                  className={` ${showHeader ? 'mt-[100px]' : 'mt-[80px]'} flex flex-col `}
                >
                  <div className="flex w-full flex-col items-center space-y-[16px] py-[80px] ">
                    <span className="font-pretendard text-[40px] font-bold leading-tight text-[#263142]">
                      이런 업무도 처리할 수 있나요?
                    </span>
                    <div className="font-pretendard leading-medium flex flex-col items-center text-[19px]  font-medium text-[#263142]">
                      <span>
                        스윕은 아래 3개의 기능을 시작으로 서비스 이용 과정에서
                        고객의 피드백을 반영해
                      </span>
                      <span>최상의 해결책을 제공할 예정입니다. </span>
                    </div>
                  </div>
                  <div
                    className="-webkit-overflow-scrolling-touch word-wrap  flex w-full touch-auto items-center justify-center"
                    style={{ wordBreak: 'keep-all' }}
                  >
                    <div
                      ref={featureScroll}
                      className=" -webkit-overflow-scrolling-touch flex w-full max-w-[100vw] touch-auto snap-x snap-mandatory space-x-[28px] overflow-x-auto scroll-smooth px-[100px]"
                    >
                      <div className="bg-custom-gradient2 flex h-[479px] w-[312px] flex-none snap-center flex-col rounded-[16px] p-[40px]">
                        <img
                          src={screen6_ic_A}
                          alt="icon"
                          className="size-[68px]"
                        />
                        <div className="flex flex-col">
                          <div className="my-[24px] flex flex-col">
                            <span className="font-pretendard mb-[16px] text-[24px] font-bold text-[#263142]">
                              쇼핑몰 추가 연동
                            </span>
                            <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                              <span>
                                쇼핑몰마다 주문 파일을 다운로드할 필요없이 주문
                                건을 자동 통합 및 다운로드합니다.
                              </span>
                            </div>
                          </div>
                          <hr className="mb-[24px] border border-[#CED7E0]" />
                          <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                            <p>
                              연동된 일부 쇼핑몰에 대해서만 주문통합 기능을
                              제공하는 기존 솔루션과 달리,{' '}
                              <span className="text-blue-500">
                                오픈마켓부터 폐쇄몰까지 모든 쇼핑몰의 주문을
                                가져와 통합 및 관리
                              </span>
                              할 수 있습니다.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-custom-gradient2 flex h-[479px] w-[312px] flex-none snap-center flex-col rounded-[16px] p-[40px]">
                        <img
                          src={screen6_ic_B}
                          alt="icon"
                          className="size-[68px]"
                        />
                        <div className="flex flex-col">
                          <div className="my-[24px] flex flex-col">
                            <span className="font-pretendard mb-[16px] text-[24px] font-bold leading-tight text-[#263142]">
                              <p>발주 메일</p>
                              <p>송수신 자동화</p>
                            </span>
                            <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                              <span>
                                메일을 하나하나 회신하지 않아도 발주할 수
                                있습니다.
                              </span>
                            </div>
                          </div>
                          <hr className="mb-[24px] border border-[#CED7E0]" />
                          <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                            <p>
                              계정으로 들어온 발주 파일을 서비스에 자동으로 반영
                              및 회신하며{' '}
                              <span className="text-blue-500">
                                메일 송수신 과정을 자동화
                              </span>
                              합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-custom-gradient2 flex h-[479px] w-[312px] flex-none snap-center flex-col rounded-[16px] p-[40px]">
                        <img
                          src={screen6_ic_C}
                          alt="icon"
                          className="size-[68px]"
                        />
                        <div className="flex flex-col">
                          <div className="my-[24px] flex flex-col">
                            <span className="font-pretendard mb-[16px] text-[24px] font-bold leading-tight text-[#263142]">
                              정산확인 및 <br />
                              세금계산서 발행
                            </span>
                            <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                              <span>
                                정산관리에 더해 거래처 확인, 세금계산서 발행까지
                                모든 정산 업무 자동화를 지원합니다.{' '}
                              </span>
                            </div>
                          </div>
                          <hr className="mb-[24px] border border-[#CED7E0]" />
                          <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                            <p>
                              일별 발주 데이터로 공급사-위탁판매사 간 정산파일을
                              생성하고,
                              <span className="text-blue-500">
                                세금계산서 발행까지 원클릭으로 제공
                              </span>
                              합니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="section">
              <section className="flex h-screen flex-col bg-[#F0F8FF] ">
                <div className="flex flex-col">
                  <div className=" flex flex-col justify-center space-y-[30px] px-[100px] pb-[30px] pt-[40px]">
                    <div className="flex">
                      <img
                        className="w-[68px] rounded-[13.6px] shadow-md"
                        src={screen7_ic}
                        alt="icon"
                      />
                    </div>
                    <div className="flex flex-col space-y-[12px]">
                      <div className="font-pretendard whitespace-nowrap text-[35px] font-bold leading-tight text-[#263142]">
                        <p>서비스</p>
                        <p>품질은 높이되,</p>
                        <p>비용은 낮게</p>
                      </div>
                      <div className="font-pretendard text-[19px] text-[#263142]">
                        <p>이 모든 기능을 월당 99,000원의</p>
                        <p>저렴한 비용으로 이용하세요.</p>
                      </div>
                      <div className="font-pretendard text-[16px] text-[#708AA2]">
                        <p>3주 무료 체험 후에 결정해도 늦지 않습니다!</p>
                      </div>
                    </div>
                  </div>

                  <div
                    ref={planScroll}
                    className="scrollable-content -webkit-overflow-scrolling-touch flex size-full max-w-[100vw] touch-auto snap-x snap-mandatory space-x-[28px] overflow-x-auto  scroll-smooth px-[100px] pb-[30px] pt-[15px]  "
                  >
                    {plans.map((plan, i) => (
                      <div
                        key={i}
                        className={`flex h-[496px] w-[312px] flex-none snap-center flex-col space-y-[20px] rounded-[20px]  bg-white p-[20px]`}
                      >
                        <div
                          className={`flex flex-col rounded-[8px] ${plan.color} px-[14px] py-[12px] leading-tight`}
                        >
                          <span
                            className={`text-[24px] font-bold ${plan.textColor}`}
                          >
                            {plan.name}
                          </span>
                          <span
                            className={`text-[14px] font-bold ${plan.desColor}`}
                          >
                            {plan.korName}
                          </span>
                        </div>
                        <div className="flex flex-col space-y-[2px] leading-tight">
                          {!(plan.new ?? false) && (
                            <span className="font-pretendard text-[16px] font-semibold text-[#9FB1C1] line-through">
                              {plan.origPrice}
                            </span>
                          )}
                          {(plan.new ?? false) && (
                            <div className="flex">
                              <span className="rounded-[4px] bg-[#F2F6FA] px-[6px] text-[13px] text-blue-500">
                                NEW
                              </span>
                            </div>
                          )}
                          <div className="flex h-full items-center space-x-[10px]">
                            <span className="font-pretendard h-full text-[26px] font-bold text-[#263142]">
                              {plan.price}
                            </span>
                            {plan.discount && (
                              <div className="flex h-[28px] flex-col justify-center rounded-[8px]  bg-blue-500   px-[8px]">
                                <span className=" font-pretendard text-center text-[14px] font-bold text-white">
                                  {plan.discount}
                                </span>
                              </div>
                            )}
                          </div>
                          <span className=" font-pretendard text-[14px] font-medium text-[#263142]">
                            부가세(VAT)별도
                          </span>
                        </div>
                        <hr className="border border-[#E0E7ED]" />
                        <div
                          className={`flex flex-col space-y-[8px] px-[20px] ${i === 2 ? 'leading-5' : ''} text-[14px] font-medium text-[#263142]`}
                        >
                          {plan.features}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex px-[100px] py-[40px]">
                    <button
                      type="button"
                      className="flex h-[42px] w-[313px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                      onClick={() => setIsModalOpen(true)}
                    >
                      도입 문의하기
                    </button>
                  </div>
                </div>
              </section>
            </div>
            <div className="section">
              <section className="bg-custom-gradient3 relative flex h-screen items-center justify-center ">
                <img
                  src={screen_8}
                  alt="absolute background"
                  className="absolute z-0 h-full  object-cover"
                ></img>
                <img
                  src={screen8dim}
                  alt="absolute background"
                  className="absolute z-0 h-full object-cover"
                ></img>

                <div className="relative flex flex-col items-center">
                  <div className="font-pretendard mb-[16px] text-center text-[40px] font-bold text-[#263142]">
                    <p className="whitespace-nowrap leading-tight">
                      차원이 다른 발주 관리
                      <br />
                      <span className="text-blue-500">3주 무료체험</span>으로
                      지금 사용해보세요
                    </p>
                  </div>

                  <div className="preserve-line-breaks mb-[50px] text-[20px] font-medium text-[#263142]">
                    <p className="max-w-[650px] text-center  text-[18px]">
                      세팅 과정에서의 부담감, 번거로움이 없도록
                      <br />
                      담당자가 화상회의로 안내드려요.
                    </p>
                  </div>
                  <div className="font-pretendard flex">
                    <button
                      type="button"
                      className="mr-2 flex h-[41px] w-[140px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                      onClick={() => setIsModalOpen(true)}
                    >
                      도입 문의하기
                    </button>
                    <button
                      type="button"
                      onClick={downloadPDF}
                      className=" flex h-[41px] w-[140px] cursor-pointer items-center justify-center rounded-lg bg-[#343D4B] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                    >
                      제품 소개서 받기
                    </button>
                  </div>
                </div>
              </section>
            </div>
            <div className="section fp-auto-height">
              <NewFooter isMobile={false} />
            </div>
          </div>
        </>
      )}
      {isMobile && !isTablet && (
        <div className="flex flex-col" id="fullpage" ref={scrollRef}>
          <div className="section" id="screen1">
            <section className={`relative flex h-screen`}>
              <img
                src={mobilepic}
                alt="absolute background"
                className="z-1 absolute  object-cover"
              />
              {currentPage === 0 && (
                <>
                  <img
                    className="animate-slideinright z-2 absolute right-[5vw] top-[35vh] w-[100px]"
                    src={section1left2}
                    alt="rightupper"
                  />
                  <img
                    className="animate-slideinleft z-2 absolute left-0 top-[50vh] w-[220px]"
                    src={section1right1}
                    alt="rightupper"
                  />
                  <img
                    className="animate-slideinright z-2 absolute right-[12vw] top-[55vh] w-[100px]"
                    src={section1right2}
                    alt="rightupper"
                  />
                </>
              )}
              <div className="flex size-full flex-col">
                <Header />
                <div className="h-64px"></div>
                <div className="flex h-full flex-col p-[40px]">
                  <div className="font-pretendard mb-[16px] text-[28px] font-bold text-[#263142]">
                    <p className="whitespace-nowrap leading-tight">
                      발주 관리 자동화를 위한
                      <br />
                      가장 앞선 솔루션
                    </p>
                  </div>

                  <div className="preserve-line-breaks mb-[50px] text-[18px] font-semibold text-[#708AA2]">
                    <p className="max-w-[650px] text-[18px]">
                      단 몇 번의 클릭만으로, 쉽게
                      <br />
                      발주 관리 업무를 자동화합니다.
                    </p>
                  </div>
                </div>
                <div className="font-pretendard absolute bottom-10 z-20 mt-auto flex w-full px-[24px] py-[60px]">
                  <button
                    type="button"
                    className="mr-2 flex h-[41px] w-full cursor-pointer items-center justify-center rounded-lg bg-blue-500 text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                    onClick={() => setIsModalOpen(true)}
                  >
                    도입 문의하기
                  </button>
                  <button
                    type="button"
                    onClick={downloadPDF}
                    className=" flex h-[41px] w-full cursor-pointer items-center justify-center rounded-lg bg-[#343D4B] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                  >
                    제품 소개서 받기
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="section">
            <section
              className={`relative flex h-screen flex-col justify-center`}
            >
              <img
                src={screen2mobile}
                alt="absolute background"
                className="absolute z-0 size-full"
              />

              <div className="flex h-full flex-col justify-center">
                <div className="font-pretendard z-10  flex w-full flex-col justify-center p-[40px] text-[24px] font-bold leading-tight text-[#263142]">
                  <span>이미 수많은</span>
                  <span>위탁판매업체, 공급업체들이</span>
                  <span>스윕을 통해 발주 관리 업무를</span>
                  <span> 자동화하고 있습니다.</span>
                </div>
                <div className="relative h-[188px]">
                  {currentPage === 1 && (
                    <>
                      {count === 1 && (
                        <div
                          className={`animate-slideindown40 absolute z-20 flex flex-col justify-center p-[40px]`}
                        >
                          <span className="font-pretendard  text-[40px] font-bold text-[#343D4B]">
                            200+
                          </span>
                          <span className="font-pretendard text-[16px] font-semibold text-[#343D4B]">
                            <span className="text-blue-500">
                              월당 200만건 이상의 주문
                            </span>
                            이 스윕을 통해 <br />
                            안정적으로 처리되고 있습니다.
                          </span>
                        </div>
                      )}
                      {count === 2 && (
                        <div
                          className={`animate-slideindown40 absolute z-20 flex flex-col justify-center p-[40px]`}
                        >
                          <span className="font-pretendard  text-[40px] font-bold text-[#343D4B]">
                            50+
                          </span>
                          <span className="font-pretendard text-[16px] font-semibold text-[#343D4B]">
                            <span className="text-blue-500">
                              이미 50개 이상의 업체
                            </span>
                            에서 스윕 솔루션을
                            <br />
                            도입해 발주관리업무를 처리하고 있습니다.
                          </span>
                        </div>
                      )}
                      {count === 3 && (
                        <div
                          className={`animate-slideindown40 absolute z-20 flex flex-col justify-center p-[40px]`}
                        >
                          <span className="font-pretendard  text-[40px] font-bold text-[#343D4B]">
                            300+
                          </span>
                          <span className="font-pretendard text-[16px] font-semibold text-[#343D4B]">
                            <span className="text-blue-500">
                              연매출 300억원 이상의 업체도
                            </span>
                            <br />
                            스윕 솔루션을 도입해 사용중입니다.
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={`relative flex items-center py-[40px]`}>
                  <div
                    className={
                      'flex skidrow items-center' +
                      ((feedbackOn ?? false) ? '' : ' hidden')
                    }
                    onMouseEnter={onStop}
                    onMouseLeave={onRun}
                  >
                    <div
                      className={
                        'flex items-center skid original' +
                        (animate ? '' : ' stop')
                      }
                    >
                      {userFeedback.map((feedback, i) => (
                        <div
                          key={i}
                          className={`z-10 mx-4 flex h-[242px] w-[240px] flex-none flex-col space-y-[12px] rounded-[16px] bg-white px-[24px] py-[28px] transition-all duration-500 ease-in-out hover:scale-125`}
                          style={{
                            boxShadow:
                              '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                          }}
                        >
                          <div className="flex w-full">
                            <img src={company} className="size-[36px]"></img>
                            <span className="font-pretendard flex items-center justify-center px-[12px] py-[6px] text-[18px] font-bold text-[#343D4B]">
                              {feedback.name}
                            </span>
                          </div>
                          <div className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                            {feedback.content}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={
                        'flex items-center skid clone' +
                        (animate ? '' : ' stop')
                      }
                    >
                      {userFeedback.map((feedback, i) => (
                        <div
                          key={i}
                          className={`z-10 mx-4 flex h-[242px] w-[240px] flex-none flex-col space-y-[12px] rounded-[16px] bg-white px-[24px] py-[28px] transition-all duration-500 ease-in-out hover:scale-125`}
                          style={{
                            boxShadow:
                              '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                          }}
                        >
                          <div className="flex w-full">
                            <img src={company} className="size-[36px]"></img>
                            <span className="font-pretendard flex items-center justify-center px-[12px] py-[6px] text-[18px] font-bold text-[#343D4B]">
                              {feedback.name}
                            </span>
                          </div>
                          <div className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                            {feedback.content}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="section">
            <section className={`flex h-screen flex-col bg-[#F0F8FF]`}>
              <div className="flex flex-col justify-center">
                <div className="flex h-full flex-col justify-center space-y-[24px] px-[40px] pt-[40px]">
                  <div className="flex">
                    <img
                      className="w-[68px] rounded-[13.6px] shadow-md"
                      src={screen3_ic}
                      alt="icon"
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-pretendard mb-[12px] whitespace-nowrap text-[24px] font-bold leading-tight text-[#263142]">
                      <p>주문 수집부터</p>
                      <p>판매처 송장 입력까지</p>
                      <p>주문처리 전 과정을 자동으로</p>
                    </div>
                    <div className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                      <p>주문 수집, 취소 건 관리, 송장 입력까지</p>
                      <p>쇼핑몰 주문처리 과정을 자동으로 처리합니다.</p>
                    </div>
                  </div>
                </div>
                <div className="relative items-center justify-center px-[40px] py-[50px]">
                  <img
                    className="w-[295px] rounded-[8px] object-cover shadow-md"
                    src={screen3_main}
                    alt="main"
                  />
                  {currentPage === 2 && (
                    <>
                      <img
                        className="animate-slideindown absolute  right-[-20px] top-[140px] w-[200px]"
                        src={screen3_rightlower}
                        alt="rightlower"
                      />
                      <img
                        className="animate-slideinleft absolute left-[-10px] top-[150px] w-[200px]"
                        src={screen3_left}
                        alt="left"
                      />
                    </>
                  )}
                </div>
                <div className="flex px-[40px]">
                  <button
                    type="button"
                    className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                    onClick={() => goto('dispatch')}
                  >
                    쇼핑몰 통합 관리하기
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="section">
            <section className={`flex h-screen flex-col bg-[#F0F8FF] `}>
              <div className="flex flex-col justify-center">
                <div className="flex h-full flex-col justify-center space-y-[24px] px-[40px] pt-[40px]">
                  <div className="flex">
                    <img
                      className="w-[68px] rounded-[13.6px] shadow-md"
                      src={screen4_ic}
                      alt="icon"
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-pretendard mb-[12px] whitespace-nowrap text-[24px] font-bold leading-tight text-[#263142]">
                      <p>번거로운 복사 붙여넣기 작업 없이</p>
                      <p>쉽고 간편한 원클릭 발주통합</p>
                    </div>
                    <div className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                      <p>주문 엑셀 파일 입력 시 </p>
                      <p>
                        <span className="text-blue-500">
                          원하는 통합 엑셀 양식으로 자동 변환
                        </span>
                        됩니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative items-center justify-center px-[40px] pb-[30px] pt-[10px]">
                  <img
                    className="w-full rounded-[8px] object-cover shadow-md"
                    src={imgsection4mobile}
                    alt="main"
                  />
                </div>
                <div className="flex px-[40px]">
                  <button
                    type="button"
                    className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                    onClick={() => navigate('/introduce-order-manage')}
                  >
                    발주 관리하기
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="section">
            <section className={`flex h-screen flex-col bg-[#F0F8FF] `}>
              <div className="flex flex-col justify-center">
                <div className="flex h-full flex-col justify-center space-y-[24px] px-[40px] pt-[40px]">
                  <div className="flex">
                    <img
                      className="w-[68px] rounded-[13.6px] shadow-md"
                      src={screen5_ic}
                      alt="icon"
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="font-pretendard mb-[12px] whitespace-nowrap text-[24px] font-bold leading-tight text-[#263142]">
                      <p>통합된 발주서에 기반해</p>
                      <p>일일 정산, 업체 및 기간별</p>
                      <p>정산도 자동으로</p>{' '}
                    </div>
                    <div className="font-pretendard text-[16px] font-semibold text-[#708AA2]">
                      <p>
                        스윕 사용 과정에서에서{' '}
                        <span className="text-blue-500">
                          저장된 주문 정보를
                        </span>
                      </p>
                      <p>
                        <span className="text-blue-500">
                          바탕으로 정산파일을 생성
                        </span>
                        해줍니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative items-center justify-center px-[40px] py-[50px]">
                  <img
                    className="w-[295px] rounded-[8px] object-cover shadow-md"
                    src={screen5_main}
                    alt="main"
                  />
                  {currentPage === 4 && (
                    <>
                      <img
                        className="animate-slideinleft absolute bottom-[70px] left-[10px] w-[200px]"
                        src={screen5_left}
                        alt="rightupper"
                      />
                      <img
                        className="animate-slideinright absolute  right-[10px] top-[70px] w-[150px]"
                        src={screen5_right}
                        alt="rightlower"
                      />
                    </>
                  )}
                </div>
                <div className="flex px-[40px]">
                  <button
                    type="button"
                    className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                    onClick={() => setIsWorkingOnModalOpen(true)}
                  >
                    정산 관리하기
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="section">
            <section className="flex size-full h-screen flex-col items-center  bg-white ">
              <div className=" flex flex-col  ">
                <div className="flex w-full flex-col px-[40px] py-[30px]  ">
                  <span className="font-pretendard mb-[12px] text-[24px] font-bold leading-tight text-[#263142]">
                    이런 업무도 처리할 수 있나요?
                  </span>
                  <div className="font-pretendard leading-medium flex flex-col text-[16px] font-semibold text-[#263142]">
                    <span>스윕은 아래 3개의 기능을 시작으로 </span>
                    <span>서비스 이용 과정에서 고객의 피드백을 반영해</span>
                    <span>최상의 해결책을 제공할 예정입니다. </span>
                  </div>
                </div>
                {/* <div className="w-full h-full max-w-[100vw] p-[40px] flex overflow-x-auto -webkit-overflow-scrolling-touch snap-x snap-mandatory space-x-[20px]">
                  <div className="snap-center flex-none animate-slideindown bg-white space-y-[20px] w-[270px] rounded-[20px] p-[24px] flex flex-col"> */}

                <div
                  className="-webkit-overflow-scrolling-touch word-wrap mb-[20px]  flex w-full touch-auto items-center justify-center"
                  style={{ wordBreak: 'keep-all' }}
                >
                  <div
                    ref={featureScroll}
                    className=" -webkit-overflow-scrolling-touch flex w-full max-w-[100vw] touch-auto snap-x snap-mandatory space-x-[20px] overflow-x-auto scroll-smooth px-[40px]"
                  >
                    <div className="bg-custom-gradient2 flex h-[450px] w-[255px] flex-none snap-center flex-col rounded-[16px] p-[24px]">
                      <img
                        src={screen6_ic_A}
                        alt="icon"
                        className="size-[68px]"
                      />
                      <div className="flex flex-col">
                        <div className="my-[24px] flex flex-col">
                          <span className="font-pretendard mb-[16px] text-[24px] font-bold text-[#263142]">
                            쇼핑몰 추가 연동
                          </span>
                          <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                            <span>
                              쇼핑몰마다 주문 파일을 다운로드할 필요없이 주문
                              건을 자동 통합 및 다운로드합니다.
                            </span>
                          </div>
                        </div>
                        <hr className="mb-[24px] border border-[#CED7E0]" />
                        <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                          <p>
                            연동된 일부 쇼핑몰에 대해서만 주문통합 기능을
                            제공하는 기존 솔루션과 달리,{' '}
                            <span className="text-blue-500">
                              오픈마켓부터 폐쇄몰까지 모든 쇼핑몰의 주문을
                              가져와 통합 및 관리
                            </span>
                            할 수 있습니다.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-custom-gradient2 flex h-[450px] w-[255px] flex-none snap-center flex-col rounded-[16px] p-[24px]">
                      <img
                        src={screen6_ic_B}
                        alt="icon"
                        className="size-[68px]"
                      />
                      <div className="flex flex-col">
                        <div className="my-[24px] flex flex-col">
                          <span className="font-pretendard mb-[16px] text-[24px] font-bold leading-tight text-[#263142]">
                            <p>발주 메일</p>
                            <p>송수신 자동화</p>
                          </span>
                          <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                            <span>
                              메일을 하나하나 회신하지 않아도 발주할 수
                              있습니다.
                            </span>
                          </div>
                        </div>
                        <hr className="mb-[24px] border border-[#CED7E0]" />
                        <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                          <p>
                            계정으로 들어온 발주 파일을 서비스에 자동으로 반영
                            및 회신하며{' '}
                            <span className="text-blue-500">
                              메일 송수신 과정을 자동화
                            </span>
                            합니다.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-custom-gradient2 flex h-[450px] w-[255px] flex-none snap-center flex-col rounded-[16px] p-[24px]">
                      <img
                        src={screen6_ic_C}
                        alt="icon"
                        className="size-[68px]"
                      />
                      <div className="flex flex-col">
                        <div className="my-[24px] flex flex-col">
                          <span className="font-pretendard mb-[16px] text-[24px] font-bold leading-tight text-[#263142]">
                            정산확인 및 <br />
                            세금계산서 발행
                          </span>
                          <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                            <span>
                              정산관리에 더해 거래처 확인, 세금계산서 발행까지
                              모든 정산 업무 자동화를 지원합니다.{' '}
                            </span>
                          </div>
                        </div>
                        <hr className="mb-[24px] border border-[#CED7E0]" />
                        <div className="font-pretendard leading-medium flex flex-col text-[16px] font-medium text-[#708AA2]">
                          <p>
                            일별 발주 데이터로 공급사-위탁판매사 간 정산파일을
                            생성하고,
                            <span className="text-blue-500">
                              세금계산서 발행까지 원클릭으로 제공
                            </span>
                            합니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="section">
            <section className="flex h-screen flex-col bg-[#F0F8FF] ">
              <div className="flex flex-col space-y-[18px] px-[40px] pt-[20px]">
                <div className="flex">
                  <img
                    className="w-[68px] rounded-[13.6px] shadow-md"
                    src={screen7_ic}
                    alt="icon"
                  />
                </div>
                <div className="flex flex-col space-y-[5px]">
                  <div className="font-pretendard whitespace-nowrap text-[24px] font-bold leading-tight text-[#263142]">
                    <p>서비스 품질은 높이되,</p>
                    <p>비용은 낮게</p>
                  </div>
                  <div className="font-pretendard text-[16px] text-[#708AA2]">
                    <p>3주 무료 체험 후에 결정해도 늦지 ���습니다!</p>
                  </div>
                </div>
              </div>
              <div className="flex w-full py-[20px]">
                <div
                  ref={planScroll}
                  className="-webkit-overflow-scrolling-touch flex w-full max-w-[100vw] touch-auto snap-x snap-mandatory space-x-[28px]  overflow-x-auto scroll-smooth  px-[40px]  "
                >
                  {plans.map((plan, i) => (
                    <div
                      key={i}
                      className="animate-slideindown flex h-[378px] w-[270px] flex-none snap-center flex-col space-y-[16px] rounded-[16px] bg-white p-[20px]"
                    >
                      <div
                        className={`flex flex-col rounded-[8px] ${plan.color} px-[14px] py-[12px] leading-tight`}
                      >
                        <span
                          className={`text-[18px] font-bold ${plan.textColor}`}
                        >
                          {plan.name}
                        </span>
                        <span
                          className={`text-[12px] font-bold ${plan.desColor}`}
                        >
                          {plan.korName}
                        </span>
                      </div>
                      <div className="flex flex-col leading-tight">
                        {!(plan.new ?? false) && (
                          <span className="font-pretendard text-[12px] font-semibold text-[#9FB1C1] line-through">
                            {plan.origPrice}
                          </span>
                        )}
                        {(plan.new ?? false) && (
                          <div className="flex">
                            <span className="rounded-[4px] bg-[#F2F6FA] px-[6px] py-[3px] text-[11px] font-semibold text-blue-500">
                              NEW
                            </span>
                          </div>
                        )}
                        <div className="flex h-full items-center space-x-[10px]">
                          <span className="font-pretendard h-full text-[20px] font-bold text-[#263142]">
                            {plan.price}
                          </span>
                          {plan.discount && (
                            <div className="flex h-[24px] flex-col justify-center rounded-[8px]  bg-blue-500   px-[8px]">
                              <span className=" font-pretendard text-center text-[12px] font-bold text-white">
                                {plan.discount}
                              </span>
                            </div>
                          )}
                        </div>

                        <span className=" font-pretendard text-[12px] font-medium text-[#263142]">
                          부가세(VAT)별도
                        </span>
                      </div>
                      <hr className="border border-[#E0E7ED]" />
                      <div
                        className={`flex flex-col space-y-[8px] px-[20px] text-[12px] ${i === 2 ? 'leading-3' : 'leading-3'} font-medium text-[#263142]`}
                      >
                        {plan.features}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex px-[40px] pb-[47px]">
                <button
                  type="button"
                  className="flex h-[42px] w-[160px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-[20px] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                  onClick={() => setIsModalOpen(true)}
                >
                  도입 문의하기
                </button>
              </div>
            </section>
          </div>
          <div className="section">
            <section className="bg-custom-gradient3 relative flex h-screen items-center justify-center ">
              <img
                src={screen_8}
                alt="absolute background"
                className="absolute z-0 h-full  object-cover"
              ></img>
              <img
                src={screen8dim}
                alt="absolute background"
                className="absolute z-0 h-full object-cover"
              ></img>

              <div className="relative flex flex-col items-center">
                <div className="font-pretendard mb-[16px] text-center text-[24px] font-bold text-[#263142]">
                  <p className="whitespace-nowrap leading-tight">
                    차원이 다른 발주 관리
                    <br />
                    <span className="text-blue-500">3주 무료체험</span>으로
                    <br />
                    지금 사용해보세요
                  </p>
                </div>

                <div className="preserve-line-breaks mb-[50px] text-[16px] font-medium text-[#708AA2]">
                  <p className="max-w-[650px] text-center  text-[18px]">
                    세팅 과정에서의 부담감, 번거로움이 없도록
                    <br />
                    담당자가 화상회의로 안내드려요.
                  </p>
                </div>
                <div className="font-pretendard flex">
                  <button
                    type="button"
                    className="mr-2 flex h-[41px] w-[160px] cursor-pointer items-center justify-center rounded-lg bg-blue-500 text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                    onClick={() => setIsModalOpen(true)}
                  >
                    도입 문의하기
                  </button>
                  <button
                    type="button"
                    onClick={downloadPDF}
                    className=" flex h-[41px] w-[160px] cursor-pointer items-center justify-center rounded-lg bg-[#343D4B] text-[14px] font-semibold text-white transition duration-300 ease-in-out hover:bg-blue-600"
                  >
                    제품 소개서 받기
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="section fp-auto-height">
            <NewFooter isMobile={true} />
          </div>
        </div>
      )}
      {isModalOpen && (
        <div
          onClick={() => setIsModalOpen(false)}
          className={`bg-opacity/50 fixed inset-0 z-40 flex size-full items-center justify-center overflow-visible bg-gray-700`}
        >
          <div
            className={`h-[820px] w-full scale-75  rounded-3xl border bg-white pt-[30px] shadow-lg md:w-[530px]`}
          >
            <div className="relative size-full px-[10px]">
              <button
                className="modal-close absolute right-7 top-[-5px] z-40 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              >
                <img src={closeIcon} alt="close" className="h-[32px]" />
              </button>
              {iframeLoading && (
                <p className="fixed inset-0 z-50 flex items-center justify-center">
                  기다려 주세요...
                </p>
              )}
              <iframe
                className="z-10 size-full pb-10"
                src={preRegistrationLink}
                onLoad={handleIframeLoad}
                style={{
                  display: iframeLoading ? 'none' : 'block',
                }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
      {isWorkingOnModalOpen && (
        <div
          onClick={() => setIsWorkingOnModalOpen(false)}
          className={`bg-opacity/50 fixed inset-0 z-40 flex size-full items-center justify-center overflow-visible bg-gray-700`}
        >
          <div
            className={` mx-[20px] flex size-[480px] flex-col rounded-[16px] border bg-white shadow-lg`}
          >
            <div className="flex size-full  flex-col space-y-[32px] p-[40px]">
              <div className="flex flex-col space-y-[8px] text-center">
                <span className="font-pretendard text-[24px] font-bold text-[#343D4B]">
                  현재 서비스 준비중입니다.
                </span>
                <span className="font-pretendard text-[16px] font-medium text-[#708AA2]">
                  보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.
                  <br />
                  빠른 시일내에 준비하여 찾아뵙겠습니다.
                </span>
              </div>
              <div className="bg-custom-gradient-light-blue flex h-full flex-col items-center justify-center rounded-[8px]">
                <img src={imgWorking} className=" h-[100px] " />
              </div>
            </div>
            <div className="flex  px-[32px] py-[20px]">
              <div className="flex h-[45px] w-full items-center justify-center rounded-[8px] bg-blue-500">
                <span className="text-[14px] font-bold text-white">확인</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div
          onClick={() => {
            navigate('/address');
            amplitude.track('Click Address');
          }}
          className="group fixed bottom-4 right-4 flex animate-bounce p-4 "
        >
          <JuSo className="w-[80px] transition-transform duration-300 hover:scale-110" />
        </div>
      )}
    </div>
  );
};

export default App;
