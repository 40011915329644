import { startOfDay } from 'date-fns';
import { chunkByInterval } from '../array';
import { DAY_IN_MS } from './constants';

export function getStartDays(range: { from: Date; to: Date }): Date[] {
  const days = chunkByInterval(range.from, range.to, DAY_IN_MS).map(
    ({ start }) => start
  );
  return days.map((day) => startOfDay(day));
}
