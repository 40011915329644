import { overlay } from 'overlay-kit';
import { IconArrowRight } from '@sweep/asset/icons';
import {
  imageTrialExpired1,
  imageTrialExpired2,
  imageTrialExpired3,
} from '@sweep/asset/images';
import { Button, Modal } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { useMount } from 'src/hooks/useMount';
import { imageLogo } from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';
import { openTrialExpiredRegisterModal } from './TrialExpiredRegisterModal';

interface TrialExpiredModalProps {
  open: boolean;
  onClose: () => void;
}

function TrialExpiredModal({ open, onClose }: TrialExpiredModalProps) {
  useMount(() => {
    amplitude.track('View address-trial-ended');
  });

  const handleSubmit = () => {
    amplitude.track('Click view-submit-inquiry-form', {
      From: 'address',
      Section: 'address-trial-ended',
    });
    onClose();
    openTrialExpiredRegisterModal();
  };

  return (
    <Modal open={open} onClose={onClose} className="max-h-full w-[684px]">
      <Modal.Title>주소 변환기 체험 기간이 종료되었어요.</Modal.Title>
      <Modal.Description className="mb-24px">
        7일간 사용하셨네요! 이제 수작업 없이 자동화해 보는 건 어떠신가요?
      </Modal.Description>
      <div className="gap-36px flex-center p-24px rounded-8px flex flex-col bg-gray-100">
        <div className="gap-4px flex items-center justify-center">
          <img src={imageLogo} alt="logo" className="h-20px" />
          <p className="text-semibold-l text-gray-600">
            도입하고 <span className="text-semibold-l text-blue-500">2주</span>
            간 무료로 계속 체험해보세요!
          </p>
        </div>

        <div className="gap-16px flex">
          <div className={item()}>
            <img
              src={imageTrialExpired1}
              alt="trial-expired-1"
              className={itemImage()}
            />
            <p className={itemNumber({ color: 'red' })}>혜택 1</p>
            <p className={itemTitle()}>자동 주소 변환</p>
            <p className={itemDescription()}>
              주문 수집 한 번으로 주소 변환, 상품명 수정, 수수료 계산까지 처리해
              보세요.
            </p>
          </div>
          <div className={item()}>
            <img
              src={imageTrialExpired2}
              alt="trial-expired-2"
              className={itemImage()}
            />
            <p className={itemNumber({ color: 'purple' })}>혜택 2</p>
            <p className={itemTitle()}>맞춤 양식 변환</p>
            <p className={itemDescription()}>
              사방넷, 샵링커 등 원하는 양식으로 변환이 가능해요.
            </p>
          </div>
          <div className={item()}>
            <img
              src={imageTrialExpired3}
              alt="trial-expired-3"
              className={itemImage()}
            />
            <p className={itemNumber({ color: 'blue' })}>혜택 3</p>
            <p className={itemTitle()}>쉽고 빠른 세팅</p>
            <p className={itemDescription()}>
              알리 계정 정보만 입력하면 바로 사용해 볼 수 있어요.
            </p>
          </div>
        </div>
      </div>
      <div className="p-20px flex w-full justify-center">
        <Button
          rightAccessory={<IconArrowRight />}
          className="w-240px"
          onClick={handleSubmit}
        >
          3초만에 신청하기
        </Button>
      </div>
    </Modal>
  );
}

const item = cva('h-200px p-24px rounded-8px relative flex flex-1 flex-col');

const itemImage = cva('absolute left-0 top-0 size-full');

const itemNumber = cva(
  [
    'rounded-6px px-8px h-24px flex-center flex w-fit',
    'font-pretendard text-[10px] font-semibold tracking-[-4%] text-white',
    'mb-12px z-[1] border border-[rgba(0,0,0,0.05)]',
  ],
  {
    variants: {
      color: {
        red: 'bg-[#E57C7A]',
        purple: 'bg-[#6B9CDF]',
        blue: ' bg-[#55ACDF]',
      },
    },
  }
);

const itemTitle = cva([
  'font-pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.6px] text-[#03183A]',
  'mb-8px z-[1]',
]);

const itemDescription = cva('text-medium-xs z-[1] break-keep text-gray-600');

export function openTrialExpiredModal() {
  return overlay.open(({ isOpen, close, unmount }) => (
    <TrialExpiredModal
      open={isOpen}
      onClose={() => {
        close();
        unmount();
      }}
    />
  ));
}
