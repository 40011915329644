import { parseNumber, sum } from '@sweep/utils';
import { SettlementOrder } from '../interface';

export function getSupplierTotalPrice(orders: SettlementOrder[]) {
  const totalQuantity = sum(
    orders.map((order) => parseNumber(order.quantity) ?? 0)
  );
  const totalSupplierPrice = sum(
    orders.map((order) => order.supplierPrice ?? 0)
  );
  const totalSupplierShippingPrice = sum(
    orders.map((order) => order.supplierShippingPrice ?? 0)
  );
  const totalPrice = sum(orders.map((order) => order.totalSupplierPrice ?? 0));

  return {
    totalQuantity,
    totalSupplierPrice,
    totalSupplierShippingPrice,
    totalPrice,
  };
}
