import { SHOPPING_MALL_NAMES, ShoppingMallName } from './ShoppingMallName';

export type ShoppingMallType =
  | 'AliExpress'
  | 'Alwayz'
  | 'LotteOn'
  | 'Coupang'
  | 'Naver'
  | 'Imweb'
  | 'Cafe'
  | 'KakaoShop'
  | 'Eleven'
  | 'Gmarket'
  | 'Auction'
  | 'KakaoMakers'
  | 'ShopBy';

const TYPE_BY_NAMES: Record<ShoppingMallName, ShoppingMallType> = {
  [SHOPPING_MALL_NAMES.ALIEXPRESS]: 'AliExpress',
  [SHOPPING_MALL_NAMES.COUPANG]: 'Coupang',
  [SHOPPING_MALL_NAMES.NAVER]: 'Naver',
  [SHOPPING_MALL_NAMES.ALWAYZ]: 'Alwayz',
  [SHOPPING_MALL_NAMES.LOTTE_ON]: 'LotteOn',
  [SHOPPING_MALL_NAMES.IMWEB]: 'Imweb',
  [SHOPPING_MALL_NAMES.CAFE24]: 'Cafe',
  [SHOPPING_MALL_NAMES.KAKAOSHOP]: 'KakaoShop',
  [SHOPPING_MALL_NAMES.ELEVEN]: 'Eleven',
  [SHOPPING_MALL_NAMES.GMARKET]: 'Gmarket',
  [SHOPPING_MALL_NAMES.AUCTION]: 'Auction',
  [SHOPPING_MALL_NAMES.KAKAOMAKERS]: 'KakaoMakers',
  [SHOPPING_MALL_NAMES.SHOPBY]: 'ShopBy',
  [SHOPPING_MALL_NAMES.CASHDEAL]: 'ShopBy',
  [SHOPPING_MALL_NAMES.TDEAL]: 'ShopBy',
  [SHOPPING_MALL_NAMES.KDEAL]: 'ShopBy',
};

export function getShoppingMallType(
  shoppingMall: string
): ShoppingMallType | null {
  return (
    Object.entries(TYPE_BY_NAMES).find(([name]) =>
      shoppingMall.startsWith(name)
    )?.[1] ?? null
  );
}
