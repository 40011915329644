import { Supplier, SupplierCustomizedSettings } from '@sweep/contract';

export function createSupplierCustomizedSettings(
  supplier: Supplier
): SupplierCustomizedSettings {
  const templateFile =
    supplier.customizedSettings?.xlsxTemplateSetting?.templateFile;

  const headerRowIndex =
    supplier.customizedSettings?.xlsxTemplateSetting?.headerRowIndex;

  const minRowIndex =
    supplier.customizedSettings?.xlsxTemplateSetting?.minRowIndex;

  const isTemplateFileEnabled =
    supplier.customizedSettings?.xlsxTemplateSetting?.enabled === true;

  return {
    templateFile,
    headerRowIndex,
    minRowIndex,
    isTemplateFileEnabled,
  };
}
