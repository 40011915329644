import { c } from '../../internal/contract';
import {
  SpreadsheetFileUploadBody,
  SpreadsheetFileUploadResponse,
} from '../../type/Spreadsheet';

export const SpreadsheetRouterContract = c.router({
  upload: {
    method: 'POST',
    path: '/spreadsheet/upload',
    contentType: 'multipart/form-data',
    body: SpreadsheetFileUploadBody,
    responses: {
      200: SpreadsheetFileUploadResponse,
    },
  },
});
