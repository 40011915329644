import {
  GET_EXCEL_SETTING_URL,
  GetExcelSettingResponse,
  PreRegistration,
  UPDATE_USER_SETTING_URL,
  UpdateUserSettingDTO,
  UpdateUserSettingResponse,
  User,
} from '@sweep/contract';
import { assert } from '@sweep/utils';
import api, { APIResponse } from './api';
import { client } from './client';

export async function preRegister(preRegistration: PreRegistration) {
  return client.user.preRegister({ body: preRegistration });
}

interface LoginResponse {
  data?: {
    token: string;
    userId: string;
  };
  message?: string;
}

export async function login(email: string, password: string) {
  const response = await api.post<LoginResponse>(
    '/user/login',
    { loginData: { email, password } },
    { validateStatus: (status) => status === 200 || status === 400 }
  );
  assert(response != null, 'Failed to login');

  return response.data;
}

interface GetUserResponse {
  data?: User;
}

export async function getUser() {
  const response = await api.get<GetUserResponse>('/user');
  assert(response != null, 'Failed to get user');
  return response.data;
}

export async function getCombinedExcelSetting() {
  const response = await api.get<APIResponse<GetExcelSettingResponse>>(
    GET_EXCEL_SETTING_URL
  );
  assert(response != null, 'Failed to get excel setting');

  return response.data;
}

export async function updateUser(updateDTO: Partial<User>) {
  const response = await api.put<APIResponse<User>>('/user', updateDTO);

  return response?.data;
}

export async function updateUserSetting(
  updateUserSettingDTO: UpdateUserSettingDTO
) {
  const response = await api.put<APIResponse<UpdateUserSettingResponse>>(
    UPDATE_USER_SETTING_URL,
    updateUserSettingDTO
  );

  return response?.data;
}
