import { z } from 'zod';
import { SHOPPING_MALL_NAMES } from '../shopping-mall/ShoppingMallName';
import {
  BaseDispatchAuthToken,
  BaseDispatchAuthTokenSchema,
} from './DispatchAutoToken';

export const ImwebTokenSchema = BaseDispatchAuthTokenSchema.extend({
  clientId: z.string(),
  clientSecret: z.string(),
  access_token: z.string(),
  refresh_token: z.string(),
  accessTokenExpiresAt: z.string().optional(),
});

export type ImwebToken = z.infer<typeof ImwebTokenSchema>;

function isImwebToken(token: BaseDispatchAuthToken): token is ImwebToken {
  return token.shoppingMall === SHOPPING_MALL_NAMES.IMWEB;
}

export function toImwebToken<T extends BaseDispatchAuthToken>(
  token: T
): ImwebToken {
  if (!isImwebToken(token)) {
    throw new Error('Invalid shopping mall');
  }

  return {
    ...token,
    clientId: token.clientId,
    clientSecret: token.clientSecret,
    access_token: token.access_token,
    refresh_token: token.refresh_token,
    accessTokenExpiresAt: token.accessTokenExpiresAt,
  };
}
