import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useSearchParams } from 'react-router-dom';
import NewFooter from 'src/components/footer/NewFooter';
import { amplitude } from 'src/third-parties/amplitude';
import Header from '../landing-page/components/Header';
import PCAliExpressScreen from './PCAliExpressScreen';

function AliExpressLayout() {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 1099px)',
  });
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const section = searchParams.get('section');

  useEffect(() => {
    amplitude.track('[Ali] View Landing Page', {
      device: isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
      From: from ?? undefined,
      Section: section ?? undefined,
    });
  }, [isMobile, isTablet, from, section]);

  return isMobile || isTablet ? (
    <div className="flex w-full items-center justify-center overflow-x-hidden bg-gray-100">
      <div className="relative flex w-full max-w-[480px] flex-col">
        <Header />
        <Outlet />
        <NewFooter />
      </div>
    </div>
  ) : (
    <PCAliExpressScreen />
  );
}

export default AliExpressLayout;
