import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { IconControlDownload, IconInfo } from '@sweep/asset/icons';
import {
  Button,
  Divider,
  RangePicker,
  RangeSelect,
  TableCount,
  TableSelectControl,
  Tooltip,
} from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useTableFilter } from 'src/components/TableFilter';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { createOrderExcel } from 'src/services/file/excel/create';
import { amplitude } from 'src/third-parties/amplitude';
import OrderDispatchSearchTable from './components/OrderDispatchSearchTable';
import OrderStatusInput from './components/OrderStatusInput';
import ShoppingMallMultiSelect from './components/ShoppingMallMultiSelect';
import TextInputWithLabel from './components/TextInputWithLabelProps';
import {
  ORDER_SEARCH_COLUMN_MAPPING,
  ORDER_SEARCH_HEADERS,
} from './constants/orderSearchColumns';
import { OrderDispatchSearchScreenStore } from './OrderDispatchSearchScreenStore';
import { getRangeSelectOptions } from './services/getRangeSelectOptions';

function OrderDispatchSearchScreen() {
  const oms = useOMSStore();
  const { store: tableFilterStore, filterOrders } = useTableFilter();
  const store = oms.getStore(OrderDispatchSearchScreenStore);

  const filteredOrders = useMemo(
    () => filterOrders(store.orders ?? []),
    [filterOrders, store.orders]
  );

  const search = () => {
    store.search();
    tableFilterStore.reset();
  };

  const downloadExcel = () => {
    if (store.orders == null) {
      return;
    }
    const orders =
      store.selectedOrders.length > 0 ? store.selectedOrders : store.orders;
    createOrderExcel(
      oms,
      orders,
      formatDate(new Date(), 'yy.MM.dd 주문조회'),
      ORDER_SEARCH_HEADERS,
      ORDER_SEARCH_COLUMN_MAPPING
    );

    amplitude.track('Click download-fetched-order');
  };

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <div className="h-42px flex items-center">
        <p className="text-extrabold-l text-gray-700">주문 조회하기</p>
      </div>
      <div className="gap-24px pt-24px pb-20px px-24px rounded-8px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="gap-60px flex">
          <div className="gap-16px flex flex-col">
            <div className="gap-16px flex items-center">
              <p className="text-medium-s w-50px text-gray-500">쇼핑몰</p>
              <ShoppingMallMultiSelect
                value={store.shoppingMalls}
                onChange={store.setShoppingMalls}
                className="w-400px"
              />
            </div>
            <TextInputWithLabel
              label="상세검색"
              placeholder="검색어를 입력하세요."
              value={store.keyword ?? ''}
              onChange={store.setKeyword}
              onEnterKeyDown={() => store.search()}
            />
          </div>
          <Divider type="vertical" />
          <div className="gap-16px flex items-start">
            <div className="w-72px h-40px flex items-center justify-between">
              <p className="text-medium-s w-50px text-gray-500">조회기간</p>
              <Tooltip placement="bottom-start">
                <Tooltip.Trigger>
                  <IconInfo className="text-gray-400" />
                </Tooltip.Trigger>
                <Tooltip.Content>결제시간 기준</Tooltip.Content>
              </Tooltip>
            </div>
            <div className="gap-12px flex w-full flex-col">
              <RangeSelect
                value={store.range}
                onChange={store.setRange}
                options={getRangeSelectOptions()}
              />
              <RangePicker
                value={store.range}
                onChange={store.setRange}
                status={store.rangeStatus}
              />
            </div>
          </div>
        </div>
        <div className="gap-16px flex items-center">
          <p className="text-medium-s w-50px text-gray-500">주문상태</p>
          <OrderStatusInput
            value={store.orderStatus}
            onChange={store.setOrderStatus}
            className="flex-1"
          />
        </div>
        <hr />
        <div className="flex">
          <div className="flex flex-1 flex-col justify-center">
            <div className="gap-6px flex">
              <IconInfo className="text-gray-400" />
              <p className="text-regular-s flex-1 text-gray-600">
                실시간 주문정보를 확인하고 싶다면 ‘주문 수집하기’에서 주문을
                불러와주세요.
              </p>
            </div>
          </div>
          <Button
            className="m-auto w-fit"
            onClick={() => search()}
            disabled={store.rangeStatus === 'error'}
          >
            검색
          </Button>
          <div className="flex-1" />
        </div>
      </div>
      {store.orders && (
        <div className="p-20px rounded-8px gap-16px shadow-line flex flex-col bg-white">
          <If is={store.orders.length > 0}>
            <div className="flex flex-col">
              <TableCount count={filteredOrders.length} />
              <div className="flex items-end justify-between">
                <TableSelectControl
                  count={store.selectedOrders.length}
                  onClear={() => store.setSelectedOrders([])}
                />
                <Button
                  color="gray"
                  rightAccessory={<IconControlDownload />}
                  onClick={downloadExcel}
                >
                  엑셀 파일
                </Button>
              </div>
            </div>
            <OrderDispatchSearchTable
              store={tableFilterStore}
              orders={store.orders}
              filteredOrders={filteredOrders}
              selectedOrders={store.selectedOrders}
              onSelect={store.setSelectedOrders}
              className="max-h-[calc(100vh-300px)]"
            />
          </If>
          <If is={store.orders.length === 0}>
            <div className="h-80px flex-center flex">
              <p className="text-medium-s text-gray-500">
                검색 결과가 없습니다.
              </p>
            </div>
          </If>
        </div>
      )}
    </div>
  );
}

export default observer(OrderDispatchSearchScreen);
