export const SHOPPING_MALL_NAMES = {
  COUPANG: '쿠팡',
  ELEVEN: '11번가',
  NAVER: '스마트스토어',
  ALIEXPRESS: 'AliExpress',
  AUCTION: '옥션',
  GMARKET: '지마켓',
  KAKAOSHOP: '카카오쇼핑',
  ALWAYZ: '올웨이즈',
  CAFE24: '카페24',
  KAKAOMAKERS: '카카오메이커스',
  SHOPBY: '샵바이',
  CASHDEAL: '캐시딜',
  TDEAL: '티딜',
  KDEAL: '케이딜',
  LOTTE_ON: '롯데온',
  IMWEB: '아임웹',
} as const;

export type ShoppingMallName =
  (typeof SHOPPING_MALL_NAMES)[keyof typeof SHOPPING_MALL_NAMES];
