import {
  IconArrowDownModal,
  IconCheckMedium,
  IconRandom,
} from '@sweep/asset/icons';
import { Button, TextInput } from '@sweep/sds';
import { imageExcelGrey, imageSweep } from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import Header from '../landing-page/components/Header';
import { useConvertAddress } from './hooks/useConvertAddress';

export function AddressValidMobileScreen() {
  const { value, onChange, convertedValue, generate, convert, copy } =
    useConvertAddress();

  const share = () => {
    amplitude.track('Click share-address');
    const link = window.location.href;

    if (typeof navigator.share === 'function') {
      navigator.share({
        title: '주소 변환기',
        url: link,
      });
      return;
    }

    navigator.clipboard.writeText(link);
    toast.success('링크가 복사되었습니다.');
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-100">
      <Header />
      <div className="h-64px px-32px flex items-center">
        <img src={imageSweep} alt="service icon" className="h-30px w-90px" />
      </div>
      <div className="gap-16px p-24px flex flex-col">
        <p className="text-[36px] font-bold text-gray-700">주소 변환기</p>
        <p className="text-semibold-xl text-gray-600">
          쇼핑몰에서{' '}
          <span className="text-semibold-xl text-blue-500">오기입된 주소</span>
          ,
          <br /> 스윕이 자동으로 변환해드릴게요.
        </p>
      </div>
      <div className="gap-24px px-24px py-40px flex flex-col">
        <div className="gap-8px flex-start flex">
          <IconCheckMedium className="text-blue-400" />
          <p className="text-semibold-s text-gray-600">주소 변환 예시</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="gap-8px px-20px py-9px text-semibold-xs flex-center flex rounded-full bg-gray-200 text-center text-gray-500">
            대한민국、서울특별시、관악구、Nambusunhwan-ro、1838, CS tower{' '}
            1201-ho
          </div>
          <IconArrowDownModal className="text-gray-400" />
          <div className="gap-8px px-20px py-9px text-semibold-xs flex-center flex rounded-full border border-blue-500 bg-blue-50 text-center text-blue-600">
            서울특별시 관악구 남부순환로 1838 CS tower 1201호
          </div>
        </div>
      </div>
      <div className="px-24px py-40px flex flex-col items-center">
        <div className="gap-8px flex-start mb-24px flex w-full items-center">
          <IconCheckMedium className="text-blue-400" />
          <p className="text-semibold-s text-gray-600">직접 변환해보기</p>
          <Button
            color="gray"
            size="xsmall"
            rightAccessory={<IconRandom />}
            onClick={generate}
          >
            예시 주소
          </Button>
        </div>
        <div className="gap-12px mb-8px flex w-full flex-col">
          <label
            htmlFor="address-input"
            className="text-medium-xs w-64px text-gray-500"
          >
            변환할 주소
          </label>
          <div className="gap-12px flex">
            <TextInput
              id="address-input"
              className="min-w-0 flex-1"
              placeholder="변환할 주소를 입력해주세요."
              value={value}
              onChange={onChange}
            />
            <Button color="gray" onClick={convert}>
              변환하기
            </Button>
          </div>
        </div>
        <IconArrowDownModal className="text-gray-400" />
        <div className="gap-12px flex w-full flex-col">
          <label className="text-medium-xs w-64px text-gray-500">
            변환 결과
          </label>
          <div className="gap-12px flex">
            <TextInput
              disabled
              className="min-w-0 flex-1"
              value={convertedValue}
            />
            <Button color="lightGray" onClick={copy}>
              복사하기
            </Button>
          </div>
        </div>
      </div>
      <div className="px-24px py-40px gap-24px flex flex-col">
        <div className="gap-8px flex-start flex">
          <IconCheckMedium className="text-blue-400" />
          <p className="text-semibold-s text-gray-600">파일 업로드하기</p>
        </div>
        <div className="gap-8px h-200px shadow-line rounded-16px flex w-full flex-col items-center justify-center bg-white">
          <div className="gap-32px flex flex-col items-center justify-center">
            <div className="gap-4px flex flex-col items-center justify-center">
              <img src={imageExcelGrey} alt="excel" className="size-92px" />
              <p className="text-medium-s text-gray-700">
                PC 환경에서 업로드할 수 있어요.
              </p>
              <p className="text-semibold-xs text-gray-400">
                양식 상관없이 주소 열만 있으면 변환이 가능해요.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20px mb-100px flex justify-center">
        <p className="text-medium-s text-gray-500">
          링크를 복사하고 PC에서 체험해보세요!
        </p>
      </div>

      <div className="gap-30px bottom-32px flex-center fixed flex w-full flex-col">
        <button
          className="w-240px h-56px flex-center shadow-box flex rounded-full bg-blue-500"
          onClick={share}
        >
          <p className="text-semibold-m text-white">링크 복사하기</p>
        </button>
      </div>
    </div>
  );
}
