import { z } from 'zod';

export const MatchingHeaderSchema = z.array(z.string());
export type MatchingHeader = z.infer<typeof MatchingHeaderSchema>;

export const ColumnMappingSchema = z.record(
  z.string(),
  z.union([z.string(), z.number(), z.null(), z.undefined()])
);
export type ColumnMapping = z.infer<typeof ColumnMappingSchema>;

export const MatchingSchema = z.object({
  id: z.string(),
  header: MatchingHeaderSchema,
  columnMapping: ColumnMappingSchema,
  exampleContents: z.array(z.array(z.string())),
  validSheetIndex: z.number().optional(),
  headerStartIndex: z.number().optional(),
  headerEndIndex: z.number().optional(),
  orderStartIndex: z.number().optional(),
  isTruncateEnabled: z.boolean().optional(),
  xlsxTemplateSetting: z
    .object({
      enabled: z.boolean(),
      name: z.string().optional(),
      templateFile: z.string().optional(),
    })
    .optional(),
});

export type Matching = z.infer<typeof MatchingSchema>;

export const PartnerSchema = z.object({
  _id: z.string(),
  name: z.string(),
  email: z.string().nullish(),
  phone: z.string().nullish(),

  pluginIds: z.array(z.string()).optional(),

  matchings: z.array(MatchingSchema).optional(),

  headerForShipping: MatchingHeaderSchema.optional(),
  columnMappingForShipping: ColumnMappingSchema.optional(),

  lastActiveMatchingId: z.string().optional(),
  partnershipUUID: z.string().optional(),
});

export type Partner = z.infer<typeof PartnerSchema>;
