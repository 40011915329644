import { useState } from 'react';
import { SHIPPING_COMPANY, ShippingCompany } from '@sweep/contract';
import { Button, Modal, Select, TextInput } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

const SAVE_BUTTON_TEXT = '저장하기';
const CANCEL_BUTTON_TEXT = '취소하기';

const familiarShippingCompanies: ShippingCompany[] = [
  SHIPPING_COMPANY.CJ,
  SHIPPING_COMPANY.LOTTE,
  SHIPPING_COMPANY.HANJIN,
  SHIPPING_COMPANY.POST_OFFICE,
  SHIPPING_COMPANY.LOGEN,
];

const VALID_SHIPPING_COMPANIES: ShippingCompany[] = [
  ...familiarShippingCompanies,
  ...Object.values(SHIPPING_COMPANY)
    .filter(
      (value) =>
        ![...familiarShippingCompanies, SHIPPING_COMPANY.ETC].includes(value)
    )
    .sort((value1, value2) => value1.localeCompare(value2)),
  SHIPPING_COMPANY.ETC,
];

function setInitShippingCompany(
  initShippingCompany: string
): ShippingCompany | undefined {
  if (
    VALID_SHIPPING_COMPANIES.includes(initShippingCompany as ShippingCompany)
  ) {
    return initShippingCompany as ShippingCompany;
  }

  return undefined;
}

export interface ShippingInvoice {
  shippingCompany: ShippingCompany;
  shippingNumber: string;
}

type EditShippingNumberFormModalProps = {
  open: boolean;
  onSubmit: (shippingInvoice: ShippingInvoice | null) => void;
  initShippingCompany: string;
  initShippingNumber: string;
};

function EditShippingNumberFormModal({
  open,
  onSubmit,
  initShippingCompany,
  initShippingNumber,
}: EditShippingNumberFormModalProps) {
  const [showError, setShowError] = useState(false);

  const [shippingCompany, setShippingCompany] = useState<
    ShippingCompany | undefined
  >(setInitShippingCompany(initShippingCompany));
  const [shippingNumber, setShippingNumber] =
    useState<string>(initShippingNumber);

  const showShippingCompanyError = showError && shippingCompany == null;
  const showShippingNumberError = showError && shippingNumber === '';

  const onClose = () => onSubmit(null);

  const handleClick = () => {
    if (shippingCompany == null || shippingNumber === '') {
      setShowError(true);
      return;
    }

    onSubmit({ shippingCompany, shippingNumber });
  };

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <div className="gap-12px mb-20px flex flex-col">
        <div className={inputContainer()}>
          <p className={label()}>배송업체</p>
          <Select
            value={shippingCompany}
            onChange={(value) => setShippingCompany(value as ShippingCompany)}
            status={showShippingCompanyError ? 'error' : 'default'}
            helperText={
              showShippingCompanyError ? '배송업체를 선택해주세요.' : ''
            }
            placeholder="선택"
            className="w-full"
          >
            {VALID_SHIPPING_COMPANIES.map((company) => (
              <Select.Option key={company} value={company}>
                {company}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={inputContainer()}>
          <p className={label()}>운송장 번호</p>
          <TextInput
            value={shippingNumber}
            onChange={setShippingNumber}
            status={showShippingNumberError ? 'error' : 'default'}
            helperText={
              showShippingNumberError ? '운송장 번호를 입력해주세요.' : ''
            }
          />
        </div>
      </div>
      <div className="gap-12px flex">
        <Modal.Close className="flex-1" color="lightGray">
          {CANCEL_BUTTON_TEXT}
        </Modal.Close>
        <Button className="flex-1" onClick={handleClick}>
          {SAVE_BUTTON_TEXT}
        </Button>
      </div>
    </Modal>
  );
}

const inputContainer = cva('gap-8px flex flex-col');

const label = cva('text-medium-m text-gray-700');

export default EditShippingNumberFormModal;
