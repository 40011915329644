import 'react-day-picker/style.css';

import { useEffect, useRef, useState } from 'react';
import { DayPicker, DayPickerProps } from 'react-day-picker';
import { ko } from 'react-day-picker/locale';
import { IconArrowRightSmall, IconCalendar } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import {
  DateRange,
  formatDate,
  max,
  min,
  noop,
  startOfMonth,
} from '@sweep/utils';
import { createDay } from './components/Day';
import { LeftNav } from './components/LeftNav';
import { MonthCaption } from './components/MonthCaption';
import { RightNav } from './components/RightNav';
import { RangePickerProvider } from './contexts/RangePickerContext';
import { useCalendarDialog } from './hooks/useCalendarDialog';
import { useMonth } from './hooks/useMonth';
import { isSelectingFrom, isSelectingTo, Selecting } from './interface';
import { getHoveredDateRange } from './services/getHoveredDateRange';
import { getNextRangeSelection } from './services/getNextRangeSelection';
import { getUpdatedDateRange } from './services/getUpdatedDateRange';

interface RangePickerProps {
  value: DateRange;
  onChange: (value: DateRange) => void;
  status?: 'default' | 'error';
}

export function RangePicker({
  value: givenValue,
  onChange: givenOnChange,
  status = 'default',
}: RangePickerProps) {
  const [value, onChange] = useState(givenValue);

  useEffect(() => {
    onChange(givenValue);
  }, [givenValue]);

  const reference = useRef<HTMLButtonElement>(null);
  const [selecting, setSelecting] = useState<Selecting>(null);
  const [hovered, setHovered] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const selectedRange = getHoveredDateRange(value, selecting, hovered);

  const { leftMonth, rightMonth, onLeftMonthChange, onRightMonthChange } =
    useMonth(selectedRange);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open === false) {
      givenOnChange({
        from: min([value.from, value.to]),
        to: max([value.from, value.to]),
      });
      setSelecting(null);
      setHovered(null);
    }
  };

  const { floatingStyles, getFloatingProps, refs } = useCalendarDialog({
    open: isOpen,
    onOpenChange: handleOpenChange,
    reference,
  });

  const openWithLeft = () => {
    onLeftMonthChange(startOfMonth(selectedRange.from));
    setIsOpen(true);
    setSelecting('first-from');
  };

  const openWithRight = (event: React.MouseEvent<HTMLParagraphElement>) => {
    event.stopPropagation();
    onRightMonthChange(startOfMonth(selectedRange.to));
    setIsOpen(true);
    setSelecting('first-to');
  };

  const handleDayClick = (date: Date) => {
    const nextSelection = getNextRangeSelection(selecting);
    setSelecting(nextSelection);

    const updatedDateRange = getUpdatedDateRange(value, selecting, date);
    onChange(updatedDateRange);

    if (nextSelection == null) {
      setIsOpen(false);
      givenOnChange(updatedDateRange);
      setHovered(null);
      return;
    }
  };

  const isHovered = hovered != null;
  return (
    <RangePickerProvider
      value={{
        range: selectedRange,
        onHoveredChange: setHovered,
        leftMonth,
        rightMonth,
        onDayClick: handleDayClick,
      }}
    >
      <div>
        <button
          ref={reference}
          onClick={openWithLeft}
          className={labelContainer({ status })}
        >
          <p
            className={label({
              selecting: isSelectingFrom(selecting) && isHovered,
            })}
          >
            {formatDate(selectedRange.from, 'yyyy.MM.dd')}
          </p>
          <IconArrowRightSmall className="text-gray-400" />
          <p
            className={label({
              selecting: isSelectingTo(selecting) && isHovered,
            })}
            onClick={openWithRight}
          >
            {formatDate(selectedRange.to, 'yyyy.MM.dd')}
          </p>
          <div className="w-32px flex items-center justify-end border-l border-gray-300 pr-[4.5px]">
            <IconCalendar className="text-gray-400" />
          </div>
        </button>
        {isOpen && (
          <div
            className={container()}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="gap-24px flex">
              <DayPicker
                mode="range"
                fixedWeeks
                showOutsideDays
                weekStartsOn={1}
                locale={ko}
                month={leftMonth}
                onMonthChange={onLeftMonthChange}
                selected={selectedRange}
                // NOTE(@이지원): https://github.com/gpbl/react-day-picker/issues/2690
                onSelect={noop}
                classNames={classNames}
                components={leftComponents}
              />
              <DayPicker
                mode="range"
                fixedWeeks
                showOutsideDays
                weekStartsOn={1}
                locale={ko}
                month={rightMonth}
                onMonthChange={onRightMonthChange}
                selected={selectedRange}
                onSelect={noop}
                classNames={classNames}
                components={rightComponents}
              />
            </div>
          </div>
        )}
      </div>
    </RangePickerProvider>
  );
}

const container = cva(
  'p-16px shadow-box rounded-12px z-dropdown absolute top-full bg-white'
);

const labelContainer = cva(
  'gap-12px pl-15px pr-7px py-6px h-40px rounded-8px flex items-center border',
  {
    variants: {
      status: {
        default: 'border-gray-300',
        error: 'border-red-500',
      },
    },
  }
);

const label = cva('text-medium-s w-80px', {
  variants: {
    selecting: {
      true: 'text-gray-400',
      false: 'text-gray-700',
    },
  },
});

const leftComponents: DayPickerProps['components'] = {
  Nav: LeftNav,
  MonthCaption,
  Day: createDay('left'),
};

const rightComponents: DayPickerProps['components'] = {
  Nav: RightNav,
  MonthCaption,
  Day: createDay('right'),
};

const monthCaption = cva('flex-center flex');
const week = cva('h-32px');

const today = cva('text-blue-500');
const outside = cva('text-gray-400');

const classNames: DayPickerProps['classNames'] = {
  month_caption: monthCaption(),
  week: week(),
  day: '',
  day_button: '',
  outside: outside(),
  today: today(),
  range_start: '',
  range_end: '',
  range_middle: '',
  selected: '',
};
