import { c } from '../internal/contract';
import { addressContract } from './address';
import { adminRouterContract } from './admin';
import { orderRouterContract } from './order';
import { FulfilledOrderTransactionRouterContract } from './partnership/FulfilledOrderTransactionRouter';
import { PartnershipRouterContract } from './partnership/PartnershipRouter';
import { PurchaseOrderTransactionRouterContract } from './partnership/PurchaseOrderTransactionRouter';
import { RemoteStorageFileRouterContract } from './remote-storage-file/RemoteStorageFileRouter';
import { fulfillOrderRouterContract } from './shopping-mall/fulfillOrder';
import { inquiryRouterContract } from './shopping-mall/inquiry';
import { SpreadsheetRouterContract } from './spreadsheet/SpreadsheetRouter';
import { userRouterContract } from './user';
import { userOpinionRouterContract } from './userOpinion';

export const contract = c.router(
  {
    address: addressContract,
    admin: adminRouterContract,
    user: userRouterContract,
    userOpinion: userOpinionRouterContract,
    order: orderRouterContract,
    inquiry: inquiryRouterContract,
    fulfillOrder: fulfillOrderRouterContract,
    remoteStorageFile: RemoteStorageFileRouterContract,
    spreadsheet: SpreadsheetRouterContract,
    partnership: PartnershipRouterContract,
    purchaseOrderTransaction: PurchaseOrderTransactionRouterContract,
    fulfilledOrderTransaction: FulfilledOrderTransactionRouterContract,
  },
  {
    // NOTE(@형준): 적용하면 type error 발생해서 비활성화
    // commonResponses: {
    //   400: CommonErrorResponseSchema,
    //   500: CommonErrorResponseSchema,
    // },
  }
);

export {
  userRouterContract,
  userOpinionRouterContract,
  orderRouterContract,
  inquiryRouterContract,
  fulfillOrderRouterContract,
  RemoteStorageFileRouterContract,
  SpreadsheetRouterContract,
  PartnershipRouterContract,
  PurchaseOrderTransactionRouterContract,
  FulfilledOrderTransactionRouterContract,
};
