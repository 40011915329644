import { PriceHistory } from '@sweep/contract';
import { sortBy } from '@sweep/utils';

export function getPriceFromHistory(
  price: number,
  priceHistories: PriceHistory[],
  date: number
): number {
  const sortedPriceHistory = sortBy(priceHistories, ['changedAt']);
  const priceHistory = sortedPriceHistory.find(
    (history) => date < new Date(history.changedAt).getTime()
  );

  return priceHistory?.value ?? price ?? 0;
}
