import { Fragment } from 'react/jsx-runtime';
import { cva } from '@sweep/tailwind';
import { DateRange, isSameDay } from '@sweep/utils';

interface RangeSelectProps {
  value: DateRange;
  onChange: (value: DateRange) => void;
  options: {
    value: DateRange;
    label: string;
  }[];
  className?: string;
}

export function RangeSelect({
  value,
  onChange,
  options,
  className,
}: RangeSelectProps) {
  const selectedIndex = options.findIndex((option) =>
    isSameRange(value, option.value)
  );

  return (
    <div className={container({ className })}>
      {options.map((option, index) => (
        <Fragment key={option.label}>
          <button
            className={button({
              selected: selectedIndex === index,
              position: getPosition(index, options.length),
            })}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </button>
          {index !== options.length - 1 && (
            <div
              className={divider({
                active: selectedIndex === index || selectedIndex === index + 1,
              })}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}

const container = cva('flex');

const button = cva(
  [
    'h-40px flex-center flex flex-1 border-y bg-white',
    'text-medium-s text-gray-700',
  ],
  {
    variants: {
      selected: {
        true: 'border-blue-500 text-blue-500',
        false: 'border-gray-300 text-gray-700',
      },
      position: {
        first: 'rounded-l-8px border-l',
        middle: '',
        last: 'rounded-r-8px border-r',
      },
    },
  }
);

const divider = cva('w-1px shrink-0', {
  variants: {
    active: {
      true: 'bg-blue-500',
      false: 'bg-gray-300',
    },
  },
});

function getPosition(index: number, length: number) {
  if (index === 0) {
    return 'first';
  }
  if (index === length - 1) {
    return 'last';
  }
  return 'middle';
}

function isSameRange(a: DateRange, b: DateRange) {
  return isSameDay(a.from, b.from) && isSameDay(a.to, b.to);
}
