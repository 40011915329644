import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';
import { UseColumnWidthReturn } from '../hooks/useColumnWidth';

export type TableContextValue = UseColumnWidthReturn;

const tableContext = createContext<TableContextValue>({
  columnWidths: {},
  onWidthChange: noop,
  onHeaderWidthChange: noop,
  onAdjustedWidthChange: noop,
  onFooterWidthChange: noop,
  onFitWidth: noop,
  isHeaderMounted: false,
  isCellMounted: false,
  isFooterMounted: false,
});

export const TableContextProvider = tableContext.Provider;

export const useTableContext = () => useContext(tableContext);
