import { computed, makeObservable } from 'mobx';
import { DateRange, endOfDay, startOfDay, subDays } from '@sweep/utils';
import { findProcessedNormalizedOrdersByDateRange } from 'src/network/order/order';
import { OMSStore } from 'src/stores/OMSStore';
import { toast } from 'src/third-parties/toast';
import { SettlementOrder } from '../interface';
import { toSettlementOrder } from '../services/toSettlementOrder';
import { RangeValueStore } from './RangeValueStore';

export class SettlementStore {
  private readonly pastOrderStore: RangeValueStore<SettlementOrder>;

  constructor(private oms: OMSStore) {
    makeObservable(this);
    this.pastOrderStore = new RangeValueStore<SettlementOrder>(
      findPastOrders(this.oms),
      ({ registeredAt }) =>
        registeredAt != null ? new Date(registeredAt) : null
    );
  }

  // NOTE(@이지원): normalizedOrders가 변경되었을 때, orders도 바로 변경될 수 있도록 findPastOrders와 분리
  @computed
  private get todayOrders(): SettlementOrder[] {
    return this.oms.order.normalizedOrders
      .filter((order) => order.shippingStatus !== 'pending')
      .map((order) => toSettlementOrder(this.oms, order));
  }

  @computed
  get orders() {
    const today = startOfDay(new Date());
    return {
      [today.toISOString()]: this.todayOrders,
      ...this.pastOrderStore.values,
    };
  }

  async ensure(range: DateRange) {
    const today = startOfDay(new Date());
    const isTodayIncluded = today.getTime() <= range.to.getTime();

    if (!isTodayIncluded) {
      await this.pastOrderStore.ensure(range);
      return;
    }

    const rangeWithoutToday = {
      from: range.from,
      to: endOfDay(subDays(today, 1)),
    };
    await this.pastOrderStore.ensure(rangeWithoutToday);
  }
}

const findPastOrders = (oms: OMSStore) => async (range: DateRange) => {
  const response = await findProcessedNormalizedOrdersByDateRange(range);
  if (response.status !== 200) {
    toast.error('주문 정보를 불러오는데 실패했습니다.');
    return [];
  }

  return response.body.normalizedOrders.map((order) =>
    toSettlementOrder(oms, order)
  );
};
