import axios from 'axios';
import { BACKEND_URL } from 'src/env';
import axiosInstance from './axiosInstance';
import { isValid } from './utils';

class BackendApi {
  async loginUser(loginData) {
    try {
      const url = `${BACKEND_URL}/user/login`;

      const {
        data: { data },
      } = await axios.post(url, { loginData });

      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }

  async initializeUserData() {
    try {
      const url = '/user/initialize';

      const {
        data: { data },
      } = await axiosInstance().get(url);

      return data;
    } catch (err) {
      console.error('Error initializing user data:', err);
      throw err;
    }
  }

  async fetchServiceVersion() {
    try {
      const url = '/version';

      const {
        data: { version },
      } = await axiosInstance().get(url);

      if (isValid(version)) {
        return version;
      } else {
        return null;
      }
    } catch (err) {
      console.error('Error fetching service version:', err);
      throw err;
    }
  }

  async registerSupplier(info) {
    try {
      const url = '/supplier/register';
      const res = await axiosInstance().post(url, {
        info,
      });

      return res.data;
    } catch (err) {
      console.error('Error registering supplier:', err);
      throw err;
    }
  }

  async updateSupplier(id, info) {
    try {
      const url = '/supplier/update';
      const res = await axiosInstance().put(url, {
        id,
        info,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating supplier info:', err);
      throw err;
    }
  }

  async deleteSupplier(id) {
    try {
      const url = '/supplier/delete';
      const res = await axiosInstance().put(url, {
        id,
      });

      return res.data;
    } catch (err) {
      console.error('Error deleting supplier info:', err);
      throw err;
    }
  }

  async updateCustomExcelSettings(columnOrder, columnTranslation) {
    try {
      const url = '/user/custom-excel';
      const res = await axiosInstance().put(url, {
        columnOrder,
        columnTranslation,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating custom excel format:', err);
      throw err;
    }
  }

  async getBillingResult(customerKey, authKey, planType) {
    try {
      const url = '/billing/register-billing-key';
      const res = await axiosInstance().get(url, {
        params: { customerKey, authKey, planType },
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async modifyBillingInfo(planType) {
    try {
      const url = '/billing/modify-billing-info';
      const res = await axiosInstance().post(url, {
        planType,
      });

      return res;
    } catch (err) {
      console.error('Error fetching shopping mall orders:', err);
      return false;
    }
  }
}

export default new BackendApi();
