import { observer } from 'mobx-react-lite';
import { Table } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { SettlementOrder } from '../../interface';
import { createSupplierSettlementTableColumns } from './createSupplierSettlementTableColumns';

interface SupplierSettlementTableProps {
  orders: SettlementOrder[];
}

function SupplierSettlementTable({ orders }: SupplierSettlementTableProps) {
  const oms = useOMSStore();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnMapping = oms.user.excelColumnMapping;

  const tableColumns = createSupplierSettlementTableColumns(
    orders,
    headerKeys,
    columnMapping
  );

  return (
    <Table
      items={orders}
      columns={tableColumns}
      getKey={(item) => item.uniqueCodeAfterCustomization}
      className="max-h-572px"
    />
  );
}

export default observer(SupplierSettlementTable);
