import saveAs from 'file-saver';
import JSZip from 'jszip';
import { ExcelFileRecord } from '@sweep/contract';
import { createOrderExcelBuffer } from 'src/services/file/excel/create/createOrderExcelBuffer';
import { createOrderExcelFromTemplateV2 } from 'src/services/file/excel/create/createOrderExcelFromTemplateV2';

export async function createPurchaseOrderZipFile(params: {
  zipFilename: string;
  purchaseOrderFileRecords: ExcelFileRecord[];
}): Promise<void> {
  const { purchaseOrderFileRecords, zipFilename } = params;

  const zip = new JSZip();

  const zipPromises = purchaseOrderFileRecords.map(async (fileRecord) => {
    const { filename, fileExtension } = fileRecord;

    const filenameWithoutExtension = `${filename}.${fileExtension}`;

    const isTemplateFileEnabled =
      fileRecord.supplierCustomizedSettings?.isTemplateFileEnabled === true;

    const buffer = isTemplateFileEnabled
      ? await createOrderExcelFromTemplateV2(fileRecord)
      : await createOrderExcelBuffer(fileRecord);

    if (buffer != null) {
      zip.file(filenameWithoutExtension, buffer);
    }
  });

  await Promise.all(zipPromises);

  const zipContent = await zip.generateAsync({ type: 'blob' });
  saveAs(zipContent, `${zipFilename}.zip`);
}
