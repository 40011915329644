import saveAs from 'file-saver';
import JSZip from 'jszip';
import { ExcelFileRecord, NormalizedOrder, UserSetting } from '@sweep/contract';
import { divideBySupplier } from '@sweep/domain/services/order';
import { selectFileFormatting } from '@sweep/domain/services/order-shipping-process-purchase-order';
import { createUserCustomizedSettings } from '@sweep/domain/services/purchase-order-file';
import { formatDate } from '@sweep/utils';
import { findHeaderKeys } from 'src/services/column-mapping/findHeaderKeys';
import { createOrderExcel } from 'src/services/file/excel/create';
import { createOrderExcelBuffer } from 'src/services/file/excel/create/createOrderExcelBuffer';
import {
  createOrderExcelFromTemplate,
  CreateOrderExcelFromTemplateOptions,
} from 'src/services/file/excel/create/createOrderExcelFromTemplate';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import { toast } from 'src/third-parties/toast';
import { OMSStore } from '../OMSStore';
// TODO(@hungjoon): SWP-447 임시 코드 제거
// - SWP-447 작업 완료하기 전에 임시 코드 제거하고 새로운 파일 로직으로 리팩토링 정리 필요

export class FileOrderStore {
  constructor(private oms: OMSStore) {}

  downloadRetailerPurchaseOrder = async (
    orders: NormalizedOrder[],
    filename: string
  ) => {
    const { ordersBySupplier, unseparatedOrders } = divideBySupplier(orders);

    const files: { name: string; buffer: Promise<ArrayBuffer | null> }[] = [];

    const today = new Date();
    this.oms.supplier.suppliers.forEach(async (supplier) => {
      const supplierKey = supplier._id;
      const supplierOrders = ordersBySupplier[supplierKey] ?? [];

      if (supplierOrders.length === 0) {
        return;
      }

      const { fileFormat, dateFormat } = selectFileFormatting([
        supplier.settings?.fileFormatting,
        this.oms.user.setting?.supplierFileFormatting,
      ]);

      const supplierFilename = fileFormat
        .replace('{date}', formatDate(today, dateFormat))
        .replace('{supplierName}', supplier.name)
        .replace('{name}', this.oms.user.name);

      const supplierHeader = supplier.header ?? [];
      const supplierColumnMapping = supplier.columnMapping ?? {};
      const userDefaultExcelHeaderKeys = this.oms.user.excelHeaderKeys;
      const userDefaultExcelColumnMapping = this.oms.user.excelColumnMapping;

      // NOTE(@hungjoon): SWP-447 임시 코드
      const { headers, headerKeys, columnMapping } = selectExcelHeaderAndMapper(
        {
          supplierHeaders: supplierHeader,
          supplierColumnMapping: supplierColumnMapping,
          userDefaultHeaderKeys: userDefaultExcelHeaderKeys,
          userDefaultColumnMapping: userDefaultExcelColumnMapping,
        }
      );
      // NOTE(@hungjoon): SWP-447 임시 코드

      const userCustomSettings = this.oms.user.setting as UserSetting;
      const supplierCustomizedSettings: {
        enabled?: boolean;
      } = supplier?.customizedSettings?.xlsxTemplateSetting ?? {};
      const isSupplierTemplateEnabled =
        supplierCustomizedSettings?.enabled === true;

      const separateAddressEnabled =
        userCustomSettings?.preprocessSettings?.separateAddress?.enabled ??
        false;

      const userSetting = userCustomSettings as UserSetting;
      const userExcelSettingFields = createUserCustomizedSettings(userSetting);

      const excelFileRecord: ExcelFileRecord = {
        filename: supplierFilename,
        fileExtension: 'xlsx',
        orders: supplierOrders,
        headers,
        headerKeys,
        columnMappings: columnMapping,
        separateAddressEnabled,
        userExcelSettingFields,
      };

      const buffer = isSupplierTemplateEnabled
        ? createOrderExcelFromTemplate(
            headers,
            columnMapping,
            supplierOrders,
            supplierCustomizedSettings as CreateOrderExcelFromTemplateOptions
          )
        : createOrderExcelBuffer(excelFileRecord);

      files.push({ name: supplierFilename, buffer });
    });

    if (unseparatedOrders.length > 0) {
      const failedOrdersFileName = formatDate(
        today,
        'yy.MM.dd 발주 분리 실패주문'
      );

      // NOTE(@hungjoon): SWP-447 임시 코드
      const migratedExcelColumnMapping = migrateExcelColumnMapping(
        this.oms.user.excelColumnMapping
      );
      const migratedExcelHeaderKeys = migrateExcelHeaderKeys(
        this.oms.user.excelHeaderKeys
      );
      // NOTE(@hungjoon): SWP-447 임시 코드
      const userSetting = this.oms.user.setting as UserSetting;
      const userExcelSettingFields = createUserCustomizedSettings(userSetting);
      const separateAddressEnabled =
        userSetting?.preprocessSettings?.separateAddress?.enabled === true;

      const excelFileRecord: ExcelFileRecord = {
        filename: failedOrdersFileName,
        fileExtension: 'xlsx',
        orders: unseparatedOrders,
        headers: migratedExcelHeaderKeys,
        headerKeys: migratedExcelHeaderKeys,
        columnMappings: migratedExcelColumnMapping,
        separateAddressEnabled,
        userExcelSettingFields,
      };

      const buffer = createOrderExcelBuffer(excelFileRecord);

      files.push({
        name: failedOrdersFileName,
        buffer,
      });
    }

    const zip = new JSZip();
    const bufferPromise = files.map((file) => file.buffer);
    const buffers = await Promise.all(bufferPromise);
    buffers.forEach((buffer, index) => {
      if (buffer == null) {
        return;
      }
      zip.file(`${files[index].name}.xlsx`, buffer);
    });

    const zipContent = await zip.generateAsync({ type: 'blob' });
    saveAs(zipContent, `${filename}.zip`);

    if (unseparatedOrders.length > 0) {
      toast.error('분류되지 않은 주문이 있습니다. 해당 상품들을 분류해주세요.');
    }
  };

  downloadSupplierPurchaseOrder = async (
    orders: NormalizedOrder[],
    filename: string
  ) => {
    const headerKeys = this.oms.user.excelHeaders;
    const columnMapping = this.oms.user.excelColumnMapping;

    // NOTE(@hungjoon): SWP-447 임시 코드
    const migratedExcelHeaderKeys = migrateExcelHeaderKeys(headerKeys);
    const migratedExcelColumnMapping = migrateExcelColumnMapping(columnMapping);
    // NOTE(@hungjoon): SWP-447 임시 코드

    createOrderExcel(
      this.oms,
      orders,
      filename,
      migratedExcelHeaderKeys,
      migratedExcelColumnMapping
    );
  };
}

function selectExcelHeaderAndMapper(params: {
  supplierHeaders: string[];
  supplierColumnMapping: Record<string, string>;
  userDefaultHeaderKeys: string[];
  userDefaultColumnMapping: Record<string, string>;
}): {
  headerKeys: string[];
  columnMapping: Record<string, string>;
  headers: string[];
} {
  const {
    supplierHeaders,
    supplierColumnMapping,
    userDefaultHeaderKeys,
    userDefaultColumnMapping,
  } = params;

  const header = supplierHeaders ?? [];
  const columnMapping = supplierColumnMapping ?? {};

  if (header.length === 0) {
    const defaultHeaderKeys = userDefaultHeaderKeys;
    const defaultColumnMapping = userDefaultColumnMapping;

    const migratedHeaderKeys = migrateExcelHeaderKeys(defaultHeaderKeys);
    const migratedColumnMapping =
      migrateExcelColumnMapping(defaultColumnMapping);

    return {
      headerKeys: migratedHeaderKeys,
      columnMapping: migratedColumnMapping,
      headers: header,
    };
  }

  const headerKeys = findHeaderKeys(header, columnMapping) as string[]; // NOTE(@hungjoon): SWP-447 임시 코드

  const migratedHeaderKeys = migrateExcelHeaderKeys(headerKeys);
  const migratedColumnMapping = migrateExcelColumnMapping(columnMapping);

  return {
    headers: header,
    headerKeys: migratedHeaderKeys,
    columnMapping: migratedColumnMapping,
  };
}

/*
 * NOTE(@hungjoon): SWP-447 임시 코드
 * 데이터 Example
 *
 * this.oms.user.excelHeaders: 수령인, 수령인 연착처, 핸드폰번호, 고유번호
 * this.oms.user.excelColumnMapping: {name: '수령인', uniqueCode: '고유번호'}
 */
function migrateExcelColumnMapping(
  excelColumnMapping: Record<string, string>
): Record<string, string> {
  const entries = Object.entries(excelColumnMapping);

  const targetEntries = entries.filter(([key]) => key === 'uniqueCode');
  if (targetEntries.length === 0) {
    return { ...excelColumnMapping, uniqueCodeAfterCustomization: '고유번호' };
  }

  if (targetEntries.length > 1) {
    ErrorReporter.captureMessage('SWP-380: uniqueCode 가 여러 개 있습니다.', {
      extra: {
        excelColumnMapping,
      },
    });
  }

  const targetEntryValue = targetEntries[0][1];

  const removedEntries = entries.filter(([key]) => key !== 'uniqueCode');
  const addedEntries = [['uniqueCodeAfterCustomization', targetEntryValue]];
  const newEntries = [...removedEntries, ...addedEntries];

  return Object.fromEntries(newEntries);
}

function migrateExcelHeaderKeys(excelHeaderKeys: string[]): string[] {
  if (excelHeaderKeys.length === 0) {
    return [];
  }

  const originalIndex = excelHeaderKeys.findIndex(
    (key) => key === 'uniqueCode'
  );

  const notFound = originalIndex === -1;

  if (notFound) {
    return [...excelHeaderKeys, 'uniqueCodeAfterCustomization'];
  }

  const updatedHeaderKeys = excelHeaderKeys.map((key, index) => {
    if (index === originalIndex) {
      return 'uniqueCodeAfterCustomization';
    }
    return key;
  });

  return updatedHeaderKeys;
}
