import saveAs from 'file-saver';
import { action, computed, observable, runInAction } from 'mobx';
import {
  FulfilledOrderTransaction,
  PurchaseOrderTransaction,
} from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import { openConfirmDialog } from 'src/components/openConfirmDialog';
import * as FulfilledOrderTransactionAPI from 'src/network/partnership/FulfilledOrderTransactionAPI';
import * as PurchaseOrderTransactionAPI from 'src/network/partnership/PurchaseOrderTransactionAPI';
import * as SpreadsheetAPI from 'src/network/partnership/SpreadsheetAPI';
import { isProtected as checkIsFileEncrypted } from 'src/services/file/excel/read/isProtected';
import { promptForPassword } from 'src/services/file/excel/read/readProtectedExcel';
import { createDebug } from 'src/third-parties/createDebug';
import { toast } from 'src/third-parties/toast';
import { modifyFilename } from 'src/utils/modifyFilename';
import { OMSStore } from '../OMSStore';

const debug = createDebug('RetailerOrderTransactionStore');

const ERROR_MESSAGE_FILE_DOWNLOAD_FAILED = '파일 다운로드에 실패했습니다.';
const ERROR_MESSAGE_FILE_UPLOAD_FAILED = '파일 업로드에 실패했습니다.';
const ERROR_MESSAGE_PARTNERSHIP_NOT_FOUND = '파트너십을 찾을 수 없습니다.';

const SUCCESS_MESSAGE_FILE_DELETED = '파일이 삭제되었습니다.';
const ERROR_MESSAGE_FILE_DELETE_FAILED =
  '파트너사가 주문을 확인하여 삭제할 수 없습니다.';

export class RetailerOrderTransactionStore {
  @observable accessor isInitialized: boolean = false;
  @observable accessor selectedDate: string;
  @observable accessor selectedPartnershipUUID: string | null = null;

  @observable accessor outgoingTransactions: FulfilledOrderTransaction[] = [];
  @observable accessor incomingTransactions: PurchaseOrderTransaction[] = [];
  @observable accessor lastIncomingTransactionId: string | null = null;

  constructor(private oms: OMSStore) {
    this.selectedDate = formatDate(new Date(), 'yyyy-MM-dd');
  }

  @computed
  get selectedOutgoingTransactions() {
    if (this.selectedPartnershipUUID == null) {
      return [];
    }

    return this.outgoingTransactions.filter(
      (transaction) =>
        transaction.partnershipUUID === this.selectedPartnershipUUID
    );
  }

  @computed
  get selectedIncomingTransactions() {
    if (this.selectedPartnershipUUID == null) {
      return [];
    }

    return this.incomingTransactions.filter(
      (transaction) =>
        transaction.partnershipUUID === this.selectedPartnershipUUID
    );
  }

  @computed
  get selectedPartnershipUploadedFileNames() {
    if (this.selectedPartnershipUUID == null) {
      return [];
    }

    return this.selectedOutgoingTransactions.map(
      (transaction) => transaction.files.original.filename
    );
  }

  @computed
  get selectedPartnershipRetailerName() {
    if (this.selectedPartnershipUUID == null) {
      return '';
    }

    const partnership = this.oms.partnership.partnerships.get(
      this.selectedPartnershipUUID
    );

    return partnership?.retailer.name;
  }

  @action.bound
  async init() {
    await this.loadOutgoingTransactions(this.selectedDate);
    await this.loadIncomingTransactions(this.selectedDate);

    this.isInitialized = true;
  }

  @action.bound
  async loadOutgoingTransactions(date: string) {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return;
    }

    const response =
      await PurchaseOrderTransactionAPI.findAllPurchaseOrderTransactions({
        retailerUserId: userId,
        createdAtDate: date,
      });

    if (response.status === 200 && response.body.success) {
      runInAction(() => {
        this.outgoingTransactions = response.body.data;
      });
    }

    // TODO(@형준) 오류 처리
  }
  @action.bound
  async loadIncomingTransactions(date: string) {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return;
    }

    const response =
      await FulfilledOrderTransactionAPI.findAllFulfilledOrderTransactions({
        retailerUserId: userId,
        createdAtDate: date,
      });

    if (response.status === 200 && response.body.success) {
      runInAction(() => {
        const incomingTransactions = response.body.data;
        const sortedIncomingTransactions = incomingTransactions.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        const lastIncomingTransaction =
          sortedIncomingTransactions[sortedIncomingTransactions.length - 1];

        this.incomingTransactions = sortedIncomingTransactions;

        this.lastIncomingTransactionId = lastIncomingTransaction?._id;
      });
    }

    // TODO(@형준) 오류 처리
  }

  @action.bound
  async checkAndReloadIncomingTransactions(): Promise<boolean> {
    const currentDate = formatDate(new Date(), 'yyyy-MM-dd');

    if (currentDate !== this.selectedDate) {
      this.selectedDate = currentDate;
      await this.loadOutgoingTransactions(currentDate);
      await this.loadIncomingTransactions(currentDate);
      return true;
    }

    const isAvailable = await this.isIncomingTransactionAvailable();

    if (isAvailable) {
      await this.loadIncomingTransactions(currentDate);
      return true;
    }

    return false;
  }

  private async isIncomingTransactionAvailable(): Promise<boolean> {
    const userId = this.oms.user.userId;

    if (userId == null) {
      return false;
    }

    const lastTransactionId =
      this.incomingTransactions[this.incomingTransactions.length - 1]?._id;

    const response =
      await FulfilledOrderTransactionAPI.countFulfilledOrderTransactions({
        retailerUserId: userId,
        lastTransactionId: lastTransactionId ?? undefined,
      });

    if (response.status === 200 && response.body.success) {
      return response.body.data.count > 0;
    }

    return false;
  }

  @action.bound
  async downloadPurchaseOrderFile(transactionId: string) {
    const response =
      await PurchaseOrderTransactionAPI.getPurchaseOrderTransactionDownloadUrl(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      const downloadUrl = response.body.data.downloadUrl;

      saveAs(downloadUrl);
    } else {
      toast.error(ERROR_MESSAGE_FILE_DOWNLOAD_FAILED);
    }
  }
  @action.bound
  async downloadFulfilledOrderFile(transactionId: string) {
    const response =
      await FulfilledOrderTransactionAPI.getFulfilledOrderTransactionDownloadUrl(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      const downloadUrl = response.body.data.downloadUrl;

      saveAs(downloadUrl);
    } else {
      toast.error(ERROR_MESSAGE_FILE_DOWNLOAD_FAILED);
    }
  }

  @action.bound
  async sendPurchaseOrderFile(
    file: File,
    partnershipUUID: string
  ): Promise<boolean> {
    const selectPartnership =
      this.oms.partnership.partnerships.get(partnershipUUID);

    if (selectPartnership == null) {
      toast.error(ERROR_MESSAGE_PARTNERSHIP_NOT_FOUND);
      return false;
    }

    const isFileEncrypted = await checkIsFileEncrypted(file);

    const password = isFileEncrypted
      ? await promptForPassword({
          filename: file.name,
          messageType: 'FIRST_ATTEMPT',
        })
      : undefined;

    const retailerName = selectPartnership.retailer.name;
    const modifiedFile = modifyFilename(
      file,
      (name) => `${retailerName}_${name}`
    );

    const uploadResponse = await SpreadsheetAPI.uploadSpreadsheetFile(
      modifiedFile,
      password ?? undefined
    );

    if (
      uploadResponse.status !== 200 ||
      uploadResponse.body.success === false
    ) {
      toast.error(ERROR_MESSAGE_FILE_UPLOAD_FAILED);
      return false;
    }

    const { fileUUID, parsedFileUUID } = uploadResponse.body.data;

    const response =
      await PurchaseOrderTransactionAPI.createPurchaseOrderTransaction({
        partnershipUUID,
        fileUUID,
        preprocessedFileUUID: parsedFileUUID,
        retailerUserId: selectPartnership.retailerUserId,
        supplierUserId: selectPartnership.supplierUserId,
      });

    if (response.status === 200 && response.body.success) {
      const insertedTransaction = response.body.data;
      this.outgoingTransactions.push(insertedTransaction);

      return true;
    }

    return false;
  }

  @action.bound
  async deletePurchaseOrderTransaction(transactionId: string) {
    const isConfirmed = await openConfirmDialog('발주 파일을 삭제할까요?');

    if (!isConfirmed) {
      return;
    }

    const response =
      await PurchaseOrderTransactionAPI.deletePurchaseOrderTransaction(
        transactionId
      );

    if (response.status === 200 && response.body.success) {
      runInAction(() => {
        this.outgoingTransactions = this.outgoingTransactions.filter(
          (transaction) => transaction._id !== transactionId
        );
      });

      toast.success(SUCCESS_MESSAGE_FILE_DELETED);
    } else {
      toast.error(ERROR_MESSAGE_FILE_DELETE_FAILED);
    }
  }
}
