import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Divider } from '@sweep/sds';
import { DateRange, groupBy, NULL_STRING, sum } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import SettlementPartnerItem from '../components/SettlementPartnerItem';
import SettlementRangeSelect from '../components/SettlementRangeSelect';
import SettlementShoppingMallItem from '../components/SettlementShoppingMallItem';
import TotalPrice from '../components/TotalPrice';
import { SettlementOrder } from '../interface';
import { getPartnerSettlements } from '../services/getPartnerSettlements';
import { getShoppingMallSettlements } from '../services/getShoppingMallSettlements';
import { isSupportShoppingMall } from '../services/isSupportShoppingMall';
import { downloadSalesChannelSettlementExcel } from '../services/downloadSalesChannelSettlementExcel';
import { SalesChannelSettlementScreenStore } from '../store/SalesChannelSettlementScreenStore';
import { SettlementStore } from '../store/SettlementStore';

function SalesChannelSettlementScreen() {
  const oms = useOMSStore();
  const shoppingMalls = oms.shoppingMall.shoppingMalls;
  const partners = oms.partner.partners;

  const settlement = oms.getStore(SettlementStore);
  const store = oms.getStore(SalesChannelSettlementScreenStore, settlement);

  useEffect(() => {
    oms.loading.batch(() => store.ensureOrders(store.range));
  }, [oms.loading, store]);

  const handleRangeChange = async (range: DateRange) => {
    store.setRange(range);
    oms.loading.batch(() => store.ensureOrders(range));
  };

  const orders = store.orders;
  const ordersByShoppingMall = groupBy(
    orders,
    (order) => order.shoppingMallId?.toString() ?? NULL_STRING
  );
  const shoppingMallSettlements = getShoppingMallSettlements(
    shoppingMalls,
    ordersByShoppingMall
  );
  const shoppingMallTotalPrice = sum(
    shoppingMallSettlements.map((settlement) => settlement.totalPrice)
  );

  const ordersByPartner = groupBy(
    orders,
    (order) => order.partnerId?.toString() ?? NULL_STRING
  );
  const partnerSettlements = getPartnerSettlements(partners, ordersByPartner);
  const partnerTotalPrice = sum(
    partnerSettlements.map((settlement) => settlement.totalPrice)
  );

  const totalPrice = shoppingMallTotalPrice + partnerTotalPrice;

  const handleDownload = (name: string, orders: SettlementOrder[]) => {
    downloadSalesChannelSettlementExcel(oms, name, store.range, orders);
  };

  return (
    <div className="py-20px gap-28px px-40px flex min-h-screen flex-col bg-gray-100">
      <p className="text-extrabold-l text-gray-700">판매처 정산 관리</p>
      <div className="p-20px rounded-8px gap-24px shadow-line flex bg-white">
        <SettlementRangeSelect
          value={store.range}
          onChange={handleRangeChange}
        />
        <Divider type="vertical" />
        <TotalPrice type="salesChannel" value={totalPrice} />
      </div>
      <If is={shoppingMallSettlements.length > 0}>
        <div className="rounded-8px p-20px shadow-line gap-20px flex flex-col bg-white">
          <div className="gap-4px flex items-center">
            <p className="text-medium-s text-gray-500">쇼핑몰</p>
            <p className="text-medium-s text-gray-500">
              (<span className="text-blue-500">{shoppingMalls.length}</span>곳)
            </p>
          </div>
          <div className="gap-24px grid grid-cols-3">
            {shoppingMallSettlements.map(
              ({ shoppingMall, totalPrice, orders }) => (
                <SettlementShoppingMallItem
                  key={shoppingMall._id}
                  shoppingMall={shoppingMall}
                  price={isSupportShoppingMall(shoppingMall) ? totalPrice : 0}
                  onDownload={() =>
                    isSupportShoppingMall(shoppingMall) &&
                    handleDownload(shoppingMall.name, orders)
                  }
                />
              )
            )}
          </div>
        </div>
      </If>
      <If is={partners.length > 0}>
        <div className="rounded-8px p-20px shadow-line gap-20px flex flex-col bg-white">
          <div className="gap-4px flex items-center">
            <p className="text-medium-s text-gray-500">판매처</p>
            <p className="text-medium-s text-gray-500">
              (<span className="text-blue-500">{partners.length}</span>곳)
            </p>
          </div>
          <div className="gap-24px grid grid-cols-3">
            {partnerSettlements.map(({ partner, totalPrice, orders }) => (
              <SettlementPartnerItem
                key={partner._id}
                partner={partner}
                price={totalPrice}
                onDownload={() => handleDownload(partner.name, orders)}
              />
            ))}
          </div>
        </div>
      </If>
    </div>
  );
}

export default observer(SalesChannelSettlementScreen);
