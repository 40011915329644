import { getShoppingMallType, ShoppingMall } from '@sweep/contract';

export function isSupportShoppingMall(shoppingMall: ShoppingMall) {
  const shoppingMallType = getShoppingMallType(shoppingMall.name);

  if (shoppingMallType == null) {
    return false;
  }

  return (
    shoppingMallType === 'AliExpress' ||
    shoppingMallType === 'Eleven' ||
    shoppingMallType === 'LotteOn' ||
    shoppingMallType === 'Imweb'
  );
}
