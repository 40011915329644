import { useOMSStore } from 'hooks/useOMSStore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserStore from 'stores/UserStore';
import Button from '../../components/buttons/Button';
import Footer from '../../components/Footer';
import Sweep from '../../images/Sweep.png';
import LoginInput from './components/LoginInput';

const LoginScreen = () => {
  const oms = useOMSStore();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      login();
    }
  };

  const handleError = (error: string) => {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const login = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.length === 0) {
      setEmailError('이메일을 입력해주세요.');
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError('올바른 메일 형식으로 입력해주세요.');
      return;
    }

    if (password.length === 0) {
      setPasswordError('비밀번호를 입력해주세요.');
      return;
    }

    oms.loading.start();

    await oms.user.login(email, password);

    try {
      const loginData = { email, password };
      const isSuccess = await UserStore.loginUser(loginData);

      if (isSuccess) {
        await oms.init();
        if (oms.user.isRetailerGuest) {
          navigate('/partnership/');
        } else {
          navigate('/order-dispatch');
        }
      } else {
        handleError('아이디 또는 비밀번호를 확인해 주세요.');
      }
    } catch {
      handleError(
        '로그인에 실패했어요. 계속 진행이 안될 시 고객센터로 문의주세요.'
      );
    } finally {
      oms.loading.end();
    }
  };

  return (
    <>
      <div className="flex h-screen items-center justify-center ">
        <div className="w-1/3 bg-white">
          <div className="mb-10 flex">
            <img src={Sweep} alt="Service icon" className="w-22.5 h-6" />
          </div>

          <div className="mb-16 flex">
            <h1 className="text-2xl font-bold text-gray-600">로그인</h1>
          </div>

          <LoginInput
            label="이메일 주소"
            type="email"
            value={email}
            onChange={setEmail}
            error={emailError}
            onKeyDown={handleKeyDown}
          />
          <LoginInput
            label="비밀번호"
            type="password"
            value={password}
            onChange={setPassword}
            error={passwordError}
            onKeyDown={handleKeyDown}
          />

          <div className="flex justify-end">
            <Button name={'로그인'} onClick={login} />
          </div>
        </div>

        {error != null && (
          <div
            style={{ padding: '16px 22px' }}
            className={`fixed left-1/2 top-1/2 max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl border bg-white shadow-2xl${
              emailError ? 'animate-scaleUp' : 'animate-scaleDownAndFade'
            } opacity-100 duration-500`}
          >
            <p className="text-center">{error}</p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default LoginScreen;
