import {
  PARSE_SPREADSHEET_GUEST_URL,
  PARSE_SPREADSHEET_URL,
  type ParseSpreadsheetErrorCode,
  type ParseSpreadsheetRequest,
  type ParseSpreadsheetResponse,
} from '@sweep/contract';
import { assert } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function parseSpreadsheet(params: ParseSpreadsheetRequest) {
  const { file, password, includeRawData, tagUploadSource } = params;

  const filename = file.name.normalize('NFC');

  const formData = new FormData();

  formData.append('spreadsheet', file);
  formData.append('filename', filename);
  if (password != null) {
    formData.append('password', password);
  }
  formData.append('includeRawData', (includeRawData === true).toString());
  if (tagUploadSource != null) {
    formData.append('tagUploadSource', tagUploadSource);
  }

  const response = await api.post<
    APIResponse<ParseSpreadsheetResponse, ParseSpreadsheetErrorCode>
  >(PARSE_SPREADSHEET_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  assert(response != null, 'Failed to parse spreadsheet');

  return response.data;
}

export async function parseSpreadsheetGuest(params: ParseSpreadsheetRequest) {
  const { file, password, includeRawData, tagUploadSource } = params;

  const filename = file.name.normalize('NFC');

  const formData = new FormData();

  formData.append('spreadsheet', file);
  formData.append('filename', filename);
  if (password != null) {
    formData.append('password', password);
  }
  formData.append('includeRawData', (includeRawData === true).toString());
  if (tagUploadSource != null) {
    formData.append('tagUploadSource', tagUploadSource);
  }

  const response = await api.post<
    APIResponse<ParseSpreadsheetResponse, ParseSpreadsheetErrorCode>
  >(PARSE_SPREADSHEET_GUEST_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  assert(response != null, 'Failed to parse spreadsheet');

  return response.data;
}
