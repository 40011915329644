import { formatDate } from '@sweep/utils';
import { Markdown } from '../Markdown';

interface InquiryWriteReplyResultProps {
  content?: string;
  replyDate?: number;
}

function InquiryWriteReplyResult({
  content,
  replyDate,
}: InquiryWriteReplyResultProps) {
  return (
    <div className="gap-8px flex w-full flex-col">
      {content != null ? (
        <Markdown className="rounded-8px py-12px px-16px w-full min-w-0 flex-1 border-[1.5px] border-gray-200">
          {content}
        </Markdown>
      ) : (
        <div className="px-15px py-12px rounded-8px w-full border-[1.5px] border-gray-200">
          <p className="text-medium-m text-gray-400">처리 완료된 문의입니다.</p>
        </div>
      )}
      {replyDate != null && (
        <div className="flex justify-end">
          <p className="text-medium-s text-gray-500">
            처리일시: {formatDate(new Date(replyDate), 'yyyy-MM-dd HH:mm:ss')}
          </p>
        </div>
      )}
    </div>
  );
}

export default InquiryWriteReplyResult;
