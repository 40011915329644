import { useCallback } from 'react';
import { TableFilterStore } from '../TableFilterStore';

export function useSort(store: TableFilterStore, id: string) {
  const { id: sortId, type } = store.sort;
  const isASC = sortId === id && type === 'ASC';
  const isDESC = sortId === id && type === 'DESC';

  const handleASCClick = useCallback(() => {
    if (sortId === id && type === 'ASC') {
      store.setSort(null, null);
      return;
    }

    store.setSort(id, 'ASC');
  }, [id, sortId, store, type]);

  const handleDESCClick = useCallback(() => {
    if (sortId === id && type === 'DESC') {
      store.setSort(null, null);
      return;
    }

    store.setSort(id, 'DESC');
  }, [id, sortId, store, type]);

  return {
    isASC,
    isDESC,
    onASCClick: handleASCClick,
    onDESCClick: handleDESCClick,
  };
}
