import {
  FulfilledOrderTransactionConfirmBody,
  FulfilledOrderTransactionCreateBody,
  FulfilledOrderTransactionFindAllQuery,
} from '@sweep/contract';
import { client } from '../client';

export async function createFulfilledOrderTransaction(
  data: FulfilledOrderTransactionCreateBody
) {
  return await client.fulfilledOrderTransaction.create({
    body: data,
  });
}

export async function findAllFulfilledOrderTransactions(
  query?: FulfilledOrderTransactionFindAllQuery
) {
  return await client.fulfilledOrderTransaction.findAll({
    query,
  });
}

export async function countFulfilledOrderTransactions(
  query?: FulfilledOrderTransactionFindAllQuery
) {
  return await client.fulfilledOrderTransaction.count({
    query,
  });
}

export async function confirmFulfilledOrderTransaction(
  fulfilledOrderTransactionId: string,
  data: FulfilledOrderTransactionConfirmBody = {}
) {
  return await client.fulfilledOrderTransaction.confirm({
    params: {
      fulfilledOrderTransactionId,
    },
    body: data,
  });
}

export async function deleteFulfilledOrderTransaction(
  fulfilledOrderTransactionId: string
) {
  return await client.fulfilledOrderTransaction.delete({
    params: {
      fulfilledOrderTransactionId,
    },
  });
}

export async function getFulfilledOrderTransactionDownloadUrl(
  fulfilledOrderTransactionId: string
) {
  return await client.fulfilledOrderTransaction.getDownloadUrl({
    params: {
      fulfilledOrderTransactionId,
    },
  });
}
