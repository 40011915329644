import { action, computed, observable, runInAction } from 'mobx';
import { OMSStore } from 'src/stores/OMSStore';
import { toast } from 'src/third-parties/toast';
import { RadioInquiryStatus } from './components/StatusRadioGroup';
import { formatInquiry } from './services/formatInquiry';
import { getInquiries } from './services/getInquiries';
import { requestShoppingMallsInquiry } from './services/requestShoppingMallsInquiry';

export class InquiryScreenStore {
  @observable
  accessor lastUpdatedAt: Date | null = null;

  @observable
  accessor selectedShoppingMall: string | null = null;

  @observable
  accessor selectedInquiryStatus: RadioInquiryStatus = 'UNANSWERED';

  @observable
  accessor selectedInquiryUniqueCode: string | null = null;

  @observable
  accessor search: string = '';

  @computed
  get shoppingMallFilteredInquiries() {
    const allInquiries = this.oms.inquiry.inquiries;

    return this.selectedShoppingMall == null
      ? allInquiries
      : allInquiries.filter(
          ({ shoppingMall }) => shoppingMall === this.selectedShoppingMall
        );
  }

  @computed
  get unansweredInquiries() {
    return this.shoppingMallFilteredInquiries.filter(
      ({ inquiryStatus }) => inquiryStatus === 'UNANSWERED'
    );
  }

  @computed
  get filteredInquiries() {
    const shoppingMallFilteredInquiries = this.shoppingMallFilteredInquiries;

    const statusFilteredInquiries =
      this.selectedInquiryStatus === 'ALL'
        ? shoppingMallFilteredInquiries
        : shoppingMallFilteredInquiries.filter(
            ({ inquiryStatus }) => inquiryStatus === this.selectedInquiryStatus
          );

    const searchFilteredInquiries = statusFilteredInquiries.filter(
      (inquiry) => {
        const formattedInquiry = formatInquiry(inquiry);
        return [
          inquiry.shoppingMall,
          formattedInquiry.date,
          formattedInquiry.categoryType,
          formattedInquiry.productName,
          formattedInquiry.title,
          formattedInquiry.buyerName,
          formattedInquiry.orderNumber,
        ].some((value) => value?.includes(this.search) ?? false);
      }
    );

    return searchFilteredInquiries;
  }

  @computed
  get selectedInquiry() {
    return this.oms.inquiry.inquiries.find(
      (inquiry) => inquiry.inquiryUniqueCode === this.selectedInquiryUniqueCode
    );
  }

  constructor(private oms: OMSStore) {}

  @action
  updateLastUpdatedAt() {
    this.lastUpdatedAt = new Date();
  }

  @action
  setSelectedShoppingMall = (shoppingMall: string | null) => {
    this.selectedShoppingMall = shoppingMall;
    this.selectedInquiryUniqueCode = null;
  };

  @action
  setSelectedInquiryStatus = (status: RadioInquiryStatus) => {
    this.selectedInquiryStatus = status;
    this.selectedInquiryUniqueCode = null;
  };

  @action
  setSelectedInquiryUniqueCode = (inquiryUniqueCode: string | null) => {
    this.selectedInquiryUniqueCode = inquiryUniqueCode;
  };

  @action
  setSearch = (search: string) => {
    this.search = search;
  };

  @action.bound
  fetchInquiries = async () => {
    await this.oms.loading.batch(() => this._fetchInquiries());
    runInAction(() => {
      this.selectedInquiryUniqueCode = null;
    });
  };

  private async _fetchInquiries(shoppingMallNames?: string[]) {
    const allShoppingMallNames = this.oms.shoppingMall.inquiryShoppingMallNames;
    const requestIds = await requestShoppingMallsInquiry(
      shoppingMallNames ?? allShoppingMallNames
    );
    const inquiriesList = await Promise.all(requestIds.map(getInquiries));

    const inquiries = inquiriesList.flatMap((inquiries) => inquiries ?? []);

    this.oms.inquiry.setInquiries(inquiries);
    this.updateLastUpdatedAt();

    return inquiries;
  }

  async reply(inquiryUniqueCode: string, reply?: string) {
    const { success, errorMessage } = await this.oms.inquiry.reply(
      inquiryUniqueCode,
      reply
    );

    if (!success) {
      toast.error(errorMessage ?? '답변 등록에 실패했습니다.');
      return;
    }

    toast.success('답변이 등록되었습니다.');
    runInAction(() => {
      this.selectedInquiryUniqueCode = null;
    });
  }
}
