import { Worksheet } from 'exceljs';
import { CheckedAddressResult } from 'src/network/fastapi';
import { LEGENDS } from '../constants';

export function updateCheckedAddressResultToSheet(
  worksheet: Worksheet,
  checkedAddressResults: CheckedAddressResult[],
  addressIndex: number
) {
  checkedAddressResults.forEach((result, rowIndex) => {
    const row = worksheet.getRow(rowIndex + 2);
    const addressExcelIndex = addressIndex + 1;

    const address = row.getCell(addressExcelIndex).value?.toString();
    const appliedResult = applyAliExpressResult(result, address);
    const isAddressValid = appliedResult.isAddressValid;

    if (isAddressValid === 'okay') {
      return;
    }

    const color = LEGENDS[isAddressValid][0];
    const cell = row.getCell(addressExcelIndex);
    cell.value = appliedResult.address;
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };
    if (isAddressValid !== 'fixed') {
      cell.font = {
        bold: true,
      };
    }

    if (appliedResult.memo != null) {
      row.getCell(addressExcelIndex).note = {
        texts: appliedResult.memo,
      };
    }
  });
}

// NOTE(@이지원): 알리에서 엑셀파일 다운로드를 받은 경우, "、" 이런 문자가 들어감.
// 이 경우 상태가 okay로는 나오는데 수정을 해줘야 함.
function applyAliExpressResult(
  result: CheckedAddressResult,
  prevAddress: string | undefined
): CheckedAddressResult {
  const isAliExpressAddress = prevAddress != null && prevAddress.includes('、');
  if (!isAliExpressAddress) {
    return result;
  }

  if (result.isAddressValid === 'okay') {
    return {
      ...result,
      isAddressValid: 'fixed',
      memo: [
        ...(result.memo ?? []),
        {
          text: '기존주소:' + prevAddress,
        },
      ],
    };
  }

  return result;
}
