import { IconControlDownload } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { useMount } from 'src/hooks/useMount';
import { CheckedAddressResult } from 'src/network/fastapi';
import { amplitude } from 'src/third-parties/amplitude';
import { openSuggestionDialog } from '../SuggestionDialog';
import CompleteResultSection from './CompleteResultSection';
import { CompleteTitleSection } from './CompleteTitleSection';

interface CompleteSectionProps {
  addresses: string[];
  checkedAddressResults: CheckedAddressResult[];
  onDownload: () => void;
}

function CompleteSection({
  addresses,
  checkedAddressResults,
  onDownload,
}: CompleteSectionProps) {
  useMount(() => amplitude.track('View download-address'));

  const handleDownload = () => {
    amplitude.track('Click download-address');
    onDownload();
    openSuggestionDialog();
  };

  return (
    <div className="gap-32px flex-center flex h-full flex-col">
      <CompleteTitleSection />
      <CompleteResultSection
        addresses={addresses}
        checkedAddressResults={checkedAddressResults}
      />
      <Button
        rightAccessory={<IconControlDownload />}
        size="large"
        className="w-240px self-end"
        onClick={handleDownload}
      >
        변환 파일 다운로드
      </Button>
    </div>
  );
}

export default CompleteSection;
