import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import LoadingIndicator from '../../../components/LoadingIndicator';
import OMSContainer from '../../../components/OMSContainer';
import DailySettlementSummaryTable from '../../../components/Tables/DailySettlementSummaryTable';
import DownloadArrow from '../../../icons/DownloadArrow';
import LoadingStore from '../../../stores/LoadingStore';
import { createDailySettlementSummary } from '../../../utils/createDailySettlementSummary';
import { isValid } from '../../../utils/utils';
import useSettlement from '../hooks/useSettlement';

const DailySettlementManagementScreen = observer(() => {
  const oms = useOMSStore();
  const [settlementData, setSettlementData] = useState<any[]>([]);

  const { downloadDailySettlementFile } = useSettlement();

  const summarizeDailySettlementData = () => {
    const data = createDailySettlementSummary(oms.order.normalizedOrders, {
      userType:
        oms.user.setting?.settlementType || oms.user.companyType || 'retailer',
    });

    setSettlementData(data);
  };

  const fetchDailySettlementData = async () => {
    if (!isValid(oms.order.normalizedOrders)) {
      return;
    }

    LoadingStore?.setIsLoading(true);

    summarizeDailySettlementData();

    LoadingStore?.setIsLoading(false);
  };

  useEffect(() => {
    fetchDailySettlementData();
  }, [oms.order.normalizedOrders]);

  const getPartnerName = (partnerId: string) => {
    const partnerInfo = oms.partner.partners.find((p) => p._id === partnerId);
    return partnerInfo ? partnerInfo.name : '기타';
  };

  const getSupplierName = (supplierId: string) => {
    const supplierInfo = oms.supplier.suppliers.find(
      (s) => s._id === supplierId
    );
    return supplierInfo ? supplierInfo.name : '기타';
  };

  const hasPartnerData = settlementData.some((item) => item.partnerId);
  const hasSupplierData = settlementData.some((item) => item.supplierId);
  const settings = {
    userType: oms.user.companyType,
    hasPartnerData,
    hasSupplierData,
    priceList: [],
  };

  const handleDownloadFile = async (data: any) => {
    if (!isValid(data)) {
      return;
    }

    // 파트너사 이름 추가
    const enhancedData = data.map((item: any) => ({
      ...item,
      partnerName: getPartnerName(item.partnerId),
      supplierName: getSupplierName(item.supplierId),
    }));

    await downloadDailySettlementFile(enhancedData, settings);
  };

  return (
    <OMSContainer className="bg-[#F2F6FA]">
      <div className="flex items-center justify-between">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          발주 수량 확인
        </h1>

        <div className="flex items-center justify-end">
          <div className="flex h-[42px] cursor-pointer flex-row rounded-md bg-[#1991FF]">
            <>
              <div
                role="button"
                onClick={() => handleDownloadFile(settlementData)}
                className="flex items-center justify-center pl-5 pr-4"
              >
                <span className="font-pretendard text-[16px] font-bold leading-[19px] text-white">
                  정산 파일
                </span>
                <div>
                  <DownloadArrow />
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="my-[40px] rounded-[8px] bg-white p-[20px]">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-[10px] overflow-x-hidden whitespace-nowrap"></div>
        </div>
        <div className="flex max-h-[calc(100vh-200px)] max-w-full overflow-x-auto break-all">
          <DailySettlementSummaryTable
            settlementData={settlementData}
            setSettlementData={setSettlementData}
          />
        </div>
      </div>

      {LoadingStore?.isLoading && <LoadingIndicator />}
    </OMSContainer>
  );
});

export default DailySettlementManagementScreen;
