import { uniq } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  IconCheckMedium,
  IconControlDownload,
  IconEmpty,
  IconFile400,
  IconFileGray,
} from '@sweep/asset/icons';
import { Button, Modal } from '@sweep/sds';
import { isNotNil } from '@sweep/utils';
import { If, SwitchCase } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { amplitude } from 'src/third-parties/amplitude';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import { toast } from 'src/third-parties/toast';
import { updateLastActiveMatchingIdFromOrders } from '../../services/updateLastActiveMatchingIdFromOrders';

enum VisibleSection {
  ALL_EMPTY = 'ALL_EMPTY',
  SHOW_ONLY_NEW_FILE = 'SHOW_ONLY_NEW_FILE',
  // SHOW_ONLY_CONFIRMED_FILE = 'SHOW_ONLY_CONFIRMED_FILE',
  SHOW_ALL = 'SHOW_ALL',
}

function selectVisibleSection({
  confirmedFileCount,
  newReceivedFileCount,
}: {
  confirmedFileCount: number;
  newReceivedFileCount: number;
}): VisibleSection {
  if (confirmedFileCount === 0 && newReceivedFileCount === 0) {
    return VisibleSection.ALL_EMPTY;
  }

  if (confirmedFileCount === 0) {
    return VisibleSection.SHOW_ONLY_NEW_FILE;
  }

  return VisibleSection.SHOW_ALL;
}

function FileItem({
  partnerName,
  filename,
  isChecked,
  onCheckedChange,
  onDownload,
}: {
  partnerName: string;
  filename: string;
  isChecked: boolean;
  onCheckedChange: (checked: boolean) => void;
  onDownload: (event: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return isChecked ? (
    <div
      className="px-20px py-12px rounded-8px flex cursor-pointer items-center bg-gray-100 hover:bg-gray-100"
      onClick={() => onCheckedChange(!isChecked)}
    >
      <div className="flex flex-1 items-center">
        <div className="gap-12px flex items-center">
          <div className="rounded-8px flex items-center justify-center bg-gray-100">
            <IconCheckMedium className="text-blue-500" />
          </div>
          <div className="rounded-8px flex items-center justify-center bg-gray-100">
            <IconFile400 />
          </div>

          <div className="gap-3px flex flex-col">
            <p className="text-bold-m text-gray-700">{partnerName}</p>
            <p className="text-medium-s text-gray-500">{filename}</p>
          </div>
        </div>
      </div>
      <div
        className="p-12px rounded-8px ml-auto bg-gray-400"
        onClick={onDownload}
      >
        <IconControlDownload className="text-white" />
      </div>
    </div>
  ) : (
    <div
      className="gap-12px px-20px py-12px rounded-8px flex cursor-pointer items-center bg-white hover:bg-gray-100"
      onClick={() => onCheckedChange(!isChecked)}
    >
      <div className="flex flex-1 items-center">
        <div className="gap-12px flex items-center">
          <div className="rounded-8px flex items-center justify-center">
            <IconCheckMedium className="text-gray-300" />
          </div>
          <div className="rounded-8px flex items-center justify-center">
            <IconFile400 />
          </div>
          <div className="gap-3px flex flex-col">
            <p className="text-bold-m text-gray-700">{partnerName}</p>
            <p className="text-medium-s text-gray-500">{filename}</p>
          </div>
        </div>
      </div>
      <div
        className="p-12px rounded-8px ml-auto bg-gray-400"
        onClick={onDownload}
      >
        <IconControlDownload className="text-white" />
      </div>
    </div>
  );
}

function FileItemTypeConfirmed({
  partnerName,
  filename,
  isChecked,
  onCheckedChange,
  onDownload,
}: {
  partnerName: string;
  filename: string;
  isChecked: boolean;
  onCheckedChange: (checked: boolean) => void;
  onDownload: (event: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return isChecked ? (
    <div
      className="px-20px py-12px rounded-8px flex cursor-pointer items-center bg-gray-100 hover:bg-gray-100"
      onClick={() => onCheckedChange(!isChecked)}
    >
      <div className="flex flex-1 items-center">
        <div className="gap-12px flex items-center">
          <div className="rounded-8px flex items-center justify-center bg-gray-100">
            <IconCheckMedium className="text-blue-500" />
          </div>
          <div className="rounded-8px flex items-center justify-center bg-gray-100">
            <IconFileGray />
          </div>

          <div className="gap-3px flex flex-col">
            <p className="text-bold-m text-gray-700">{partnerName}</p>
            <p className="text-medium-s text-gray-500">{filename}</p>
          </div>
        </div>
      </div>
      <div
        className="p-12px rounded-8px ml-auto bg-gray-400"
        onClick={onDownload}
      >
        <IconControlDownload className="text-white" />
      </div>
    </div>
  ) : (
    <div
      className="gap-12px px-20px py-12px rounded-8px flex cursor-pointer items-center bg-white hover:bg-gray-100"
      onClick={() => onCheckedChange(!isChecked)}
    >
      <div className="flex flex-1 items-center">
        <div className="gap-12px flex items-center">
          <div className="rounded-8px flex items-center justify-center">
            <IconCheckMedium className="text-gray-300" />
          </div>
          <div className="rounded-8px flex items-center justify-center">
            <IconFileGray />
          </div>
          <div className="gap-3px flex flex-col">
            <p className="text-bold-m text-gray-700">{partnerName}</p>
            <p className="text-medium-s text-gray-500">{filename}</p>
          </div>
        </div>
      </div>
      <div
        className="p-12px rounded-8px ml-auto bg-gray-400"
        onClick={onDownload}
      >
        <IconControlDownload className="text-white" />
      </div>
    </div>
  );
}

function EmptySection() {
  return (
    <div className="gap-12px py-40px flex h-[300px] flex-col items-center justify-center">
      <div className="gap-16px flex flex-col items-center">
        <IconEmpty />
        <p className="text-medium-s text-gray-500">파일이 없습니다.</p>
      </div>
    </div>
  );
}
type PartnershipSupplierInboundFileTransactionModalProps = {
  open: boolean;
  onClose: () => void;
};

export function PartnershipSupplierInboundFileTransactionModal({
  open,
  onClose,
}: PartnershipSupplierInboundFileTransactionModalProps) {
  const oms = useOMSStore();
  const [selectedTransactions, setTransactions] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const confirmedFiles = oms.supplierOrderTransaction.confirmedFiles;
  const newReceivedFiles = oms.supplierOrderTransaction.newReceivedFiles;

  const toggleTransactionsCheckChange = (transactionId: string) => {
    const isChecked = selectedTransactions.includes(transactionId);

    const updatedSelections = isChecked
      ? selectedTransactions.filter((t) => t !== transactionId)
      : [...selectedTransactions, transactionId];

    setTransactions(updatedSelections);
  };

  useEffect(() => {
    if (open) {
      setTransactions([...newReceivedFiles.map((file) => file._id)]);
    }
  }, [open]);

  const onConfirm = async () => {
    if (isProcessing) {
      return;
    }

    if (selectedTransactions.length === 0) {
      toast.error('파일을 선택해주세요.');
      return;
    }

    if (selectedTransactions.length > 0) {
      amplitude.track('Upload Order', {
        Section: 'partnership-purchase-order',
      });
    }

    try {
      setIsProcessing(true);

      const spreadsheets = await Promise.all(
        selectedTransactions.map((transactionId) =>
          oms.supplierOrderTransaction.downloadPreprocessedContent(
            transactionId
          )
        )
      );

      const parsedSpreadsheets = spreadsheets.filter(isNotNil);

      if (parsedSpreadsheets.length === 0) {
        setIsProcessing(false);
        return false;
      }

      const orders = await oms.order.mergeSpreadsheet(parsedSpreadsheets);

      const isSuccess = await oms.loading.batch(async () => {
        const filenames = uniq(
          orders?.map((order) => order.originFile) ?? []
        ).filter(isNotNil);

        if (filenames.length > 0) {
          await oms.order.draft.register(filenames);
        }

        const matchedFileUUIDs = filenames
          .map((filename) =>
            oms.supplierOrderTransaction.incomingTransactions.find(
              (transaction) => transaction.files.original.filename === filename
            )
          )
          .filter(isNotNil);

        await Promise.all(
          matchedFileUUIDs.map((matchedFileUUID) =>
            oms.supplierOrderTransaction.confirm(matchedFileUUID._id)
          )
        );

        if (orders != null) {
          await updateLastActiveMatchingIdFromOrders({
            orders,
            updateHandler: oms.partner.update,
            errorHandler: ErrorReporter.captureError,
          });
        }

        return true;
      });

      setIsProcessing(false);

      if (isSuccess) {
        onClose();
      }
    } catch (error) {
      ErrorReporter.captureError(error);
      toast.error('파일 처리 중 오류가 발생했습니다.');
    } finally {
      setIsProcessing(false);
    }
  };

  const newReceivedFileCount = newReceivedFiles.length;
  const showNewFileEmptySection = newReceivedFileCount === 0;

  const confirmedFileCount = confirmedFiles.length;
  const showConfirmedFileEmptySection = confirmedFileCount === 0;

  const selectedFileCount = selectedTransactions.length;
  const isConfirmButtonDisabled = selectedFileCount === 0;

  return (
    <Modal open={open} onClose={onClose} className="max-h-[800px] w-[684px]">
      <Modal.Title>판매처 발주 파일</Modal.Title>
      <Modal.Description>
        판매처가 업로드한 발주 파일이 나타납니다.
      </Modal.Description>

      <div className="gap-20px pt-24px flex flex-col overflow-scroll">
        <SwitchCase
          value={selectVisibleSection({
            confirmedFileCount,
            newReceivedFileCount,
          })}
          caseBy={{
            [VisibleSection.ALL_EMPTY]: <EmptySection />,
            [VisibleSection.SHOW_ONLY_NEW_FILE]: (
              <>
                <If is={showNewFileEmptySection}>
                  <EmptySection />
                </If>
                <If is={!showNewFileEmptySection}>
                  <div className="gap-12px flex flex-col">
                    <p className="text-medium-s text-gray-500">
                      새로운 파일(
                      <span className="text-blue-500">
                        {newReceivedFiles.length}개
                      </span>
                      )을 선택하고 추가해주세요.
                    </p>
                    {newReceivedFiles.map((file) => (
                      <FileItem
                        key={file._id}
                        partnerName={file.retailer.name}
                        filename={file.files.original.filename}
                        isChecked={selectedTransactions.includes(file._id)}
                        onCheckedChange={() =>
                          toggleTransactionsCheckChange(file._id)
                        }
                        onDownload={(
                          event: React.MouseEvent<HTMLDivElement>
                        ) => {
                          event.stopPropagation();
                          amplitude.track(
                            'Click download-partnership-purchase-order',
                            {
                              'File name': file.files.original.filename,
                            }
                          );

                          oms.supplierOrderTransaction.downloadPurchaseOrderFile(
                            file._id
                          );
                        }}
                      />
                    ))}
                  </div>
                </If>
              </>
            ),
            [VisibleSection.SHOW_ALL]: (
              <>
                <If is={showNewFileEmptySection}>
                  <EmptySection />
                </If>
                <If is={!showNewFileEmptySection}>
                  <div className="gap-12px flex flex-col">
                    <p className="text-medium-s text-gray-500">
                      새로운 파일(
                      <span className="text-blue-500">
                        {newReceivedFiles.length}개
                      </span>
                      )을 선택하고 추가해주세요.
                    </p>
                    {newReceivedFiles.map((file) => (
                      <FileItem
                        key={file._id}
                        partnerName={file.retailer.name}
                        filename={file.files.original.filename}
                        isChecked={selectedTransactions.includes(file._id)}
                        onCheckedChange={() =>
                          toggleTransactionsCheckChange(file._id)
                        }
                        onDownload={(
                          event: React.MouseEvent<HTMLDivElement>
                        ) => {
                          event.stopPropagation();
                          amplitude.track(
                            'Click download-partnership-purchase-order',
                            {
                              'File name': file.files.original.filename,
                            }
                          );
                          oms.supplierOrderTransaction.downloadPurchaseOrderFile(
                            file._id
                          );
                        }}
                      />
                    ))}
                  </div>
                </If>
                <hr className="w-full" />
                <If is={showConfirmedFileEmptySection}>
                  <EmptySection />
                </If>
                <If is={!showConfirmedFileEmptySection}>
                  <div className="gap-12px flex flex-col">
                    <p className="text-medium-s text-gray-500">
                      이미 추가한 파일을 확인할 수 있어요.
                    </p>
                    {confirmedFiles.map((file) => (
                      <FileItemTypeConfirmed
                        key={file._id}
                        partnerName={file.retailer.name}
                        filename={file.files.original.filename}
                        isChecked={selectedTransactions.includes(file._id)}
                        onCheckedChange={() =>
                          toggleTransactionsCheckChange(file._id)
                        }
                        onDownload={(
                          event: React.MouseEvent<HTMLDivElement>
                        ) => {
                          event.stopPropagation();
                          amplitude.track(
                            'Click download-partnership-purchase-order',
                            {
                              'File name': file.files.original.filename,
                            }
                          );
                          oms.supplierOrderTransaction.downloadPurchaseOrderFile(
                            file._id
                          );
                        }}
                      />
                    ))}
                  </div>
                </If>
              </>
            ),
          }}
        />
      </div>

      <div className="gap-12px pt-20px px-20px flex items-center justify-center bg-white">
        <Button
          className="w-200px"
          color="lightGray"
          onClick={onClose}
          disabled={isProcessing}
        >
          닫기
        </Button>
        <Button
          className="w-200px"
          onClick={onConfirm}
          disabled={isConfirmButtonDisabled || isProcessing}
        >
          {isProcessing ? '처리 중...' : '파일 추가하기'}
        </Button>
      </div>
    </Modal>
  );
}

export default observer(PartnershipSupplierInboundFileTransactionModal);
