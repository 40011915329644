import { Supplier } from '@sweep/contract';
import { isNotNil, NULL_STRING, sortBy, sum } from '@sweep/utils';
import { SettlementOrder, SupplierSettlement } from '../interface';

export function getSupplierSettlements(
  suppliers: Supplier[],
  ordersBySupplier: Record<string, SettlementOrder[]>
) {
  const supplierById: Record<string, Supplier | undefined> = Object.fromEntries(
    suppliers.map((supplier) => [supplier._id, supplier])
  );

  const ordersByAllSupplier: Record<string, SettlementOrder[]> =
    Object.fromEntries([
      ...suppliers.map((supplier) => [
        supplier._id,
        ordersBySupplier[supplier._id] ?? [],
      ]),
      [NULL_STRING, ordersBySupplier[NULL_STRING] ?? []],
    ]);

  const supplierSettlements = Object.entries(ordersByAllSupplier)
    .map(([supplierId, orders]): SupplierSettlement | null => {
      const totalPrice = sum(orders.map((order) => order.totalSupplierPrice));
      if (supplierId === NULL_STRING) {
        return {
          supplier: null,
          orders,
          totalPrice,
        };
      }
      const supplier = supplierById[supplierId];
      return supplier != null
        ? {
            supplier,
            orders,
            totalPrice,
          }
        : null;
    })
    .filter(isNotNil);

  const sortedSupplierSettlements = sortBy(supplierSettlements, [
    ({ totalPrice }) => (totalPrice > 0 ? -1 : 0),
    ({ supplier }) => (supplier == null ? 1 : 0),
    ({ supplier }) => supplier?.name ?? '',
  ]);

  return sortedSupplierSettlements;
}
