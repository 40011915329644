import { useMount } from 'src/hooks/useMount';
import { amplitude } from 'src/third-parties/amplitude';
import { MatchingSelectSection } from './MatchingSelectSection';
import { MatchingTitleSection } from './MatchingTitleSection';

interface MatchingSectionProps {
  rows: string[][];
  onSubmit: (addressColumn: number) => Promise<void>;
}

function MatchingSection({ rows, onSubmit }: MatchingSectionProps) {
  useMount(() => amplitude.track('View address-matching'));
  return (
    <div className="gap-60px flex-center flex flex-1 flex-col">
      <MatchingTitleSection />
      <MatchingSelectSection rows={rows} onSubmit={onSubmit} />
    </div>
  );
}

export default MatchingSection;
