import { action, computed, observable, runInAction } from 'mobx';
import {
  CompositionMatching,
  CreateCompositionMatchingDTO,
  CreateOptionCodeMatchingDTO,
  OptionCodeMatching,
  UpdateCompositionMatchingDTO,
  UpdateOptionCodeMatchingDTO,
} from '@sweep/contract';
import {
  createCompositionMatching,
  createCompositionMatchings,
  getCompositionMatchings,
  incrementCompositionMatchingCounts,
  updateCompositionMatching,
} from 'src/network/compositionMatching';
import {
  createOptionCodeMatching,
  createOptionCodeMatchings,
  getOptionCodeMatchings,
  incrementOptionCodeMatchingCounts,
  updateOptionCodeMatching,
} from 'src/network/optionCodeMatching';
import { OMSStore } from './OMSStore';

export class CMStore {
  @observable
  accessor compositionMatchings: CompositionMatching[] = [];

  @observable
  accessor optionCodeMatchings: OptionCodeMatching[] = [];

  @computed
  get compositionMatchingsById() {
    return this.compositionMatchings.reduce<
      Record<string, CompositionMatching>
    >((acc, cm) => {
      acc[cm._id] = cm;
      return acc;
    }, {});
  }

  @computed
  get optionCodeMatchingsById() {
    return this.optionCodeMatchings.reduce<Record<string, OptionCodeMatching>>(
      (acc, ocm) => {
        acc[ocm._id] = ocm;
        return acc;
      },
      {}
    );
  }

  getCompositionMatchingById(id: string) {
    return this.compositionMatchingsById[id];
  }

  getOptionCodeMatchingById(id: string) {
    return this.optionCodeMatchingsById[id];
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await Promise.all([
      this.loadCompositionMatchings(),
      this.loadOptionCodeMatchings(),
    ]);
  }

  @action.bound
  async loadCompositionMatchings() {
    const response = await getCompositionMatchings();
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = response.data;
    });
  }

  @action.bound
  async loadOptionCodeMatchings() {
    const response = await getOptionCodeMatchings();
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = response.data;
    });
  }

  @action.bound
  async createCompositionMatching(
    compositionMatching: CreateCompositionMatchingDTO
  ) {
    const response = await createCompositionMatching(compositionMatching);

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = [...this.compositionMatchings, response.data];
    });

    return response.data;
  }

  @action.bound
  async createCompositionMatchings(
    compositionMatchings: CreateCompositionMatchingDTO[]
  ) {
    const response = await createCompositionMatchings(compositionMatchings);

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = [
        ...this.compositionMatchings,
        ...response.data,
      ];
    });

    return response.data;
  }

  @action.bound
  async updateCompositionMatching(
    compositionMatching: UpdateCompositionMatchingDTO
  ) {
    const response = await updateCompositionMatching(compositionMatching);

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = this.compositionMatchings.map((cm) =>
        cm._id === compositionMatching._id
          ? {
              ...cm,
              ...compositionMatching,
            }
          : cm
      );
    });

    return response.data;
  }

  @action.bound
  async createOptionCodeMatching(
    optionCodeMatching: CreateOptionCodeMatchingDTO
  ) {
    const response = await createOptionCodeMatching(optionCodeMatching);

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = [...this.optionCodeMatchings, response.data];
    });

    return response.data;
  }

  @action.bound
  async createOptionCodeMatchings(
    optionCodeMatchings: CreateOptionCodeMatchingDTO[]
  ) {
    const response = await createOptionCodeMatchings(optionCodeMatchings);

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = [
        ...this.optionCodeMatchings,
        ...response.data,
      ];
    });

    return response.data;
  }

  @action.bound
  async updateOptionCodeMatching(
    optionCodeMatching: UpdateOptionCodeMatchingDTO
  ) {
    const response = await updateOptionCodeMatching(optionCodeMatching);

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = this.optionCodeMatchings.map((ocm) =>
        ocm._id === optionCodeMatching._id
          ? {
              ...ocm,
              ...optionCodeMatching,
            }
          : ocm
      );
    });

    return response.data;
  }

  /**
   * @deprecated
   */
  @action.bound
  setCompositionMatchings(compositionMatchings: CompositionMatching[]) {
    this.compositionMatchings = compositionMatchings;
  }

  @action.bound
  _deleteCompositionMatchings(ids: string[]) {
    this.compositionMatchings = this.compositionMatchings.filter(
      (cm) => !ids.includes(cm._id)
    );
  }

  @action.bound
  _deleteOptionCodeMatchings(ids: string[]) {
    this.optionCodeMatchings = this.optionCodeMatchings.filter(
      (ocm) => !ids.includes(ocm._id)
    );
  }

  @action.bound
  async incrementCompositionMatchingCounts(ids: string[]) {
    this.compositionMatchings = this.compositionMatchings.map((cm) =>
      ids.includes(cm._id) ? { ...cm, count: cm.count + 1 } : cm
    );
    await incrementCompositionMatchingCounts(ids);
  }

  @action.bound
  async incrementOptionCodeMatchingCounts(ids: string[]) {
    this.optionCodeMatchings = this.optionCodeMatchings.map((ocm) =>
      ids.includes(ocm._id) ? { ...ocm, count: ocm.count + 1 } : ocm
    );
    await incrementOptionCodeMatchingCounts(ids);
  }
}
