import React, { forwardRef } from 'react';
import { cva } from '@sweep/tailwind';
import { TableHeaderCellProps } from '../interface';

function DefaultTableFooterCell(
  { context, className, children, ...rest }: TableHeaderCellProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { lastColumn = false } = context;

  return (
    <div ref={ref} className={footerCell({ lastColumn, className })} {...rest}>
      {children}
    </div>
  );
}

const footerCell = cva(
  [
    'flex items-center',
    'text-medium-s whitespace-nowrap text-left text-gray-700',
    'px-16px border-y border-gray-200 bg-gray-50 py-0',
  ],
  {
    variants: {
      lastColumn: {
        true: '',
        false: 'border-r',
      },
    },
  }
);

export default forwardRef(DefaultTableFooterCell);
