import { IconEmpty } from '@sweep/asset/icons';

export default function FileSectionEmpty() {
  return (
    <div className="gap-16px py-40px border-1px rounded-16px flex flex-col items-center justify-center border-gray-200 bg-gray-200">
      <IconEmpty />
      <p className="text-medium-m text-gray-500">파일이 없습니다.</p>
    </div>
  );
}
