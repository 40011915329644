import { z } from 'zod';
import { c } from '../../internal/contract';
import {
  REMOTE_STORAGE_FILE_TYPE,
  RemoteStorageFileSchema,
  RemoteStorageFileUploadSchema,
} from '../../type/remote-storage-file/RemoteStorageFile';

export const RemoteStorageFileRouterContract = c.router({
  upload: {
    method: 'POST',
    path: '/remote-storage-file/:fileType',
    contentType: 'multipart/form-data',
    pathParams: z.object({
      fileType: z.enum([
        REMOTE_STORAGE_FILE_TYPE.SPREADSHEET,
        REMOTE_STORAGE_FILE_TYPE.SPREADSHEET_PARSED,
      ]),
    }),
    body: RemoteStorageFileUploadSchema,
    responses: {
      200: z.object({
        success: z.boolean(),
        data: RemoteStorageFileSchema,
      }),
    },
  },
});
