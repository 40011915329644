import { useRef, useState } from 'react';
import { IconArrowRightXX } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { If, SwitchCase } from '@sweep/utils/react';
import { Device, useDevice } from 'src/hooks/useDevice';
import { useMount } from 'src/hooks/useMount';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { normalizeAddresses } from 'src/network/address';
import {
  CheckedAddressResult,
  toCheckedAddressResult,
} from 'src/network/fastapi';
import { readExcelGuest } from 'src/services/file/excel/readExcelGuest';
import { ExcelFile } from 'src/services/file/interface';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import Footer from '../../components/Footer';
import PreRegistrationForm from '../ali-express/PCcomponents/PreRegistrationForm';
import Header from '../landing-page/components/Header';
import { AddressValidMobileScreen } from './AddressValidMobileScreen';
import CompleteSection from './components/sections/CompleteSection';
import InitialSection from './components/sections/InitialSection';
import MatchingSection from './components/sections/MatchingSection';
import { openUserOpinionModal } from './components/UserOpinionModal';
import { saveCheckedAddressExcel } from './services/excel/saveCheckedAddressExcel';
import { getAddressIndex } from './services/getAddressIndex';
import { getUsedDateLength } from './services/getUsedDateLength';
import { saveUsageDate } from './services/saveUsageDate';

type Phase = 'initial' | 'matching' | 'complete';

const AddressValidScreen = () => {
  const oms = useOMSStore();
  const device = useDevice();

  const registrationFormRef = useRef<HTMLDivElement>(null);
  const scrollToRegistrationForm = () => {
    registrationFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useMount(() => {
    amplitude.track('Pageview address');
  });

  const [phase, setPhase] = useState<Phase>('initial');

  const checkedAddressResultRef = useRef<CheckedAddressResult[]>([]);
  const rawAddressesRef = useRef<string[]>([]);
  const addressIndexRef = useRef<number | null>(null);

  const fileRef = useRef<File | null>(null);
  const excelFileRef = useRef<ExcelFile | null>(null);

  const handleFile = async (file: File) => {
    amplitude.track('Upload address (Server)', {
      Day: getUsedDateLength(),
    });
    fileRef.current = file;

    const excelFile = await oms.loading.batch(() => readExcelGuest(file));
    if (excelFile == null || excelFile.data.length === 0) {
      toast.error('엑셀 파일 읽기에 실패하였습니다. 파일을 확인해주세요.');
      return;
    }
    excelFileRef.current = excelFile;

    const rows = excelFile.data;
    const headers = rows[0];
    const { addressIndex, postCodeIndex } = getAddressIndex(headers);
    if (addressIndex == null) {
      setPhase('matching');
      return;
    }

    await handleSubmit(addressIndex, postCodeIndex);
  };

  const handleSubmit = async (addressIndex: number, postCodeIndex?: number) => {
    addressIndexRef.current = addressIndex;
    const excelFile = excelFileRef.current;
    if (excelFile == null) {
      toast.error('엑셀 파일을 찾을 수 없습니다.');
      return;
    }

    const rows = excelFile.data;
    const rowsWithoutHeader = rows.slice(1);
    const addresses = rowsWithoutHeader.map((row) => ({
      address: row[addressIndex],
      postCode: postCodeIndex != null ? row.at(postCodeIndex) : null,
    }));
    const newRawAddresses = addresses.map((address) => address.address);
    rawAddressesRef.current = newRawAddresses;

    const response = await oms.loading.batch(() =>
      normalizeAddresses(addresses)
    );
    if (response.status !== 200) {
      toast.error('주소 검증에 실패했습니다. 다시 시도해주세요.');
      return;
    }

    const checkedAddresses = response.body;
    const checkedAddressResults = checkedAddresses.map((checkedAddress) =>
      toCheckedAddressResult(checkedAddress, {
        fixInvalidAddress: true,
      })
    );
    checkedAddressResultRef.current = checkedAddressResults;

    setPhase('complete');
    saveUsageDate();
  };

  const download = async () => {
    amplitude.track('Click download-address');
    if (addressIndexRef.current == null) {
      toast.error('주소 열을 찾을 수 없습니다.');
      return;
    }

    const excelFile = excelFileRef.current;
    const file = fileRef.current;
    if (excelFile == null || file == null) {
      toast.error('파일을 찾을 수 없습니다.');
      return;
    }

    const result = await saveCheckedAddressExcel({
      checkedAddressResults: checkedAddressResultRef.current,
      excelFile,
      file,
      addressIndex: addressIndexRef.current,
    });

    if (result.success === false) {
      toast.error(result.error);
      return;
    }
  };

  if (device === Device.MOBILE) {
    return <AddressValidMobileScreen />;
  }

  return (
    <div className="w-full bg-gray-100">
      <Header onOpen={scrollToRegistrationForm} />
      <div className="flex h-screen flex-col items-center">
        <SwitchCase
          value={phase}
          caseBy={{
            initial: <InitialSection onFileChange={handleFile} />,
            matching: (
              <MatchingSection
                rows={excelFileRef.current?.data ?? []}
                onSubmit={handleSubmit}
              />
            ),
            complete: (
              <CompleteSection
                addresses={rawAddressesRef.current}
                checkedAddressResults={checkedAddressResultRef.current}
                onDownload={download}
              />
            ),
          }}
        />
      </div>
      <button className={floatingButton()} onClick={openUserOpinionModal}>
        변환에 문제가 있나요?
      </button>
      <PreRegistrationForm hasEnoughHeight ref={registrationFormRef} />
      <If is={phase !== 'initial'}>
        <div onClick={() => setPhase('initial')} className={prevButton()}>
          <IconArrowRightXX className="-scale-x-100 text-gray-500" />
        </div>
      </If>
      <Footer />
    </div>
  );
};

const floatingButton = cva([
  'px-20px py-9px flex-center w-190px flex',
  'fixed bottom-[5vh] left-[calc(50%-95px)] animate-bounce',
  'text-semibold-m text-gray-500',
  'shadow-box cursor-pointer rounded-full bg-white',
]);

const prevButton = cva([
  'fixed left-8 top-1/2',
  'flex rounded-full bg-white p-4',
  'cursor-pointer shadow-md transition-transform duration-300 hover:scale-125',
]);

export default AddressValidScreen;
