import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import {
  IconErrorLarge,
  IconFile400,
  IconSuccessLarge,
} from '@sweep/asset/icons';
import { Button, Modal } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { SwitchCase } from '@sweep/utils/react';
import { openConfirmDialog } from 'src/components/openConfirmDialog';
import { useOMSStore } from 'src/hooks/useOMSStore';
import SendResultTabButton from './SendResultTabButton';

function FailedItem({
  partnerName,
  filename,
}: {
  partnerName: string;
  filename: string;
}) {
  return (
    <div className="gap-12px px-20px py-12px rounded-8px flex flex-col justify-center bg-white">
      <div className="gap-12px flex items-center">
        <IconFile400 />
        <div className="rounded-8px flex items-center justify-center bg-gray-100"></div>
        <div className="gap-3px flex flex-col">
          <p className="text-bold-m text-gray-700">{partnerName}</p>
          <p className="text-medium-s text-gray-500">{filename}</p>
        </div>
        <IconErrorLarge />
      </div>
    </div>
  );
}

function SuccessItem({
  partnerName,
  filename,
}: {
  partnerName: string;
  filename: string;
}) {
  return (
    <div className="gap-12px px-20px py-12px rounded-8px flex flex-col justify-center bg-white">
      <div className="gap-12px flex items-center">
        <IconFile400 />
        <div className="rounded-8px flex items-center justify-center bg-gray-100"></div>
        <div className="gap-3px flex flex-col">
          <p className="text-bold-m text-gray-700">{partnerName}</p>
          <p className="text-medium-s text-gray-500">{filename}</p>
        </div>
        <IconSuccessLarge />
      </div>
    </div>
  );
}

function RetryFulfillPurchaseOrderFileModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const oms = useOMSStore();
  const [selectedTab, setSelectedTab] = useState<'fail' | 'success'>('fail');
  const successTransactionProgress =
    oms.supplierOrderTransaction.outgoingTransactionProgress.filter(
      (transaction) => transaction.isSuccess === true
    );
  const failedTransactionProgress =
    oms.supplierOrderTransaction.outgoingTransactionProgress.filter(
      (transaction) => transaction.isSuccess === false
    );

  const onRetry = async () => {
    const isAllSuccess =
      await oms.supplierOrderTransaction.retryFailedOutgoingTransactionProgress();

    if (isAllSuccess) {
      onClose();
    }
  };

  const handleCloseButtonClick = async () => {
    const remainingFilenames = failedTransactionProgress.map(
      (transactionProgress) => transactionProgress.payload.filename
    );
    const result = await openConfirmDialog(
      '아직 업로드가 되지 않은 파일이 있어요.',
      `이 화면을 나가면 업로드를 다시 시도할 수 없습니다. 판매처에 직접 주문서를 전송해주세요.\n- ${remainingFilenames.join(
        '\n- '
      )}`
    );

    if (result) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
    >
      <Modal.Title>판매처 주문서 업로드</Modal.Title>
      <Modal.Description>
        업로드에 실패한 파일이 있습니다. 다시 시도해주세요.
      </Modal.Description>
      <div className="h-32px" />
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <SendResultTabButton
            type="fail"
            isSelected={selectedTab === 'fail'}
            onClick={() => setSelectedTab('fail')}
            count={failedTransactionProgress.length}
          />
          <SendResultTabButton
            type="success"
            isSelected={selectedTab === 'success'}
            onClick={() => setSelectedTab('success')}
            count={successTransactionProgress.length}
          />
        </div>
      </div>
      <div className={container()}>
        <SwitchCase
          value={selectedTab}
          caseBy={{
            fail: failedTransactionProgress.map(
              (transactionProgress, index) => (
                <FailedItem
                  key={index}
                  partnerName={transactionProgress.extra.partnerName}
                  filename={transactionProgress.payload.filename}
                />
              )
            ),
            success: successTransactionProgress.map(
              (transactionProgress, index) => (
                <SuccessItem
                  key={index}
                  partnerName={transactionProgress.extra.partnerName}
                  filename={transactionProgress.payload.filename}
                />
              )
            ),
          }}
        />
      </div>
      <div className="gap-12px p-20px flex items-center justify-center bg-white">
        <Button
          className="w-200px"
          color="lightGray"
          onClick={handleCloseButtonClick}
        >
          닫기
        </Button>
        <Button className="w-200px" onClick={onRetry}>
          실패 건 다시 시도하기
        </Button>
      </div>
    </Modal>
  );
}

const container = cva([
  'flex flex-col gap-[8px]',
  'h-[360px] overflow-y-scroll px-[32px] py-[24px]',
  'rounded-r-[8px] border border-gray-300 bg-gray-100',
]);

export default observer(RetryFulfillPurchaseOrderFileModal);
