import { offset, useDismiss, useFloating } from '@floating-ui/react';
import { ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import { IconControlArrowDown } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { Separated } from '@sweep/utils/react';
import {
  MultiSelectCollection,
  MultiSelectContextProvider,
} from './MultiSelectContext';
import MultiSelectMultiOption from './MultiSelectMultiOption';
import MultiSelectOption from './MultiSelectOption';
import MultiSelectValue from './MultiSelectValue';

interface MultiSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  status?: 'default' | 'active';
  className?: string;
  children?: ReactNode;
}

export function MultiSelect({
  value,
  onChange,
  placeholder = '선택',
  status = 'default',
  className,
  children,
}: MultiSelectProps) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);
  const [fragment] = useState<DocumentFragment>(() => new DocumentFragment());

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange: setOpen,
    middleware: [offset(6)],
  });

  useDismiss(context, {
    outsidePress: true,
  });

  return (
    <MultiSelectContextProvider value={{ open, value, onChange }}>
      <MultiSelectCollection.Provider>
        <div
          ref={refs.setReference}
          className={container({ open, status, className })}
          onClick={toggle}
        >
          <MultiSelectValue value={value} placeholder={placeholder} />
          <IconControlArrowDown className="text-gray-700" />
          {open ? (
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className={optionContainer()}
            >
              <Separated with={<hr className={divider()} />}>
                {children}
              </Separated>
            </div>
          ) : (
            ReactDOM.createPortal(children, fragment)
          )}
        </div>
      </MultiSelectCollection.Provider>
    </MultiSelectContextProvider>
  );
}

const container = cva(
  [
    'relative cursor-pointer',
    'h-40px px-16px flex items-center',
    'rounded-8px border bg-white',
  ],
  {
    variants: {
      open: {
        true: 'border-blue-500',
        false: '',
      },
      status: {
        default: '',
        active: '',
      },
    },
    compoundVariants: [
      {
        open: false,
        status: 'default',
        className: 'border-gray-300',
      },
      {
        open: false,
        status: 'active',
        className: 'border-blue-500',
      },
    ],
  }
);

const optionContainer = cva([
  'max-h-250px flex w-full flex-col',
  'rounded-8px border border-gray-300 bg-white',
  'z-dropdown overflow-y-scroll shadow-[0_4px_12px_0_rgba(0,0,0,0.12)]',
]);

const divider = cva('h-px shrink-0 border-0 bg-gray-200');

MultiSelect.Option = MultiSelectOption;
MultiSelect.MultiOption = MultiSelectMultiOption;
