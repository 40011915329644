import { type FormulaeSpreadsheetTypes } from '@sweep/spreadsheet/types';
import * as SpreadsheetUtils from '@sweep/spreadsheet/utils';
import { parseSpreadsheet } from 'src/network/spreadsheet';
import ErrorReporter from 'src/third-parties/ErrorReporter';

interface ReadExcelOptions {
  password?: string | null;
  includeRawData?: boolean;
  targetSheetName?: string;
  tagUploadSource?: string;
}

interface Sheet {
  name: string;
  data: FormulaeSpreadsheetTypes.SheetData;
}

const EMPTY_SHEET_DATA: FormulaeSpreadsheetTypes.SheetData = [];
const ERROR_TAG = 'READ_EXCEL:V3';

const findTargetSheet = (sheets: Sheet[], targetSheetName?: string) => {
  const defaultSheetName = sheets?.[0]?.name;
  const sheetNameToFind = targetSheetName ?? defaultSheetName;

  return sheets.find((sheet: Sheet) => sheet.name === sheetNameToFind);
};

const convertSheetData = (
  sheetData: FormulaeSpreadsheetTypes.SheetData | undefined
): string[][] => {
  return SpreadsheetUtils.convertFormulaeTo2DArrayWithTrim(
    sheetData ?? EMPTY_SHEET_DATA
  );
};

const addBreadcrumb = (
  message: string,
  extra: {
    filename: string;
    options: ReadExcelOptions;
  }
) => {
  ErrorReporter.addBreadcrumb({
    message,
    extra: {
      errorTag: ERROR_TAG,
      filename: extra?.filename,
      options: extra?.options,
    },
  });
};

export const readExcelV3 = async (
  file: File,
  options?: ReadExcelOptions
): Promise<string[][] | null> => {
  try {
    const {
      password,
      includeRawData = false,
      tagUploadSource,
      targetSheetName,
    } = options ?? {};

    const payload = {
      file,
      password,
      includeRawData,
      tagUploadSource,
    };

    const result = await parseSpreadsheet(payload);

    if (!result.success) {
      addBreadcrumb('failed to parse using v3', {
        filename: file?.name,
        options: options ?? {},
      });
      return null;
    }

    const targetSheet = findTargetSheet(result.data.sheets, targetSheetName);

    return convertSheetData(targetSheet?.data);
  } catch {
    addBreadcrumb('failed to parse using v3', {
      filename: file?.name,
      options: options ?? {},
    });

    return null;
  }
};
