import { getFileExtension } from 'services/file/getFileExtension';
import { getFilename } from 'services/file/getFilename';
import type { FormulaeSpreadsheetTypes } from '@sweep/spreadsheet/types';
import * as SpreadsheetUtils from '@sweep/spreadsheet/utils';
import { readExcelOnServer } from 'src/network/file';
import { parseSpreadsheet } from 'src/network/spreadsheet';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import { MultiSheetExcelFile } from '../../interface';
import { isProtected } from '../read/isProtected';
import { promptForPassword } from '../read/readProtectedExcel';
import { toExcelJson } from '../read/toExcelJson';

export async function readExcelV2MultiSheet(
  file: File,
  options?: {
    includeRawData?: boolean;
  }
): Promise<MultiSheetExcelFile | null> {
  const originalFilename = file.name;
  const includeRawData = options?.includeRawData === true;

  try {
    const filename = getFilename(file);
    const extension = getFileExtension(file) ?? '';
    const isProtectedFile = await isProtected(file);

    const onSuccess = createOnSuccess({
      filename,
      extension,
    });
    const onError = createOnError(originalFilename);

    if (isProtectedFile) {
      return await handleProtectedSpreadsheetFile({
        file,
        filename: originalFilename,
        includeRawData,
        onSuccess,
        onError: onError('READ_EXCEL:V2:PROTECTED'),
      });
    } else {
      const parsedUnprotectedData = await handleSpreadsheetFile({
        file,
        includeRawData,
        onError: onError('READ_EXCEL:V2:UNPROTECTED'),
      });

      if (parsedUnprotectedData != null) {
        return onSuccess(parsedUnprotectedData);
      }
    }

    onError('READ_EXCEL:V2:FAILED')(new Error('failed to parse using v2'));
  } catch (error) {
    const extra = {
      filename: originalFilename,
      errorTag: 'READ_EXCEL:V2:UNKNOWN',
    };

    ErrorReporter.captureError(error, {
      extra,
    });
  }

  return null;
}

const createOnSuccess =
  (params: { filename: string; extension: string }) =>
  (data: FormulaeSpreadsheetTypes.IntermediateData): MultiSheetExcelFile => {
    const { filename, extension } = params;

    const sheets = data.sheets;
    const convertedData = sheets.map(({ data }) =>
      SpreadsheetUtils.convertFormulaeTo2DArrayWithTrim(data)
    );

    return {
      data: convertedData,
      name: filename,
      extension,
      templateFile: data.templateFile,
    };
  };

const createOnError =
  (filename: string) =>
  (errorTag: string) =>
  (error: unknown): void => {
    ErrorReporter.captureError(error, {
      extra: { filename, errorTag },
    });
  };

async function handleProtectedSpreadsheetFile(params: {
  file: File;
  filename: string;
  includeRawData: boolean;
  onSuccess: (
    data: FormulaeSpreadsheetTypes.IntermediateData
  ) => MultiSheetExcelFile;
  onError: (error: unknown) => void;
}): Promise<MultiSheetExcelFile | null> {
  const { file, filename, onSuccess, onError } = params;

  const MAX_RETRY_COUNT = 3;
  let retryCount = 0;

  while (retryCount < MAX_RETRY_COUNT) {
    const password = promptForPassword({
      filename: filename,
      messageType: 'FIRST_ATTEMPT',
    });

    if (password == null) {
      return null;
    }

    try {
      const result = await parseSpreadsheet({
        file,
        password,
      });

      if (result?.success === true && result.data != null) {
        return onSuccess(result.data);
      }

      ErrorReporter.addBreadcrumb({
        message: 'READ_EXCEL:V2:PROTECTED:FAILED',
        extra: {
          filename,
          errorTag: 'READ_EXCEL:V2:PROTECTED:FAILED',
        },
      });
    } catch (error) {
      onError(error);
    }

    try {
      const result = await readExcelOnServer(file, password);
      if (result.success) {
        return {
          data: [toExcelJson(result.data) ?? []],
          name: filename,
          extension: getFileExtension(file) ?? '',
        };
      }
    } catch (error) {
      onError(error);
    }

    retryCount++;
  }

  return null;
}

async function handleSpreadsheetFile(params: {
  file: File;
  includeRawData: boolean;
  onError: (error: unknown) => void;
}): Promise<FormulaeSpreadsheetTypes.IntermediateData | null> {
  const { file, includeRawData, onError } = params;

  try {
    const result = await parseSpreadsheet({
      file,
      includeRawData,
    });

    if (result?.success === true && result.data != null) {
      return result.data;
    }
  } catch (error) {
    onError(error);
  }

  return null;
}
