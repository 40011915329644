import { IconSuccess } from '@sweep/asset/icons';

export function CompleteTitleSection() {
  return (
    <div className="gap-16px flex flex-col items-center">
      <IconSuccess />
      <p className="font-pretendard text-[32px] font-bold leading-[38px] text-gray-700">
        주소 변환이 완료되었습니다.
      </p>
      <p className="text-medium-l text-gray-500">
        구체적인 변환 사유는 엑셀 파일 내 '범례' 시트를 참고해주세요.
      </p>
    </div>
  );
}
